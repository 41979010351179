import Repository from "../../api/RepositoryFactory";
const StoreTiming = Repository.get("StoreTiming");
const { updateStoreTiming, getStoreTime } = StoreTiming;
import Toast, { successBackground } from "../../config/toastConfig";

const state = {};
const getters = {};

const mutations = {};

const actions = {
  async updateStoreTiming({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await updateStoreTiming(data);

      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        let userData = localStorage.getItem("majhiUser");
        let user = JSON.parse(userData);
        user.store_timing = response.data;

        localStorage.setItem("majhiUser", JSON.stringify(user));

        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async getStoreTimeData({ commit }) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getStoreTime();
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      return response?.data?.store_timining;
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log("error", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
