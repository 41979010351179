<template>
  <div
    class="w-100 min-vh-100 d-flex justify-content-center align-items-center signin-page"
  >
    <div class="signin-container">
      <div class="left position-relative">
        <div class="position-absolute top-0 start-0 mt-3 ms-3">
          <a :href="`${majhiLandingBaseUrl}/${getLanguage}`">
            <div class="d-flex align-items-center">
              <img
                src="../../assets/logo/Bangla/majhi_logo_primary_bangla_2.png"
                alt=""
                width="144"
                :class="getLanguage == 'bn' ? 'd-block' : 'd-none'"
              />
              <img
                src="../../assets/logo/English/majhi_logo_primary_english_2.png"
                alt=""
                width="144"
                :class="getLanguage == 'en' ? 'd-block' : 'd-none'"
              />
            </div>
          </a>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <img
            alt=""
            src="https://majhi-space.sgp1.cdn.digitaloceanspaces.com/Static/auth_img.png"
            class="large-image"
          />
        </div>
      </div>

      <div class="right">
        <signin
          v-if="!numberGiven"
          v-on:requestOtp="sendOtp($event)"
          @showOtpForFixedNumber="showOtpForFixedNumber()"
        />
        <otp
          v-else-if="numberGiven"
          :phoneNumber="phoneNumber"
          v-on:requestVerifyOtp="confirmOtp($event)"
          v-on:requestResendOtp="hadnleResendOtpRequest($event)"
        />
      </div>
    </div>
    <loading-spinner />
  </div>
</template>

<script>
import "mosha-vue-toastify/dist/style.css"
import { mapMutations, mapGetters, mapActions } from "vuex"

import LoadingSpinner from "../../components/LoadingSpinner.vue"
import Signin from "../../components/auth/Signin.vue"
import Otp from "../../components/auth/Otp.vue"

export default {
  components: { Signin, Otp, LoadingSpinner },

  data() {
    return {
      appVerifier: "",
      phoneNumber: "",
    }
  },

  computed: {
    ...mapGetters("auth", ["getauthOtpError", "numberGiven"]),
    ...mapGetters("language", ["getLanguage"]),

    majhiLandingBaseUrl() {
      return process.env.VUE_APP_FRONTED_BASE_URL
    },
  },

  methods: {
    ...mapMutations("auth", ["SET_AUTH_OTP_ERROR", "SET_NUMBER_STATUS"]),
    ...mapActions("auth", ["otpRequest", "resendOtpRequest", "signInRequest"]),

    async confirmOtp(payload) {
      this.phoneNumber = payload.phoneNumber
      let data = {
        phone: payload.phoneNumber,
        user_type: 1,
        otp: payload.otp,
      }

      await this.signInRequest(data)
    },

    async hadnleResendOtpRequest(phoneNo) {
      let data = {
        phone: phoneNo,
        api_key: "U8cPDR8#",
      }
      await this.resendOtpRequest(data)
    },

    showOtpForFixedNumber() {
      this.phoneNumber = "01572018053"
      this.SET_NUMBER_STATUS(true)
    },

    async sendOtp(phoneNo) {
      if (phoneNo.length != 11) {
        console.log("invalid phone number")
      } else {
        this.phoneNumber = phoneNo
        // this.SET_NUMBER_STATUS(true)
        let data = {
          phone: phoneNo,
          api_key: "U8cPDR8#",
        }
        await this.otpRequest(data)
      }
    },
  },
  created() {
    this.SET_NUMBER_STATUS(false)
  },
}
</script>

<style scoped>
.signin-page {
  background-color: #f3f6f9;
}

.signin-container {
  width: 60%;
  min-height: 80%;
  box-shadow: 0px 3px 12px #1d1d1d0d;
  display: flex;
  border-radius: 10px;
}

.left {
  width: 50%;
}

.left .large-image {
  width: 100%;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media only screen and (max-width: 900px) {
  .left,
  .right {
    width: 100%;
  }

  .signin-container {
    flex-direction: column;
  }

  .left .large-image {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .signin-page {
    background-color: white;
  }
  .signin-container {
    width: 100%;
    height: 100vh;
  }

  .signin-container {
    box-shadow: none;
  }

  .left {
    background-color: #f3f6f9;
  }

  .right {
    margin-top: -100px;
    z-index: 10;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
}
</style>
