import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getSocialSupportData() {
    //let language = store.getters["language/getLanguage"];
    return Client({
      url: `/social_support_list`,
      method: "GET",
    });
  },

  updateSocialSupportData(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/social_support_update/${language}`,
      method: "PUT",
      data,
    });
  },
};
