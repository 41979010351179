import Repository from "../../api/RepositoryFactory";
import Toast, { successBackground } from "../../config/toastConfig";
const productCategories = Repository.get("productCategories");
const {
  createCategory,
  instantCategoryCreate,
  getSellerProductCategory,
  updateStatus,
  deleteCategory,
  getAllProductCategoryUnderBusinessCategory,
} = productCategories;

const state = {
  allProductCategoriesUnderBusinessCategory: {},
  filteredProductCategories: [],
  allProductCategories: null,
  searchField: "",
};

const getters = {
  getAllProductCategoriesUnderBusinessCategory: (state) =>
    state.allProductCategoriesUnderBusinessCategory,

  filteredProductCategories: (state) => state.filteredProductCategories,
  getSearchField: (state) => state.searchField,
};

const mutations = {
  setAllProductCategoriesUnderBusinessCategory: (state, data) => {
    state.allProductCategoriesUnderBusinessCategory = data;
  },

  SET_CATEGORY_DATA: (state, data) => {
    state.filteredProductCategories = data;
    state.allProductCategories = data;
  },

  REMOVE_PRODUCT_CATEGORY: (state, id) => {
    state.filteredProductCategories = state.filteredProductCategories.filter(
      (category) => category.id_product_category !== id
    );
  },

  FILTER_CATEGORY(state, searchField) {
    state.searchField = searchField;

    if (state.filteredProductCategories == null) return;

    state.filteredProductCategories = state.filteredProductCategories.filter(
      (category) => {
        const regex = new RegExp(`${searchField}`, `gi`);
        return category.product_category_name.match(regex);
      }
    );

    if (!searchField) {
      state.filteredProductCategories = state.allProductCategories;
    }
  },

  SET_SEARCHFIELD(state, searchField) {
    state.searchField = searchField;
  },
};

const actions = {
  async getAllCategoryUnderBusinessCategory({ commit }, payload) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getAllProductCategoryUnderBusinessCategory(
        payload.idBusinessCategory
      );
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success && response.data) {
        commit("setAllProductCategoriesUnderBusinessCategory", response.data);
        commit("SET_CATEGORY_DATA", response.data.product_assign_categories);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async categoryCreateRequest({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await createCategory(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      console.log(response.data);
      if (response.success && response.data) {
        if (response.data.is_new_category) {
          commit(
            "product/APPEND_NEW_CATEGORY",
            {
              id: response.data.id_product_category,
              text: response.data.product_category_name,
              status: 1,
            },
            { root: true }
          );
        }

        return {
          id_product_category: response.data.id_product_category,
          product_category_name: response.data.product_category_name,
          id: response.data.id_product_category,
          text: response.data.product_category_name,
          status: 1,
        };
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async instantCategoryCreate({ commit }, payload) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await instantCategoryCreate(payload);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success && response.data) {
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
        return true;
      }
      return false;
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
      return false;
    }
  },

  async getSellerProductCategory({ commit }) {
    try {
      let userObject = localStorage.getItem("majhiUser");
      let authUser = JSON.parse(userObject);
      let businessCategoryId = authUser.business_category_id;

      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getSellerProductCategory(businessCategoryId);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success && response.data) {
        commit("SET_CATEGORY_DATA", response.data.product_category);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async updateCategoryStatus({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await updateStatus(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success && response.data) {
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async requestDeleteCategory({ commit }, category_id) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await deleteCategory(category_id);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success && response.data) {
        commit("REMOVE_PRODUCT_CATEGORY", category_id);
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
