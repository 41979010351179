<template>
  <div class="p-3">
    <h5>{{ $t("product.main.product_attribute.attribute_list") }}</h5>
    <div v-for="attribute in allAttributes" :key="attribute.id_attribute_value">
      <div
        v-if="attribute.global_attribute_name.toLowerCase() == 'color'"
        class="attribute-container"
      >
        <div class="attribute-name px-2 py-1">
          <p class="state-color__fg--grey1 medium-text m-0">Color</p>
        </div>
        <div class="attribute-value">
          <div
            v-for="attributeVal in attribute.seller_attribute_value"
            :key="attributeVal.name"
            class="d-flex justify-content-between my-3 px-2"
          >
            <div class="d-flex align-items-center">
              <div
                class="color-code-box border rounded me-2"
                v-bind:style="{ 'background-color': attributeVal.name }"
              ></div>
              <p class="m-0">{{ colorCodeToName(attributeVal.name) }}</p>
            </div>
            <img
              src="../../../assets/icon/cross1.svg"
              alt="cross"
              class="make-cursor"
              @click="
                deleteAttributeValue(attributeVal.id_attribute_value, attribute)
              "
            />
          </div>
        </div>
      </div>

      <div v-else class="attribute-container">
        <div class="attribute-name px-2 py-1">
          <p class="state-color__fg--grey1 medium-text text-capitalize m-0">
            {{ attribute.global_attribute_name }}
          </p>
        </div>

        <div class="attribute-value">
          <div
            v-for="attributeVal in attribute.seller_attribute_value"
            :key="attributeVal.name"
            class="d-flex justify-content-between my-3 px-2"
          >
            <p class="m-0">{{ attributeVal.name }}</p>
            <img
              src="../../../assets/icon/cross1.svg"
              alt="cross"
              class="make-cursor"
              @click="
                deleteAttributeValue(attributeVal.id_attribute_value, attribute)
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  props: ["allAttributes"],

  methods: {
    ...mapActions("Loader", ["setOverlayStatus"]),

    deleteAttributeValue(idGlobalAttribute, attribute) {
      Swal.fire({
        title: this.$t("product.main.delete_confirmation_message.title"),
        text: this.$t("product.main.delete_confirmation_message.attribute"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "product.main.delete_confirmation_message.delete_btn"
        ),
        cancelButtonText: this.$t(
          "product.main.delete_confirmation_message.cancel_btn"
        ),
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.setOverlayStatus(true);
          this.$emit("requestToDeleteAttributeValue", {
            id_attribute_value: idGlobalAttribute,
            id_product_category: attribute.id_product_category,
          });
        }
      });
    },

    colorCodeToName(code) {
      const ntc = require("ntcjs");

      const n_match = ntc.name(code);
      // let n_rgb = n_match[0]; // RGB value of closest match
      // let n_name = n_match[1]; // Text string: Color name
      // let n_exactmatch = n_match[2]; // True if exact color match
      return n_match[1];
    },
  },
};
</script>

<style>
.show-attribute-field-container .attribute-container .attribute-name {
  background-color: #f2f4f7;
}

.show-attribute-field-container .attribute-container .color-code-box {
  width: 16px;
  height: 18px;
  border-radius: 5px;
}

.attribute-container .attribute-value {
  max-height: 220px;
  min-height: 100px;
  overflow-y: auto;
}
</style>
