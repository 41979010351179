<template>
  <div
    class="w-100 min-vh-100 d-flex justify-content-center align-items-center signin-page"
  >
    <div class="store-time-container">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <img
          src="../../assets/primary/store-time.png"
          alt=""
          class="container_image"
        />
        <h5 class="py-3 title">
          {{ $t("manage.main.store_time.set_store_time") }}
        </h5>
      </div>

      <div class="all-categories__container border overflow" style="">
        <table>
          <thead class="table-header" style="height:50px">
            <tr class="state-color__fg--grey1" style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.options.o1") }}
              </td>
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.options.o2") }}
              </td>
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.options.o3") }}
              </td>
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.options.o4") }}
              </td>
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.options.o6") }}
              </td>

              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.options.o5") }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.days.d1") }}
              </td>

              <td
                :class="{
                  disable: !saturdayData.flag,
                  closeTd: !saturdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="saturdayData.is24Open"
                  class="btn border rounded py-2 px-4 mb-0"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="openingUpdate(saturdayData)"
                >
                  {{ saturdayData.opening_hours }}
                </button>
              </td>

              <td
                :class="{
                  disable: !saturdayData.flag,
                  mydibtn: !saturdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="saturdayData.is24Open"
                  :class="[
                    'btn border rounded py-2 px-4 mb-0',
                    { closeBtn: !saturdayData.flag },
                  ]"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="closingUpdate(saturdayData)"
                >
                  <span :class="{ level: !saturdayData.flag }">
                    {{ saturdayData.closing_hours }}
                  </span>

                  <span :class="{ textClass: !saturdayData.flag }"> </span>
                </button>
              </td>

              <td
                :class="{ disable: !saturdayData.flag }"
                class="google_sans_medium"
              >
                <!-- {{totalTime(saturdayData)}} hours -->

                {{ calculate(saturdayData) }}
                {{ $t("manage.main.store_time.hours") }}
              </td>

              <td>
                <Toggle
                  v-model="saturdayData.is24Open"
                  @click="store24Event(saturdayData)"
                />
              </td>

              <td>
                <Toggle
                  v-model="saturdayData.flag"
                  @change="storeOnOffEvent($event)"
                />
                <span
                  v-bind:class="[
                    'status',
                    { open: saturdayData.flag },
                    { close: !saturdayData.flag },
                  ]"
                >
                  {{
                    saturdayData.flag
                      ? $t("manage.main.store_time.open")
                      : $t("manage.main.store_time.close")
                  }}
                </span>
              </td>
            </tr>

            <tr style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.days.d2") }}
              </td>
              <td
                :class="{
                  disable: !sundayData.flag,
                  closeTd: !sundayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="sundayData.is24Open"
                  class="btn border rounded py-2 px-4 mb-0"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="openingUpdate(sundayData)"
                >
                  {{ sundayData.opening_hours }}
                </button>
              </td>
              <td
                :class="{
                  disable: !sundayData.flag,
                  mydibtn: !sundayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="sundayData.is24Open"
                  :class="[
                    'btn border rounded py-2 px-4 mb-0',
                    { closeBtn: !sundayData.flag },
                  ]"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="closingUpdate(sundayData)"
                >
                  <span :class="{ level: !sundayData.flag }">
                    {{ sundayData.closing_hours }}
                  </span>

                  <span :class="{ textClass: !sundayData.flag }"> </span>
                </button>
              </td>
              <td
                :class="{ disable: !sundayData.flag }"
                class="google_sans_medium"
              >
                {{ calculate(sundayData) }}
                {{ $t("manage.main.store_time.hours") }}
              </td>

              <td>
                <Toggle
                  v-model="sundayData.is24Open"
                  @click="store24Event(sundayData)"
                />
              </td>

              <td>
                <Toggle
                  v-model="sundayData.flag"
                  @change="storeOnOffEvent($event)"
                />
                <span
                  v-bind:class="[
                    'status',
                    { open: sundayData.flag },
                    { close: !sundayData.flag },
                  ]"
                >
                  {{
                    sundayData.flag
                      ? $t("manage.main.store_time.open")
                      : $t("manage.main.store_time.close")
                  }}
                </span>
              </td>
            </tr>

            <tr style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.days.d3") }}
              </td>
              <td
                :class="{
                  disable: !mondayData.flag,
                  closeTd: !mondayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="mondayData.is24Open"
                  class="btn border rounded py-2 px-4 mb-0"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="openingUpdate(mondayData)"
                >
                  {{ mondayData.opening_hours }}
                </button>
              </td>
              <td
                :class="{
                  disable: !mondayData.flag,
                  mydibtn: !mondayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="mondayData.is24Open"
                  :class="[
                    'btn border rounded py-2 px-4 mb-0',
                    { closeBtn: !mondayData.flag },
                  ]"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="closingUpdate(mondayData)"
                >
                  <span :class="{ level: !mondayData.flag }">
                    {{ mondayData.closing_hours }}
                  </span>

                  <span :class="{ textClass: !mondayData.flag }"> </span>
                </button>
              </td>
              <td
                :class="{ disable: !mondayData.flag }"
                class="google_sans_medium"
              >
                {{ calculate(mondayData) }}
                {{ $t("manage.main.store_time.hours") }}
              </td>

              <td>
                <Toggle
                  v-model="mondayData.is24Open"
                  @click="store24Event(mondayData)"
                />
              </td>

              <td>
                <Toggle
                  v-model="mondayData.flag"
                  @change="storeOnOffEvent($event)"
                />

                <span
                  v-bind:class="[
                    'status',
                    { open: mondayData.flag },
                    { close: !mondayData.flag },
                  ]"
                >
                  {{
                    mondayData.flag
                      ? $t("manage.main.store_time.open")
                      : $t("manage.main.store_time.close")
                  }}
                </span>
              </td>
            </tr>

            <tr style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.days.d4") }}
              </td>
              <td
                :class="{
                  disable: !tuesdayData.flag,
                  closeTd: !tuesdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="tuesdayData.is24Open"
                  class="btn border rounded py-2 px-4 mb-0"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="openingUpdate(tuesdayData)"
                >
                  {{ tuesdayData.opening_hours }}
                </button>
              </td>
              <td
                :class="{
                  disable: !tuesdayData.flag,
                  mydibtn: !tuesdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="tuesdayData.is24Open"
                  :class="[
                    'btn border rounded py-2 px-4 mb-0',
                    { closeBtn: !tuesdayData.flag },
                  ]"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="closingUpdate(tuesdayData)"
                >
                  <!-- {{ tuesdayData.closing_hours }} -->

                  <span :class="{ level: !tuesdayData.flag }">
                    {{ tuesdayData.closing_hours }}
                  </span>

                  <span :class="{ textClass: !tuesdayData.flag }"> </span>
                </button>
              </td>
              <td
                :class="{ disable: !tuesdayData.flag }"
                class="google_sans_medium"
              >
                <!-- {{totalTime(tuesdayData)}} hours -->
                {{ calculate(tuesdayData) }}
                {{ $t("manage.main.store_time.hours") }}
              </td>

              <td>
                <Toggle
                  v-model="tuesdayData.is24Open"
                  @click="store24Event(tuesdayData)"
                />
              </td>

              <td>
                <Toggle
                  v-model="tuesdayData.flag"
                  @change="storeOnOffEvent($event)"
                />

                <span
                  v-bind:class="[
                    'status',
                    { open: tuesdayData.flag },
                    { close: !tuesdayData.flag },
                  ]"
                >
                  {{
                    tuesdayData.flag
                      ? $t("manage.main.store_time.open")
                      : $t("manage.main.store_time.close")
                  }}
                </span>
              </td>
            </tr>

            <tr style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.days.d5") }}
              </td>
              <td
                :class="{
                  disable: !wednesdayData.flag,
                  closeTd: !wednesdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="wednesdayData.is24Open"
                  class="btn border rounded py-2 px-4 mb-0"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="openingUpdate(wednesdayData)"
                >
                  {{ wednesdayData.opening_hours }}
                </button>
              </td>
              <td
                :class="{
                  disable: !wednesdayData.flag,
                  mydibtn: !wednesdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="wednesdayData.is24Open"
                  :class="[
                    'btn border rounded py-2 px-4 mb-0',
                    { closeBtn: !wednesdayData.flag },
                  ]"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="closingUpdate(wednesdayData)"
                >
                  <!-- {{ wednesdayData.closing_hours }} -->

                  <span :class="{ level: !wednesdayData.flag }">
                    {{ wednesdayData.closing_hours }}
                  </span>

                  <span :class="{ textClass: !wednesdayData.flag }"> </span>
                </button>
              </td>
              <td
                :class="{ disable: !wednesdayData.flag }"
                class="google_sans_medium"
              >
                <!-- {{totalTime(wednesdayData)}} hours -->
                {{ calculate(wednesdayData) }}
                {{ $t("manage.main.store_time.hours") }}
              </td>

              <td>
                <Toggle
                  v-model="wednesdayData.is24Open"
                  @click="store24Event(wednesdayData)"
                />
              </td>

              <td>
                <Toggle
                  v-model="wednesdayData.flag"
                  @change="storeOnOffEvent($event)"
                />
                <span
                  v-bind:class="[
                    'status',
                    { open: wednesdayData.flag },
                    { close: !wednesdayData.flag },
                  ]"
                >
                  {{
                    wednesdayData.flag
                      ? $t("manage.main.store_time.open")
                      : $t("manage.main.store_time.close")
                  }}
                </span>
              </td>
            </tr>

            <tr style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.days.d6") }}
              </td>
              <td
                :class="{
                  disable: !thursdayData.flag,
                  closeTd: !thursdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="thursdayData.is24Open"
                  class="btn border rounded py-2 px-4 mb-0"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="openingUpdate(thursdayData)"
                >
                  {{ thursdayData.opening_hours }}
                </button>
              </td>
              <td
                :class="{
                  disable: !thursdayData.flag,
                  mydibtn: !thursdayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="thursdayData.is24Open"
                  :class="[
                    'btn border rounded py-2 px-4 mb-0',
                    { closeBtn: !thursdayData.flag },
                  ]"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="closingUpdate(thursdayData)"
                >
                  <span :class="{ level: !thursdayData.flag }">
                    {{ thursdayData.closing_hours }}
                  </span>

                  <span :class="{ textClass: !thursdayData.flag }"> </span>
                </button>
              </td>
              <td
                :class="{ disable: !thursdayData.flag }"
                class="google_sans_medium"
              >
                <!-- {{totalTime(thursdayData)}} hours -->
                {{ calculate(thursdayData) }}
                {{ $t("manage.main.store_time.hours") }}
              </td>

              <td>
                <Toggle
                  v-model="thursdayData.is24Open"
                  @click="store24Event(thursdayData)"
                />
              </td>

              <td>
                <Toggle
                  v-model="thursdayData.flag"
                  @change="storeOnOffEvent($event)"
                />
                <span
                  v-bind:class="[
                    'status',
                    { open: thursdayData.flag },
                    { close: !thursdayData.flag },
                  ]"
                >
                  {{
                    thursdayData.flag
                      ? $t("manage.main.store_time.open")
                      : $t("manage.main.store_time.close")
                  }}
                </span>
              </td>
            </tr>

            <tr style="border: none">
              <td class="google_sans_medium">
                {{ $t("manage.main.store_time.days.d7") }}
              </td>
              <td
                :class="{
                  disable: !fridayData.flag,
                  closeTd: !fridayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="fridayData.is24Open"
                  class="btn border rounded py-2 px-4 mb-0"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="openingUpdate(fridayData)"
                >
                  {{ fridayData.opening_hours }}
                </button>
              </td>
              <td
                :class="{
                  disable: !fridayData.flag,
                  mydibtn: !fridayData.flag,
                }"
              >
                <button
                  type="button"
                  :disabled="fridayData.is24Open"
                  :class="[
                    'btn border rounded py-2 px-4 mb-0',
                    { closeBtn: !fridayData.flag },
                  ]"
                  id="instorePickupMOdalBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#settingInstorePickupModal"
                  @click="closingUpdate(fridayData)"
                >
                  <span :class="{ level: !fridayData.flag }">
                    {{ fridayData.closing_hours }}
                  </span>
                  <span :class="{ textClass: !fridayData.flag }"> </span>
                </button>
              </td>

              <td
                :class="{ disable: !fridayData.flag }"
                class="google_sans_medium"
              >
                {{ calculate(fridayData) }}
                {{ $t("manage.main.store_time.hours") }}
              </td>

              <td>
                <Toggle
                  v-model="fridayData.is24Open"
                  @click="store24Event(fridayData)"
                />
              </td>

              <td>
                <Toggle
                  v-model="fridayData.flag"
                  @change="storeOnOffEvent($event)"
                />
                <span
                  v-bind:class="[
                    'status',
                    { open: fridayData.flag },
                    { close: !fridayData.flag },
                  ]"
                >
                  {{
                    fridayData.flag
                      ? $t("manage.main.store_time.open")
                      : $t("manage.main.store_time.close")
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div
        class="store-timing-container d-flex justify-content-center mx-auto my-3"
      >
        <button
          @click="UpdateStoreTime"
          class="btn btn-primary px-5 py-3 confirm-btn"
        >
          Confirm
        </button>
      </div> -->

      <div class="w-100 d-flex justify-content-center py-4">
        <AppBaseBlueBtn @click="UpdateStoreTime"
          ><template v-slot:btn_name>{{
            $t("manage.main.store_time.confirm")
          }}</template>
        </AppBaseBlueBtn>
      </div>
    </div>
    <loading-spinner />
  </div>

  <!--  Modal -->

  <time-modal
    :data="selectedData"
    @UpdatedData="receive"
    ref="passSelectedData"
  />
</template>

<script>
import "mosha-vue-toastify/dist/style.css";
import { mapGetters, mapActions } from "vuex";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import Toggle from "@vueform/toggle";
import TimeModal from "../../components/manage/timeModal.vue";

import moment from "moment";
import AppBaseBlueBtn from "./AppBaseBlueBtn.vue";

export default {
  components: {
    LoadingSpinner,
    Toggle,
    TimeModal,
    AppBaseBlueBtn,
  },

  data() {
    return {
      // storeOnOffFlag: true,
      saturdayData: {
        day: "saturday",
        flag: false,
        opening_hours: "01:00 AM",
        closing_hours: "02:00 AM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      sundayData: {
        day: "sunday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "10:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      mondayData: {
        day: "monday",
        flag: false,
        opening_hours: "09:00 AM",
        closing_hours: "02:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      tuesdayData: {
        day: "tuesday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "02:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      wednesdayData: {
        day: "wednesday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "03:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      thursdayData: {
        day: "thursday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "12:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      fridayData: {
        day: "friday",
        flag: false,
        opening_hours: "12:00 AM",
        closing_hours: "12:00 AM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },

      selectedData: {},

      updatedData: {},

      default_hour: "12:00 AM",
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
  async created() {
    let timing = await this.getStoreTimeData();
    if (timing) {
      this.renderResponseData(timing);
    }

    window.addEventListener("resize", this.myEventHandler);
  },

  methods: {
    ...mapActions("storeTime", ["updateStoreTiming", "getStoreTimeData"]),

    storeOnOffEvent(status) {
      if (!status) return;

      const levels = document.querySelectorAll(".level");
      let closeText = document.querySelectorAll(".textClass");

      const nodeList = document.querySelectorAll(".mydibtn");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].setAttribute("colspan", 0);
        closeText[i].innerHTML = "";
        levels[i].style.display = "block";
      }
    },

    store24Event(data) {
      if (!data.is24Open) return;
      switch (data.day) {
        case "saturday":
          this.saturdayData.opening_hours = this.default_hour;
          this.saturdayData.closing_hours = this.default_hour;
          break;
        case "sunday":
          this.sundayData.opening_hours = this.default_hour;
          this.sundayData.closing_hours = this.default_hour;
          break;

        case "monday":
          this.mondayData.opening_hours = this.default_hour;
          this.mondayData.closing_hours = this.default_hour;
          break;

        case "tuesday":
          this.tuesdayData.opening_hours = this.default_hour;
          this.tuesdayData.closing_hours = this.default_hour;
          break;

        case "wednesday":
          this.wednesdayData.opening_hours = this.default_hour;
          this.wednesdayData.closing_hours = this.default_hour;
          break;

        case "thursday":
          this.thursdayData.opening_hours = this.default_hour;
          this.thursdayData.closing_hours = this.default_hour;
          break;

        case "friday":
          this.fridayData.opening_hours = this.default_hour;
          this.fridayData.closing_hours = this.default_hour;
          break;

        default:
          break;
      }
    },
    openingUpdate(data) {
      this.selectedData = data;
      this.selectedData.type = "open";
      this.$refs.passSelectedData.openData(data);
    },

    closingUpdate(data) {
      this.selectedData = data;
      this.selectedData.type = "close";
      this.$refs.passSelectedData.closeData(data);
    },

    calculate(data) {
      var startTime = moment(data.opening_hours, "hh:mm a");
      var endTime = moment(data.closing_hours, "hh:mm a");
      if (endTime.diff(startTime, "hours") < 0) {
        return 12 + endTime.diff(startTime, "hours") * -1;
      } else if (endTime.diff(startTime, "hours") > 24) {
        return endTime.diff(startTime, "hours") - 24;
      }
      if (endTime.diff(startTime, "hours") === 0) {
        let opening_pre = data.opening_hours.split(" ")[1];
        let closing_pre = data.closing_hours.split(" ")[1];
        if (opening_pre !== closing_pre) {
          return endTime.diff(startTime, "hours");
        }

        return endTime.diff(startTime, "hours") + 24;
      }

      return endTime.diff(startTime, "hours");
    },

    myEventHandler(e) {
      if (e.currentTarget.outerWidth) {
        const levels = document.querySelectorAll(".level");
        let closeText = document.querySelectorAll(".textClass");

        if (e.currentTarget.outerWidth <= 1200) {
          const nodeList = document.querySelectorAll(".mydibtn");
          for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].setAttribute("colspan", 2);
            closeText[i].innerHTML = "close";
            levels[i].style.display = "none";
          }
        } else {
          const nodeList = document.querySelectorAll(".mydibtn");
          for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].setAttribute("colspan", 0);
            closeText[i].innerHTML = "";
            levels[i].style.display = "block";
          }
        }
      }
    },

    renderResponseData(store_timing) {
      if (store_timing) {
        if (store_timing.length === 7) {
          this.saturdayData = store_timing[6];
          this.saturdayData.flag = Boolean(Number(store_timing[6].is_closed));
          this.saturdayData.is24Open = Boolean(
            Number(store_timing[6].always_open)
          );

          this.sundayData = store_timing[0];
          this.sundayData.flag = Boolean(Number(store_timing[0].is_closed));
          this.sundayData.is24Open = Boolean(
            Number(store_timing[0].always_open)
          );

          this.mondayData = store_timing[1];
          this.mondayData.flag = Boolean(Number(store_timing[1].is_closed));
          this.mondayData.is24Open = Boolean(
            Number(store_timing[1].always_open)
          );

          this.tuesdayData = store_timing[2];
          this.tuesdayData.flag = Boolean(Number(store_timing[2].is_closed));
          this.tuesdayData.is24Open = Boolean(
            Number(store_timing[2].always_open)
          );

          this.wednesdayData = store_timing[3];
          this.wednesdayData.flag = Boolean(Number(store_timing[3].is_closed));
          this.wednesdayData.is24Open = Boolean(
            Number(store_timing[3].always_open)
          );

          this.thursdayData = store_timing[4];
          this.thursdayData.flag = Boolean(Number(store_timing[4].is_closed));
          this.thursdayData.is24Open = Boolean(
            Number(store_timing[4].always_open)
          );

          this.fridayData = store_timing[5];
          this.fridayData.flag = Boolean(Number(store_timing[5].is_closed));
          this.fridayData.is24Open = Boolean(
            Number(store_timing[5].always_open)
          );
        }
      } else {
        //console.log("store timing not found!");
      }
    },

    // set the store time ------------------------------------------
    receive(childData) {
      if (childData.day == "saturday") {
        childData.type === "close"
          ? (this.saturdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.saturdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "sunday") {
        childData.type === "close"
          ? (this.sundayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.sundayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "monday") {
        childData.type === "close"
          ? (this.mondayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.mondayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "tuesday") {
        childData.type === "close"
          ? (this.tuesdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.tuesdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "wednesday") {
        childData.type === "close"
          ? (this.wednesdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.wednesdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "thursday") {
        childData.type === "close"
          ? (this.thursdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.thursdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "friday") {
        childData.type === "close"
          ? (this.fridayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.fridayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      }
    },

    // update store time--------------------------------
    async UpdateStoreTime() {
      let data = {
        time: [
          {
            closing_hours: this.saturdayData.is24Open
              ? this.default_hour
              : this.saturdayData.closing_hours,
            day: this.saturdayData.day,
            is_closed: this.saturdayData.flag ? 1 : 0,
            always_open: this.saturdayData.is24Open ? 1 : 0,
            opening_hours: this.saturdayData.is24Open
              ? this.default_hour
              : this.saturdayData.opening_hours,
          },
          {
            closing_hours: this.sundayData.is24Open
              ? this.default_hour
              : this.sundayData.closing_hours,
            day: this.sundayData.day,
            is_closed: this.sundayData.flag ? 1 : 0,
            always_open: this.sundayData.is24Open ? 1 : 0,
            opening_hours: this.sundayData.is24Open
              ? this.default_hour
              : this.sundayData.opening_hours,
          },

          {
            closing_hours: this.mondayData.is24Open
              ? this.default_hour
              : this.mondayData.closing_hours,
            day: this.mondayData.day,
            is_closed: this.mondayData.flag ? 1 : 0,

            always_open: this.mondayData.is24Open ? 1 : 0,

            opening_hours: this.mondayData.is24Open
              ? this.default_hour
              : this.mondayData.opening_hours,
          },

          {
            closing_hours: this.tuesdayData.is24Open
              ? this.default_hour
              : this.tuesdayData.closing_hours,
            day: this.tuesdayData.day,
            is_closed: this.tuesdayData.flag ? 1 : 0,
            always_open: this.tuesdayData.is24Open ? 1 : 0,

            opening_hours: this.tuesdayData.is24Open
              ? this.default_hour
              : this.tuesdayData.opening_hours,
          },

          {
            closing_hours: this.wednesdayData.is24Open
              ? this.default_hour
              : this.wednesdayData.closing_hours,
            day: this.wednesdayData.day,
            is_closed: this.wednesdayData.flag ? 1 : 0,
            always_open: this.wednesdayData.is24Open ? 1 : 0,
            opening_hours: this.wednesdayData.is24Open
              ? this.default_hour
              : this.wednesdayData.opening_hours,
          },

          {
            closing_hours: this.thursdayData.is24Open
              ? this.default_hour
              : this.thursdayData.closing_hours,
            day: this.thursdayData.day,
            is_closed: this.thursdayData.flag ? 1 : 0,
            always_open: this.thursdayData.is24Open ? 1 : 0,
            opening_hours: this.thursdayData.is24Open
              ? this.default_hour
              : this.thursdayData.opening_hours,
          },

          {
            closing_hours: this.fridayData.is24Open
              ? this.default_hour
              : this.fridayData.closing_hours,
            day: this.fridayData.day,
            is_closed: this.fridayData.flag ? 1 : 0,
            always_open: this.fridayData.is24Open ? 1 : 0,
            opening_hours: this.fridayData.is24Open
              ? this.default_hour
              : this.fridayData.opening_hours,
          },
        ],
      };

      let stringifyData = JSON.stringify(data);
      let formData = new URLSearchParams();
      formData.append("store", stringifyData);

      await this.updateStoreTiming(formData);

      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.confirm-btn {
  width: 400px;
}

.title {
  font: normal normal bold 32px/46px Google Sans;
}

.signin-page {
  background: #f3f6f9;
}

.store-time-container {
  width: 60%;
  min-height: 80%;
  box-shadow: 0px 3px 12px #1d1d1d0d;
  /* display: flex; */
  border-radius: 10px;
  background-color: white !important;
  padding: 50px 0;
}

.container_image {
  width: 25%;
}

.left {
  width: 50%;
}

.left .large-image {
  width: 100%;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-header {
  background: #f4f4f4;
  padding: 8px !important;
}

.border {
  border: 1px solid black;
  border-radius: 8px 8px 0px 0px;
  width: 80%;
  margin-left: 10%;
}

.overflow {
  overflow-y: auto;
  height: 350px;
}

@media only screen and (max-width: 900px) {
  .confirm-btn {
    width: 200px;
  }
  .left,
  .right {
    width: 100%;
  }

  .store-time-container {
    flex-direction: column;
  }

  .left .large-image {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .confirm-btn {
    width: 200px;
  }

  .signin-page {
    background-color: white;
  }
  .store-time-container {
    width: 100%;
    height: 100vh;
  }

  .store-time-container {
    box-shadow: none;
  }

  .left {
    background-color: #f3f6f9;
  }

  .right {
    margin-top: -100px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
}
</style>
