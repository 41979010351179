<template>
  <div :class="`position-fixed end-0 ${$style.container}`">
    <div
      :class="`text-white position-relative make-cursor ${$style.content}`"
      @click="$emit('onClick')"
    >
      <p :class="`mb-0 ${$style.text}`">Complete your Store setup</p>
      <div :class="`position-absolute ${$style.circle}`">
        {{ remainingStepsCount }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { remainingStepsCount: { type: Number, default: 0 } },
};
</script>

<style module>
.container {
  top: 90%;
}
.content {
  background-color: #262626;
  margin: 0 2.7rem;
  border-radius: 10px;
}

.text {
  padding: 1.25rem 2.5rem;
}

.circle {
  background-color: #d84242;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -0.9rem;
  right: -0.9rem;
}
</style>

<script></script>
