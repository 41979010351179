<template>
  <div
    class="container d-flex justify-content-center
    align-items-center vh-100
"
  >
    <div class="d-flex flex-column align-items-center">
      <img src="../assets/icon/no-internet.svg" alt="" />
      <h3>{{ $t("notFound.Internet.title") }}</h3>
      <p>
        {{ $t("notFound.Internet.description") }}
      </p>

      <div>
        <button
          class="btn btn-primary google_sans_medium py-3 px-4"
          @click="refreshPage"
        >
          {{ $t("notFound.Internet.btn_text") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (window.navigator.onLine) {
      this.$router.go(-1);
    }
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
  },
};
</script>
