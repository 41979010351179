import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getExistingBanner() {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/banners_list/${language}`,
      method: "GET",
    });
  },

  uploadBanner(data) {
    let language = store.getters["language/getLanguage"];

    return Client({
      url: `/banner_create/${language}`,
      method: "POST",
      data,
    });
  },

  deleteExisting(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/remove_banner/${language}`,
      method: "POST",
      data,
    });
  },
};
