import Repository from "../../api/RepositoryFactory";
const SocialMediaSupport = Repository.get("SocialMediaSupport");
import Toast, { successBackground } from "../../config/toastConfig";
const { getSocialSupportData, updateSocialSupportData } = SocialMediaSupport;

const state = {
  socialSupport: {
    facebook_url: "facebook.com/",
    instagram_url: "Instagram.com/",
    youtube_url: "YouTube.com/",
    linkedin_url: "",
    support_email: "",
    support_phone: "",
    whatsapp_phone: "",
    delivery_duration: "",
  },
  isUpdateBtnDisabled: false,
};
const getters = {
  socialSupport: (state) => state.socialSupport,
  getIsUpdateBtnDisabled: (state) => state.isUpdateBtnDisabled,
};

const mutations = {
  SET_SOCIAL_MEDIA_SUPPORT_DATA(state, data) {
    state.socialSupport = data;
    if (data.support_phone) {
      state.socialSupport.support_phone = `0${data.support_phone}`;
    }
    if (data.whatsapp_phone) {
      state.socialSupport.whatsapp_phone = `0${data.whatsapp_phone}`;
    }
  },

  SET_UPDATE_BTN_VISIBILITY(state, flag) {
    state.isUpdateBtnDisabled = flag;
  },
};

const actions = {
  async getSocialSupportData({ commit }) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getSocialSupportData();
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success) {
        commit("SET_SOCIAL_MEDIA_SUPPORT_DATA", response.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async updateSocialSupport({ commit }, data) {
    data.support_phone = data.support_phone && data.support_phone.toString();
    data.whatsapp_phone = data.whatsapp_phone && data.whatsapp_phone.toString();

    if (data.support_phone && data.support_phone[0] == !0) {
      data.support_phone = `0${data.support_phone}`;
    }

    if (data.whatsapp_phone && data.whatsapp_phone[0] == !0) {
      data.whatsapp_phone = `0${data.whatsapp_phone}`;
    }

    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await updateSocialSupportData(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success && response.data) {
        commit("SET_UPDATE_BTN_VISIBILITY", true);
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
