<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0" style="display: flex; flex-direction: column">
        <top-bar />
        <div class="setting-container app_container">
          <div class="m-3 d-flex page-heading-top-padding">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Settings',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="ms-3 my-0">{{ $t("setting.main.profile.title") }}</h4>
          </div>

          <div
            class="row m-0 d-flex justify-content-center min-vh-80"
            style="background-color: #f3f6f9"
          >
            <profile-update
              v-bind:profileEmailError="profileEmailError"
              v-on:requestProfileUpdate="updateProfile($event)"
            />
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import Toast, {
  errorBackground,
  successBackground,
} from "../../config/toastConfig";
import { mapGetters } from "vuex";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import ProfileUpdate from "../../components/settings/ProfileUpdate.vue";

import axios from "axios";

// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
  components: { SideBar, TopBar, ProfileUpdate, LoadingSpinner },
  data: function() {
    return {
      errorPhone: "",
      profileEmailError: "",
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    async updateProfile(formData) {
      let vm = this;

      await axios
        .post(`/update_seller_profile/${this.getLanguage}`, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("majhiAccessToken"),
          },
        })
        .then(function(response) {
          // store mail to user data
          let userObject = localStorage.getItem("majhiUser");
          let authUser = JSON.parse(userObject);
          authUser.email = response.data.data.user[0].email;
          authUser.shop_address = response.data.data.user[0].shop_address;
          authUser.store_logo = response.data.data.user[0].store_logo_path;
          authUser.shop_name = response.data.data.user[0].shop_name;
          authUser.phone = response.data.data.user[0].phone;

          authUser.id_country = response.data.data.user[0].id_country;
          authUser.country_name = response.data.data.user[0].country_name;
          authUser.id_division = response.data.data.user[0].id_division;
          authUser.division_name = response.data.data.user[0].division_name;
          authUser.id_city = response.data.data.user[0].id_city;
          authUser.division_name = response.data.data.user[0].city_name;
          authUser.id_area = response.data.data.user[0].id_area;
          authUser.area_name = response.data.data.user[0].area_name;
          authUser.lat = response.data.data.user[0].lat;
          authUser.long = response.data.data.user[0].lon;

          localStorage.setItem("majhiUser", JSON.stringify(authUser));

          vm.profileEmailError = "";
          vm.$store.commit("setProfileUpdatePhoneEroor", "");

          vm.$store.commit("auth/SET_SHOP_LOGO_URL", authUser.store_logo);
          vm.$store.commit("Loader/SET_LOADER_VISIBILITY", false); //loader open

          Toast.fire({
            icon: "success",
            title: response.data.message,
            background: successBackground,
          });
        })
        .catch(function(error) {
          if (error.response.data.data.phone) {
            vm.$store.commit(
              "setProfileUpdatePhoneEroor",
              error.response.data.data.phone[0]
            );
            vm.profileEmailError = "";

            Toast.fire({
              icon: "error",
              title: vm.$t("manage.validation.profile.update_failed"),
              background: errorBackground,
            });
          } else if (error.response.data.data.email) {
            vm.$store.commit("setProfileUpdatePhoneEroor", "");
            vm.profileEmailError = error.response.data.data.email[0];

            Toast.fire({
              icon: "error",
              title: vm.$t("manage.validation.profile.update_failed"),
              background: errorBackground,
            });
          } else if (error.response.data.data.shop_logo) {
            vm.$store.commit("setProfileUpdatePhoneEroor", "");

            Toast.fire({
              icon: "error",
              title: error.response.data.data.shop_logo[0],
              background: errorBackground,
            });
          } else {
            vm.$store.commit("setProfileUpdatePhoneEroor", "");
            vm.profileEmailError = "";

            Toast.fire({
              icon: "error",
              title: vm.$t("manage.validation.profile.update_failed"),
              background: errorBackground,
            });
          }

          vm.$store.commit("Loader/SET_LOADER_VISIBILITY", false); //loader open
        });
    },
  },
};
</script>

<style scoped>
.setting-container {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #f3f6f9;
}
</style>
