<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container" style="min-width: 450px">
          <div class="m-3 d-flex">
            <!-- <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="$router.go(-1)"
            /> -->
            <h4 class="ms-3 my-0">{{ $t("setting.main.title") }}</h4>
          </div>

          <div
            class="bg-white common-border__radius coupon-create_container my-4 mx-auto"
          >
            <div
              class="d-flex justify-content-between border-bottom py-4 px-4 my-2"
            >
              <router-link
                :to="{
                  name: 'EditProfile',
                  params: { languageCode: getLanguage },
                }"
                class="d-flex fg-black text-decoration-none text-dark justify-content-center align-items-center"
              >
                <div class="d-flex justify-content-center">
                  <img
                    v-if="authUser.store_logo"
                    :src="authUser.store_logo"
                    alt=""
                    width="100"
                    height="100"
                    class="store-logo"
                  />
                  <div
                    v-else
                    class="shop-logo-container mx-auto d-flex align-items-center justify-content-center flex-column"
                  >
                    <img
                      src="../../assets/icon/demo-shop.svg"
                      alt=""
                      width="50"
                    />
                  </div>

                  <div class="d-flex flex-column justify-content-center ms-3">
                    <h5>{{ authUser.shop_name }}</h5>
                    <p class="mb-0">+88 {{ authUser.phone }}</p>
                  </div>
                </div>
              </router-link>
              <router-link
                :to="{
                  name: 'EditProfile',
                  params: { languageCode: getLanguage },
                }"
                class="d-flex justify-content-center align-items-center"
              >
                <img
                  src="../../assets/icon/back_btn_image.svg"
                  alt="back"
                  style="transform: rotate(180deg)"
                />
              </router-link>
            </div>

            <div class="px-4 py-3">
              <div
                class="d-flex"
                :class="
                  authUser.apk_download_link == null
                    ? 'text-muted'
                    : 'make-cursor'
                "
                @click="redirectToApkDownloadPage()"
              >
                <img
                  src="../../assets/settings/disabled_apk.png"
                  width="26"
                  alt=""
                  v-if="authUser.apk_download_link == null"
                />
                <img
                  src="../../assets/settings/setting-app.svg"
                  alt=""
                  v-else
                />
                <p class="my-0 ms-3">{{ $t("setting.main.options.o1") }}</p>
              </div>
            </div>

            <div
              class="px-4 py-3 d-flex justify-content-between settings-option"
            >
              <div class="d-flex">
                <img src="../../assets/settings/setting-whatsapp.svg" alt="" />
                <p class="my-0 ms-3">{{ $t("setting.main.options.o2") }}</p>
              </div>
              <div class="form-check form-switch settings-switch">
                <Toggle
                  v-model="whatsappFlag"
                  @click="editWhatsAppChatSupport()"
                />
              </div>
            </div>

            <div
              class="px-4 py-3 d-flex justify-content-between settings-option"
            >
              <div class="d-flex">
                <img src="../../assets/settings/setting-instore.svg" alt="" />
                <p class="my-0 ms-3">{{ $t("setting.main.options.o3") }}</p>
              </div>
              <div class="form-check form-switch settings-switch">
                <Toggle
                  v-model="instoreFlag"
                  @click="editInsttorePickupFlag()"
                />
              </div>
            </div>

            <div class="px-4 py-3" @click="chatWithAdmin">
              <div class="d-flex">
                <img src="../../assets/settings/setting-help.svg" alt="" />

                <div
                  class="fg-black text-decoration-none my-0 ms-3 make-cursor"
                >
                  {{ $t("setting.main.options.o4") }}
                </div>
              </div>
            </div>

            <div class="px-4 py-3">
              <div class="d-flex">
                <img src="../../assets/settings/setting-tutorial.svg" alt="" />
                <a
                  href="https://www.youtube.com/watch?v=ntpCHvKYXK8"
                  target="_blank"
                  class="fg-black text-decoration-none my-0 ms-3"
                  >{{ $t("setting.main.options.o5") }}</a
                >
              </div>
            </div>

            <div class="px-4 py-3">
              <div class="d-flex">
                <img src="../../assets/settings/setting-terms.svg" alt="" />
                <a
                  target="_blank"
                  :href="
                    `${baseUrl}/${getLanguage}/terms-condition?user=seller`
                  "
                  class="fg-black text-decoration-none my-0 ms-3"
                  >{{ $t("setting.main.options.o6") }}</a
                >
              </div>
            </div>

            <div class="px-4 py-3">
              <div class="d-flex">
                <img
                  src="../../assets/settings/setting-lockscreen.svg"
                  alt=""
                />
                <a
                  target="_blank"
                  :href="`${baseUrl}/${getLanguage}/privacy-policy`"
                  class="fg-black text-decoration-none my-0 ms-3"
                  >{{ $t("setting.main.options.o7") }}</a
                >
              </div>
            </div>

            <div class="px-4 py-3" role="button" @click="signOut">
              <div class="d-flex">
                <img src="../../assets/settings/setting-logout.svg" alt="" />
                <p class="my-0 ms-3">{{ $t("setting.main.options.o8") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
    <store-address-modal @close="instoreFlag = false" />
    <whats-app-number-modal @close="whatsappFlag = false" />
    <button
      id="whatsappChatModalBtn"
      data-bs-toggle="modal"
      data-bs-target="#addWhatsAppNumberModal"
      class="d-none"
    ></button>

    <button
      id="instorePickupModal"
      data-bs-toggle="modal"
      data-bs-target="#settingInstorePickupModal"
      class="d-none"
    ></button>
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import WhatsAppNumberModal from "./whatsAppNumberModal.vue";
import StoreAddressModal from "../../components/settings/storeAddressModal.vue";

//import axios from "axios";
import Toggle from "@vueform/toggle";
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import { mapActions, mapGetters } from "vuex";
import "mosha-vue-toastify/dist/style.css";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    Toggle,
    StoreAddressModal,
    WhatsAppNumberModal,
  },
  setup() {
    const toast = (message, type) => {
      createToast(message, {
        type: type,
        transition: "bounce",
      });
    };
    return { toast };
  },

  data: function() {
    return {
      whatsappFlag: false,
      instoreFlag: false,
      authUser: Object,
      adminPhone: "01322908231",
      baseUrl: "",
    };
  },

  computed: {
    ...mapGetters("settings", ["getWhatsappSupportStatus"]),
    ...mapGetters("instorePickup", ["inStorePickupStatus"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  watch: {
    getWhatsappSupportStatus(newValue, oldValue) {
      console.log(oldValue);
      if (newValue === 1) {
        this.whatsappFlag = true;
      } else if (newValue === 2) {
        this.OpenPhoneModal();
      }
    },

    inStorePickupStatus(newValue) {
      if (newValue == 1) {
        this.instoreFlag = true;
      }
    },
  },

  methods: {
    ...mapActions("auth", ["signOutRequest"]),
    ...mapActions("Loader", ["setOverlayStatus"]),
    ...mapActions("settings", ["updateWhatsAppChatSupport"]),
    ...mapActions("instorePickup", ["updateInstorePickupStatus"]),

    // instoreFlagChange() {
    //   if (this.instoreFlag) {
    //     $("#instorePickupMOdalBtn").trigger("click");
    //   }
    // },

    OpenPhoneModal() {
      $("#whatsappChatModalBtn").trigger("click");
    },

    async editWhatsAppChatSupport() {
      this.updateWhatsAppChatSupport({ status: this.whatsappFlag });
    },

    async editInsttorePickupFlag() {
      await this.updateInstorePickupStatus({ status: this.instoreFlag });
    },

    redirectToApkDownloadPage() {
      if (this.authUser?.apk_download_link == null) return;
      window.location.href = this.authUser.apk_download_link;
    },

    extractUser() {
      const userObject = localStorage.getItem("majhiUser");
      this.authUser = JSON.parse(userObject);
      this.whatsappFlag = this.authUser.whatsapp_chat;

      if (this.authUser.instore_pickup === 1) {
        this.instoreFlag = true;
      } else if (this.authUser.instore_pickup === 0) {
        this.instoreFlag = false;
      }
    },

    async signOut() {
      await this.signOutRequest();
    },

    chatWithAdmin() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.location.replace(
          `https://api.whatsapp.com/send?phone=${this.adminPhone}`
        );
        setTimeout(() => {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=com.whatsapp"
          );
        }, 700);
        // setTimeout(fallbackToStore, 700);
        // upper 700ms is problematic. not work perfectly.
        // (50-700) ms is ok.
      } else {
        //window.open("https://web.whatsapp.com/", "");
        window.open(`https://api.whatsapp.com/send?phone=${this.adminPhone}`);
      }
    },
  },

  created() {
    this.extractUser();
    this.baseUrl = process.env.VUE_APP_FRONTED_BASE_URL;
  },
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style>
/* .settings-option .form-check-input:checked{
    background-color: #34C759;
    border-color: #34C759;
}

.settings-option .form-check-input{
    background-color: #D80027;
    border-color: #D80027;
} */

:root {
  --toggle-width: 2rem;
  --toggle-height: 1rem;
  --toggle-bg-on: #34c759;
  --toggle-border-on: #34c759;

  --toggle-bg-off: red;
  --toggle-border-off: red;
}

.toggle-handle {
  height: 15px;
  width: 15px;
}

.shop-logo-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #e0e0e0;
}

.store-logo {
  border-radius: 50%;
}
</style>
