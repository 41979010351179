import { createI18n } from "vue-i18n";
import { en, bn } from "./languageConfig";

// let selectedLanguage = localStorage.getItem("selectedLanguage")
//   ? localStorage.getItem("selectedLanguage")
//   : "en";

const i18n = createI18n({
  locale: "bn",
  fallbackFormat: "en",
  allowComposition: true, // you need to specify that!
  messages: { en, bn },
});

export default i18n;
