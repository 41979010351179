import Repository from "../../api/RepositoryFactory";
const Notification = Repository.get("Notification");
import Toast, { successBackground } from "../../config/toastConfig";

const {
  getAllNotification,
  markAllNotificationRed,
  markNotificationRed,
  deleteNotification,
} = Notification;

export default {
  namespaced: true,
  state: {
    notifications: [],
    unReadNotification: 0,
    modalStatus: false,
    lastPage: 0,
  },

  getters: {
    allNotification: (state) => state.notifications,

    getUnReadNotification: (state) => state.unReadNotification,

    getModalStatus: (state) => state.modalStatus,

    lastPage: (state) => state.lastPage,
  },

  mutations: {
    changeModalStatus(state, data) {
      state.modalStatus = data;
    },

    addNotification(state, data) {
      state.notifications = data;
    },

    catchPushNotification(state, data) {
      state.notifications = [data, ...state.notifications];
      state.unReadNotification += 1;
    },

    deleteNotification(state, id) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id_notification !== id
      );
    },

    setUnreadNotification(state, data) {
      state.unReadNotification = data;
    },

    visitedNotification(state, id) {
      state.notifications = state.notifications.map((notification) =>
        notification.id_notification === id
          ? { ...notification, read_at: new Date() }
          : notification
      );
    },

    markAllRead(state) {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        read_at: new Date(),
      }));

      state.unReadNotification = 0;
    },

    setLastPage(state, data) {
      state.lastPage = data;
    },
  },

  actions: {
    async getAllNotification({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await getAllNotification(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success && response.data) {
          commit("addNotification", response.data.webNotificationList);
          commit(
            "setUnreadNotification",
            response.data.totalUnreadNotifications
          );

          commit("setLastPage", response.data.last_page);
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async markAllNotificationRed({ commit }, sellerKey) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await markAllNotificationRed(sellerKey);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          commit("markAllRead");
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log({ error });
      }
    },

    async markNotificationRed({ commit }, id) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await markNotificationRed(id);
        if (response.success && response.data) {
          commit(
            "setUnreadNotification",
            response.data.totalUnreadNotifications
          );
          commit("visitedNotification", id);
        }
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log({ error });
      }
    },

    async deleteNotificationRequest({ commit }, id) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await deleteNotification(id);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        if (response.success && response.data) {
          commit("deleteNotification", id);
          commit(
            "setUnreadNotification",
            response.data.totalUnreadNotifications
          );

          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log({ error });
      }
    },
  },
};
