<template>
  <form @submit.prevent="handleSubmit()" class="mb-3 profile_update_form_width">
    <div class="p-2 common-border__radius">
      <div class="bg-white mb-3 py-3 px-4 radius-10">
        <div
          class="
            mx-auto
            d-flex
            align-items-center
            justify-content-center
            flex-column
            mt-5
          "
        >
          <img
            v-if="url"
            class="rounded-circle"
            :src="url"
            alt=""
            width="90"
            height="90"
          />
        </div>

        <div class="d-flex align-items-center justify-content-center mx-2">
          <label
            class="custom-file-upload state-color__fg--Info google_sans_medium"
          >
            <input
              type="file"
              accept="image/*"
              id="file-input"
              @change="uploadImage($event)"
            />
            {{ $t("setting.main.profile.change_logo") }}
          </label>
        </div>

        <div
          class="border-bottom d-flex align-items-center justify-content-center"
          style="height: 60px !important; border-bottom-color:#EBEBEB"
        >
          <p v-if="errorShopLogo != ''" class="state-color__fg--error mt-2">
            {{ errorShopLogo }}
          </p>
        </div>

        <div class="form-group row mr-2 py-3 mt-5">
          <label
            for="inputBusinessName"
            class="col-sm-4 col-form-label google_sans_medium"
          >
            {{ $t("setting.main.profile.bus_name") }}
            <span class="text-primary">*</span></label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control google_sans_medium"
              id="inputBusinessName"
              placeholder="Business Name"
              v-model="shop_name"
              readonly
            />
          </div>
        </div>

        <div class="form-group row mr-2 py-3">
          <label
            for="inputstoreLink"
            class="col-sm-4 col-form-label google_sans_medium"
          >
            {{ $t("setting.main.profile.store_link") }}
            <span class="text-primary">*</span></label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control google_sans_medium"
              id="inputstoreLink"
              placeholder="Store Link"
              v-model="shopUrl"
              readonly
            />
          </div>
        </div>

        <div class="form-group row mr-2 py-3">
          <label
            for="inputstoreLink"
            class="col-sm-4 col-form-label google_sans_medium"
          >
            {{ $t("setting.main.profile.bus_category") }}
            <span class="text-primary">*</span></label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control google_sans_medium"
              id="inputBusinessCategoryName"
              placeholder="Business Category Name"
              v-model="businessCategoryName"
              readonly
            />
          </div>
        </div>

        <div class="form-group row mr-2 pt-3">
          <label
            for="inputPhoneNo"
            class="col-sm-4 col-form-label google_sans_medium"
          >
            {{ $t("setting.main.profile.phone") }}
            <span class="text-primary">*</span></label
          >
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control google_sans_medium"
              id="inputPhoneNo"
              placeholder="Phone No"
              v-model.trim="phone"
              readonly
            />
            <small
              type="button"
              class="text-primary float-end pt-1"
              data-bs-toggle="modal"
              data-bs-target="#addPhoneNumberModal"
              @click="setOtpContactNumber()"
            >
              {{ $t("setting.main.profile.change_phone") }}
            </small>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <p class="col-sm-4"></p>
            <p
              class="col-sm-8 state-color__fg--error"
              v-if="$store.state.profileUpdatePhoneEroor != ''"
            >
              {{ $store.state.profileUpdatePhoneEroor }}
            </p>
          </div>
        </div>

        <div class="form-group row mr-2 py-3">
          <label
            for="inputEmail"
            class="col-sm-4 col-form-label google_sans_medium"
          >
            {{ $t("setting.main.profile.email") }}
          </label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control google_sans_medium"
              id="inputEmail"
              placeholder="E-mail"
              v-model.trim="email"
            />
          </div>
          <div class="d-flex justify-content-end mt-2">
            <p class="col-sm-4"></p>
            <p class="col-sm-8 state-color__fg--error">
              {{ profileEmailError }}
            </p>
          </div>
        </div>
      </div>

      <div
        role="button"
        class="my-3 py-3 float-start deleteStoreBtn  google_sans_medium"
        @click="deleteStoreRequest"
        data-bs-toggle="modal"
        data-bs-target="#deleteAccountConformationModal"
      >
        <img
          src="../../assets/icon/delete.svg"
          alt="delete icon"
          width="20"
          height="18"
        />&nbsp;&nbsp; {{ $t("setting.main.profile.delete") }}
      </div>
      <button
        type="submit"
        class="btn btn-primary button_padding float-end google_sans_medium mt-3 radius-8"
      >
        {{ $t("setting.main.profile.save") }}
      </button>
    </div>
  </form>

  <button
    type="button"
    id="otpModalBtn"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#otpModal"
  >
    OTP
  </button>

  <!-- modal -->
  <otp-modal
    :isProfile="true"
    :phoneNumber="temporaryContactNumber"
    ref="otpModalComponent"
    @resetPhoneNumber="setNewContactNumber($event)"
    refModal="#addPhoneNumberModal"
  />
  <otp-contact-number-modal
    :phoneNumber="phone"
    @requestToSendOtp="sendOtp($event)"
    ref="fixContactNumberOtpModal"
  />

  <google-map-modal />
  <ProfileDeleteConfirmation />

  <crop-image-modal
    :ImageUrl="originalImageUrl"
    :image="blobImage"
    @croppedData="croppedProfileImage"
    parent="profile"
    :ratio="100 / 100"
  />
  <button
    id="imageCrop"
    data-bs-toggle="modal"
    data-bs-target="#addCropImageModal"
    class="d-none"
  ></button>
</template>

<script>
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import OtpModal from "./otpModal.vue";
import OtpContactNumberModal from "./otpContactNumberModal.vue";
import GoogleMapModal from "./googleMapModal.vue";
import ProfileDeleteConfirmation from "./ProfileDeleteConfirmation.vue";
import cropImageModal from "../product/cropImageModal.vue";
import Toast, {
  successBackground,
  errorBackground,
} from "../../config/toastConfig";

export default {
  name: "ProfileUpdate",
  props: ["profileEmailError"],
  emits: ["requestProfileUpdate"],
  components: {
    OtpModal,
    OtpContactNumberModal,
    GoogleMapModal,
    ProfileDeleteConfirmation,
    cropImageModal,
  },

  data: function() {
    return {
      shop_name: "",
      image: null,
      url: null,
      phone: "",
      email: "",
      shopFrontUrl: "",
      businessCategoryName: "",

      errorShopLogo: "",
      temporaryContactNumber: "",

      originalImageUrl: "",
      blobImage: "",
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),

    shopUrl() {
      if (this.getLanguage == "bn") {
        return this.shopFrontUrl.replace("/en/", "/bn/");
      } else return this.shopFrontUrl;
    },
  },

  methods: {
    ...mapActions("profileUpdate", [
      "countryListRequest",
      "divisionByCountryRequest",
      "cityByDivisionRequest",
      "areaByCityRequest",
      "getUserDetailsInfo",
    ]),

    ...mapActions("auth", ["storeDeleteRequest", "otpRequest"]),
    ...mapActions("loader", ["setOverlayStatus"]),
    ...mapActions("storeTime", ["updateStoreTiming", "getStoreTimeData"]),

    showTimeData(data) {
      if (data.is_closed === 0) {
        return "Close";
      } else if (data.always_open === 1) {
        return "24 hours";
      } else {
        return `${data.opening_hours} - ${data.closing_hours}`;
      }
    },

    uploadImage(event) {
      if (!event.target.files[0]) return;
      this.image = event.target.files[0];

      if (this.image.size > 1024 * 1021 * 2) {
        this.errorShopLogo = this.$t("setting.main.profile.image_size");
        this.image = null;
        return;
      }

      this.errorShopLogo = "";
      //this.url = URL.createObjectURL(this.image);

      this.originalImageUrl = URL.createObjectURL(event.target.files[0]);
      const blob = URL.createObjectURL(event.target.files[0]);
      this.blobImage = {
        src: blob,
        type: event.target.files[0].type,
      };
      $("#imageCrop").trigger("click");
    },

    croppedProfileImage(data) {
      this.url = data.url;
      this.image = data.image;
    },

    removeImage() {
      this.image = null;
      this.url = null;
    },

    async handleSubmit() {
      var email_pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let vm = this;
      vm.$store.commit("setProfileUpdatePhoneEroor", "");

      if (this.phone?.length != 11) {
        vm.$store.commit(
          "setProfileUpdatePhoneEroor",
          "Phone number must be 11 digit long"
        );
        return;
      } else if (!this.phone.match(/^01[0-9]+$/)) {
        vm.$store.commit(
          "setProfileUpdatePhoneEroor",
          "Phone number must contain only valid digits starts with 01"
        );

        return;
      } else if (
        this.email?.length &&
        !this.email.toLowerCase().match(email_pattern)
      ) {
        Toast.fire({
          icon: "error",
          title:
            this.$t("auth.signUp.email_invalid") + " 'example@domain.com' ",
          background: errorBackground,
        });

        return;
      }
      vm.$store.commit("setProfileUpdatePhoneEroor", "");
      vm.$store.commit("Loader/SET_LOADER_VISIBILITY", true); //loader open

      const formData = new FormData();

      if (vm.email != null) {
        formData.append("email", vm.email);
      }

      formData.append("lat", vm.lat);
      formData.append("lon", vm.long);
      if (vm.image != null) {
        formData.append("shop_logo", vm.image);
      }
      this.$emit("requestProfileUpdate", formData);
    },

    setOtpContactNumber() {
      this.$refs.fixContactNumberOtpModal.setContactNumber();
    },

    sendOtp(phoneNo) {
      let data = {
        phone: phoneNo,
        api_key: "U8cPDR8#",
      };
      this.temporaryContactNumber = phoneNo;

      this.otpRequest(data);
      this.$refs.otpModalComponent.startTimer();
      $("#otpModalBtn").trigger("click");
    },

    setNewContactNumber(phoneNo) {
      this.phone = phoneNo;
      Toast.fire({
        icon: "success",
        title: "Your contact number updated",
        background: successBackground,
      });
    },

    // de-active user store -----------------------------
    async deleteStore() {
      await this.storeDeleteRequest();
    },

    deleteStoreRequest() {},

    getArea({ text }) {
      this.areaName = text;
    },

    extractUserData(authUser) {
      this.shop_name = authUser.shop_name;
      this.url = authUser.store_logo_path;
      this.phone = authUser.phone;
      this.shopFrontUrl = authUser.shop_front_url;
      this.lat = authUser.lat;
      this.long = authUser.long;
      this.email = authUser.email;
      this.businessCategoryName = authUser.business_category_name;
    },

    // avioded axios base url
    // extract lat and logn based on the address
  },

  async created() {
    //this.$store.commit("setOverlayStatus", true); //loader open

    let userObject = localStorage && localStorage.getItem("majhiUser");
    let currentUser = JSON.parse(userObject);
    let seller_key = currentUser?.seller_key;
    if (seller_key) {
      const response = await this.getUserDetailsInfo(seller_key);
      if (response) {
        console.log("userProfileInfo", response);
        this.extractUserData(response);
      }
    }

    this.$store.commit("setOverlayStatus", false);
  },
};
</script>

<style>
.deleteStoreBtn {
  color: #ee5153;
  font-size: 16px;
}

.company-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ff9300;
}

.select2-container {
  max-width: 100% !important;
}

.select2-container .select2-selection--single {
  height: 40px;
}

.profile-address-set-map {
  border: 1px solid #bdbdbd;
}

.title {
  font-size: 20px/25px;
  font-weight: 600;
}

.radius-8 {
  border-radius: 8px !important;
}
.radius-10 {
  border-radius: 10px !important;
}

.category_btn {
  padding: 0.82rem 2rem !important;
}

.profile_update_form_width {
  width: 50% !important;
}

@media only screen and (max-width: 576px) {
  .profile_update_form_width {
    width: 95% !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .profile_update_form_width {
    width: 95% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .profile_update_form_width {
    width: 95% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .profile_update_form_width {
    width: 80% !important;
  }
}
</style>
