export default {
  namespaced: true,

  state: {
    loaderVisibility: false,
  },

  getters: {
    getOverlayStatus: (state) => state.loaderVisibility,
  },

  mutations: {
    SET_LOADER_VISIBILITY(state, flag) {
      state.loaderVisibility = flag;
    },
  },

  actions: {
    setOverlayStatus({ commit }, value) {
      commit("SET_LOADER_VISIBILITY", value);
    },
  },
};
