import Repository from "../../api/RepositoryFactory";
const customerList = Repository.get("AllCustomer");
const { getAllCustomer, downloadCustomerExcl } = customerList;

const state = {
  CustomerDetails: [],
  page: "",
};

const getters = {
  allCustomerDetails: (state) => state.CustomerDetails,
  getPage: (state) => state.page,
};

const mutations = {
  SET_CUSTOMER_DATA: (state, data) => {
    state.CustomerDetails = data;
    console.log("customer data ", data);
  },
};

const actions = {
  async AllCustomer({ commit }, data) {
    if (!data) {
      data = { page: 1, searchKay: "" };
    }
    const { page, searchKay } = data;
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getAllCustomer(page, searchKay);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success && response.data.customerList) {
        commit("SET_CUSTOMER_DATA", response.data);
      } else {
        commit("SET_CUSTOMER_DATA", []);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },
  async DownloadCustomerList({ commit }) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await downloadCustomerExcl();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "customerList.xlsx");
      document.body.appendChild(link);
      link.click();

      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      const url = window.URL.createObjectURL(new Blob([error]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "customerList.xlsx");
      document.body.appendChild(link);
      link.click();
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
