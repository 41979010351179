<template>
  <div
    class="position-relative language-select-container h-100"
    v-click-outside="hidleLanguageDropdownFlag"
  >
    <div
      class="d-flex justify-content-center align-items-center border common-border__radius-8 make-cursor h-100 padding-5px"
      style="padding-left:10px; padding-right:10px;"
      @click="toggleLanguageDropdownFlag()"
    >
      <div v-if="getLanguage === 'en'">
        <img
          src="../../assets/icon/language-en.svg"
          alt="home"
          width="20"
          height="16"
          class="fs-5 m-auto"
        />
        <span
          class="d-none small-text d-sm-inline ms-1"
          :style="{ color: fgColor }"
          >{{ $t("home.main.topBar.language.english") }}</span
        >
      </div>
      <div v-if="getLanguage === 'bn'">
        <img
          src="../../assets/icon/language-bd.svg"
          alt="home"
          width="20"
          height="16"
          class="fs-5 m-auto"
        />
        <span
          class="d-none small-text d-sm-inline ms-1"
          :style="{ color: fgColor }"
          >{{ $t("home.main.topBar.language.bangla") }}</span
        >
      </div>
    </div>

    <div
      class="language-content bg-white common-border__radius-8"
      v-if="dropdownFlag"
    >
      <div
        class="p-3 d-flex border-bottom"
        @click="changeLanguage('en')"
        @touchend="changeLanguage('en')"
      >
        <img
          src="../../assets/icon/language-en.svg"
          alt="home"
          width="20"
          height="16"
          class="fs-5 m-auto"
        />
        <span class="d-none small-text d-sm-inline make-cursor ms-1">{{
          $t("home.main.topBar.language.english")
        }}</span>
      </div>
      <div
        class="p-3 d-flex justify-content-start"
        @click="changeLanguage('bn')"
        @touchend="changeLanguage('bn')"
      >
        <img
          src="../../assets/icon/language-bd.svg"
          alt="home"
          width="20"
          height="16"
          class="my-auto"
        />
        <span class="d-none small-text d-sm-inline make-cursor ms-1">{{
          $t("home.main.topBar.language.bangla")
        }}</span>
      </div>

      <!-- border-bottom
      <div class="p-3 d-flex">
        <img
          src="../../assets/icon/language-bd.svg"
          alt="home"
          width="20"
          height="16"
          class="fs-5 m-auto"
        />
        <span class="d-none small-text d-sm-inline ms-1">Bangla</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import { mapMutations, mapGetters } from "vuex";

export default {
  props: ["fgColor"],
  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      dropdownFlag: false,
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapMutations("language", ["SET_LANGUAGE"]),
    toggleLanguageDropdownFlag() {
      this.dropdownFlag = !this.dropdownFlag;
    },

    hidleLanguageDropdownFlag() {
      this.dropdownFlag = false;
    },

    changeLanguage(data) {
      this.dropdownFlag = false;
      this.language = data;

      this.SET_LANGUAGE(data);
      this.$i18n.locale = data;
      let params = this.$route.params;
      let newParams = {
        ...params,
        languageCode: data,
      };
      this.$router.push({ ...this.$route, params: newParams });
    },
  },
};
</script>

<style scoped>
.padding-5px {
  padding: 6px 8px;
}
</style>
