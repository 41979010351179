<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div
            class="mt-3 d-flex align-items-center state-color__fg--grey page-heading-top-padding"
          >
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="font-weight-500 ms-3 mb-0 page-heading-text-size">
              {{ $t("manage.main.customer.title") }}
            </h4>
          </div>
          <div
            class="d-flex justify-content-between mt-4 mb-3 flex-column flex-md-row"
            style="padding: 0.3rem;"
          >
            <div
              class="input-group  border border-1 bg-white w-50 search-box__container common-border__radius d-flex align-items-center"
            >
              <div
                class="input-group-prepend bg-white p-2 common-border__radius"
                style="padding-left:1rem !important"
              >
                <img
                  src="../../assets/icon/search.svg"
                  alt=""
                  class="make-cursor"
                  @click="getAllCustomer(page)"
                  height="23"
                  width="23"
                />
              </div>
              <input
                type="text"
                class="form-control no-border common-border__radius"
                :placeholder="$t('manage.main.customer.placeholder')"
                aria-label=""
                aria-describedby="basic-addon1"
                v-model.trim="searchField"
                @keypress.enter="getAllCustomer(page)"
              />
            </div>
            <button
              class="btn state-color__bg--Info common-border__radius-5 text-light mt-4 mt-md-0"
              role="button"
              style="max-width:200px; min-height:50px"
              @click="excelExport"
            >
              Export Customer List
            </button>
          </div>

          <div class="bg-white my-4" style="border-radius:10px">
            <customer-table
              v-if="allCustomerDetails.customerList"
              v-bind:customerList="allCustomerDetails.customerList"
              v-bind:currentPage="allCustomerDetails.page"
              v-bind:lastPage="allCustomerDetails.last_page"
              v-on:requestCustomerByPageNumber="getAllCustomer($event)"
            />

            <div v-else class="text-center">
              <img src="../../assets/icon/No-customer.svg" alt="" />
              <h5 class="text-center state-color__fg--grey1 pb-3">
                {{ $t("manage.main.customer.not_found") }}!
              </h5>
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import CustomerTable from "../../components/manage/CustomerTable.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
//import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { SideBar, TopBar, LoadingSpinner, CustomerTable },
  data: function() {
    return {
      //allCustomerDetails: [],
      searchField: "",
      page: 1,
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
    ...mapGetters("CustomerList", ["allCustomerDetails"]),
  },

  methods: {
    ...mapActions("CustomerList", ["AllCustomer", "DownloadCustomerList"]),

    async getAllCustomer(pageNum) {
      this.page = pageNum;
      await this.AllCustomer({ page: this.page, searchKay: this.searchField });
    },

    async excelExport() {
      await this.DownloadCustomerList();
    },
  },

  created() {
    this.AllCustomer();
  },
};
</script>

<style></style>
