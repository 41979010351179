import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getAllCustomer(page, searchKey) {
    let language = store.getters["language/getLanguage"];
    if (searchKey[0] == 0) {
      searchKey = searchKey.slice(1);
    }
    return Client({
      url: `/customer_list/${language}/${page}?search=${searchKey}`,
      method: "GET",
    });
  },

  downloadCustomerExcl() {
    return Client({
      url: `/customer-list-export`,
      method: "POST",
      headers: {
        "content-type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },

      responseType: "blob",
    });
  },
};
