<template>
  <div class="container-fluid">
    <div class="row ">
      <side-bar />
      <div
        class="col min-vh-100 px-0"
        style="background-color: #f3f6f9; display: flex; flex-direction: column; min-width: 25em;"
      >
        <div
          class="px-0 m-0 d-flex justify-content-around flex-column nav-image"
        >
          <div class="sidebar-toggle__btn" style="margin-left:15px">
            <img
              src="../../assets/sidebar/Menu_burger@2x.png"
              width="20"
              class="sidebar-toggle__btn"
              @click="
                $store.commit(
                  'setSidebarVisibility',
                  !this.$store.getters.getSidebarVisibility
                )
              "
            />
          </div>
          <div class="row mx-1 p-0 navbar-row">
            <div class="col-md-6 d-flex">
              <div class="ontop-store__info my-auto app-shop-name">
                <h5
                  class="text-white font-weight-600 google_sans_bold shop-name"
                >
                  {{ authUser.shop_name }}
                </h5>
                <a
                  target="_blank"
                  :href="shopUrl"
                  class="text-white text-decoration-none small-text1  google_sans_medium store-link-font-weight"
                  >{{ shopUrl }}
                  <img
                    style="margin-left:10px"
                    src="../../assets/icon/redirect.svg"
                    alt=""
                    width="14"
                    height="14"
                  />
                </a>
              </div>
            </div>

            <div class="col-md-6 app-profile-padding">
              <div class="d-flex justify-content-end align-items-center">
                <div style="margin-right:8px">
                  <language-dropdown :fgColor="'#ffffff'" />
                </div>
                <div class="notification-container">
                  <div
                    class="h-100 border common-border__radius-8 make-cursor max-height-40px mx-2 p-1"
                    @click="handleNotificationModal()"
                    style="padding-left:5px !important ;padding-right:5px !important"
                  >
                    <img
                      v-if="getUnReadNotification === 0"
                      src="../../assets/icon/notification-white.svg"
                      alt="home"
                      width="20"
                      height="20"
                      class=""
                    />
                    <img
                      v-if="getUnReadNotification !== 0"
                      src="../../assets/icon/notification-active.svg"
                      alt="home"
                      width="20"
                      height="20"
                      class=""
                    />
                  </div>

                  <div
                    v-if="isNotificationModalOpen"
                    class="notification-dropdown"
                  >
                    <NotificationModal />

                    <div v-if="notifications.length" style="padding:15px">
                      <hr />
                      <router-link
                        :to="{
                          name: 'Notification',
                          params: { languageCode: getLanguage },
                        }"
                        class="nav-link px-0 align-middle"
                      >
                        <span class=" ml-2 text-primary">{{
                          $t("home.notification.all")
                        }}</span>
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end option-box">
                  <div style="margin-left:20px">
                    <button
                      class="btn btn-secondary rounded-circle p-0"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        :src="authUser.store_logo"
                        alt=""
                        width="35"
                        height="35"
                        class="rounded-circle my-auto mr-0 pr-0"
                      />
                    </button>
                    <ul
                      class="dropdown-menu p-0"
                      aria-labelledby="dropdownMenuButton1"
                      style="min-width: 250px; border-radius: 10px !important"
                    >
                      <li
                        class="
                      nav-image__profile
                      d-flex
                      align-items-center
                      justify-content-start
                    "
                      >
                        <div
                          class="
                        d-flex
                        align-items-center
                        justify-content-center
                        flex-column
                        rounded-circle
                        ms-3
                      "
                        >
                          <img
                            :src="authUser.store_logo"
                            alt=""
                            width="35"
                            height="35"
                            class="rounded-circle mr-0 pr-0"
                          />
                        </div>
                        <div
                          class="
                        d-flex
                        align-items-center
                        justify-content-center
                        text-white
                        mx-2
                      "
                        >
                          <div>
                            <p class="small-text1 font-weight-500 mb-1">
                              Hello, {{ authUser.shop_name }}
                            </p>
                            <p class="m-0" style="font-size: 12px">
                              {{ authUser.phone }}
                            </p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'EditProfile',
                            params: { languageCode: getLanguage },
                          }"
                          class="nav-link px-0 align-middle"
                        >
                          <img
                            src="../../assets/icon/profile.svg"
                            alt="home"
                            width="15"
                            class="mx-3 mb-1"
                          />
                          <span class="text-dark">{{
                            $t("home.main.topBar.profile.account")
                          }}</span>
                        </router-link>
                      </li>
                      <li>
                        <router-link
                          :to="{
                            name: 'Settings',
                            params: { languageCode: getLanguage },
                          }"
                          class="nav-link px-0 align-middle"
                        >
                          <img
                            src="../../assets/icon/top-setting-setting.svg"
                            alt="home"
                            width="15"
                            class="mx-3 mb-1"
                          />
                          <span class="text-dark">{{
                            $t("home.main.topBar.profile.setting")
                          }}</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-link px-0 align-middle pt-3"
                        style="border-top: 1px solid #ebebeb; cursor: pointer"
                        @click="handleSignOutRequest()"
                      >
                        <img
                          src="../../assets/icon/signout.svg"
                          alt="home"
                          width="15"
                          class="mx-3 mb-1"
                        />
                        <span class="text-dark">{{
                          $t("home.main.topBar.profile.signout")
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- dropdown -->
          <div
            class="row mx-1 app_container navbar-row-two"
            v-show="salesDetails?.added_first_product"
          >
            <div
              class="col-6 col-sm-6 col-md-6 col-lg-8 col-xl-8 col-xxl-8 d-flex align-items-center"
            >
              <h5 class="text-white mb-0" style="font-weight: bold">
                {{ $t("home.main.overview") }}
              </h5>
            </div>
            <div
              class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-lg-end option-box"
            >
              <div class="w-100 d-flex justify-content-end">
                <select
                  class="form-select common-border__radius-8 google_sans_medium  mb-0 py-2 px-3"
                  aria-label=".form-select-lg example"
                  style="max-width: 160px;height:50px"
                  @change="handleDropdownChange($event.target.value)"
                  @click="openCustomDateModal($event)"
                >
                  <option value="7" selected>{{
                    $t("home.main.filter_7")
                  }}</option>
                  <option value="365">{{ $t("home.main.filter_12") }}</option>
                  <option value="custom">{{ $t("home.main.custom") }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-show="salesDetails?.added_first_product">
          <CompletedStoreView
            :salesData="salesData"
            :salesGraphCategories="salesGraphCategories"
            :viewsData="viewsData"
            :viewsGraphCategories="viewsGraphCategories"
            :bestSellingData="bestSellingData"
            :bestSellingCategories="bestSellingCategories"
            :bestProduct="bestProduct"
          />
        </div>
        <div v-show="!salesDetails?.added_first_product && !getOverlayStatus">
          <CompleteStoreSetup
            :isFirstProductAdded="salesDetails?.added_first_product"
          />
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-primary d-none p-3"
      data-bs-toggle="modal"
      data-bs-target="#CustomOverview"
      id="customSearchModal"
    >
      Custom Search
    </button>

    <div v-show="storeCompletedStatus == 0">
      <CompleteStoreModal
        :remainingStepsCount="salesDetails?.summary_count_remaining"
        @onClick="storeCompletedStatus = 2"
      />
    </div>
    <div v-show="storeCompletedStatus == 2">
      <CompleteStoreStepModal @onModalClose="storeCompletedStatus = 0" />
    </div>

    <custom-overview @searchData="getData" />
  </div>
  <loading-spinner />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

import CustomOverview from "../../components/home/CustomOverview.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import LanguageDropdown from "../../components/library/language-dropdown.vue";
import NotificationModal from "../../components/notification/NotificationModal.vue";
import CompletedStoreView from "../../components/home/CompletedStoreView.vue";
import CompleteStoreSetup from "../../components/home/store-setup/index.vue";
import CompleteStoreModal from "../../components/home/store-setup-modal/complete-store/index.vue";
import CompleteStoreStepModal from "../../components/home/store-setup-modal/complete-store-step/index.vue";

export default {
  components: {
    SideBar,
    LoadingSpinner,
    LanguageDropdown,
    NotificationModal,
    CustomOverview,
    CompletedStoreView,
    CompleteStoreSetup,
    CompleteStoreModal,
    CompleteStoreStepModal,
  },

  data: function() {
    return {
      authUser: Object,
      activeMenuId: 0,
      salesGraphCategories: [],
      viewsGraphCategories: [],
      bestSellingCategories: [],
      salesData: [],
      viewsData: [],
      bestSellingData: [],
      bestProduct: {},
      last7Days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri"],
      last12Months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      totalViews: [{ total_order: 0, total_pay_amount: 0 }],

      storeCompletedStatus: 1, // 0 => not completed, 1 => completed , 2 => step modal opened
    };
  },

  computed: {
    ...mapGetters("graph", ["salesDetails"]),
    ...mapGetters("language", ["getLanguage"]),
    ...mapGetters("notification", ["getUnReadNotification"]),
    ...mapGetters("Loader", ["getOverlayStatus"]),

    isNotificationModalOpen() {
      return this.$store.getters[`notification/getModalStatus`];
    },

    notifications() {
      return this.$store.getters[`notification/allNotification`].slice(0, 4);
    },

    shopUrl() {
      if (this.getLanguage == "bn") {
        return this.authUser.shop_front_url.replace("/en/", "/bn/");
      } else return this.authUser.shop_front_url;
    },
  },

  methods: {
    ...mapActions("notification", ["getAllNotification"]),
    ...mapActions("Order", ["allAvailableStatus", "getOrderByStatus"]),
    ...mapActions("auth", ["signOutRequest"]),
    ...mapActions("graph", ["requestGraphInfo"]),

    async handleSignOutRequest() {
      await this.signOutRequest();
    },

    async handleDropdownChange(day) {
      if (day === "custom") {
        document.getElementById("customSearchModal").click();
        return;
      }

      await this.getGraphInfo(day);
      if (day == 7) {
        await this.seltSalesArray(7);
        await this.setViewsArray(7);
        this.salesGraphCategories = this.last7Days;
        this.viewsGraphCategories = this.last7Days;
      } else if (day == 365) {
        this.seltSalesArray(12);
        await this.setViewsArray(12);
        this.salesGraphCategories = this.last12Months;
        this.viewsGraphCategories = this.last12Months;
      }
      await this.setBestProductArray();
    },

    seltSalesArray(day) {
      let i = 0;
      this.salesData = Array(day).fill(0);
      for (i = 0; i < this.salesDetails.total_sales_details?.length; i++) {
        this.salesData[
          this.salesDetails.total_sales_details[i].number - 1
        ] = this.salesDetails.total_sales_details[i].total_sales;
      }
    },

    setViewsArray(day) {
      let i = 0;
      this.viewsData = Array(day).fill(0);
      for (i = 0; i < this.salesDetails?.total_views_details?.length; i++) {
        this.viewsData[
          this.salesDetails.total_views_details[i].number - 1
        ] = this.salesDetails.total_views_details[i].total_views;
      }
    },

    setBestProductArray() {
      let i = 0;
      this.bestSellingCategories = [];
      this.bestSellingData = [];
      this.bestProduct = {};
      if (this.salesDetails?.best_selling_product?.length >= 1) {
        this.bestProduct = this.salesDetails.best_selling_product[0];
        this.salesDetails.best_selling_product.splice(0, 1);
      }
      for (i = 0; i < this.salesDetails?.best_selling_product?.length; i++) {
        this.bestSellingCategories.push(
          this.salesDetails.best_selling_product[i].product_title?.slice(0, 20)
        );

        this.bestSellingData.push(
          this.salesDetails.best_selling_product[i].total_sold
        );
      }
    },

    async getGraphInfo(data) {
      await this.requestGraphInfo({ timeline: data, start: "", end: "" });
    },

    async getAllAvailableOrderStatus() {
      await this.allAvailableStatus();
    },

    extractUser() {
      let userObject = localStorage.getItem("majhiUser");
      this.authUser = JSON.parse(userObject);
    },

    async handleNotificationModal() {
      let sellerKey = JSON.parse(localStorage.getItem("majhiUser")).seller_key;

      this.$store.commit(
        "notification/changeModalStatus",
        !this.isNotificationModalOpen
      );
      if (this.$store.getters[`notification/getModalStatus`]) {
        let data = {
          sellerKey,
          page: 1,
        };
        await this.getAllNotification(data);
      }
    },

    async getData(data) {
      const diffInMs = new Date(data.end) - new Date(data.start);
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      var day1 = moment(data.end);
      var day2 = moment(data.start);
      var result = [moment({ ...day2 })];

      while (day1.date() != day2.date()) {
        day2.add(1, "day");
        result.push(moment({ ...day2 }));
      }
      const dateArr = result.map((x) => x.format("YYYY-MM-DD"));
      await this.requestGraphInfo({
        timeline: "",
        start: data.start,
        end: data.end,
      });
      await this.seltSalesArray(diffInDays + 1);
      await this.setViewsArray(diffInDays + 1);
      this.salesGraphCategories = dateArr;
      this.viewsGraphCategories = dateArr;
      await this.setBestProductArray();
    },

    openCustomDateModal(event) {
      var isChrome = navigator.userAgent.indexOf("Chrome") != -1;

      if (!isChrome) return;

      if (event.detail === 0) {
        if (event.target.value === "custom") {
          document.getElementById("customSearchModal").click();
          return;
        }
      }
    },
  },
  async created() {
    this.$store.commit("setOverlayStatus", true); //loader open
    await this.extractUser();
    await this.handleDropdownChange(7);
    await this.getAllAvailableOrderStatus();

    if (this.salesDetails.summary_count_remaining == 0)
      this.storeCompletedStatus = 1;
    else this.storeCompletedStatus = 0;

    this.$store.commit("setOverlayStatus", false); //loader open
  },
};
</script>

<style>
.home-container {
  padding: 0px 32px 0px 32px;
}

.shop-name {
  font-size: 24px;
  font-weight: bold;
}

.nav-image {
  background-image: url("../../assets/Group 1464@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  /* min-height: 250px; */
}

.nav-image__profile {
  background-image: url("../../assets/Group 1464@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 70px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.option-box select {
  min-width: 6em;
  padding: 0.5em 1em;
  background-image: url("../../assets/icon/dropdown-white.svg") !important;
  border: 1px white solid;
  color: inherit;
  font: inherit;
  color: white;
  background-color: #3a86ff;
}

.active-menu {
  border-bottom: 2px solid #3a86ff !important;
  color: #3a86ff !important;
}

.max-height-40px {
  max-height: 35px;
}

.store-link-font-weight {
  font-weight: 300 !important;
}

.app-shop-name {
  padding-left: 2rem;
}

.app-profile-padding {
  padding-right: 2.5rem !important;
}

.navbar-row {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.navbar-row-two {
  margin-bottom: 3.5rem !important;
}

@media only screen and (max-width: 800px) {
  .ontop-store__info h5 {
    /* font-size: 15px !important; */
    font-size: 0.9375rem !important;
  }

  .ontop button {
    width: 100px;
    /* font-size: 15px; */
    font-size: 0.9375rem;
  }

  .ontop-store__info label {
    /* font-size: 10px !important; */
    font-size: 0.625rem !important ;
    max-width: 80px;
  }
}

@media only screen and (max-width: 700px) {
  .app-shop-name {
    padding-left: 0px !important;
  }

  .app-profile-padding {
    padding-right: 0.5rem !important;
  }
}

@media only screen and (max-width: 1000px) {
  .share-btn button {
    font-size: 0.9375rem !important;
  }

  .nav-image {
    min-height: 300px !important;
  }
}
</style>
