<template>
  <ul class="nav nav-pills orders_menu common-border__radius">
    <li class="nav-item order-status-menu" v-if="homeFlag == 0">
      <button
        class="nav-link state-color__fg--grey1 google_sans_medium"
        :class="activeMenuNumber == 0 ? 'button-active' : ''"
        @click="handleRequestOrderBystatus(0, 1)"
      >
        All
      </button>
    </li>
    <li
      class="nav-item order-status-menu"
      v-for="orderStatus in allAvailableStatusList"
      :key="orderStatus.id_order_status"
    >
      <button
        class="nav-link state-color__fg--grey1 letter-spacing-5px google_sans_medium"
        :class="
          activeMenuNumber == orderStatus.id_order_status ? 'button-active' : ''
        "
        @click="handleRequestOrderBystatus(orderStatus.id_order_status, 1)"
      >
        {{ orderStatus.name }}
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["allAvailableStatusList", "homeFlag"],
  emits: ["requestOrderDataByStatus"],

  computed: mapGetters("Order", ["activeMenu", "currentPage"]),

  // activeMenuNumber: 0
  // meanse all status product
  // activeMenuNumber > 0
  // other order status based on the api response

  data: function() {
    return {
      activeMenuNumber: 0,
      pageNumber: 0,
    };
  },

  methods: {
    ...mapMutations("Order", [
      "SET_ACTIVE_MENU",
      "SET_CURRENT_PAGE",
      "SET_SEARCH_KEYWORD",
    ]),
    //request order by status or all => (0)
    handleRequestOrderBystatus(activeMenuId, pageNumber) {
      if (document.getElementById("order-search")) {
        document.getElementById("order-search").value = "";
        this.SET_SEARCH_KEYWORD("");
      }

      this.SET_ACTIVE_MENU(activeMenuId);

      this.$store.commit("setOverlayStatus", true); //loader open

      this.activeMenuNumber = activeMenuId;
      this.pageNumber = pageNumber;
      this.$emit("requestOrderDataByStatus", {
        activeMenuId: activeMenuId,
        pageNumber: pageNumber,
      });
    },
  },

  mounted() {
    if (this.homeFlag == 1) {
      this.activeMenuNumber = 1;
    }

    if (this.homeFlag !== 1) {
      this.handleRequestOrderBystatus(this.activeMenu, this.currentPage);
    }

    // if(this.activeMenu){
    //   this.handleRequestOrderBystatus(this.activeMenu,this.currentPage)
    // }
  },
};
</script>

<style>
.order-status-menu {
  position: relative;
}

/* .orders_menu .button-active {
  border-bottom: 2px solid #3a86ff !important;
} */

.button-active::after {
  content: "";
  position: absolute;
  left: 23%;
  bottom: 0;
  height: 1px;
  width: 50%; /* or 100px */
  border-bottom: 2px solid #3a86ff;
}
</style>
