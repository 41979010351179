<template>
  <div
    class="modal fade"
    id="settingInstorePickupModal"
    tabindex="-1"
    aria-labelledby="settingInstorePickupModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content  common-border__radius p-4 ">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important;"
        >
          <h5>{{ $t("manage.main.store_time.select_time") }}</h5>
          <button
            type="button"
            class="modal-close"
            id="leave-page-action-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div
          class="modal-body d-flex justify-content-center align-items-center flex-column m-2 p-2"
        >
          <!-- <div class="row time-view__width border-bottom py-2">
            <h5
              class="col-3 disable time-font make-cursor"
              @click="decreaseHour(hour)"
            >
              {{ parseInt(hour) > 1 ? parseInt(hour) - 1 : 12 }}
            </h5>
            <h5 class="col-3 disable time-font">:</h5>
            <h5
              class="col-3 disable time-font make-cursor"
              @click="decreaseminutes(minutes)"
            >
              {{ parseInt(minutes) > 0 ? parseInt(minutes) - 1 : 60 }}
            </h5>
            <h5 class="col-3 time-font"></h5>
          </div> -->
          <!-- <div class="row time-view__width border-bottom py-2">
            <h5 class="col-3 time-font">{{ hour }}</h5>
            <h5 class="col-3 time-font">:</h5>
            <h5 class="col-3 time-font">{{ minutes }}</h5>
            <h5 class="col-3 time-font">
              {{ period }}
            </h5>
          </div> -->
          <!-- <div class="row time-view__width py-2">
            <h5
              class="col-3 disable time-font make-cursor"
              @click="increaseHour(hour)"
            >
              {{ parseInt(hour) &lt; 12 ? parseInt(hour)+1 : 1 }}
            </h5>
            <h5 class="col-3 disable time-font">:</h5>
            <h5
              class="col-3 disable time-font  make-cursor"
              @click="increaseminutes(minutes)"
            >
              {{ parseInt(minutes) &lt; 60 ? parseInt(minutes) + 1 : "00" }}
            </h5>
            <h5
              class="col-3 disable time-font make-cursor"
              @click="changeTimePeriod('AM')"
              v-if="period == 'PM'"
            >
              AM
            </h5>
            <h5
              class="col-3 disable time-font make-cursor"
              @click="changeTimePeriod('PM')"
              v-else-if="period == 'AM'"
            >
              PM
            </h5>
          </div> -->

          <scroll-picker :options="optionValues" v-model="selections" />

          <div class="time-view__width d-flex justify-content-center pt-3">
            <button
              class="btn btn-primary py-3 px-5 save-btn"
              @click="saveTime"
            >
              {{ $t("manage.main.store_time.save_btn") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollPicker from "vue3-scroll-picker";
import Toast, { errorBackground } from "../../config/toastConfig";
import moment from "moment";

export default {
  data: function() {
    return {
      hour: "08",
      minutes: "00",
      period: "AM",

      selections: ["01", "01", "AM"],

      optionValues: [],
      currentData: null,
    };
  },

  components: {
    ScrollPicker,
  },

  props: ["data"],

  created() {
    this.fillTimeOptions();
  },

  methods: {
    changeTimePeriod(period) {
      this.period = period;
    },
    increaseHour(hour) {
      let hourInt = 0;
      hourInt = parseInt(hour) + 1;
      if (hourInt > 12) {
        hourInt = 1;
      }
      this.hour = hourInt.toString();
      if (this.hour.length == 1) {
        this.hour = "0" + this.hour;
      }
    },
    decreaseHour(hour) {
      let hourInt = 0;
      hourInt = parseInt(hour) - 1;
      if (hourInt <= 0) {
        hourInt = 12;
      }
      this.hour = hourInt.toString();
      if (this.hour.length == 1) {
        this.hour = "0" + this.hour;
      }
    },
    increaseminutes(min) {
      let minInt = 0;
      minInt = parseInt(min) + 1;
      if (minInt > 59) {
        minInt = 0;
      }
      this.minutes = minInt.toString();
      if (this.minutes.length == 1) {
        this.minutes = "0" + this.minutes;
      }
    },
    decreaseminutes(min) {
      let minInt = 0;
      minInt = parseInt(min) - 1;
      if (minInt < 0) {
        minInt = 59;
      }
      this.minutes = minInt.toString();
      if (this.minutes.length == 1) {
        this.minutes = "0" + this.minutes;
      }
    },

    saveTime() {
      let new_day =
        this.data.day.charAt(0).toLowerCase() + this.data.day.substring(1);
      if (this.data.type === "close") {
        let hours = Math.floor(Number.parseInt(this.selections[1]) / 60);
        let totalHour = Number.parseInt(this.selections[0]) + hours;

        if (this.selections[2] === "PM") {
          totalHour = totalHour + 12;
        }

        let opening_time = this.currentData?.opening_hours;

        let dateArray = opening_time.split(":");
        let opening_hour = Number.parseInt(dateArray[0]);
        let minAndPried = dateArray[1];
        let minAndPriedArray = minAndPried.split(" ");
        let opening_minutes = Number.parseInt(minAndPriedArray[0]);
        let current_period = minAndPriedArray[1];
        let opening_min_hour = Math.floor(opening_minutes / 60);
        let total_opening_hour = opening_hour + opening_min_hour;
        if (current_period === "PM") {
          total_opening_hour = total_opening_hour + 12;
        }
        if (Number.parseInt(totalHour) < Number.parseInt(total_opening_hour)) {
          Toast.fire({
            icon: "error",
            title: this.$t("manage.validation.store_time.time_select_error"),
            background: errorBackground,
          });
          return;
        }
      }

      let data = {
        hour: this.selections[0],
        minutes: this.selections[1],
        period: this.selections[2],
        type: this.data.type,
        day: new_day,
      };

      let open;
      let close;

      let selectedTimeFormat = `${data.hour}:${data.minutes} ${data.period}`;
      if (data.type === "close") {
        close = selectedTimeFormat;
        open = this.currentData.opening_hours;
      } else {
        close = this.currentData.closing_hours;
        open = selectedTimeFormat;
      }

      var startTime = moment(open, "hh:mm a");
      var endTime = moment(close, "hh:mm a");
      if (endTime.diff(startTime, "hours") === 0) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.store_time.same_time_error"),
          background: errorBackground,
        });
      }

      //this.$store.commit('setUpdatedData',data);
      this.$emit("UpdatedData", data);

      const element = document.getElementById("leave-page-action-close");
      element.click();
    },

    openData(data) {
      this.currentData = data;

      let date = data.opening_hours;
      let dateArray = date.split(":");
      this.hour = dateArray[0];
      let minAndPried = dateArray[1];
      let minAndPriedArray = minAndPried.split(" ");
      this.minutes = minAndPriedArray[0];
      this.period = minAndPriedArray[1];

      this.selections = [
        dateArray[0],
        minAndPriedArray[0],
        minAndPriedArray[1],
      ];
    },

    closeData(data) {
      this.currentData = data;
      let date = data.closing_hours;
      let dateArray = date.split(":");
      this.hour = dateArray[0];
      let minAndPried = dateArray[1];
      let minAndPriedArray = minAndPried.split(" ");
      this.minutes = minAndPriedArray[0];
      this.period = minAndPriedArray[1];

      this.selections = [
        dateArray[0],
        minAndPriedArray[0],
        minAndPriedArray[1],
      ];
    },

    fillTimeOptions() {
      let minArray = [];
      let secArray = [];

      // set minutes
      for (let i = 1; i < 13; i++) {
        if (i.toString().length === 1) {
          let option = {
            label: `0${i.toString()}`,
            value: `0${i.toString()}`,
          };
          minArray.push(option);
        } else {
          let option = {
            label: `${i.toString()}`,
            value: `${i.toString()}`,
          };
          minArray.push(option);
        }
      }

      // set second
      for (let i = 0; i < 60; i++) {
        if (i.toString().length === 1) {
          let option = {
            label: `0${i.toString()}`,
            value: `0${i.toString()}`,
          };
          secArray.push(option);
        } else {
          let option = {
            label: `${i.toString()}`,
            value: `${i.toString()}`,
          };
          secArray.push(option);
        }
      }

      this.optionValues.push(minArray);
      this.optionValues.push(secArray);
      this.optionValues.push([
        {
          label: "AM",
          value: "AM",
        },
        {
          label: "PM",
          value: "PM",
        },
      ]);
    },
  },
};
</script>

<style>
.time-view__width {
  width: 50%;
}

.time-font {
  font-size: 20px;
}

.disable {
  color: #bdbdbd;
}

.time-modal__height {
  height: 300px;
}

.animation {
  animation: 1s ease-out 0s 1 slideInFromBottom;
}

.scroll-picker-container {
  width: 50%;
  justify-content: space-around;
}

.column-content {
  scrollbar-width: none;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 800px) {
  .time-view__width {
    width: 80%;
  }

  .scroll-picker-container {
    width: 75%;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 700px) {
  .scroll-picker-container {
    width: 85%;
    justify-content: space-around;
  }
}

.row-option {
  font-weight: bolder;
}
</style>
