<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container" style="min-width: 500px;">
          <div class="m-3 d-flex">
            <img src="../../assets/icon/back_btn_image.svg" alt="back" />
            <h3 class="ms-3 my-0">Order Form</h3>
          </div>

          <div
            class="bg-white common-border__radius coupon-create_container my-4 mx-auto p-4"
          >
            <div
              class="d-flex flex-column  flex-md-row flex-lg-row flex-xl-row justify-content-between flex-wrap my-2"
            >
              <div
                class="discount-inputfield  m-query-width-90 order-form__field py-auto my-3"
              >
                <p class="my-2 ms-3" style="font-size:1.0625rem; color:#333333">
                  Name<span class="state-color__fg--Info"> *</span>
                </p>
              </div>
              <div
                class="discount-inputfield  m-query-width-90 order-form__field py-auto my-3"
              >
                <p class="my-2 ms-3" style="font-size:1.0625rem; color:#333333">
                  Contact Number<span class="state-color__fg--Info"> *</span>
                </p>
              </div>
              <div
                class="discount-inputfield  m-query-width-90 order-form__field py-auto my-3"
              >
                <p class="my-2 ms-3" style="font-size:1.0625rem; color:#333333">
                  Division<span class="state-color__fg--Info"> *</span>
                </p>
              </div>
              <div
                class="discount-inputfield  m-query-width-90 order-form__field py-auto my-3"
              >
                <p class="my-2 ms-3" style="font-size:1.0625rem; color:#333333">
                  City<span class="state-color__fg--Info"> *</span>
                </p>
              </div>

              <div
                class="discount-inputfield  m-query-width-90 order-form__field py-auto my-3"
              >
                <p class="my-2 ms-3" style="font-size:1.0625rem; color:#333333">
                  Area<span class="state-color__fg--Info"> *</span>
                </p>
              </div>
              <div
                class="discount-inputfield  m-query-width-90 order-form__field py-auto my-3"
              >
                <p class="my-2 ms-3" style="font-size:1.0625rem; color:#333333">
                  Address<span class="state-color__fg--Info"> *</span>
                </p>
              </div>

              <div
                class="discount-inputfield  m-query-width-90 order-form__field py-auto my-3"
              >
                <p class="my-2 ms-3" style="font-size:1.0625rem; color:#333333">
                  Postal Code<span class="state-color__fg--Info"> *</span>
                </p>
              </div>
            </div>

            <div class="d-grid">
              <button
                type="button"
                class="btn btn-outline-primary py-2"
                data-bs-toggle="modal"
                data-bs-target="#addNewOrderFieldModal"
              >
                Add New Field
              </button>
            </div>
          </div>

          <div class="coupon-create_container my-4 mx-auto">
            <button type="button" class="btn btn-primary float-end px-4 py-3">
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
    <!-- Extra Charge Modal -->
    <add-new-order-field-modal />
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import AddNewOrderFieldModal from "../../components/manage/addNewOrderFieldModal.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";

export default {
  components: { SideBar, TopBar, LoadingSpinner, AddNewOrderFieldModal },
};
</script>

<style>
.order-form__field {
  height: 50px;
  background-color: #f8fbff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
}
</style>
