import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getFavicon() {
    return Client({
      url: "/seller_favicon",
      method: "GET",
    });
  },

  uploadFavicon(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_favicon_create/${language}`,
      method: "POST",
      data,
    });
  },

  deleteFavicon(data = {}) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_favicon_delete/${language}`,
      method: "DELETE",
      data,
    });
  },
};
