const postStyle = [
  {
    name: "1.png",
    left: `35%`,
    top: `20px`,
    fontFamily: `'Belleza', sans-serif`,
    color: "#FFFFFF",
    fontSize: "24px",
  },

  {
    name: "2.png",
    left: `32%`,
    top: `520px`,
    fontFamily: `'Open Sans', sans-serif`,
    color: "#FFFFFF",
    fontSize: "24px",
  },

  {
    name: "3.png",
    left: `25%`,
    top: `260px`,
    fontFamily: `'Bebas Neue', cursive`,
    color: "#FFFFFF",
    fontSize: "24px",
    letterSpacing: "5px",
  },

  {
    name: "4.png",
    left: `32%`,
    top: `480px`,
    fontFamily: `'Open Sans', sans-serif`,
    color: "#FFFFFF",
    fontSize: "20px",
  },

  {
    name: "5.png",
    left: `32%`,
    top: `510px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#7F1E39",
    fontSize: "20px",
  },

  {
    name: "6.png",
    left: `32%`,
    top: `550px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#D9221F",
    fontSize: "20px",
  },

  {
    name: "7.png",
    left: `32%`,
    top: `220px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#D9221F",
    fontSize: "20px",
  },

  {
    name: "8.png",
    left: `23%`,
    top: `420px`,
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: "600",
    color: "#5E268E",
    fontSize: "20px",
  },

  {
    name: "9.png",
    left: `32%`,
    top: `520px`,
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: "600",
    color: "#FFD89C",
    fontSize: "20px",
    letterSpacing: "2.1px",
  },

  {
    name: "11.png",
    left: `32%`,
    top: `460px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#FFFFFF",
    fontSize: "20px",
    letterSpacing: "1.36px",
  },

  {
    name: "12.png",
    left: `30%`,
    top: `410px`,
    fontFamily: `'Belleza', sans-serif`,
    color: "#FFDE59",
    fontSize: "20px",
    letterSpacing: "1.12px",
    textTransform: "uppercase",
  },

  {
    name: "13.png",
    left: `30%`,
    top: `350px`,
    fontFamily: `'Playfair Display', serif`,
    color: "#FFFFFF",
    fontSize: "20px",
    letterSpacing: "1.18px",
  },

  {
    name: "14.png",
    left: `33%`,
    top: `430px`,
    fontFamily: `'Open Sans', sans-serif`,
    color: "#2988AB",
    fontSize: "20px",
  },

  {
    name: "15.png",
    left: `20%`,
    top: `525px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#D5D5D5",
    fontSize: "20px",
    fontWeight: "600",
    textTransform: "uppercase",
  },

  {
    name: "16.png",
    left: `20%`,
    top: `430px`,
    fontFamily: `'Poppins', sans-serif`,
    color: "#FFFFFF",
    fontSize: "20px",
    textTransform: "uppercase",
    letterSpacing: "4.32px",
  },

  {
    name: "18.png",
    left: `32%`,
    top: `490px`,
    fontFamily: `'Open Sans', sans-serif`,
    color: "#FFFFFF",
    fontSize: "20px",
    letterSpacing: "0.7px",
  },

  {
    name: "19.png",
    left: `32%`,
    top: `520px`,
    fontFamily: `'Montserrat', sans-serif`,
    color: "#1D5777",
    fontSize: "18px",
    letterSpacing: "1.04px",
    fontWeight: "600",
  },

  {
    name: "21.png",
    left: `35%`,
    top: `460px`,
    fontFamily: `'Open Sans', sans-serif`,
    color: "#FFFFFF",
    fontSize: "18px",
    letterSpacing: "0px",
  },

  {
    name: "22.png",
    left: `31%`,
    top: `420px`,
    fontFamily: `'Nunito Sans', sans-serif`,
    color: "#000000",
    fontSize: "18px",
    letterSpacing: "0.84px",
    textTransform: "uppercase",
    fontWeight: "600",
  },
];

export default postStyle;
