import Repository from "../../api/RepositoryFactory";
import Toast, { errorBackground } from "../../config/toastConfig";
const ProfileUpdate = Repository.get("ProfileUpdate");
//import router from "../../router";

const {
  countryList,
  divisionByCountry,
  cityByDivision,
  areaByCity,
  updateContactNumber,
  updateProfile,
  sellerInfoByShopName,
  userProfileDetails,
} = ProfileUpdate;

const state = {
  addressCountryList: [],
  addressDivisionList: [],
  addressCityList: [],
  addressAreaList: [],
  divisionName: null,
  idCity: null,
  cityName: null,
  idArea: null,
  userProfileInfo: null,
};

const getters = {
  addressCountryList: (state) => state.addressCountryList,
  addressDivisionList: (state) => state.addressDivisionList,
  addressCityList: (state) => state.addressCityList,
  addressAreaList: (state) => state.addressAreaList,
  divisionName: (state) => state.divisionName,
  idCity: (state) => state.idCity,
  idArea: (state) => state.idArea,
  userProfileInfo: (state) => state.userProfileUpdate,
};

const mutations = {
  SET_COUNTRY_LIST(state, data) {
    let json = JSON.parse(
      JSON.stringify(data)
        .split('"country_name":')
        .join('"text":')
    );
    state.addressCountryList = JSON.parse(
      JSON.stringify(json)
        .split('"id_country":')
        .join('"id":')
    );

    state.addressDivisionList = null;
    state.addressCityList = null;
    state.addressAreaList = null;
  },

  SET_DIVISION_BY_COUNTRY(state, data) {
    let json = JSON.parse(
      JSON.stringify(data)
        .split('"division_name":')
        .join('"text":')
    );
    state.addressDivisionList = JSON.parse(
      JSON.stringify(json)
        .split('"id_division":')
        .join('"id":')
    );

    state.addressCityList = null;
    state.addressAreaList = null;
  },

  SET_CITY_BY_DIVISION(state, data) {
    let json = JSON.parse(
      JSON.stringify(data)
        .split('"city_name":')
        .join('"text":')
    );
    state.addressCityList = JSON.parse(
      JSON.stringify(json)
        .split('"id_city":')
        .join('"id":')
    );

    state.addressAreaList = null;
  },

  SET_DIVISION_NAME(state, data) {
    state.divisionName = data;
  },

  SET_USER_PROFILE_INFO(state, data) {
    state.userProfileInfo = data;
  },

  SET_CITY_ID(state, data) {
    state.idCity = data;
  },

  SET_CITY_NAME(state, data) {
    state.cityName = data;
  },

  SET_ADDRESS_AREA_LIST(state, data) {
    let json = JSON.parse(
      JSON.stringify(data)
        .split('"area_name":')
        .join('"text":')
    );
    state.addressAreaList = JSON.parse(
      JSON.stringify(json)
        .split('"id_area":')
        .join('"id":')
    );
  },
};

const actions = {
  async updateSellerContactNumber(_, payload) {
    const response = await updateContactNumber(payload);
    if (response.success) return response;
    else {
      Promise.reject();
    }
  },

  async countryListRequest({ commit }) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await countryList();
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      commit("SET_COUNTRY_LIST", response.data);
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log({ error });
      Toast.fire({
        icon: "error",
        title: error.response.data,
        background: errorBackground,
      });
    }
  },

  async divisionByCountryRequest({ commit }, id) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await divisionByCountry(id);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      commit("SET_DIVISION_BY_COUNTRY", response.data);
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error.response.data,
        background: errorBackground,
      });
    }
  },

  async cityByDivisionRequest({ commit }, id) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await cityByDivision(id);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      commit("SET_CITY_BY_DIVISION", response.data);
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async areaByCityRequest({ commit }, id) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await areaByCity(id);

      commit("SET_ADDRESS_AREA_LIST", response.data);
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async userProfileUpdate({ commit }, payload) {
    try {
      const response = await updateProfile(payload);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success) {
        let userObject = localStorage.getItem("majhiUser");
        let authUser = JSON.parse(userObject);
        authUser.email = response.data.user[0].email;
        authUser.shop_address = response.data.user[0].shop_address;
        authUser.store_logo = response.data.user[0].store_logo_path;
        authUser.shop_name = response.data.user[0].shop_name;
        authUser.phone = response.data.user[0].phone;

        authUser.id_country = response.data.user[0].id_country;
        authUser.country_name = response.data.user[0].country_name;
        authUser.id_division = response.data.user[0].id_division;
        authUser.division_name = response.data.user[0].division_name;
        authUser.id_city = response.data.user[0].id_city;
        authUser.city_name = response.data.user[0].city_name;
        authUser.id_area = response.data.user[0].id_area;
        authUser.area_name = response.data.user[0].area_name;
        authUser.lat = response.data.user[0].lat;
        authUser.long = response.data.user[0].lon;

        localStorage.setItem("majhiUser", JSON.stringify(authUser));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
      return false;
    }
  },

  async sellerInfoByShopName({ commit }, payload) {
    try {
     // commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await sellerInfoByShopName(payload.shopName);
      return response.data;
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: false });
    }
  },

  async getUserDetailsInfo({ commit }, payload) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await userProfileDetails(payload);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(response);
      if (response.success) {
        return response?.data[0];
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
