import Repository from "../../api/RepositoryFactory";
const Order = Repository.get("Order");
const {
  getAllOrder,
  getOrderByStatus,
  getAvailableStatus,
  getOrderDetails,
  updateOrderStatus,
  generateInvoice,
  updateOrderQuantity,
} = Order;
import Toast, { successBackground } from "../../config/toastConfig";
import router from "../../router";
import store from "../../store";

const state = {
  orders: [],
  total: 0,
  lastPage: 0,
  availableStatusList: null,
  orderDetails: "",
  invoiceLink: "",
  activeMenu: 0,
  currentPage: 1,
  customSearch: false,
  customSearchDate: null,
  searchKeyword: "",
};
const getters = {
  allOrders: (state) => state.orders,
  totalPage: (state) => state.total,
  lastPage: (state) => state.lastPage,
  allAvailableStatusList: (state) => state.availableStatusList,
  orderDetails: (state) => state.orderDetails,
  getInvoiceLink: (state) => state.invoiceLink,
  activeMenu: (state) => state.activeMenu,
  currentPage: (state) => state.currentPage,
  isCustomSearch: (state) => state.customSearch,
  customSearchDate: (state) => state.customSearchDate,
  isSearchKeyword: (state) => state.searchKeyword,
};

const mutations = {
  SET_ORDERS_DATA: (state, data) => {
    state.orders = data.orders;
    state.total = data.total;
    state.lastPage = data.last_page;
  },

  SET_AVAILABLE_STATUS: (state, data) => {
    state.availableStatusList = data;
  },

  SET_ORDER_DETAILS: (state, data) => {
    state.orderDetails = data;
  },

  SET_INVOICE_LINK: (state, data) => {
    state.invoiceLink = data;
  },

  SET_ACTIVE_MENU: (state, data) => {
    state.activeMenu = data;
  },

  SET_CURRENT_PAGE: (state, data) => {
    state.currentPage = data;
  },

  SET_CUSTOM_SEARCH_STATUS: (state, data) => {
    state.customSearch = data;
  },

  SET_CUSTOM_SEARCH_DATE: (state, data) => {
    state.customSearchDate = data;
  },

  SET_SEARCH_KEYWORD: (state, data) => {
    state.searchKeyword = data;
  },
};

const actions = {
  async allOrderRequest({ commit }, url) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getAllOrder(url);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success && response.data) {
        commit("SET_ORDERS_DATA", response.data);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async getOrderByStatus({ commit }, url) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getOrderByStatus(url);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success && response.data) {
        commit("SET_ORDERS_DATA", response.data);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async allAvailableStatus({ commit }) {
    try {
      // commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getAvailableStatus();

      if (response.success && response.data) {
        commit("SET_AVAILABLE_STATUS", response.data.orders_status);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async requestOrderDetails({ commit }, orderId) {
    try {
      console.log("Hello===>");

      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getOrderDetails(orderId);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success && response.data && response.data.orders) {
        commit("SET_ORDER_DETAILS", response.data.orders[0]);
      } else {
        commit("SET_ORDER_DETAILS", null);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log("catch", error);
    }
  },

  async requestUpdateOrderStatus({ commit, dispatch }, { data, redirect }) {
    try {
      const { id_order } = data;
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await updateOrderStatus(data);

      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        dispatch("requestOrderDetails", id_order);
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });

        if (redirect) {
          router.push({
            name: "AllOrders",
            params: { languageCode: store.getters["language/getLanguage"] },
          });
        }
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async generateInvoice({ commit }, id) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await generateInvoice(id);

      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success && response.data) {
        commit("SET_INVOICE_LINK", response.data.download_link);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async updateOrderQuantity({ commit, dispatch }, data) {
    const { formData, orderId } = data;
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await updateOrderQuantity(formData);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
        //router.push({ name: "AllOrders" });
        dispatch("requestOrderDetails", orderId);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
