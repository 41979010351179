<template>
  <div
    class="notification "
    v-click-outside="hideNotificationModal"
    style="width:400px !important"
  >
    <div
      v-if="notifications.length"
      class="p-3 d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div class="p-3 heading">{{ $t("home.notification.title") }}</div>
        <div class="new">
          {{ newNotification }} {{ $t("home.notification.new") }}
        </div>
      </div>

      <a
        v-if="newNotification !== 0"
        href="#"
        class="text-decoration-none all-read"
        @click="markAllRead()"
        >{{ $t("home.notification.mark_all_btn") }}</a
      >
    </div>

    <div
      v-if="!notifications.length"
      class="text-center d-flex flex-column justify-content-center align-items-center"
      style="height:500px"
    >
      <img src="../../assets/icon/No-notifications.svg" alt="" />
      <h5 class="text-center state-color__fg--grey1">
        {{ $t("home.notification.not_found") }}!
      </h5>
    </div>

    <div class="d-flex flex-column">
      <notification-card
        v-for="notification in notifications"
        :key="notification.id_notification"
        :notification="notification"
      />
    </div>
  </div>
</template>

<script>
import NotificationCard from "./NotificationCard.vue";
import vClickOutside from "click-outside-vue3";
import { mapActions } from "vuex";
export default {
  components: {
    NotificationCard,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  computed: {
    // ...mapGetters("notification",["allNotification","getUnReadNotification","lastPage"]),
    notifications() {
      return this.$store.getters[`notification/allNotification`].slice(0, 5);
    },

    newNotification() {
      return this.$store.getters[`notification/getUnReadNotification`];
    },
  },

  methods: {
    ...mapActions("notification", ["markAllNotificationRed"]),

    async markAllRead() {
      let sellerKey = JSON.parse(localStorage.getItem("majhiUser")).seller_key;
      await this.markAllNotificationRed(sellerKey);
    },

    hideNotificationModal() {
      this.$store.commit("notification/changeModalStatus", false);
    },
  },
};
</script>

<style>
.heading {
  /* font-size: 17px; */
  font-size: 1.0625rem !important;
  color: #1d1d1d;
  font-weight: 600;
}

.new {
  background: #eaf2ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 7px;
  font-size: 0.625rem !important;
  color: #3a86ff;
}
</style>
