import Client from "../client/axiosClient"
import store from "../../store"

export default {
  getAllProductCategoryUnderBusinessCategory(sellerBusinessId) {
    let language = store.getters["language/getLanguage"]
    return Client({
      url: `/product_category_by_business_category/${language}/${sellerBusinessId}`,
      method: "GET",
    })
  },

  getSellerProductCategory(sellerBusinessId) {
    return Client({
      url: "/product_category_by_id/" + sellerBusinessId,
      method: "GET",
    })
  },

  createCategory(data) {
    let language = store.getters["language/getLanguage"]
    return Client({
      url: `/product_category_create/${language}`,
      method: "POST",
      data,
    })
  },

  instantCategoryCreate(data) {
    let language = store.getters["language/getLanguage"]
    return Client({
      url: `/product_category_list_assign/${language}`,
      method: "POST",
      data,
    })
  },

  updateStatus(data) {
    return Client({
      url: `/statusupdate_product_category`,
      method: "PUT",
      data,
    })
  },

  deleteCategory(category_id) {
    return Client({
      url: `/product_category_delete/${category_id}`,
      method: "DELETE",
    })
  },

  // MANY OTHER ENDPOINT RELATED STUFFS
}
