<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div
        class="col px-0 min-vh-100 d-flex flex-column justify-content-start "
      >
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div class="m-3 d-flex page-heading-top-padding">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="ms-3 my-0 page-heading-text-size">
              {{ $t("manage.main.store_time.heading") }}
            </h4>
          </div>

          <div
            class="bg-white common-border__radius store-timing-container mt-4 mb-3 mx-auto store-table-padding"
          >
            <h5>{{ $t("manage.main.store_time.title") }}</h5>
            <p class="state-color__fg--grey1">
              {{ $t("manage.main.store_time.sub_title") }}
            </p>

            <div class="store-time overflow-x-auto">
              <table>
                <thead>
                  <tr class="state-color__fg--grey1">
                    <td class="google_sans_medium text-uppercase">
                      {{ $t("manage.main.store_time.options.o1") }}
                    </td>
                    <td class="google_sans_medium text-uppercase">
                      {{ $t("manage.main.store_time.options.o2") }}
                    </td>
                    <td class="google_sans_medium text-uppercase">
                      {{ $t("manage.main.store_time.options.o3") }}
                    </td>
                    <td class="google_sans_medium text-uppercase">
                      {{ $t("manage.main.store_time.options.o4") }}
                    </td>

                    <td class="google_sans_medium text-uppercase" width="13%">
                      {{ $t("manage.main.store_time.options.o6") }}
                    </td>

                    <td class="google_sans_medium text-uppercase">
                      {{ $t("manage.main.store_time.options.o5") }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="google_sans_medium">
                      {{ $t("manage.main.store_time.days.d1") }}
                    </td>

                    <td
                      :class="{
                        disable: !saturdayData.flag,
                        closeTd: !saturdayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="saturdayData.is24Open"
                        class="btn border rounded py-2 px-4 mb-0"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="openingUpdate(saturdayData)"
                      >
                        {{ saturdayData.opening_hours }}
                      </button>
                    </td>

                    <td
                      :class="{
                        disable: !saturdayData.flag,
                        mydibtn: !saturdayData.flag,
                      }"
                    >
                      <button
                        :disabled="saturdayData.is24Open"
                        type="button"
                        :class="[
                          'btn border rounded py-2 px-4 mb-0',
                          { closeBtn: !saturdayData.flag },
                        ]"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="closingUpdate(saturdayData)"
                      >
                        <span :class="{ level: !saturdayData.flag }">
                          {{ saturdayData.closing_hours }}
                        </span>

                        <span :class="{ textClass: !saturdayData.flag }">
                        </span>
                      </button>
                    </td>

                    <td
                      :class="{ disable: !saturdayData.flag }"
                      class="google_sans_medium"
                    >
                      <!-- {{totalTime(saturdayData)}} hours -->

                      {{ calculate(saturdayData) }}
                      {{ $t("manage.main.store_time.hours") }}
                    </td>

                    <td>
                      <Toggle
                        v-model="saturdayData.is24Open"
                        @click="store24Event(saturdayData)"
                      />
                    </td>

                    <td>
                      <Toggle
                        v-model="saturdayData.flag"
                        @change="storeOnOffEvent($event)"
                      />
                      <span
                        v-bind:class="[
                          'status',
                          { open: saturdayData.flag },
                          { close: !saturdayData.flag },
                        ]"
                      >
                        {{
                          saturdayData.flag
                            ? $t("manage.main.store_time.open")
                            : $t("manage.main.store_time.close")
                        }}
                      </span>
                    </td>
                  </tr>

                  <tr style="border: none">
                    <td class="google_sans_medium">
                      {{ $t("manage.main.store_time.days.d2") }}
                    </td>
                    <td
                      :class="{
                        disable: !sundayData.flag,
                        closeTd: !sundayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="sundayData.is24Open"
                        class="btn border rounded py-2 px-4 mb-0"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="openingUpdate(sundayData)"
                      >
                        {{ sundayData.opening_hours }}
                      </button>
                    </td>
                    <td
                      :class="{
                        disable: !sundayData.flag,
                        mydibtn: !sundayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="sundayData.is24Open"
                        :class="[
                          'btn border rounded py-2 px-4 mb-0',
                          { closeBtn: !sundayData.flag },
                        ]"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="closingUpdate(sundayData)"
                      >
                        <span :class="{ level: !sundayData.flag }">
                          {{ sundayData.closing_hours }}
                        </span>

                        <span :class="{ textClass: !sundayData.flag }"> </span>
                      </button>
                    </td>
                    <td
                      :class="{ disable: !sundayData.flag }"
                      class="google_sans_medium"
                    >
                      {{ calculate(sundayData) }}
                      {{ $t("manage.main.store_time.hours") }}
                    </td>

                    <td>
                      <Toggle
                        v-model="sundayData.is24Open"
                        @click="store24Event(sundayData)"
                      />
                    </td>

                    <td>
                      <Toggle
                        v-model="sundayData.flag"
                        @change="storeOnOffEvent($event)"
                      />
                      <span
                        v-bind:class="[
                          'status',
                          { open: sundayData.flag },
                          { close: !sundayData.flag },
                        ]"
                      >
                        {{
                          sundayData.flag
                            ? $t("manage.main.store_time.open")
                            : $t("manage.main.store_time.close")
                        }}
                      </span>
                    </td>
                  </tr>

                  <tr style="border: none">
                    <td class="google_sans_medium">
                      {{ $t("manage.main.store_time.days.d3") }}
                    </td>
                    <td
                      :class="{
                        disable: !mondayData.flag,
                        closeTd: !mondayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="mondayData.is24Open"
                        class="btn border rounded py-2 px-4 mb-0"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="openingUpdate(mondayData)"
                      >
                        {{ mondayData.opening_hours }}
                      </button>
                    </td>
                    <td
                      :class="{
                        disable: !mondayData.flag,
                        mydibtn: !mondayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="mondayData.is24Open"
                        :class="[
                          'btn border rounded py-2 px-4 mb-0',
                          { closeBtn: !mondayData.flag },
                        ]"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="closingUpdate(mondayData)"
                      >
                        <span :class="{ level: !mondayData.flag }">
                          {{ mondayData.closing_hours }}
                        </span>

                        <span :class="{ textClass: !mondayData.flag }"> </span>
                      </button>
                    </td>
                    <td
                      :class="{ disable: !mondayData.flag }"
                      class="google_sans_medium"
                    >
                      {{ calculate(mondayData) }}
                      {{ $t("manage.main.store_time.hours") }}
                    </td>

                    <td>
                      <Toggle
                        v-model="mondayData.is24Open"
                        @click="store24Event(mondayData)"
                      />
                    </td>

                    <td>
                      <Toggle
                        v-model="mondayData.flag"
                        @change="storeOnOffEvent($event)"
                      />

                      <span
                        v-bind:class="[
                          'status',
                          { open: mondayData.flag },
                          { close: !mondayData.flag },
                        ]"
                      >
                        {{
                          mondayData.flag
                            ? $t("manage.main.store_time.open")
                            : $t("manage.main.store_time.close")
                        }}
                      </span>
                    </td>
                  </tr>

                  <tr style="border: none">
                    <td class="google_sans_medium">
                      {{ $t("manage.main.store_time.days.d4") }}
                    </td>
                    <td
                      :class="{
                        disable: !tuesdayData.flag,
                        closeTd: !tuesdayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="tuesdayData.is24Open"
                        class="btn border rounded py-2 px-4 mb-0"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="openingUpdate(tuesdayData)"
                      >
                        {{ tuesdayData.opening_hours }}
                      </button>
                    </td>
                    <td
                      :class="{
                        disable: !tuesdayData.flag,
                        mydibtn: !tuesdayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="tuesdayData.is24Open"
                        :class="[
                          'btn border rounded py-2 px-4 mb-0',
                          { closeBtn: !tuesdayData.flag },
                        ]"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="closingUpdate(tuesdayData)"
                      >
                        <!-- {{ tuesdayData.closing_hours }} -->

                        <span :class="{ level: !tuesdayData.flag }">
                          {{ tuesdayData.closing_hours }}
                        </span>

                        <span :class="{ textClass: !tuesdayData.flag }"> </span>
                      </button>
                    </td>
                    <td
                      :class="{ disable: !tuesdayData.flag }"
                      class="google_sans_medium"
                    >
                      <!-- {{totalTime(tuesdayData)}} hours -->
                      {{ calculate(tuesdayData) }}
                      {{ $t("manage.main.store_time.hours") }}
                    </td>

                    <td>
                      <Toggle
                        v-model="tuesdayData.is24Open"
                        @click="store24Event(tuesdayData)"
                      />
                    </td>

                    <td>
                      <Toggle
                        v-model="tuesdayData.flag"
                        @change="storeOnOffEvent($event)"
                      />

                      <span
                        v-bind:class="[
                          'status',
                          { open: tuesdayData.flag },
                          { close: !tuesdayData.flag },
                        ]"
                      >
                        {{
                          tuesdayData.flag
                            ? $t("manage.main.store_time.open")
                            : $t("manage.main.store_time.close")
                        }}
                      </span>
                    </td>
                  </tr>

                  <tr style="border: none">
                    <td class="google_sans_medium">
                      {{ $t("manage.main.store_time.days.d5") }}
                    </td>
                    <td
                      :class="{
                        disable: !wednesdayData.flag,
                        closeTd: !wednesdayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="wednesdayData.is24Open"
                        class="btn border rounded py-2 px-4 mb-0"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="openingUpdate(wednesdayData)"
                      >
                        {{ wednesdayData.opening_hours }}
                      </button>
                    </td>
                    <td
                      :class="{
                        disable: !wednesdayData.flag,
                        mydibtn: !wednesdayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="wednesdayData.is24Open"
                        :class="[
                          'btn border rounded py-2 px-4 mb-0',
                          { closeBtn: !wednesdayData.flag },
                        ]"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="closingUpdate(wednesdayData)"
                      >
                        <!-- {{ wednesdayData.closing_hours }} -->

                        <span :class="{ level: !wednesdayData.flag }">
                          {{ wednesdayData.closing_hours }}
                        </span>

                        <span :class="{ textClass: !wednesdayData.flag }">
                        </span>
                      </button>
                    </td>
                    <td
                      :class="{ disable: !wednesdayData.flag }"
                      class="google_sans_medium"
                    >
                      <!-- {{totalTime(wednesdayData)}} hours -->
                      {{ calculate(wednesdayData) }}
                      {{ $t("manage.main.store_time.hours") }}
                    </td>

                    <td>
                      <Toggle
                        v-model="wednesdayData.is24Open"
                        @click="store24Event(wednesdayData)"
                      />
                    </td>

                    <td>
                      <Toggle
                        v-model="wednesdayData.flag"
                        @change="storeOnOffEvent($event)"
                      />
                      <span
                        v-bind:class="[
                          'status',
                          { open: wednesdayData.flag },
                          { close: !wednesdayData.flag },
                        ]"
                      >
                        {{
                          wednesdayData.flag
                            ? $t("manage.main.store_time.open")
                            : $t("manage.main.store_time.close")
                        }}
                      </span>
                    </td>
                  </tr>

                  <tr style="border: none">
                    <td class="google_sans_medium">
                      {{ $t("manage.main.store_time.days.d6") }}
                    </td>
                    <td
                      :class="{
                        disable: !thursdayData.flag,
                        closeTd: !thursdayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="thursdayData.is24Open"
                        class="btn border rounded py-2 px-4 mb-0"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="openingUpdate(thursdayData)"
                      >
                        {{ thursdayData.opening_hours }}
                      </button>
                    </td>
                    <td
                      :class="{
                        disable: !thursdayData.flag,
                        mydibtn: !thursdayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="thursdayData.is24Open"
                        :class="[
                          'btn border rounded py-2 px-4 mb-0',
                          { closeBtn: !thursdayData.flag },
                        ]"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="closingUpdate(thursdayData)"
                      >
                        <span :class="{ level: !thursdayData.flag }">
                          {{ thursdayData.closing_hours }}
                        </span>

                        <span :class="{ textClass: !thursdayData.flag }">
                        </span>
                      </button>
                    </td>
                    <td
                      :class="{ disable: !thursdayData.flag }"
                      class="google_sans_medium"
                    >
                      <!-- {{totalTime(thursdayData)}} hours -->
                      {{ calculate(thursdayData) }}
                      {{ $t("manage.main.store_time.hours") }}
                    </td>

                    <td>
                      <Toggle
                        v-model="thursdayData.is24Open"
                        @click="store24Event(thursdayData)"
                      />
                    </td>

                    <td>
                      <Toggle
                        v-model="thursdayData.flag"
                        @change="storeOnOffEvent($event)"
                      />
                      <span
                        v-bind:class="[
                          'status',
                          { open: thursdayData.flag },
                          { close: !thursdayData.flag },
                        ]"
                      >
                        {{
                          thursdayData.flag
                            ? $t("manage.main.store_time.open")
                            : $t("manage.main.store_time.close")
                        }}
                      </span>
                    </td>
                  </tr>

                  <tr style="border: none">
                    <td class="google_sans_medium">
                      {{ $t("manage.main.store_time.days.d7") }}
                    </td>
                    <td
                      :class="{
                        disable: !fridayData.flag,
                        closeTd: !fridayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="fridayData.is24Open"
                        class="btn border rounded py-2 px-4 mb-0"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="openingUpdate(fridayData)"
                      >
                        {{ fridayData.opening_hours }}
                      </button>
                    </td>
                    <td
                      :class="{
                        disable: !fridayData.flag,
                        mydibtn: !fridayData.flag,
                      }"
                    >
                      <button
                        type="button"
                        :disabled="fridayData.is24Open"
                        :class="[
                          'btn border rounded py-2 px-4 mb-0',
                          { closeBtn: !fridayData.flag },
                        ]"
                        id="instorePickupMOdalBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#settingInstorePickupModal"
                        @click="closingUpdate(fridayData)"
                      >
                        <span :class="{ level: !fridayData.flag }">
                          {{ fridayData.closing_hours }}
                        </span>
                        <span :class="{ textClass: !fridayData.flag }"> </span>
                      </button>
                    </td>

                    <td
                      :class="{ disable: !fridayData.flag }"
                      class="google_sans_medium"
                    >
                      {{ calculate(fridayData) }}
                      {{ $t("manage.main.store_time.hours") }}
                    </td>

                    <td>
                      <Toggle
                        v-model="fridayData.is24Open"
                        @click="store24Event(fridayData)"
                      />
                    </td>

                    <td>
                      <Toggle
                        v-model="fridayData.flag"
                        @change="storeOnOffEvent($event)"
                      />
                      <span
                        v-bind:class="[
                          'status',
                          { open: fridayData.flag },
                          { close: !fridayData.flag },
                        ]"
                      >
                        {{
                          fridayData.flag
                            ? $t("manage.main.store_time.open")
                            : $t("manage.main.store_time.close")
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="store-timing-container d-flex justify-content-end mx-auto"
          >
            <button
              @click="UpdateStoreTime"
              class="update-time-btn btn btn-primary"
            >
              {{ $t("manage.main.store_time.save_btn") }}
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
    <!--  Modal -->

    <time-modal
      :data="selectedData"
      @UpdatedData="receive"
      ref="passSelectedData"
    />
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import Toggle from "@vueform/toggle";
import TimeModal from "../../components/manage/timeModal.vue";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    Toggle,
    TimeModal,
  },

  data: function() {
    return {
      // storeOnOffFlag: true,
      saturdayData: {
        day: "saturday",
        flag: false,
        opening_hours: "01:00 AM",
        closing_hours: "02:00 AM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      sundayData: {
        day: "sunday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "10:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      mondayData: {
        day: "monday",
        flag: false,
        opening_hours: "09:00 AM",
        closing_hours: "02:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      tuesdayData: {
        day: "tuesday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "02:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      wednesdayData: {
        day: "wednesday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "03:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      thursdayData: {
        day: "thursday",
        flag: false,
        opening_hours: "10:00 AM",
        closing_hours: "12:00 PM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },
      fridayData: {
        day: "friday",
        flag: false,
        opening_hours: "12:00 AM",
        closing_hours: "12:00 AM",
        duration: 10,
        isSpanActive: false,
        is24Open: false,
      },

      selectedData: {},

      updatedData: {},
      default_hour: "12:00 AM",
    };
  },
  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
  async created() {
    let timing = await this.getStoreTimeData();
    if (timing) {
      this.renderResponseData(timing);
    }

    window.addEventListener("resize", this.myEventHandler);
  },

  methods: {
    ...mapActions("storeTime", ["updateStoreTiming", "getStoreTimeData"]),

    storeOnOffEvent(data) {
      if (!data) return;

      const levels = document.querySelectorAll(".level");
      let closeText = document.querySelectorAll(".textClass");

      const nodeList = document.querySelectorAll(".mydibtn");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].setAttribute("colspan", 0);
        closeText[i].innerHTML = "";
        levels[i].style.display = "block";
      }
    },

    store24Event(data) {
      //console.log(data);

      if (!data.is24Open) return;
      switch (data.day.toLowerCase()) {
        case "saturday":
          this.saturdayData.opening_hours = this.default_hour;
          this.saturdayData.closing_hours = this.default_hour;
          break;
        case "sunday":
          this.sundayData.opening_hours = this.default_hour;
          this.sundayData.closing_hours = this.default_hour;
          break;

        case "monday":
          this.mondayData.opening_hours = this.default_hour;
          this.mondayData.closing_hours = this.default_hour;
          break;

        case "tuesday":
          this.tuesdayData.opening_hours = this.default_hour;
          this.tuesdayData.closing_hours = this.default_hour;
          break;

        case "wednesday":
          this.wednesdayData.opening_hours = this.default_hour;
          this.wednesdayData.closing_hours = this.default_hour;
          break;

        case "thursday":
          this.thursdayData.opening_hours = this.default_hour;
          this.thursdayData.closing_hours = this.default_hour;
          break;

        case "friday":
          this.fridayData.opening_hours = this.default_hour;
          this.fridayData.closing_hours = this.default_hour;
          break;

        default:
          break;
      }
    },

    openingUpdate(data) {
      this.selectedData = data;
      this.selectedData.type = "open";
      this.$refs.passSelectedData.openData(data);
    },

    closingUpdate(data) {
      this.selectedData = data;
      this.selectedData.type = "close";
      this.$refs.passSelectedData.closeData(data);
    },

    calculate(data) {
      var startTime = moment(data.opening_hours, "hh:mm a");
      var endTime = moment(data.closing_hours, "hh:mm a");
      if (endTime.diff(startTime, "hours") < 0) {
        //return 12 + endTime.diff(startTime, "hours") * -1;
        return 24 + endTime.diff(startTime, "hours");
      } else if (endTime.diff(startTime, "hours") > 24) {
        return endTime.diff(startTime, "hours") - 24;
      }
      if (endTime.diff(startTime, "hours") === 0) {
        let opening_pre = data.opening_hours.split(" ")[1];
        let closing_pre = data.closing_hours.split(" ")[1];
        if (opening_pre !== closing_pre) {
          return endTime.diff(startTime, "hours");
        }
        return endTime.diff(startTime, "hours") + 24;
      }
      return endTime.diff(startTime, "hours");
    },

    myEventHandler(e) {
      if (e.currentTarget.outerWidth) {
        const levels = document.querySelectorAll(".level");
        let closeText = document.querySelectorAll(".textClass");

        if (e.currentTarget.outerWidth <= 1200) {
          const nodeList = document.querySelectorAll(".mydibtn");
          for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].setAttribute("colspan", 2);
            closeText[i].innerHTML = "close";
            levels[i].style.display = "none";
          }
        } else {
          const nodeList = document.querySelectorAll(".mydibtn");
          for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].setAttribute("colspan", 0);
            closeText[i].innerHTML = "";
            levels[i].style.display = "block";
          }
        }
      }
    },

    renderResponseData(store_timing) {
      if (store_timing) {
        if (store_timing.length === 7) {
          this.saturdayData = store_timing[6];
          this.saturdayData.flag = Boolean(Number(store_timing[6].is_closed));
          this.saturdayData.is24Open = Boolean(
            Number(store_timing[6].always_open)
          );

          this.sundayData = store_timing[0];
          this.sundayData.flag = Boolean(Number(store_timing[0].is_closed));
          this.sundayData.is24Open = Boolean(
            Number(store_timing[0].always_open)
          );

          this.mondayData = store_timing[1];
          this.mondayData.flag = Boolean(Number(store_timing[1].is_closed));
          this.mondayData.is24Open = Boolean(
            Number(store_timing[1].always_open)
          );

          this.tuesdayData = store_timing[2];
          this.tuesdayData.flag = Boolean(Number(store_timing[2].is_closed));
          this.tuesdayData.is24Open = Boolean(
            Number(store_timing[2].always_open)
          );

          this.wednesdayData = store_timing[3];
          this.wednesdayData.flag = Boolean(Number(store_timing[3].is_closed));
          this.wednesdayData.is24Open = Boolean(
            Number(store_timing[3].always_open)
          );

          this.thursdayData = store_timing[4];
          this.thursdayData.flag = Boolean(Number(store_timing[4].is_closed));
          this.thursdayData.is24Open = Boolean(
            Number(store_timing[4].always_open)
          );

          this.fridayData = store_timing[5];
          this.fridayData.flag = Boolean(Number(store_timing[5].is_closed));
          this.fridayData.is24Open = Boolean(
            Number(store_timing[5].always_open)
          );
        }
      }
    },

    // set the store time ------------------------------------------
    receive(childData) {
      if (childData.day == "saturday") {
        childData.type === "close"
          ? (this.saturdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.saturdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "sunday") {
        childData.type === "close"
          ? (this.sundayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.sundayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "monday") {
        childData.type === "close"
          ? (this.mondayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.mondayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "tuesday") {
        childData.type === "close"
          ? (this.tuesdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.tuesdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "wednesday") {
        childData.type === "close"
          ? (this.wednesdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.wednesdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "thursday") {
        childData.type === "close"
          ? (this.thursdayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.thursdayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      } else if (childData.day === "friday") {
        childData.type === "close"
          ? (this.fridayData.closing_hours = `${childData.hour}:${childData.minutes} ${childData.period}`)
          : (this.fridayData.opening_hours = `${childData.hour}:${childData.minutes} ${childData.period}`);
      }
    },

    // update store time--------------------------------
    async UpdateStoreTime() {
      let data = {
        time: [
          {
            closing_hours: this.saturdayData.is24Open
              ? this.default_hour
              : this.saturdayData.closing_hours,
            day: this.saturdayData.day,
            is_closed: this.saturdayData.flag ? 1 : 0,
            always_open: this.saturdayData.is24Open ? 1 : 0,
            opening_hours: this.saturdayData.is24Open
              ? this.default_hour
              : this.saturdayData.opening_hours,
          },
          {
            closing_hours: this.sundayData.is24Open
              ? this.default_hour
              : this.sundayData.closing_hours,
            day: this.sundayData.day,
            is_closed: this.sundayData.flag ? 1 : 0,
            always_open: this.sundayData.is24Open ? 1 : 0,
            opening_hours: this.sundayData.is24Open
              ? this.default_hour
              : this.sundayData.opening_hours,
          },

          {
            closing_hours: this.mondayData.is24Open
              ? this.default_hour
              : this.mondayData.closing_hours,
            day: this.mondayData.day,
            is_closed: this.mondayData.flag ? 1 : 0,

            always_open: this.mondayData.is24Open ? 1 : 0,

            opening_hours: this.mondayData.is24Open
              ? this.default_hour
              : this.mondayData.opening_hours,
          },

          {
            closing_hours: this.tuesdayData.is24Open
              ? this.default_hour
              : this.tuesdayData.closing_hours,
            day: this.tuesdayData.day,
            is_closed: this.tuesdayData.flag ? 1 : 0,
            always_open: this.tuesdayData.is24Open ? 1 : 0,

            opening_hours: this.tuesdayData.is24Open
              ? this.default_hour
              : this.tuesdayData.opening_hours,
          },

          {
            closing_hours: this.wednesdayData.is24Open
              ? this.default_hour
              : this.wednesdayData.closing_hours,
            day: this.wednesdayData.day,
            is_closed: this.wednesdayData.flag ? 1 : 0,
            always_open: this.wednesdayData.is24Open ? 1 : 0,
            opening_hours: this.wednesdayData.is24Open
              ? this.default_hour
              : this.wednesdayData.opening_hours,
          },

          {
            closing_hours: this.thursdayData.is24Open
              ? this.default_hour
              : this.thursdayData.closing_hours,
            day: this.thursdayData.day,
            is_closed: this.thursdayData.flag ? 1 : 0,
            always_open: this.thursdayData.is24Open ? 1 : 0,
            opening_hours: this.thursdayData.is24Open
              ? this.default_hour
              : this.thursdayData.opening_hours,
          },

          {
            closing_hours: this.fridayData.is24Open
              ? this.default_hour
              : this.fridayData.closing_hours,
            day: this.fridayData.day,
            is_closed: this.fridayData.flag ? 1 : 0,
            always_open: this.fridayData.is24Open ? 1 : 0,
            opening_hours: this.fridayData.is24Open
              ? this.default_hour
              : this.fridayData.opening_hours,
          },
        ],
      };

      let stringifyData = JSON.stringify(data);
      let formData = new URLSearchParams();
      formData.append("store", stringifyData);

      await this.updateStoreTiming(formData);
    },
  },
};
</script>

<style>
.store-table-padding {
  padding: 1.7rem;
}

.store-timing-container {
  width: 60%;
}

.wd-20 {
  max-width: 20%;
}

.store-off {
  border-color: #bdbdbd !important;
  color: #bdbdbd !important;
}

.update-time-btn {
  width: 20%;
  height: 50px;
}

.toggle-off {
  background: #c6c6c8;
  border-color: #c6c6c8;
}

.disable {
  opacity: 0.4;
}

.status {
  margin-left: 5px;
}

.open {
  color: #2dc06b;
}
.close {
  color: #f13939;
}

@media only screen and (max-width: 1370px) {
  .store-timing-container {
    width: 100%;
  }

  .update-time-btn {
    width: 25%;
    margin-left: 70%;
  }
}

@media only screen and (max-width: 1200px) {
  .store-timing-container {
    width: 100%;
  }

  .update-time-btn {
    width: 30%;
    margin-left: 35%;
  }

  .status {
    display: none;
  }

  .closeBtn {
    width: 100%;
  }

  .closeTd {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .update-time-btn {
    width: 50%;
    margin-bottom: 1rem !important;
    margin: auto;
  }
}
</style>
