<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div
            class="mt-3 d-flex align-items-center state-color__fg--grey page-heading-top-padding"
          >
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Theme',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="font-weight-500 ms-3 mb-0 page-heading-text-size ">
              {{ $t("appearance.main.pages.heading") }}
            </h4>
          </div>

          <div class="my-3">
            <div
              class="d-flex justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end flex-column flex-lg-row flex-md-row flex-xl-row"
            >
              <!-- <div
                class="
                    input-group
                    mb-3
                    border border-1
                    bg-white
                    w-50
                    search-box__container
                    common-border__radius
                    "
                style="max-height:60px"
              >
                <div
                  class="input-group-prepend bg-white my-auto px-2 common-border__radius "
                >
                  <img
                    src="../../assets/icon/search.svg"
                    alt=""
                    class="my-auto"
                  />
                </div>
                <input
                  type="text"
                  class="form-control no-border common-border__radius"
                  placeholder="Search by page name"
                  aria-label=""
                  aria-describedby="basic-addon1"
                />
              </div> -->
              <div class="mb-2">
                <router-link
                  :to="{
                    name: 'AddNewPage',
                    params: { languageCode: getLanguage },
                  }"
                  type="button"
                  class="btn btn-primary google_sans_medium button_padding d-flex align-items-center mobile-screen"
                >
                  <img src="../../assets/icon/button-add.svg" alt="" />

                  <span style="margin-left:10px !important">
                    {{ $t("appearance.main.pages.add_page_btn") }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="bg-white common-border__radius my-4">
            <ul class="nav nav-pills orders_menu common-border__radius">
              <li class="nav-item page-status-menu">
                <button
                  class="nav-link state-color__fg--grey1 google_sans_medium"
                  :class="{ 'button-active': activePage == 1 }"
                  @click="getAllPagesRequest()"
                >
                  {{ $t("appearance.main.pages.all") }}
                </button>
              </li>

              <li class="nav-item page-status-menu">
                <button
                  class="nav-link state-color__fg--grey1 google_sans_medium"
                  :class="{ 'button-active': activePage == 2 }"
                  @click="PublishedPage()"
                >
                  {{ $t("appearance.main.pages.published") }}
                </button>
              </li>
              <li class="nav-item page-status-menu" @click="draftPage">
                <button
                  class="nav-link state-color__fg--grey1 google_sans_medium"
                  :class="{ 'button-active': activePage == 3 }"
                >
                  {{ $t("appearance.main.pages.draft") }}
                </button>
              </li>
            </ul>
          </div>

          <div class="my-4">
            <all-pages-table
              :pagesData="allPages"
              :shopUrl="shopUrl"
              @deletedPageId="deletePageRequest"
            />
          </div>

          <!-- <div
            v-if="!allPages || (allPages && !allPages.length)"
            class="d-flex flex-column  justify-content-center align-items-center"
          >
            <img src="../../assets/icon/no-page.svg" alt="" />
            <h5>No page found!</h5>
          </div> -->
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import AllPagesTable from "../../components/manage/theme/AllPagesTable.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { SideBar, TopBar, LoadingSpinner, AllPagesTable },

  data() {
    return {
      activePage: 1,
      pageData: "",
      shopUrl: "",
    };
  },

  async created() {
    let localStorageData = localStorage.getItem("majhiUser");
    let data = JSON.parse(localStorageData);
    this.shopUrl = data.shop_front_url;
    await this.getAllPages();
  },

  computed: {
    ...mapGetters("ExtraPages", ["allPages"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("ExtraPages", [
      "getAllPages",
      "getRequestedPages",
      "deletePage",
    ]),

    async getAllPagesRequest() {
      this.activePage = 1;
      await this.getAllPages();
    },

    async PublishedPage() {
      this.activePage = 2;
      await this.getRequestedPages(1);
    },

    async draftPage() {
      this.activePage = 3;
      await this.getRequestedPages(0);
    },

    async deletePageRequest(id) {
      await this.deletePage(id);
    },
  },
};
</script>

<style scoped>
.page-status-menu {
  position: relative;
}

.button-active::after {
  content: "";
  position: absolute;
  left: 23%;
  bottom: 0;
  height: 1px;
  width: 50%; /* or 100px */
  border-bottom: 2px solid #3a86ff;
}

@media only screen and (max-width: 700px) {
  .mobile-screen {
    margin: 10px 2rem !important;
  }
}
</style>
