<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container">
          <div
            class="
              mt-3
              state-color__fg--grey
              d-flex
              justify-content-between
              flex-column flex-lg-row flex-md-row flex-xl-row
            "
          >
            <div class="my-auto d-flex state-color__fg--grey">
              <img
                src="../../assets/icon/back_btn_image.svg"
                alt="back"
                class="make-cursor"
                height="25"
                width="25"
                @click="destroyCurrentTab()"
              />
              <h5 class="ms-3 mb-0">
                {{ $t("product.main.product_attribute.title") }}
              </h5>
            </div>
          </div>
          <div class="d-flex justify-content-center attribute-container mt-5">
            <div
              class="
                bg-white
                common-border__radius
                create-attribute-input-field-container
                my-1
                mx-3
              "
            >
              <create-attribute-container
                :allAttributes="allAttributes"
                @requestToSetNewAttributeList="getAttributeBycategory()"
              />
            </div>

            <div
              class="
                bg-white
                common-border__radius
                show-attribute-field-container
                my-1
                mx-3
              "
            >
              <show-created-attributes
                :allAttributes="allAttributes"
                @requestToDeleteAttributeValue="deleteAttribute($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toast, {
  errorBackground,
  successBackground,
} from "../../config/toastConfig";
import { mapActions, mapGetters } from "vuex";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import CreateAttributeContainer from "../../components/product/with-attribute/CreateAttributeContainer.vue";
import ShowCreatedAttributes from "../../components/product/with-attribute/ShowCreatedAttributes.vue";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    CreateAttributeContainer,
    ShowCreatedAttributes,
  },

  data() {
    return {
      allAttributes: [],
      selectedCategoryId: 0,
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("Loader", ["setOverlayStatus"]),

    deleteAttribute(payload) {
      const vm = this;

      axios
        .delete(
          "/seller_attribute_value_delete/" + payload.id_attribute_value,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("majhiAccessToken"),
            },
          }
        )
        .then(function(response) {
          vm.getAttributeBycategory();
          Toast.fire({
            icon: "success",
            title: response.data.message,
            background: successBackground,
          });

          localStorage.setItem("newAttributeValueFlag", "yes");
        })
        .catch(function(error) {
          Toast.fire({
            icon: "error",
            title: vm.$t("product.main.toast.combination_err"),
            background: errorBackground,
          });

          vm.setOverlayStatus(false);
          console.log(error);
        });
    },

    async getAttributeBycategory() {
      let vm = this;
      //vm.selectedCategoryId = 72; // this line will be removed using the stable database
      await axios
        .get(
          `/seller_product_attribute_list_by_cat/${this.getLanguage}/` +
            vm.selectedCategoryId,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("majhiAccessToken"),
            },
          }
        )
        .then(function(response) {
          vm.allAttributes = response.data.data;
          vm.$store.commit("setOverlayStatus", false);
          vm.setOverlayStatus(false);
        })
        .catch(function(error) {
          vm.$store.commit("setOverlayStatus", false); //loader close
          console.log(error);
        });
    },

    destroyCurrentTab() {
      window.close();
    },
  },

  async created() {
    this.$store.commit("setOverlayStatus", true);
    this.selectedCategoryId = await this.$route.params.categoryId;
    await this.getAttributeBycategory();
    this.$store.commit("setOverlayStatus", false);
  },
};
</script>

<style scoped>
.create-attribute-input-field-container {
  width: 60%;
}

.show-attribute-field-container {
  width: 20%;
}

@media screen and (max-width: 600px) {
  .attribute-container {
    flex-direction: column;
    align-items: center;
  }

  .show-attribute-field-container,
  .create-attribute-input-field-container {
    width: 90%;
  }
}
</style>
