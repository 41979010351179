<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container">
          <div class="d-flex align-items-center">
            <AppBaseCustomBackBtn @redirectToPreviousPage="$router.go(-1)">
              <template v-slot:btn_placeholder>{{
                $t("product.category.direct_category_assign.update_heading")
              }}</template>
            </AppBaseCustomBackBtn>
          </div>

          <div class="d-flex justify-content-center">
            <div :class="$style.width_50">
              <div
                class="bg-white common-border__radius"
                :class="[$style.white_bg_container]"
              >
                <div
                  class="d-flex justify-content-between align-items-center flex-wrap"
                >
                  <h5 class="mb-0">
                    {{
                      $t(
                        "product.category.direct_category_assign.update_heading"
                      )
                    }}
                  </h5>
                  <p class="mb-0">
                    {{
                      $t("product.category.direct_category_assign.cant_find")
                    }}
                    <span
                      class="state-color__fg--Info text-decoration-underline fw-bold"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#addCategoriesModal"
                      id="addNewCategoryBtn"
                    >
                      {{ $t("product.category.add_category_btn") }}
                    </span>
                  </p>
                </div>

                <div :class="$style.search_field_container">
                  <AppBaseInputSearchField
                    :input_field_placeholder="
                      $t(
                        'product.category.direct_category_assign.search_place_holder'
                      )
                    "
                    @sendSearchedField="searchInput($event)"
                  ></AppBaseInputSearchField>
                </div>

                <div>
                  <OnboardingCategoryTabContainer
                    :searchedKey="searchedKey"
                    @setSelectedCategory="setSelectedCategory"
                  ></OnboardingCategoryTabContainer>
                </div>
              </div>

              <div
                class="d-flex justify-content-end"
                :class="$style.app_btn_container"
              >
                <AppBaseBlueBtn
                  :horizontalPadding="4"
                  @click="submitSelectedCategory()"
                  ><template v-slot:btn_name>{{
                    $t("product.category.direct_category_assign.submit_btn")
                  }}</template></AppBaseBlueBtn
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <loading-spinner />
    </div>

    <AddNewCategoryModal />
  </div>
</template>

<script setup>
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import AppBaseCustomBackBtn from "../../components/primary/AppBaseCustomBackBtn.vue";
import AppBaseInputSearchField from "../../components/primary/AppBaseInputSearchField.vue";
import OnboardingCategoryTabContainer from "../../components/primary/OnboardingCategoryTabContainer.vue";
import AppBaseBlueBtn from "../../components/primary/AppBaseBlueBtn.vue";
import AddNewCategoryModal from "../../components/category/AddNewCategoryModal.vue";
import Toast, { errorBackground } from "../../config/toastConfig";

import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const searchedKey = ref("");
const selectedCategories = ref({});
const store = useStore();
const { t } = useI18n(); // use as global scope
const user = store.getters["auth/getUser"];

const searchInput = (searchedField) => {
  searchedKey.value = searchedField;
};

const setSelectedCategory = (categories) => {
  selectedCategories.value = categories;
};

const convertExistingCategoryToString = () => {
  let existingcommaSeparatedCategories = "";
  store.getters[
    "productCategories/getAllProductCategoriesUnderBusinessCategory"
  ].selected_categories.forEach(function(item) {
    existingcommaSeparatedCategories =
      item.id_product_category + "," + existingcommaSeparatedCategories;
  });
  return existingcommaSeparatedCategories;
};

const submitSelectedCategory = async () => {
  if (Object.keys(selectedCategories.value).length == 0) {
    Toast.fire({
      icon: "error",
      title: t("product.category.direct_category_assign.error_select_category"),
      background: errorBackground,
    });
    return;
  }
  let idProductCategories = "";
  store.dispatch("Loader/setOverlayStatus", true);
  let existingCommaSeparatedString = convertExistingCategoryToString();

  idProductCategories = Object.values(selectedCategories.value).join(",");
  let isCategoryCreated = await store.dispatch(
    "productCategories/instantCategoryCreate",
    {
      id_product_categories: existingCommaSeparatedString + idProductCategories,
      id_business_category: user.business_category_id,
    }
  );
  store.dispatch("Loader/setOverlayStatus", false);
  if (!isCategoryCreated) return;
};
</script>

<style module>
.width_50 {
  width: 50%;
}

.white_bg_container {
  margin-top: 1.93rem;
  padding: 1.5rem;
  min-height: 33rem;
}

.search_field_container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.app_btn_container {
  margin-top: 1.25rem !important;
}

@media only screen and (max-width: 576px) {
  .width_50 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .width_50 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .width_50 {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
}

@media only screen and (min-width: 1400px) {
}
</style>
