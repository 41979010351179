import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

// import store  from '../store'
//sign up pages import
import SignUp from "../views/signup/SignUp.vue";
import SignUp1 from "../views/signup/SignUp1.vue";
import SignupSuccess from "../views/signup/RegistrationSuccess.vue";

//sign up pages import
import SignIn from "../views/signin/SignIn.vue";

// on boarding
import OnBoarding1 from "../views/OnBoarding1.vue";
import OnBoarding2 from "../views/OnBoarding2.vue";
import OnBoarding3 from "../components/primary/StoreTime";

//home
import Home from "../views/home/Home.vue";

//all products
import AllCategories from "../views/product/AllCategories.vue";
import AllProducts from "../views/product/AllProducts.vue";

import ProductsBasedOnCategory from "../views/product/ProductsBasedOnCategory.vue";

import AddNewProductWithAttribuites from "../views/product/AddNewProductWithAttributes.vue";
import CreateProductAttribute from "../views/product/CreateProductAttribute.vue";
import EditProductWithAttribute from "../views/product/EditProductWithAttribute.vue";

import AppDirectProductCategoryAssign from "../views/product/AppDirectProductCategoryAssign";

//orders
import AllOrders from "../views/order/Order.vue";
import OrderDetails from "../views/order/OrderDetails.vue";

//manage
import Manage from "../views/manage/Manage.vue";
import CustomerList from "../views/manage/CustomerList.vue";
import StoreQRCode from "../views/manage/StoreQRCode.vue";

import Theme from "../views/manage/Theme.vue";
import AllPages from "../views/manage/AllPages.vue";
import AddNewPage from "../views/manage/AddNewPage.vue";
import PaymentMethods from "../views/manage/PaymentMethods.vue";
import StoreAddress from "../views/manage/StoreAddress.vue";

//manage coupon
import CreateCoupon from "../views/manage/CreateCoupon.vue";
import CouponView from "../views/manage/CouponView.vue";
import UpdateCoupon from "../views/manage/UpdateCoupon.vue";

import ExtraCharges from "../views/manage/ExtraCharges.vue";
import OrderForm from "../views/manage/OrderForm.vue";
import SocialMediaSupport from "../views/manage/SocialMediaSupport.vue";

//settings
import EditProfile from "../views/settings/EditProfile.vue";

import Settings from "../views/settings/Settings.vue";

//upload banner
import UploadBanner from "../views/manage/UploadBanner.vue";

// store timing
import StoreTiming from "../views/manage/StoreTiming.vue";

// marketing materials
import MarketingMaterials from "../views/manage/MarketingMaterials.vue";

import Invoice from "../components/Invoice.vue";

import PrintInvoice from "../views/manage/PrintInvoice.vue";

import Notification from "../views/Notification.vue";

// Appearance
import Appearance from "../views/appearance/Appearance.vue";

import NotFound from "../../src/views/404.vue";
import NoInternet from "../../src/views/NoInternet.vue";

import RedirectToHome from "../components/RedirectToHome";

const routes = [
  {
    path: "/:languageCode/no-internet",
    name: "NoInternet",
    component: NoInternet,
    props: true,
  },

  {
    path: "/",
    name: "prehome",
    component: RedirectToHome,
  },

  {
    path: "/:languageCode/signup",
    name: "SignUp",
    component: SignUp,
  },

  {
    path: "/:languageCode/signup-business",
    name: "SignUp1",
    component: SignUp1,
    props: true,
  },

  {
    path: "/:languageCode/signup-success",
    name: "SignupSuccess",
    component: SignupSuccess,
    props: true,
  },

  {
    path: "/:languageCode/signin",
    name: "SignIn",
    component: SignIn,
  },

  {
    path: "/:languageCode/step-1",
    name: "OnBoarding1",
    component: OnBoarding1,
    beforeEnter: (to, from, next) => {
      if (to.params.isValidForOnBoarding == "true") {
        next();
      }
      next({
        name: "Home",
        params: { languageCode: store.getters["language/getLanguage"] },
      });
    },
  },

  {
    path: "/:languageCode/step-2",
    name: "OnBoarding2",
    component: OnBoarding2,
    beforeEnter: (to, from, next) => {
      if (to.params.isValidForOnBoarding == "true") {
        next();
      }
      next({
        name: "Home",
        params: { languageCode: store.getters["language/getLanguage"] },
      });
    },
  },

  {
    path: "/:languageCode/step-3",
    name: "OnBoarding3",
    component: OnBoarding3,
    beforeEnter: (to, from, next) => {
      if (to.params.isValidForOnBoarding == "true") {
        next();
      }
      next({
        name: "Home",
        params: { languageCode: store.getters["language/getLanguage"] },
      });
    },
  },

  {
    path: "/:languageCode",
    name: "Home",
    component: Home,
  },

  {
    path: "/:languageCode/invoice",
    name: "Invoice",
    component: Invoice,
  },

  {
    path: "/:languageCode/print-invoice/:id",
    name: "PrintInvoice",
    component: PrintInvoice,
  },

  // product routes

  {
    path: "/:languageCode/products/categories",
    name: "AllCategories",
    component: AllCategories,
  },

  {
    path: "/:languageCode/categories/:categoryId/:categoryName",
    name: "CategoriesProduct",
    component: ProductsBasedOnCategory,
    props: true,
  },

  {
    path: "/:languageCode/products/product",
    name: "AllProducts",
    component: AllProducts,
  },

  {
    path: "/:languageCode/products/product-edit",
    name: "EditProductWithAttribute",
    component: EditProductWithAttribute,
    props: true,
  },

  {
    path: "/:languageCode/products/product-add",
    name: "AddNewProductWithAttribuites",
    component: AddNewProductWithAttribuites,
  },

  {
    path: "/:languageCode/products/create-attribute/:categoryId",
    name: "CreateProductAttribute",
    component: CreateProductAttribute,
  },

  {
    path: "/:languageCode/profile/category-assign",
    name: "AppDirectProductCategoryAssign",
    component: AppDirectProductCategoryAssign,
  },

  //orders route
  {
    path: "/:languageCode/orders",
    name: "AllOrders",
    component: AllOrders,
  },
  {
    path: "/:languageCode/orders/details/:orderId",
    name: "OrderDetails",
    component: OrderDetails,
  },

  // manage routes
  {
    path: "/:languageCode/manage",
    name: "Manage",
    component: Manage,
  },

  {
    path: "/:languageCode/manage/customer-list",
    name: "CustomerList",
    component: CustomerList,
  },

  {
    path: "/:languageCode/manage/store-qr-code",
    name: "StoreQRCode",
    component: StoreQRCode,
  },

  {
    path: "/:languageCode/manage/payment-methods",
    name: "PaymentMethods",
    component: PaymentMethods,
  },

  {
    path: "/:languageCode/manage/store-address",
    name: "StoreAddress",
    component: StoreAddress,
  },

  //manage coupon

  {
    path: "/:languageCode/manage/coupon/create",
    name: "CreateCoupon",
    component: CreateCoupon,
  },

  {
    path: "/:languageCode/manage/coupon/update/:id",
    name: "UpdateCoupon",
    component: UpdateCoupon,
  },

  {
    path: "/:languageCode/manage/coupon/view",
    name: "CouponView",
    component: CouponView,
  },

  //extra charges

  {
    path: "/:languageCode/manage/extra-charges",
    name: "ExtraCharges",
    component: ExtraCharges,
  },

  //manage order form
  {
    path: "/:languageCode/manage/order-form",
    name: "OrderForm",
    component: OrderForm,
  },

  //manage social media support
  {
    path: "/:languageCode/manage/social-media",
    name: "SocialMediaSupport",
    component: SocialMediaSupport,
  },

  //upload banner
  {
    path: "/:languageCode/manage/banner-upload",
    name: "UploadBanner",
    component: UploadBanner,
  },

  //store timing
  {
    path: "/:languageCode/manage/store-timing",
    name: "StoreTiming",
    component: StoreTiming,
  },

  // marketing materials
  {
    path: "/:languageCode/manage/marketing-materials",
    name: "MarketingMaterials",
    component: MarketingMaterials,
  },

  //settings
  {
    path: "/:languageCode/settings",
    name: "Settings",
    component: Settings,
  },

  // profile route
  {
    path: "/:languageCode/profile-update",
    name: "EditProfile",
    component: EditProfile,
  },

  // notification
  {
    path: "/:languageCode/notification",
    name: "Notification",
    component: Notification,
  },

  //Appearances
  {
    path: "/:languageCode/appearance",
    name: "Appearance",
    component: Appearance,
  },

  {
    path: "/:languageCode/appearance/my-theme",
    name: "Theme",
    component: Theme,
  },

  {
    path: "/:languageCode/appearance/my-theme/all-pages",
    name: "AllPages",
    component: AllPages,
  },

  //manage theme add new pages
  {
    path: "/:languageCode/appearance/my-theme/page/add",
    name: "AddNewPage",
    component: AddNewPage,
  },

  //manage theme edit page
  {
    path: "/:languageCode/appearance/my-theme/page/edit/:id",
    name: "EditPage",
    component: AddNewPage,
  },

  {
    path: "/:languageCode/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  // {
  //   path: "/:pathMatch(.*)",
  //   redirect: "/signin",
  // },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    props: true,
  },
];

const openRoutes = ["SignUp", "SignUp1", "SignIn"];
const closeRoutes = [
  "SignupSuccess",
  "Home",
  "About",
  "EditProfile",
  "AllCategories",
  "AllProducts",
  "AddNewProduct",
  "Appearance",
  "AddNewProductWithAttribuites",
  "CreateProductAttribute",
  "EditProduct",
  "EditProductWithAttribute",
  "AllOrders",
  "OrderDetails",
  "Manage",
  "CustomerList",
  "PaymentMethods",
  "StoreQRCode",
  "Theme",
  "AllPages",
  "EditPage",
  "AddNewPage",
  "CreateCoupon",
  "UpdateCoupon",
  "CouponView",
  "ExtraCharges",
  "OrderForm",
  "SocialMediaSupport",
  "UploadBanner",
  "StoreTiming",
  "MarketingMaterials",
  "Settings",
  "PrintInvoice",
  "Invoice",
  "Notification",
  "CategoriesProduct",
  "OnBoarding1",
  "OnBoarding2",
  "OnBoarding3",
  "AppDirectProductCategoryAssign",
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("isAuthenticated") === "true") {
    if (closeRoutes.includes(to.name)) {
      next();
    } else {
      if (openRoutes.includes(to.name)) {
        next({
          name: "Home",
          params: { languageCode: store.getters["language/getLanguage"] },
        });
      } else {
        next();
      }
    }
  } else {
    if (openRoutes.includes(to.name)) {
      next();
    } else {
      if (closeRoutes.includes(to.name)) {
        next({
          name: "SignIn",
          params: { languageCode: store.getters["language/getLanguage"] },
        });
      } else {
        next();
      }
    }
  }
});

export default router;
