<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="CustomOrderSearch"
    tabindex="-1"
    aria-labelledby="CustomOrderSearchLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius p-4">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important"
        >
          <h5 class="modal-title" id="CustomOrderSearchLabel">
            {{ $t("home.order.order_search.title") }}
          </h5>
          <button
            type="button"
            class="modal-close"
            id="custom-order-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">{{
              $t("home.order.order_search.start_date")
            }}</label>
            <input
              type="date"
              class="form-control date-text"
              name="custom-order-search-start-date"
              v-model="startDate"
              @change="startDateUpdate()"
            />
          </div>

          <div class="mb-3">
            <label class="form-label">{{
              $t("home.order.order_search.end_date")
            }}</label>
            <input
              type="date"
              class="form-control date-text"
              name="custom-order-search-end-date"
              v-model="endDate"
              @change="endDateUpdate()"
            />
          </div>

          <button class="btn btn-primary p-2" @click="searchOrder()">
            {{ $t("home.order.order_search.btn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: function() {
    return {
      startDate: "",
      endDate: "",
    };
  },

  computed: {
    ...mapGetters("Order", [
      "activeMenu",
      "currentPage",
      "isCustomSearch",
      "isSearchKeyword",
    ]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("Order", ["allOrderRequest", "getOrderByStatus"]),
    ...mapMutations("Order", [
      "SET_CUSTOM_SEARCH_DATE",
      "SET_CUSTOM_SEARCH_STATUS",
    ]),

    async searchOrder() {
      this.SET_CUSTOM_SEARCH_STATUS(true);
      this.SET_CUSTOM_SEARCH_DATE([this.startDate, this.endDate]);

      if (this.activeMenu !== 0) {
        if (this.isSearchKeyword) {
          let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenu}/${this.currentPage}?start=${this.startDate}&end=${this.endDate}&filter=${this.isSearchKeyword}`;
          await this.getOrderByStatus(url);
        } else {
          //let url = "/seller_order_by_status/en/" + this.activeMenuId + "/1";
          let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenu}/${this.currentPage}?start=${this.startDate}&&end=${this.endDate}`;
          await this.getOrderByStatus(url);
        }
      } else {
        if (this.isSearchKeyword) {
          let url = `seller_order_list/${this.getLanguage}/1?start=${this.startDate}&end=${this.endDate}&filter=${this.isSearchKeyword}`;
          await this.allOrderRequest(url);
        } else {
          let url = `seller_order_list/${this.getLanguage}/1?start=${this.startDate}&&end=${this.endDate}`;
          await this.allOrderRequest(url);
        }
      }

      document.getElementById("custom-order-close").click();
    },

    startDateUpdate() {
      document
        .getElementsByName("custom-order-search-end-date")[0]
        .setAttribute("min", this.startDate);
    },

    endDateUpdate() {
      document
        .getElementsByName("custom-order-search-start-date")[0]
        .setAttribute("max", this.endDate);
    },
  },

  mounted() {
    let today = new Date().toISOString().split("T")[0];
    document
      .getElementsByName("custom-order-search-start-date")[0]
      .setAttribute("max", today);
    document
      .getElementsByName("custom-order-search-end-date")[0]
      .setAttribute("max", today);
  },
};
</script>

<style scoped>
.date-text {
  color: gray !important;
}
</style>
