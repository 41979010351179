import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getAllProduct(url) {
    return Client({
      url,
      method: "GET",
    });
  },

  deleteProduct(productId) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/product_delete/${language}/${productId}`,
      method: "DELETE",
    });
  },

  deleteProductImage({ productId, imageName }) {
    return Client({
      url: `/product_image_delete/${productId}/${imageName}`,
      method: "DELETE",
    });
  },

  getAllCategories(businessCategoryId) {
    return Client({
      url: `/product_category_by_id/${businessCategoryId}`,
      method: "GET",
    });
  },

  addNewProduct(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/product_create/${language}`,
      method: "POST",
      data,
    });
  },

  getAttributeByCategory(id) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_product_attribute_list_by_cat/${language}/${id}`,
      method: "GET",
    });
  },

  getAllRibbons() {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/ribbon/${language}`,
      method: "GET",
    });
  },

  getAllUnits() {
    return Client({
      url: "/unit",
      method: "GET",
    });
  },

  updateProduct(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/product_update/${language}`,
      method: "POST",
      data,
    });
  },
};
