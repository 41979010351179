<template>
  <div class="all-categories__container common-border__radius overflow-x-auto">
    <table>
      <thead>
        <tr
          class="state-color__fg--grey1 google_sans_medium product-table-heading"
        >
          <td class="product-table-single-header">
            {{ $t("product.main.product_table_header.h1") }}
          </td>
          <td style="width: 10%" class="product-table-single-header">
            {{ $t("product.main.product_table_header.h2") }}
          </td>
          <td class="product-table-single-header">
            {{ $t("product.main.product_table_header.h3") }}
          </td>
          <td class="product-table-single-header">
            {{ $t("product.main.product_table_header.h4") }}
          </td>
          <td class="product-table-single-header">
            {{ $t("product.main.product_table_header.h5") }}
          </td>
          <td class="product-table-single-header">
            {{ $t("product.main.product_table_header.h6") }}
          </td>
          <td class="product-table-single-header" style="width: 25%">
            {{ $t("product.main.product_table_header.h7") }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="filteredProduct in filteredProducts"
          :key="filteredProduct.id_product"
          class="product-table-body-text-size"
        >
          <td data-label="Item" style="padding-left:20px !important">
            <div
              class="d-lg-flex d-md-flex justify-content-lg-start ustify-content-md-start"
              style="min-height: 100px"
            >
              <img
                v-if="filteredProduct?.product_images"
                :src="filteredProduct?.product_images[0]?.image_path"
                alt=""
                width="80"
                height="80"
                class="border common-border__radius-8 my-auto"
                style="object-fit:contain"
              />
              <p class="my-auto google_sans_medium mx-1 product-title">
                {{ filteredProduct.title }}
              </p>
            </div>
          </td>
          <td data-label="Product Id">
            {{ filteredProduct.id_product }}
          </td>
          <td data-label="Category" style="word-wrap: break-word">
            {{ filteredProduct.product_category?.product_category_name }}
          </td>
          <td data-label="Price">
            <p
              v-if="!filteredProduct.product_attributes_combination"
              class="mb-0"
            >
              <span class="">&#2547;{{ filteredProduct.price }}</span>
            </p>
            <p v-else class="mb-0">
              <span v-html="price(filteredProduct)"> </span>
            </p>
          </td>

          <td>
            <p
              v-if="!filteredProduct.product_attributes_combination"
              class="mb-0"
            >
              <span class="">{{ filteredProduct.quantity }}</span>
            </p>
            <p v-else class="mb-0">
              <span>{{ quantity(filteredProduct) }}</span>
            </p>
          </td>

          <td data-label="Availability">
            <div
              class="cursor-pointer"
              v-if="filteredProduct.stock_status == 1"
              data-bs-toggle="modal"
              data-bs-target="#updateProductStockModal"
              @click="
                showAvailabilityStatus(
                  filteredProduct.id_product,
                  filteredProduct.stock_status
                )
              "
            >
              <label class="mx-1 text-in-stock">{{
                $t("product.main.update_stock.in_stock")
              }}</label>
              <img src="../../assets/icon/instock.png" alt="" />
            </div>

            <div
              class="cursor-pointer"
              v-else-if="filteredProduct.stock_status == 0"
              data-bs-toggle="modal"
              data-bs-target="#updateProductStockModal"
              @click="
                showAvailabilityStatus(
                  filteredProduct.id_product,
                  filteredProduct.stock_status
                )
              "
            >
              <label class="mx-1 text-hide">{{
                $t("product.main.update_stock.hide")
              }}</label>
              <img src="../../assets/icon/hide.png" alt="" />
            </div>

            <div
              class="cursor-pointer"
              v-else-if="filteredProduct.stock_status == 2"
              data-bs-toggle="modal"
              data-bs-target="#updateProductStockModal"
              @click="
                showAvailabilityStatus(
                  filteredProduct.id_product,
                  filteredProduct.stock_status
                )
              "
            >
              <label class="mx-1 text-out-of-stock">{{
                $t("product.main.update_stock.out_of_stock")
              }}</label>
              <img src="../../assets/icon/outofstock.png" alt="" />
            </div>
          </td>
          <td data-label="Action">
            <button
              type="button"
              class="btn btn-outline-secondary m-1"
              @click="
                copy(filteredProduct.id_product, filteredProduct.product_slug)
              "
            >
              <img src="../../assets/icon/menu_share.svg" alt="" width="14" />
              <span class="product-action-button" style="margin-left:5px">{{
                $t("product.main.share_btn")
              }}</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary m-1"
              @click="editProduct(filteredProduct)"
            >
              <img src="../../assets/icon/edit.svg" alt="" width="20" />

              <span class="product-action-button">{{
                $t("product.main.edit_btn")
              }}</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary m-1"
              @click="deleteProduct(filteredProduct.id_product)"
            >
              <img src="../../assets/icon/menu_delete.svg" alt="" width="14" />

              <span class="product-action-button" style="margin-left:5px">{{
                $t("product.main.delete_btn")
              }}</span>
            </button>
          </td>
        </tr>

        <tr
          v-if="
            !filteredProducts ||
              (filteredProducts && !filteredProducts.length && !searchField)
          "
        >
          <td colspan="7">
            <div
              class="d-flex justify-content-center mb-5"
              v-if="!getOverlayStatus"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="../../assets/icon/No_products.svg" alt="" />
                <h6 class="state-color__fg--grey1">
                  {{ $t("product.main.not_found.title") }}!
                </h6>
                <p>{{ $t("product.main.not_found.sub_title") }}</p>

                <div>
                  <router-link
                    :to="{
                      name: 'AddNewProductWithAttribuites',
                      params: { languageCode: getLanguage },
                    }"
                    class="btn btn-primary google_sans_medium py-3 px-4 d-flex justify-content-center align-items-center"
                  >
                    <img src="../../assets/icon/button-add.svg" alt="" />

                    <span style="margin-left:10px">{{
                      $t("product.main.add_product_btn")
                    }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr
          v-else-if="
            (!filteredProducts ||
              (filteredProducts && !filteredProducts.length)) &&
              searchField
          "
        >
          <td colspan="7">
            <search />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-component
    v-if="filteredProducts.length"
    v-bind:lastPage="lastPage"
    v-bind:currentPage="currentPage"
    v-on:requestDataByPageNumber="getDataByPage($event)"
  />

  <!-- modal  -->
  <div
    class="modal fade"
    id="updateProductStockModal"
    tabindex="-1"
    aria-labelledby="updateProductStockModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius">
        <div class="modal-header">
          <h5 class="modal-title" id="updateStockModalLabel">
            {{ $t("product.main.update_stock.title") }}
          </h5>
          <button type="button" class="modal-close" data-bs-dismiss="modal">
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="d-flex align-items-start">
              <input
                class="my-1"
                type="radio"
                id="huey"
                name="drone"
                value="1"
                v-model="availabilityStatus"
                :checked="availabilityStatus == 1"
                @click="requestUpdateAvaailabilityStatus(1)"
              />
              <div class="mx-2">
                <h6>{{ $t("product.main.update_stock.in_stock") }}</h6>
                <p class="state-color__fg--grey1 radio-font">
                  {{ $t("product.main.update_stock.in_stock_message") }}
                </p>
              </div>
            </div>

            <div class="d-flex align-items-start">
              <input
                class="my-1"
                type="radio"
                id="huey"
                name="drone"
                value="2"
                v-model="availabilityStatus"
                :checked="availabilityStatus == 2"
                @click="requestUpdateAvaailabilityStatus(2)"
              />
              <div class="mx-2">
                <h6>{{ $t("product.main.update_stock.out_of_stock") }}</h6>
                <p class="state-color__fg--grey1 radio-font">
                  {{ $t("product.main.update_stock.out_of_stock_message") }}
                </p>
              </div>
            </div>

            <div class="d-flex align-items-start">
              <input
                class="my-1"
                type="radio"
                id="huey"
                name="drone"
                value="0"
                v-model="availabilityStatus"
                :checked="availabilityStatus == 0"
                @click="requestUpdateAvaailabilityStatus(0)"
              />
              <div class="mx-2">
                <h6>{{ $t("product.main.update_stock.hide") }}</h6>
                <p class="state-color__fg--grey1 radio-font">
                  {{ $t("product.main.update_stock.hide_message") }}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import useClipboard from "vue-clipboard3";
import Toast, {
  errorBackground,
  successBackground,
} from "../../config/toastConfig";

import PaginationComponent from "../PaginationComponent.vue";
import { useI18n } from "vue-i18n";
import Search from "../not-found/search.vue";
import Swal from "sweetalert2";

export default {
  components: { PaginationComponent, Search },
  props: ["filteredProducts", "totalPage", "lastPage", "searchField"],
  emits: ["requestDataByPageNumber"],

  data: function() {
    return {
      currentPage: 1,
      availabilityStatus: 0,
      productId: 0,
    };
  },

  setup() {
    const { toClipboard } = useClipboard();
    const { t } = useI18n();

    const copy = async (idProduct, slug) => {
      try {
        let userObject = localStorage.getItem("majhiUser");
        let authUser = JSON.parse(userObject);

        await toClipboard(
          authUser.shop_front_url + "/product-details/" + idProduct + "-" + slug
        );
        Toast.fire({
          icon: "success",
          title: t("product.main.toast.copy_link_success"),
          background: successBackground,
        });
      } catch (e) {
        Toast.fire({
          icon: "error",
          title: t("product.main.toast.copy_link_error"),
          background: errorBackground,
        });
        console.error(e);
      }
    };

    return { copy };
  },

  computed: {
    ...mapGetters("Loader", ["getOverlayStatus"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  watch: {
    lastPage() {
      this.currentPage = 1;
    },
  },

  methods: {
    ...mapActions("product", ["deleteProductRequest"]),
    price(product) {
      let text = "";

      if (product.product_attributes_combination) {
        let attributes_list =
          product.product_attributes_combination[0].attributes_list;

        for (let i = 0; i < attributes_list.length; i++) {
          let name = attributes_list[i].name;
          let arr_name = attributes_list[i].global_attribute_name;
          if (arr_name === "color") {
            let color = this.colorCodeToName(name);
            text += `<span style="display:inline-block;background:${name};width:16px; height:16px"></span> ${color} X `;
          } else {
            text += `${name}(${arr_name}) X `;
          }
        }
        text += `<span> &#2547; ${product.product_attributes_combination[0].price}</span>`;
        return text;
      }
    },

    color(product) {
      if (product.product_attributes_combination) {
        let attributes_list =
          product.product_attributes_combination[0].attributes_list;
        for (let i = 0; i < attributes_list.length; i++) {
          let name = attributes_list[i].name;
          let arr_name = attributes_list[i].global_attribute_name;
          if (arr_name === "color") {
            return name;
          }
        }
      }
    },

    colorCodeToName(code) {
      const ntc = require("ntcjs");

      const n_match = ntc.name(code);
      return n_match[1];
    },

    quantity(product) {
      return product.product_attributes_combination[0].quantity;
    },

    getDataByPage(pageNumber) {
      if (pageNumber == this.currentPage) {
        return;
      }
      this.$store.commit("setOverlayStatus", true); //loader open
      this.currentPage = pageNumber;
      this.$emit("requestDataByPageNumber", pageNumber);
    },

    showAvailabilityStatus(idProduct, availabilityStatus) {
      this.availabilityStatus = availabilityStatus;
      this.productId = idProduct;
    },

    async requestUpdateAvaailabilityStatus(requestedStatus) {
      let vm = this;
      if (requestedStatus == vm.availabilityStatus) {
        return;
      }

      await vm.$store.commit("setOverlayStatus", true); //loader open
      vm.availabilityStatus = requestedStatus;

      axios
        .put(
          `/product_stock_status/${this.getLanguage}`,
          {
            id_product: vm.productId,
            stock_status: requestedStatus,
          },
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("majhiAccessToken"),
            },
          }
        )
        .then(function(response) {
          vm.$emit("requestDataByPageNumber", vm.currentPage);
          Toast.fire({
            icon: "success",
            title: response?.data?.message,
            background: successBackground,
          });
        })
        .catch(function(error) {
          vm.$store.commit("setOverlayStatus", false); //loader close
          Toast.fire({
            icon: "error",
            title: error.response?.data?.data,
            background: errorBackground,
          });
          console.log(error);
        });
    },

    async deleteProduct(productId) {
      Swal.fire({
        title: this.$t("product.main.delete_confirmation_message.title"),
        text: this.$t("product.main.delete_confirmation_message.product"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "product.main.delete_confirmation_message.delete_btn"
        ),
        cancelButtonText: this.$t(
          "product.main.delete_confirmation_message.cancel_btn"
        ),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteProductRequest(productId);
        }
      });
    },

    editProduct(product) {
      this.$router.push({
        name: "EditProductWithAttribute",
        params: { existingProductInfo: JSON.stringify(product) },
      });
    },
  },
};
</script>

<style>
.text-out-of-stock {
  color: #f13939;
}

.text-in-stock {
  color: #2dc06b;
}

.text-hide {
  color: #8f3cff;
}

.product-action-button {
  font-size: 14px;
  color: #1d1d1d;
}

.product-table-heading {
  background-color: #fafcfd !important;
  font-size: 14px;
  height: 60px !important;
  margin: auto;
}

.product-table-single-header {
  padding-top: 15px !important;
}

.product-table-body-text-size {
  font-size: 14px;
  color: #1d1d1d;
}

.all-categories__container .btn-outline-secondary {
  background-color: white !important;
  color: #6c757d !important;
  text-decoration: none;
}

.price-color__grey {
  color: #a6a6a6;
}

@media screen and (max-width: 700px) {
}

input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.radio-font {
  /* font-size: 12px; */
  font-size: 0.75rem;
}

.product-title {
  font-size: 16px;
  padding-left: 5px;
}
</style>
