const storyStyle = [
  {
    name: "S1.png",
    left: `22%`,
    top: `400px`,
    fontFamily: `'Nunito Sans', sans-serif`,
  },

  {
    name: "S2.png",
    left: `26%`,
    top: `460px`,
    fontFamily: `'Nunito Sans', sans-serif`,
    color: "white",
  },

  {
    name: "S3.png",
    left: `26%`,
    top: `480px`,
    fontFamily: `'Nunito Sans', sans-serif`,
    color: "white",
  },

  {
    name: "S5.png",
    left: `30%`,
    top: `400px`,
    fontFamily: `'Nunito Sans', sans-serif`,
  },

  {
    name: "S9.png",
    left: `30%`,
    top: `350px`,
    fontFamily: `'Nunito Sans', sans-serif`,
  },

  {
    name: "S24.png",
    left: `30%`,
    top: `450px`,
    fontFamily: `'Montserrat', sans-serif`,
    color: "#FFFFFF",
  },

  {
    name: "S10.png",
    left: `30%`,
    top: `565px`,
    fontFamily: `'Montserrat', sans-serif`,
    color: "#FFFB00",
  },

  {
    name: "S21.png",
    left: `30%`,
    top: `385px`,
    fontFamily: `'Nunito Sans', sans-serif`,
    color: "#000000",
  },
  {
    name: "S22.png",
    left: `30%`,
    top: `535px`,
    fontFamily: `'Open Sans', sans-serif`,
    color: "#FFFFFF",
  },

  {
    name: "S12.png",
    left: `25%`,
    top: `495px`,
    fontFamily: `'Mulish', sans-serif`,
    color: "#000000",
    textTransform: "uppercase",
  },

  {
    name: "S16.png",
    left: `32%`,
    top: `325px`,
    fontFamily: `'Bebas Neue', cursive`,
    color: "#FFFFFF",
  },

  {
    name: "S17.png",
    left: `32%`,
    top: `565px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#D9221F",
  },

  {
    name: "S18.png",
    left: `32%`,
    top: `230px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#D9221F",
  },

  {
    name: "S19.png",
    left: `32%`,
    top: `530px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#ED3838",
  },

  {
    name: "S25.png",
    left: `16%`,
    top: `530px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#ED3838",
    textTransform: "uppercase",
    padding: "3px",
    background: "white",
    borderRadius: "3px",
  },

  {
    name: "S26.png",
    left: `32%`,
    top: `200px`,
    fontWeight: "700",
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#D9221F",
  },

  {
    name: "S4.png",
    left: `30%`,
    top: `460px`,
    fontFamily: `'Lexend Deca', sans-serif`,
    color: "#FFB600",
  },

  {
    name: "S8.png",
    left: `30%`,
    top: `560px`,
    fontFamily: `'Open Sans', sans-serif`,
    textTransform: "uppercase",
    color: "#FFFFFF",
  },
];

export default storyStyle;
