<template>
  <div
    class="
      bg-white
      ms-2
      favicon-upload
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <div class="">
      <div class="favicon-container my-4 me-3" v-if="getImageUrl">
        <img
          src="../../../assets/icon/cross.svg"
          width="25"
          height="25"
          alt=""
          class="new-product__close broder-circle"
          @click="deleteFavicon()"
        />
        <img
          class=" h-full w-full common-border__radius new-image"
          :src="getImageUrl"
        />
      </div>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center"
      v-if="!getImageUrl"
    >
      <img
        src="../../../assets/icon/image.svg"
        alt=""
        width="40"
        class=""
        @click="triggerFileInput()"
      />

      <div>
        <label class="custom-file-upload mx-auto my-auto state-color__fg--Info">
          <input
            ref="productImageUploader"
            type="file"
            accept="image/*"
            @change="chooseFavicon($event)"
            id="file-input"
          />
          {{ $t("appearance.main.favicon.placeholder") }}
        </label>
      </div>
      <div class="text-center">
        <p class="state-color__fg--grey1 mb-1">
          {{ $t("appearance.main.favicon.help_text") }}
        </p>

        <p class="state-color__fg--grey1">
          {{ $t("appearance.main.favicon.support") }}
        </p>
      </div>
    </div>
  </div>
  <div class="my-3 d-flex justify-content-end wd-35">
    <button
      class="btn btn-primary"
      style="padding:10px 45px"
      type="submit"
      @click="uploadFavicon"
    >
      {{ $t("appearance.main.favicon.button") }}
    </button>
  </div>

  <crop-image-modal
    :ImageUrl="originalImageUrl"
    :image="blobImage"
    @croppedData="addProductCroppedImage"
    parent="favicon"
    :ratio="250 / 250"
  />
  <button
    id="imageCrop"
    data-bs-toggle="modal"
    data-bs-target="#addCropImageModal"
    class="d-none"
  ></button>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import Toast, { errorBackground } from "../../../config/toastConfig"
import Swal from "sweetalert2"
import cropImageModal from "../../product/cropImageModal.vue"
import $ from "jquery"
export default {
  data: function() {
    return {
      storeName: "",
      url: "",
      image: "",
      originalImageUrl: "",
      blobImage: "",
    }
  },

  components: {
    cropImageModal,
  },

  computed: mapGetters("favicon", ["getImageUrl", "faviconStatus"]),

  methods: {
    ...mapMutations("favicon", ["SET_IMAGE_URL"]),
    ...mapActions("favicon", [
      "uploadFaviconRequest",
      "faviconDeleteRequest",
      "getFaviconRequest",
    ]),

    chooseFavicon(event) {
      let image = event.target.files[0]
      let allowImageType = ["image/png", "image/jpg", "image/jpeg", "image/bmp"]

      if (allowImageType.includes(image.type)) {
        this.originalImageUrl = URL.createObjectURL(event.target.files[0])
        const blob = URL.createObjectURL(event.target.files[0])
        this.blobImage = {
          src: blob,
          type: event.target.files[0].type,
        }

        $("#imageCrop").trigger("click")

        //this.image = image;
        //this.SET_IMAGE_URL(URL.createObjectURL(image));
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.favicon.image_type_error"),
          background: errorBackground,
        })
      }
    },

    addProductCroppedImage(data) {
      this.image = data.image
      this.SET_IMAGE_URL(data.url)
    },

    async uploadFavicon() {
      if (this.image) {
        const formData = new FormData()

        formData.append("favicon_image", this.image)
        await this.uploadFaviconRequest(formData)
      }
    },

    async deleteFavicon() {
      if (!this.faviconStatus) {
        await this.faviconDeleteRequest()
        return
      }

      Swal.fire({
        title: this.$t("manage.main.delete_confirmation_message.title"),
        text: this.$t("manage.main.delete_confirmation_message.fav_icon"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "manage.main.delete_confirmation_message.delete_btn"
        ),
        cancelButtonText: this.$t(
          "manage.main.delete_confirmation_message.cancel_btn"
        ),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.faviconDeleteRequest()
        }
      })
    },
  },

  created() {
    this.getFaviconRequest()
  },
}
</script>

<style scoped>
.favicon-upload {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%233A86FFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='43' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 14px;
  width: 35%;
  height: 100%;
}

.wd-35 {
  width: 35%;
}

.favicon-container {
  height: 10em;
  width: 11em;
  position: relative !important;
}

.favicon-container .new-product__close {
  position: absolute;
  z-index: 1;
  right: 5%;
  top: 5%;
  cursor: pointer;
}

.favicon-container .new-image {
  position: relative;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .favicon-upload {
    width: 100%;
    margin-top: 15px;
    min-height: 300px;
  }

  .wd-35 {
    width: 100%;
  }
}
</style>
