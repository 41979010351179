<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div
        class="col-12 col-md-12 col-xl-10 px-0 min-vh-100 d-flex flex-column justify-content-start"
      >
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div
            class=" state-color__fg--grey d-flex justify-content-between flex-column flex-lg-row flex-md-row flex-xl-row"
            style="padding-top:30px !important"
          >
            <div
              class="my-auto d-flex align-items-center state-color__fg--grey"
            >
              <img
                src="../../assets/icon/back_btn_image.svg"
                alt="back"
                class="make-cursor"
                height="25"
                width="20"
                @click="
                  $router.push({
                    name: 'AllProducts',
                    params: { languageCode: getLanguage },
                  })
                "
              />
              <h4 class="ms-3 mb-0">{{ $t("product.main.create.heading") }}</h4>
            </div>

            <button
              type="button"
              class="btn btn-primary add-product-btn"
              @click="createNewProduct()"
            >
              {{ $t("product.main.create.btn") }}
            </button>
          </div>
          <div class="bg-white common-border__radius m-1 mt-3">
            <new-product-image />
          </div>

          <div class="bg-white common-border__radius  mt-3 basic-info-padding">
            <basic-information
              :isProductAddPage="'true'"
              @requestToResetAttributeCombination="restAttributeCombination"
            />
          </div>

          <div
            class="bg-white common-border__radius  mt-3  w-100 basic-info-padding"
          >
            <select-product-attribute ref="productAttributeCombination" />
          </div>

          <div
            class="bg-white common-border__radius  mt-3  mb-4 basic-info-padding"
          >
            <details-information />
          </div>

          <div class="d-flex justify-content-end mb-4 pb-3">
            <button
              type="button"
              class="btn btn-primary add-product-btn"
              @click="createNewProduct()"
            >
              {{ $t("product.main.create.btn") }}
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Toast, {
  errorBackground,
  warningBackground,
} from "../../config/toastConfig";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import NewProductImage from "../../components/product/NewProductImage.vue";
import BasicInformation from "../../components/product/with-attribute/BasicInformation.vue";
import DetailsInformation from "../../components/product/with-attribute/DetailsInformation.vue";
import SelectProductAttribute from "../../components/product/with-attribute/SelectProductAttribute.vue";

export default {
  components: {
    SideBar,
    TopBar,
    NewProductImage,
    LoadingSpinner,
    BasicInformation,
    SelectProductAttribute,
    DetailsInformation,
  },

  data: function() {
    return {
      businessCategoryId: 0,
    };
  },

  computed: {
    ...mapState("product", ["setAllRibbons"]),
    ...mapGetters("product", [
      "productImages",
      "productName",
      "productCategory",
      "allProductCategories",
      "productPrice",
      "productDiscountedPrice",
      "productChargeName",
      "productChargeInsideCity",
      "productChargeOutsideCity",
      "quantityAmount",
      "quantityName",
      "productDetails",
      "productRibbons",
      "fixedDeliveryCharge",
      "productAttributeCombination",
      "productCreateInputErrorNo",
      "productCreateInputErrorMessage",

      "existingProdcutImages",
    ]),

    ...mapGetters("language", ["getLanguage"]),
    ...mapGetters("auth", ["getUser"]),
  },

  methods: {
    ...mapActions("product", [
      "checkProductCreateErrorInputError",
      "addNewProductRequest",
      "getAllCategories",
      "requestGetAllRibbons",
      "requestGetAllUnits",
    ]),
    ...mapActions("profileUpdate", ["sellerInfoByShopName"]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    async createNewProduct() {
      let errorCode = 0,
        i = 0,
        ribbonList = [];
      this.$store.commit("setOverlayStatus", true);

      errorCode = await this.checkProductCreateErrorInputError({
        productImages: this.productImages,
        existingImages: this.existingProdcutImages,
        productTitle: this.productName,
        productCategory: this.productCategory,
        price: this.productPrice,
        discountPrice: this.productDiscountedPrice,
        chargeName: this.productChargeName,
        chargeInsideCity: this.productChargeInsideCity,
        chargeOutsideCity: this.productChargeOutsideCity,
        quantityAmount: this.quantityAmount,
        quantityUnit: this.quantityName,
        description: this.productDetails,
        attributeCombination: this.productAttributeCombination,
        ribbonList: this.productRibbons,
        i18n: this.$t,
      });

      if (errorCode == 0) {
        this.setOverlayStatus(false);

        Toast.fire({
          icon: "error",
          title: this.productCreateInputErrorMessage,
          background: errorBackground,
        });

        return;
      }

      const formData = new FormData();

      this.productImages.forEach((file) => {
        formData.append("image[]", file.image);
      });
      formData.append("product_title", JSON.stringify(this.productName));
      formData.append("id_product_category", this.productCategory.id);
      if (
        this.productAttributeCombination.product_attribute_combination.length >
        1
      ) {
        formData.append("price", 0);
        formData.append("discount_price", 0);
        formData.append("quantity", 0);
      } else {
        formData.append("price", this.productPrice);
        formData.append("quantity", this.quantityAmount);
        if (this.productDiscountedPrice == "") {
          formData.append("discount_price", 0.0);
        } else if (!this.productDiscountedPrice) {
          formData.append("discount_price", 0.0);
        } else {
          formData.append(
            "discount_price",
            parseFloat(this.productDiscountedPrice)
          );
        }
      }

      formData.append("charge_name", this.productChargeName);
      formData.append(
        "charge_amount_inside_city",
        this.productChargeInsideCity
      );
      formData.append(
        "charge_amount_outside_city",
        this.productChargeOutsideCity
      );
      formData.append("unit_id", this.quantityName.id);
      formData.append(
        "product_description",
        JSON.stringify({
          description_en: this.productDetails,
          description_bn: "none",
        })
      );

      for (i = 0; i < this.productRibbons.length; i++) {
        ribbonList.push({ ribbon_id: this.productRibbons[i] });
      }

      formData.append("ribbon_id", JSON.stringify({ ribbons: ribbonList }));
      let attributeCombination = JSON.parse(
        JSON.stringify(this.productAttributeCombination)
      );
      attributeCombination.product_attribute_combination.pop();

      formData.append(
        "product_attribute_combination",
        JSON.stringify(attributeCombination)
      );

      formData.append("fixed_delivery_charge", this.fixedDeliveryCharge);

      await this.addNewProductRequest(formData);
    },

    async getAllProductCategories() {
      await this.getAllCategories();

      // if (
      //   this.allProductCategories == null ||
      //   this.allProductCategories.length <= 0
      // ) {
      //   Toast.fire({
      //     icon: "warning",
      //     title: this.$t("product.main.toast.no_category_found"),
      //     background: warningBackground,
      //   });

      //   this.$router.push({
      //     name: "AppDirectProductCategoryAssign",
      //     params: { languageCode: this.getLanguage },
      //   });
      // }
    },

    async isShopAddressExists() {
      let shopName = this.getUser.shop_name.replace(/\s+/g, "-").toLowerCase();
      const data = await this.sellerInfoByShopName({ shopName });

      if (data.seller[0].shop_address == "") {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.no_address_added"),
          background: warningBackground,
        });
        this.$router.push({ name: "AllProducts" });
      }
    },

    async getAllRibbons() {
      await this.requestGetAllRibbons();
    },

    async getAllUnits() {
      await this.requestGetAllUnits();
    },

    restAttributeCombination() {
      this.$refs.productAttributeCombination.clearAttributeCombination();
    },

    extractUserData() {
      let userObject = localStorage.getItem("majhiUser");
      let authUser = JSON.parse(userObject);
      this.businessCategoryId = authUser.business_category_id;
    },
  },

  async created() {
    this.setOverlayStatus(true);
    this.$store.commit("product/setProductCreateErrorValue", {
      errorNo: 0,
      msg: "",
    });
    this.$store.commit("product/resetAllExistingValue");
    this.$store.commit("product/setCategoryAttributeVal", {
      categoryAttribute: [],
    });

    await this.extractUserData();
    await this.getAllProductCategories();
    // await this.isShopAddressExists();
    await this.getAllRibbons();
    await this.getAllUnits();
    this.setOverlayStatus(false);
  },
};
</script>

<style scoped>
.basic-info-padding {
  padding: 30px 22px;
}

.add-product-btn {
  background: #3a86ff;
  border-radius: 8px;
  font-size: 1rem;
  padding: 0.9rem 1.8rem;
  margin-right: 5px !important;
}

@media screen and (max-width: 700px) {
  .add-product-btn {
    margin: 1rem;
    width: 50% !important;
  }
}
</style>
