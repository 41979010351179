export default {
  namespaced: true,

  state: {
    language: "bn",
  },

  getters: {
    getLanguage: (state) => state.language,
  },

  mutations: {
    SET_LANGUAGE(state, languageCode) {
      state.language = languageCode;
    },
  },

  actions: {
    setLanguageCode({ commit }, language) {
      commit("SET_LANGUAGE", language);
    },
  },
};
