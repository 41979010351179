<template>
  <div>
    <h5 class="mx-3">{{ $t("product.main.basic_info.title") }}</h5>
    <div class="mt-5 product-basic-info-container row">
      <div class="col-md-7">
        <div class="row mx-3">
          <div class="col-md-3 d-flex align-items-center">
            <p class="m-0">
              {{ $t("product.main.basic_info.name") }}
              <span class="text-primary">*</span>
            </p>
          </div>
          <div class="col-md-9 ps-0">
            <input
              style=""
              type="text"
              id="idProductName"
              autocomplete="off"
              class="inp-field p ps-2 w-100 app-input-field"
              :class="
                productCreateInputErrorNo == 2 ? 'border border-danger' : ''
              "
              :placeholder="$t('product.main.basic_info.name_placeholder')"
              @input="updateproductName"
            />
            <p
              class="text-danger mb-0 pt-1"
              v-if="productCreateInputErrorNo == 2"
            >
              {{ $t(productCreateInputErrorMessage) }}
            </p>
          </div>
        </div>

        <div class="row mx-3 mt-4">
          <div class="col-md-3 d-flex align-items-center">
            <p class="m-0">
              {{ $t("product.main.basic_info.category") }}
              <span class="text-primary">*</span>
            </p>
          </div>
          <div class="col-md-9 ps-0">
            <div class="width-100 p-0">
              <Select2
                :disabled="category_disable"
                :placeholder="
                  $t('product.main.basic_info.category_placeholder')
                "
                class="mt-2 w-100 rounded"
                id="id_product_category_select"
                :class="
                  productCreateInputErrorNo == 3 ? 'border border-danger' : ''
                "
                v-model="idSelectedCategory"
                :options="selectedProductCategory"
                @select="updateproductCategory($event)"
              />
            </div>
            <p
              class="text-danger mb-0 pt-1"
              v-if="productCreateInputErrorNo == 3"
            >
              {{ productCreateInputErrorMessage }}
            </p>
          </div>
        </div>

        <!-- <div class="row mx-3 mt-4" v-if="isProductAddPage">
          <div class="col-md-3"></div>
          <div class="col-md-9 ps-0">
            <p
              class="mb-0 state-color__fg--Info make-cursor"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addCategoriesModal"
            >
              {{ $t("product.category.add_category_btn") }}
            </p>
          </div>
        </div> -->

        <div class="row mx-3 mt-4">
          <div class="col-md-3 d-flex align-items-center">
            <p class="m-0">
              {{ $t("product.main.basic_info.price") }}
              <span class="text-primary">*</span>
            </p>
          </div>
          <div class="col-md-9 ps-0">
            <div
              class="custom-input-field w-100"
              :class="
                productCreateInputErrorNo == 4 ? 'border border-danger' : ''
              "
            >
              <div class="w-100 h-100 d-flex align-items-center">
                <span class="taka px-2">&#2547;</span>
                <input
                  type="number"
                  name=""
                  id="idProductPrice"
                  autocomplete="off"
                  class="borderless-inp-field w-100"
                  :placeholder="$t('product.main.basic_info.price_placeholder')"
                  :disabled="
                    productCategory == null ||
                      productAttributeCombination.product_attribute_combination
                        .length > 1
                  "
                  @input="updateproductPrice"
                />
              </div>
            </div>
            <p
              class="text-danger mb-0 pt-1"
              v-if="productCreateInputErrorNo == 4"
            >
              {{ productCreateInputErrorMessage }}
            </p>
          </div>
        </div>

        <div class="row mx-3 mt-4">
          <div class="col-md-3 d-flex align-items-center">
            <p class="m-0">
              {{ $t("product.main.basic_info.discounted_price") }}
            </p>
          </div>
          <div class="col-md-9 ps-0">
            <div
              class="custom-input-field w-100"
              :class="
                productCreateInputErrorNo == 5 ? 'border border-danger' : ''
              "
            >
              <div class="w-100 h-100 d-flex align-items-center">
                <span class="taka px-2">&#2547;</span>
                <input
                  type="number"
                  name=""
                  id="idDiscountedPrice"
                  autocomplete="off"
                  class="borderless-inp-field w-100"
                  :placeholder="
                    $t('product.main.basic_info.discounted_price_placeholder')
                  "
                  :disabled="
                    productCategory == null ||
                      productAttributeCombination.product_attribute_combination
                        .length > 1
                  "
                  @input="updateproductDiscountedPrice"
                />
              </div>
            </div>
            <p
              class="text-danger mb-0 pt-1"
              v-if="productCreateInputErrorNo == 5"
            >
              {{ productCreateInputErrorMessage }}
            </p>
          </div>
        </div>

        <div class="row  charge-container">
          <div class="col-md-6 mt-4">
            <div class="w-100 flex-row-col">
              <div class="d-flex align-items-center">
                <p class="m-0">
                  {{ $t("product.main.basic_info.charge_name") }}
                </p>
              </div>
              <input
                type="text"
                name=""
                id="idChargeName"
                autocomplete="off"
                class="extra-charge-name-inp-field"
                :class="
                  productCreateInputErrorNo == 6 ? 'border border-danger' : ''
                "
                :placeholder="
                  $t('product.main.basic_info.charge_name_placeholder')
                "
                @input="updateproductChargeName"
              />
            </div>
          </div>
          <div class="col-md-6 mt-4">
            <div class="w-100 flex-row-col">
              <div class="d-flex align-items-center">
                <p class="m-0">
                  {{ $t("product.main.basic_info.charge_amount") }}
                </p>
              </div>
              <div class="width-60-100">
                <input
                  type="number"
                  name=""
                  id="idChargeInsideCity"
                  autocomplete="off"
                  class="extra-charge-inp-field common-border-left-radius__8"
                  :class="
                    productCreateInputErrorNo == 7 ? 'border border-danger' : ''
                  "
                  min="0"
                  :placeholder="$t('product.main.basic_info.charge_amount_p1')"
                  @input="updateproductChargeInsideCity"
                  @keydown="checkIsNumber($event)"
                />
                <input
                  type="number"
                  name=""
                  id="idChargeOutsideCity"
                  autocomplete="off"
                  class="extra-charge-inp-field common-border-right-radius__8"
                  :class="
                    productCreateInputErrorNo == 8 ? 'border border-danger' : ''
                  "
                  :placeholder="$t('product.main.basic_info.charge_amount_p2')"
                  min="0"
                  @input="updateproductChargeOutsideCity"
                  @keydown="checkIsNumber($event)"
                />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <p
              class="text-danger mb-0 pt-1"
              v-if="productCreateInputErrorNo == 6"
            >
              {{ productCreateInputErrorMessage }}
            </p>
          </div>
          <div class="col-md-6">
            <p
              class="text-danger mb-0 pt-1"
              v-if="
                productCreateInputErrorNo == 7 || productCreateInputErrorNo == 8
              "
            >
              {{ productCreateInputErrorMessage }}
            </p>
          </div>
        </div>

        <div class="row mx-3 mt-4">
          <div class="col-md-3">
            {{ $t("product.main.basic_info.charge_per_order") }}
          </div>
          <div class="col-md-9">
            <Toggle
              v-model="fixed_delivery_charge"
              @change="toggleFixedDeliveryCharge($event)"
            />
          </div>
        </div>

        <div class="row mx-3 mt-4">
          <div class="col-md-3 d-flex align-items-center">
            <p class="m-0">
              {{ $t("product.main.basic_info.quantity")
              }}<span class="text-primary">*</span>
            </p>
          </div>
          <div class="col-md-9 ps-0">
            <div
              class="custom-input-field w-100"
              :class="
                productCreateInputErrorNo == 9 ? 'border border-danger' : ''
              "
            >
              <div
                class="w-100 h-100 d-flex justify-content-between align-items-center qunatity-input-field-container"
              >
                <input
                  type="number"
                  name=""
                  id="idProductQuantity"
                  autocomplete="off"
                  class="borderless-inp-field w-100 ps-2"
                  :placeholder="
                    $t('product.main.basic_info.quantity_placeholder')
                  "
                  min="0"
                  @input="updatequantityAmount"
                  @keydown="checkIsNumber($event)"
                />
                <div class="p-0 width-120">
                  <Select2
                    :placeholder="$t('product.main.basic_info.unit')"
                    class="w-100"
                    :class="
                      $store.getters.getProductErrorFlag == 2
                        ? 'border-danger'
                        : ''
                    "
                    :options="allUnits"
                    @select="updatequantityName($event)"
                    v-model="idSelectedUnit"
                  />
                </div>
              </div>
            </div>

            <p
              class="text-danger mb-0 pt-1"
              v-if="productCreateInputErrorNo == 9"
            >
              {{ productCreateInputErrorMessage }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-5 tips-container">
        <p class="large-text">{{ $t("product.main.tips") }}</p>
        <ul class="m-0 p-3">
          <li class="small-text">
            {{ $t("product.main.basic_info.tips.t1") }}
          </li>
          <li class="small-text mt-3">
            {{ $t("product.main.basic_info.tips.t2") }}
          </li>
          <li class="small-text mt-3">
            {{ $t("product.main.basic_info.tips.t3") }}
          </li>
        </ul>
      </div>
      <AddNewCategoryModal
        @updateSelectedCategoryId="setSelectedCategoryByNewId"
      />
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Toast, { errorBackground } from "../../../config/toastConfig";
import Toggle from "@vueform/toggle";
import AddNewCategoryModal from "../../category/AddNewCategoryModal.vue";
import $ from "jquery";

export default {
  components: { Select2, Toggle, AddNewCategoryModal },
  data() {
    return {
      productCategoriesPlaceholder: "Choose product category",
      unitPlaceholder: "Unit",

      idSelectedCategory: 0,
      idSelectedUnit: 0,
      fixed_delivery_charge: false,
    };
  },

  props: {
    category_disable: {
      type: Boolean,
      default: false,
    },

    isProductAddPage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("product", [
      "productName",
      "productPrice",
      "productDiscountedPrice",
      "productChargeName",
      "productChargeInsideCity",
      "productChargeOutsideCity",
      "quantityAmount",
      "quantityName",
      "allProductCategories",
      "allUnits",
      "fixedDeliveryCharge",
      "productCategory",
      "categoryAttributeValue",
      "productAttributeCombination",
      "existingValueFlag",
      "productCreateInputErrorNo",
      "productCreateInputErrorMessage",
    ]),

    selectedProductCategory() {
      return this.allProductCategories?.map((category) =>
        category.status === 1 ? category : null
      );
    },
  },

  watch: {
    async existingValueFlag(value) {
      if (value) {
        //    this.setOverlayStatus(true);
        await this.getAttributeByCategory();
        document.getElementById("idProductPrice").value = this.productPrice;
        document.getElementById(
          "idDiscountedPrice"
        ).value = this.productDiscountedPrice;
        document.getElementById(
          "idProductName"
        ).value = this.productName.title_en;
        document.getElementById("idChargeName").value = this.productChargeName;
        document.getElementById(
          "idChargeInsideCity"
        ).value = this.productChargeInsideCity;
        document.getElementById(
          "idChargeOutsideCity"
        ).value = this.productChargeOutsideCity;
        document.getElementById(
          "idProductQuantity"
        ).value = this.quantityAmount;

        this.idSelectedCategory = this.productCategory.id;
        this.idSelectedUnit = this.quantityName.id_unit;
        if (this.fixedDeliveryCharge == "yes") {
          this.fixed_delivery_charge = true;
        } else {
          this.fixed_delivery_charge = false;
        }
        //this.setOverlayStatus(false);
      }
    },
  },

  methods: {
    ...mapActions("product", ["requestAttributeByCategory"]),
    ...mapMutations("product", ["setproductChargeName"]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    updateproductName(event) {
      this.$store.commit("product/setProductName", {
        name: event.target.value,
      });
    },
    async updateproductCategory(category) {
      this.setOverlayStatus(true);
      this.$store.commit("product/setProductCategory", {
        name: category,
      });
      this.idSelectedCategory = category.id;
      await this.getAttributeByCategory(category.id);

      this.$emit("requestToResetAttributeCombination");
      this.setOverlayStatus(false);
    },

    async updateproductPrice(event) {
      if (await this.checkCombinationExistance()) {
        // document.getElementById("idProductPrice").value = "";
        return;
      } else if (this.productCategory == null) {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.category"),
          background: errorBackground,
        });
        document.getElementById("idProductPrice").value = "";
        return;
      } else if (
        event.target.value != "" &&
        parseFloat(event.target.value) !=
          parseFloat(event.target.value).toFixed(2)
      ) {
        document.getElementById("idProductPrice").value = parseFloat(
          event.target.value
        ).toFixed(2);
        return;
      }

      this.$store.commit("product/setProductPrice", {
        price: event.target.value,
      });
    },

    async updateproductDiscountedPrice(event) {
      if (await this.checkCombinationExistance()) {
        // document.getElementById("idDiscountedPrice").value = "";
        return;
      } else if (this.productCategory == null) {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.category"),
          background: errorBackground,
        });

        document.getElementById("idProductPrice").value = "";
        return;
      } else if (
        event.target.value != "" &&
        parseFloat(event.target.value) !=
          parseFloat(event.target.value).toFixed(2)
      ) {
        document.getElementById("idDiscountedPrice").value = parseFloat(
          event.target.value
        ).toFixed(2);
        return;
      }

      this.$store.commit("product/setproductDiscountedPrice", {
        price: event.target.value,
      });
    },

    updateproductChargeName(event) {
      this.$store.commit("product/setproductChargeName", {
        name: event.target.value,
      });
    },

    updateproductChargeInsideCity(event) {
      this.$store.commit("product/setproductChargeInsideCity", {
        chargeInsideCity: event.target.value,
      });
    },

    updateproductChargeOutsideCity(event) {
      this.$store.commit("product/setproductChargeOutsideCity", {
        chargeOutsideCity: event.target.value,
      });
    },

    async updatequantityAmount(event) {
      if (await this.checkCombinationExistance()) {
        document.getElementById("idProductQuantity").value = "";
        return;
      }

      this.$store.commit("product/setquantityAmount", {
        amount: event.target.value,
      });
    },
    updatequantityName(quantity) {
      this.$store.commit("product/setquantityName", {
        name: quantity,
      });
    },

    checkCombinationExistance() {
      if (
        this.productAttributeCombination.product_attribute_combination.length >
          0 &&
        this.productAttributeCombination.product_attribute_combination[0]
          .price != "" &&
        this.productAttributeCombination.product_attribute_combination.length >
          1
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.remove_att_combination"),
          background: errorBackground,
        });

        return true;
      } else return false;
    },

    async getAttributeByCategory(idCategory = this.idSelectedCategory) {
      if (idCategory == 0) return;
      this.idSelectedCategory = idCategory;
      await this.requestAttributeByCategory(idCategory);
    },

    toggleFixedDeliveryCharge(fixedDeliveryCharge) {
      if (fixedDeliveryCharge) {
        this.$store.commit("product/setFixedDeliveryCharge", {
          fixedCharge: "yes",
        });
      } else {
        this.$store.commit("product/setFixedDeliveryCharge", {
          fixedCharge: "no",
        });
      }
    },

    checkIsNumber(event) {
      if (
        (event.which != 8 && event.which != 0 && event.which < 48) ||
        event.which > 57
      ) {
        event.preventDefault();
      }
    },

    setSelectedCategoryByNewId(newCategory) {
      this.idSelectedCategory = newCategory.id_product_category;
      this.$store.commit("product/setProductCategory", {
        name: newCategory,
      });
      this.updateproductCategory(newCategory);
    },
  },

  created() {
    let vm = this;
    window.addEventListener("storage", function(event) {
      if (event.key == "newAttributeValueFlag" && event.newValue == "yes") {
        vm.getAttributeByCategory(
          document.getElementById("id_product_category_select").value
        );
        localStorage.removeItem("newAttributeValueFlag");

        vm.setOverlayStatus(false);
      }
    });

    this.setproductChargeName({ name: " " });
  },

  mounted() {
    $("#id_product_category_select")
      .select2()
      .on("select2:open", () => {
        $(".select2-results:not(:has(p))").append(
          '<p class="mb-0 state-color__fg--grey1 text-end py-2 pe-4" >Can’t find any category? <span class="make-cursor fg-black text-decoration-underline" type="button" data-bs-toggle="modal" data-bs-target="#addCategoriesModal">Add product category</span></p>'
        );
      });
  },
};
</script>

<style>
.charge-container {
  margin: 0 1rem !important;
}
.tips-container {
  margin-top: 0;
}

/* #idProductName {
  padding-left: 20px !important;
} */

/* #idProductName,
#id_product_category_select,
#idProductPrice,
#idDiscountedPrice,
#idChargeName,
#idChargeInsideCity,
#idChargeOutsideCity,
#idProductQuantity {
  padding-left: 20px !important;
} */

.product-basic-info-container .inp-field {
  height: 50px;
  width: 70%;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
}

.select2-container .select2-selection--single {
  height: 49px !important;
  border-radius: 8px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 5;
  top: 10px;
  right: 1px;
  width: 20px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #a6a6a6;
}

.width-75 {
  width: 74% !important;
}

.width-60-100 {
  width: 60%;
}

.width-120 {
  width: 120px;
}

.product-basic-info-container .custom-input-field {
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  height: 50px;
}

.product-basic-info-container .borderless-inp-field {
  border: none;
  height: 100%;
  border-radius: 8px;
}

.flex-row-col {
  display: flex;
  justify-content: space-between;
}

.product-basic-info-container .extra-charge-name-inp-field {
  width: 50%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  /* font-size: 16px; */
  font-size: 1rem;
  padding-left: 5px;
}

.product-basic-info-container .extra-charge-inp-field {
  width: 50%;
  height: 50px;

  border: 1px solid #bdbdbd;
  /* font-size: 16px; */
  font-size: 1rem;

  padding-left: 5px;
}

.product-basic-info-container .taka {
  font-weight: 500;
}

.qunatity-input-field-container
  .select2-container--default
  .select2-selection--single {
  border: none;
  background-color: #eaeaea;
  border-radius: 8px;
}

.qunatity-input-field-container
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: black !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
}

.product-refresh-btn-container .refresh {
  width: 100px;
}

/* .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 20px;
} */

@media screen and (max-width: 600px) {
  .flex-row-col {
    flex-direction: column;
  }

  .product-basic-info-container .extra-charge-name-inp-field,
  .width-75 {
    width: 95% !important;
  }

  .width-60-100 {
    width: 95%;
  }

  .product-basic-info-container .custom-input-field {
    width: 90%;
  }

  .product-basic-info-container .inp-field {
    width: 95%;
  }

  .charge-container {
    margin: 0px 3px !important;
  }
  .tips-container {
    margin-top: 1rem !important;
  }
}
</style>
