import productCategoriesRepository from "./product/productCategories";
import coupon from "./coupon";
import ExtraPages from "./extra-pages";
import StoreTiming from "./store-timing";
import AllCustomer from "./manage/customerList";
import Banner from "./appearance/banner";
import Order from "./order";
import Product from "./product";
import SocialMediaSupport from "./manage/socialMediaSupport";
import Notification from "./notification";
import Auth from "./auth";
import Graph from "./graph";
import ProfileUpdate from "./user/profileUpdate";
import FavIcon from "./appearance/favicon";
import settings from "./settings/index";
import instorePickup from "./settings/instorePickup";
import address from "./manage/address";

const repositories = {
  productCategories: productCategoriesRepository,
  coupon,
  ExtraPages,
  StoreTiming,
  AllCustomer,
  Banner,
  Order,
  Product,
  SocialMediaSupport,
  Notification,
  Auth,
  Graph,
  ProfileUpdate,
  FavIcon,
  settings,
  instorePickup,
  address,
};

export default {
  get: (name) => repositories[name],
};
