<template>
  <div
    class="modal fade"
    id="categoryImageCropModal"
    tabindex="-1"
    aria-labelledby="categoryImageCropModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    style="z-index: 1080;"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content  common-border__radius p-3 ">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important;"
        >
          <h5>{{ $t("product.modal.image_crop.title") }}</h5>
          <button
            type="button"
            class="modal-close"
            id="addCategoriesModalClose"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="">
          <div class="imageHeight">
            <div>
              <cropper
                ref="cropper"
                class="copper"
                @error="onFailedImageLoad"
                :src="ImageUrl"
                :stencil-props="{
                  aspectRatio: ratio,
                  class: 'cropper-stencil',
                  handlersClasses: {
                    default: 'cropper-handler',
                    eastNorth: 'cropper-handler--east-north',
                    westNorth: 'cropper-handler--west-north',
                    eastSouth: 'cropper-handler--east-south',
                    westSouth: 'cropper-handler--west-south',
                  },
                }"
              />
              <div class="d-flex justify-content-center my-3 mt-4">
                <button class="btn btn-primary px-4 py-3" @click="cropImage()">
                  {{ $t("product.modal.image_crop.btn") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import $ from "jquery";

import Toast, { errorBackground } from "../../config/toastConfig";

export default {
  props: {
    ImageUrl: {
      type: String,
      required: true,
    },

    image: {
      type: Object,
    },

    ratio: {
      type: Number,
      default: 1,
    },

    parent: {
      type: String,
      default: "product",
    },
  },

  components: {
    Cropper,
  },

  methods: {
    setStencilSize() {
      let size = this.$refs.cropper.imageSize;

      return { width: size.width, height: size.height };
    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      const canvas = result.canvas;
      let url = result.canvas.toDataURL(this.image.type);
      this.cropUrl = url;
      let vm = this;
      canvas.toBlob((blob) => {
        let data = {
          image: blob,
          url: url,
          fileName: this.image.name,
        };

        vm.$emit("croppedData", data);
      }, this.image.type);

      $("#addCategoriesModalClose").trigger("click");
    },

    onFailedImageLoad() {
      Toast.fire({
        icon: "error",
        title: this.$t("product.modal.image_crop.broken_image_error"),
        background: errorBackground,
      });

      setTimeout(() => {
        $("#addCategoriesModalClose").trigger("click");
      }, 200);
    },
  },
};
</script>

<style scoped>
.copper {
  height: 600px;
  width: 600px;
  margin: auto;
  background: #ddd;
}

.cropper-handler {
  display: block;
  opacity: 0.7;
  position: relative;
  flex-shrink: 0;
  transition: opacity 0.5s;
  border: none;
  background: rgb(50, 50, 92);
  top: auto;
  left: auto;
  height: 7px;
  width: 7px;
}

.vue-preview--fill {
  border: 1px solid rgb(50, 50, 92);
}
</style>
