<template>
    <div class="container">
        <div class="header">
            <div class="header-info-container">
                <p class="invoice" >Invoice</p>
                <p>Invoice No:#11121</p>
                <p>Date: 30 November-2021</p>
            </div>
            <div class="icon-container">
                <img height="80" src="http://localhost:8080/img/logo.ee981e01.png" alt="">
                <p>Majhi</p>
            </div>
        </div>
        <div class="billing-info-container">
            <table>
                <thead>
                    <th class="heading">BILL TO</th>
                    <th class="heading">BILL FROM</th>
                    <th class="heading">INVOICE OF</th>
                </thead>
                <tbody>
                    <tr>
                        <td >
                            <p class="text-bold">Zakir Hossain</p>
                            <p>47/A indria road</p>
                            <p>Framgate,Dhaka</p>
                            <p>Dhaka-1229</p>
                            <p>Phone: 01725-440564</p>
                            <p>Email: demo@gmail.com</p>    
                        </td>

                        <td>
                            <div>
                                <p class="text-bold">Majhi Store</p>
                                <p>Store No:5436,Block-A</p>
                                <p>Floor-3,Jamuna Future</p>
                                <p>Park,Dhaka-1229</p>
                                <p>Phone: 01725-440564</p>
                            </div>
                        </td>

                        <td class="row-data">
                            
                                <p>Order ID: ID623234</p>
                                <p>Amount: <span class="amount">&#2547; 34,456</span> </p>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>



        <div class="product-container">
            <table>
                <thead>
                    <th class="heading">SL</th>
                    <th class="heading">ITEM NAME</th>
                    <th class="heading">QTY</th>
                    <th class="heading">RATE</th>
                    <th class="heading">AMOUNT</th>
                </thead>

                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Women Hoodie</td>
                        <td>2</td>
                        <td>&#2547; 5742.00</td>
                        <td>&#2547; 10,484.00</td>
                    </tr>

                    <tr>
                        <td>2</td>
                        <td>Women Hoodie</td>
                        <td>2</td>
                        <td>&#2547; 5742.00</td>
                        <td>&#2547; 10,484.00</td>
                    </tr>

                    <tr>
                        <td>3</td>
                        <td>Women Hoodie</td>
                        <td>2</td>
                        <td>&#2547; 5742.00</td>
                        <td>&#2547; 10,484.00</td>
                    </tr>

                    <tr>
                        <td>4</td>
                        <td>Women Hoodie</td>
                        <td>2</td>
                        <td>&#2547; 5742.00</td>
                        <td>&#2547; 10,484.00</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Women Hoodie</td>
                        <td>2</td>
                        <td>&#2547; 5742.00</td>
                        <td>&#2547; 10,484.00</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Women Hoodie</td>
                        <td>2</td>
                        <td>&#2547; 5742.00</td>
                        <td>&#2547; 10,484.00</td>
                    </tr>
                </tbody>

            </table>
        </div>


        <div class="payment-details">
            <table style="text-align:bottom">
                <thead>
                    <th class="heading">PAYMENT DETAILS</th>
                    <th class="heading">
                        INVOICE TOTAL
                        <hr>

                    </th>
                </thead>
                <tbody>
                    <td class="row-data">
                        Payment method: Cash on delivery
                    </td>
                    <td>
                        <table>
                            <thead>
                                <th></th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="heading">Sub Total</td>
                                    <td>&#2547; 80,564.00</td>
                                </tr>

                                <tr>
                                    <td class="heading">Delivery Charge</td>
                                    <td>&#2547; 800.00</td>
                                </tr>

                                <tr>
                                    <td class="heading">Vat(10%)</td>
                                    <td>&#2547; 80.00</td>
                                </tr>
                                <tr>
                                    <td class="heading">Discount</td>
                                    <td>&#2547; -150.00</td>
                                </tr>
                                <tr>
                                     
                                    <th>
                                    <hr>
                                    Total (BTD)  
                                    </th>
                                    <th>
                                    <hr >
                                    <span class="amount">&#2547; 78,765.00</span>   
                                    </th>
                                </tr>
                                
                            </tbody>
                        </table>
                    </td>
                </tbody>
            </table>
        </div>
        <hr>

        <div class="footer">
            <p>Thank You!- Zakir Hossain</p>
            <p>Invoice Powered by Majhi</p>
        </div>

    </div>
</template>



<style scoped>


.container{
    margin: 20px auto;
    width: 700px;
    padding: 15px 20px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}


.row-data{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}




.icon-container{
    display: flex;
    align-items: center;
}

.icon-container p{
    font-size: 28px;
    color: #3A86FF;
    font-weight: bold;
}

.color-blue{
    color: #3A86FF;
}

.amount{
    color: #3A86FF;
    font-weight: 700;
}


.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.heading{
    color: #707070;  
}

.invoice{
    font-size: 30px;
    color: #1D1D1D;
    font-weight: bold;
}



.billing-info-container{
    margin-top: 30px;
}

.header-info-container p{
    margin: 0;
    
}



.billing-info-container table{
    width: 100%;
}

.billing-info-container p{
    margin: 0;
    font-size: 14px;
    color: #1D1D1D;
}


.text-bold{
    font-weight: 700;
    font-size: 18px;
}

.product-container{
    margin: 50px 0;
}

.product-container table{
    width: 100%;
}

.product-container td{
    font-size: 14px;
    font-weight: 500;
    color:#1D1D1D
}

.payment-details table{
    width: 100%;
}

.footer{
    display: flex;
    justify-content: space-between;
}


</style>


