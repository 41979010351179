<template>
  <div class="d-flex" :class="$style.global_search_input_container">
    <img src="../../assets/icon/search.svg" alt="" />
    <input
      type="text"
      name=""
      id=""
      class="w-100"
      :class="$style.search_input"
      :placeholder="props.input_field_placeholder"
      @keyup="passSearchedFieldToParent"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"

const props = defineProps({
  input_field_placeholder: { type: String, default: "" },
})
const emit = defineEmits(["sendSearchedField"])
const passSearchedFieldToParent = (event) => {
  emit("sendSearchedField", event.target.value)
}
</script>

<style module>
.global_search_input_container {
  border: 1px solid #e0e0e0;
  border-radius: 0.625rem;
  padding-left: 1.43rem;
}
.search_input {
  height: 3.25rem;
  border: none;
  padding-left: 0.87rem;
  border-radius: 0.625rem;
}
</style>
