<template>
  <div
    class="modal fade"
    id="shareCouponModal"
    tabindex="-1"
    aria-labelledby="shareCouponModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius ">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important;"
        >
          <h5>Share Coupon</h5>
          <button
            type="button"
            class="modal-close"
            id="leave-page-action-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div
          class="modal-body d-flex justify-content-center align-items-center flex-column m-2 p-2"
        >
          <div class="share-doupon-div text-center">
            <h5 class="text-white">Coupon Name</h5>
          </div>

          <div class="d-flex justify-content-center mt-3">
            <button
              type="button"
              class="share-btn common-border__radius-5 px-4 py-2 mx-1"
            >
              Share
            </button>
            <button type="button" class="btn btn-primary px-4 py-2 mx-1">
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.share-doupon-div {
  width: 400px;
  height: 190px;
  background-color: #e0013f;
  border-radius: 10px;
}

#shareCouponModal .share-btn {
  background-color: white;
  border: 1px solid #3a86ff;
}
</style>
