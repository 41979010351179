<template>
  <div class="product-details-info-holder">
    <h5>
      {{ $t("product.main.details.title") }}
    </h5>
    <div class="row mt-4">
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-3">
            <p class="m-0">
              {{ $t("product.main.details.description") }}
              <span class="text-primary">*</span>
            </p>
          </div>
          <div class="col-md-9 ps-0">
            <editor
              api-key="6qm1kbb9466eaye6dw56ity8g0w81iv7jvbtnk52q9xu5j3p"
              v-model.trim="productDetails"
              @selectionChange="detectProductDetailsChange"
              :init="{
                menubar: false,

                plugins: 'lists link image emoticons',
                toolbar:
                  'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons',
                height: '400',
              }"
            />
            <p
              class="text-danger mb-0 pt-1"
              v-if="productCreateInputErrorNo == 10"
            >
              {{ productCreateInputErrorMessage }}
            </p>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-md-3 d-flex align-items-center">
            <p class="m-0">
              {{ $t("product.main.ribbon") }}<span class="text-primary">*</span>
            </p>
          </div>
          <div class="col-md-9 ps-0">
            <Multiselect
              v-model="selectedRibbonList"
              @select="setRibbonList"
              @deselect="setRibbonList"
              @keyup="setRibbonList"
              class="h-100 px-0 py-1"
              :class="productCreateInputErrorNo == 11 ? 'border-danger' : ''"
              :placeholder="$t('product.main.ribbon_placeholder')"
              mode="tags"
              :closeOnSelect="false"
              :searchable="true"
              :createTag="false"
              :openDirection="'top'"
              :options="allRibbons"
              style="border-radius:8px !important; padding-top:10px; padding-bottom:10px"
            />
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <p class="large-text">{{ $t("product.main.tips") }}</p>
        <ul class="m-0 p-3">
          <li class="small-text">
            {{ $t("product.main.details.tips.t1") }}
          </li>
          <li class="small-text mt-3">
            {{ $t("product.main.details.tips.t2") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script scoped>
import Editor from "@tinymce/tinymce-vue";
import Multiselect from "@vueform/multiselect";
import { mapGetters, mapState } from "vuex";

export default {
  components: { editor: Editor, Multiselect },
  data: function() {
    return {
      selectedRibbonList: [1],
      productDetails: "",
    };
  },

  computed: {
    ...mapGetters("product", [
      "allRibbons",
      "productRibbons",
      "existingValueFlag",
      "productCreateInputErrorNo",
      "productCreateInputErrorMessage",
    ]),
    ...mapState("product", ["setProductDetails", "setProductRibbons"]),
  },

  watch: {
    existingValueFlag(newVal) {
      if (newVal) {
        this.productDetails = this.$store.getters["product/productDetails"];
        this.selectedRibbonList = this.productRibbons;
      }
    },
  },

  methods: {
    detectProductDetailsChange() {
      this.$store.commit("product/setProductDetails", {
        productDetails: this.productDetails,
      });
    },

    setRibbonList() {
      this.$store.commit("product/setProductRibbons", {
        productRibbons: this.selectedRibbonList,
      });
    },
  },
};
</script>

<style>
.product-details-info-holder .multiselect.is-active {
  box-shadow: none;
}

.product-details-info-holder .multiselect-tag {
  position: relative;
  padding: 8px !important;

  background: #eaeaea !important;
  color: black !important;
  font-weight: normal !important;
  border-radius: 10px !important;
  margin: 4px 0;
}

.product-details-info-holder .multiselect-tag-remove {
  position: absolute;
  z-index: 1;
  right: -10%;
  top: -10%;
  height: 18px !important;
  width: 18px !important;
  cursor: pointer;
  background-color: #999999;
  border-radius: 50% !important;
  color: white !important;
}

.product-details-info-holder .multiselect-tag-remove:hover {
  background-color: #999999 !important;
}

.product-details-info-holder .multiselect {
  height: auto !important;
}

.tox-tinymce {
  border-radius: 10px !important;
}

.tox .tox-statusbar {
  display: none !important;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
