<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div
          class="m-0 p-3 catageries-container"
          style="padding-left:32px !important; padding-right:32px !important"
        >
          <div
            class=" d-flex   state-color__fg--grey"
            style="padding-top:30px !important"
          >
            <div>
              <img
                src="../../assets/icon/back_btn_image.svg"
                alt="back"
                class="make-cursor mt-2"
                @click="
                  $router.push({
                    name: 'AllCategories',
                    params: { languageCode: getLanguage },
                  })
                "
              />
            </div>

            <div class="d-flex flex-column ">
              <h4 class="text-capitalize font-weight-500 ms-3 mb-0">
                {{ categoryName }}
              </h4>
              <p style="color:#777777; font-size:14px" class="ms-3">
                {{ totalPage }} Products
              </p>
            </div>
          </div>

          <div
            class="
              d-flex
              flex-column-reverse flex-lg-row flex-md-column
              justify-content-between
              align-items-center
              my-3
            "
          >
            <product-search
              v-on:requestSearchProductByCategory="requestToSearchData($event)"
              :isAllCategoryOPtionOpen="false"
            />
          </div>

          <!-- <div class="bg-white common-border__radius">
            <menu-bar
              :routeName="{ name: 'CategoriesProduct' }"
              :isCategoryOpen="false"
            />
          </div> -->

          <div class="bg-white my-4" style="border-radius:10px">
            <product-view
              v-bind:filteredProducts="filteredProducts"
              v-bind:totalPage="totalPage"
              v-bind:lastPage="lastPage"
              v-on:requestDataByPageNumber="requestDataByPageNumber($event)"
              :searchField="searchField"
            />
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
//import MenuBar from "../../components/product/MenuBar.vue";
import ProductSearch from "../../components/product/productSearch.vue";
import ProductView from "../../components/product/productView.vue";
//import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    LoadingSpinner,
    SideBar,
    ProductSearch,
    TopBar,
    ProductView,
  },

  props: {
    name: String,
  },

  data: function() {
    return {
      currentPage: 1,
      searchCategory: "",
      searchKey: "",

      businessCategoryId: 0,
      AllAvailableProductCategories: null,
      categoryName: "",
      searchField: "",
    };
  },

  computed: {
    ...mapGetters("product", [
      "filteredProducts",
      "allProducts",
      "totalPage",
      "lastPage",
      "AllAvailableProductCategories",
    ]),
    ...mapGetters("language", ["getLanguage"]),
  },

  async created() {
    const category = this.$route.params.categoryId;
    let url = `/seller_products/en/${this.currentPage}?cat=${category}&search=`;
    //this.getSellerProducts(url);
    await this.getAllProduct(url);
    let name = this.$route.params.categoryName;
    this.categoryName = name;
  },

  methods: {
    ...mapActions("product", ["getAllProduct", "getAllCategories"]),

    async requestToSearchData(searchFields) {
      this.searchField = searchFields.inputProdcutSearch;

      let keyword = searchFields.inputProdcutSearch;
      this.searchKey = keyword;
      const category = this.$route.params.categoryId;
      let url = `/seller_products/en/${this.currentPage}?cat=${category}&search=${keyword}`;
      //this.getSellerProducts(url);
      await this.getAllProduct(url);
    },

    async requestDataByPageNumber(pageNumber) {
      this.currentPage = pageNumber;
      const category = this.$route.params.categoryId;
      let url = `/seller_products/en/${this.currentPage}?cat=${category}&search=${this.searchKey}`;
      //this.getSellerProducts(url);
      await this.getAllProduct(url);
    },
  },
};
</script>

<style>
.catageries-container {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #f3f6f9;
  min-height: 95vh;
}

.category-based-product-container {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
</style>
