<template>
  <div class="all-categories__container common-border__radius overflow-x-auto">
    <table>
      <thead>
        <tr
          class="state-color__fg--grey1 google_sans_medium category-table-heading"
        >
          <td scope="col" class="category-table-single-header">
            {{ $t("manage.main.customer.table_headers.h1") }}
          </td>
          <td scope="col" class="category-table-single-header">
            {{ $t("manage.main.customer.table_headers.h2") }}
          </td>
          <td scope="col" class="category-table-single-header">
            {{ $t("manage.main.customer.table_headers.h3") }}
          </td>
          <td scope="col" class="category-table-single-header">
            {{ $t("manage.main.customer.table_headers.h4") }}
          </td>
          <td scope="col" class="category-table-single-header">
            {{ $t("manage.main.customer.table_headers.h5") }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          class="category-table-body-text"
          v-for="customer in customerList"
          :key="customer.customer_phone"
        >
          <td data-label="ID">
            {{ customer.id_customer }}
          </td>
          <td data-label="NAME">
            {{ customer.customer_name }}
          </td>
          <td data-label="PHONE">
            {{ "0" + customer.customer_phone }}
          </td>
          <td data-label="LOCATION">
            {{ customer.customer_location }}
          </td>
          <td data-label="TOTAL ORDER">
            {{ customer.total_orders }}
          </td>
        </tr>
        <tr
          v-if="
            customerList == null || (customerList && customerList.length === 0)
          "
        >
          <td colspan="5">
            <div class="text-center">
              <img src="../../assets/icon/No-customer.svg" alt="" />
              <h5 class="text-center state-color__fg--grey1">
                {{ $t("manage.main.customer.not_found") }}!
              </h5>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <pagination-component
    v-if="customerList !== null && customerList.length !== 0"
    v-bind:lastPage="lastPage"
    v-bind:currentPage="currentPage"
    v-on:requestDataByPageNumber="getDataByPage($event)"
  />
</template>

<script>
import PaginationComponent from "../PaginationComponent.vue";
export default {
  components: { PaginationComponent },
  props: ["customerList", "currentPage", "lastPage"],
  emits: ["requestCustomerByPageNumber"],
  data: function() {
    return {};
  },

  methods: {
    getDataByPage(pageNumber) {
      this.$emit("requestCustomerByPageNumber", pageNumber);
    },
  },
};
</script>

<style scoped>
.category-table-heading {
  background-color: #fafcfd !important;
  height: 60px !important;
  margin: auto;
}

.category-table-single-header {
  padding-top: 15px !important;
}

.category-table-body-text {
  font-size: 14px !important;
  color: #1d1d1d !important;
}
</style>
