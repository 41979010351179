import Client from "../client/axiosClient";
import store from "../../store";

export default {
  updateSellerAddress(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_store_address_update/${language}`,
      method: "PUT",
      data,
    });
  },
};
