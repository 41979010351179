import home from "../languages/english/home";
import auth from "../languages/english/auth";
import setting from "../languages/english/setting";
import appearance from "../languages/english/appearance";
import manage from "../languages/english/manage";
import product from "../languages/english/product";
import notFound from "../languages/english/not-found";

//bangla

import homeB from "../languages/bangla/home";
import authB from "../languages/bangla/auth";
import settingB from "../languages/bangla/setting";
import appearanceB from "../languages/bangla/appearance";
import manageB from "../languages/bangla/manage";
import productB from "../languages/bangla/product";
import notFoundB from "../languages/bangla/not-found";

export const en = {
  home,
  auth,
  setting,
  appearance,
  manage,
  product,
  notFound: notFound,
};

export const bn = {
  home: homeB,
  auth: authB,
  setting: settingB,
  appearance: appearanceB,
  manage: manageB,
  product: productB,
  notFound: notFoundB,
};
