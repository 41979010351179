<template>
  <div>
    <div
      class="modal fade"
      id="addCategoriesModal"
      tabindex="-1"
      aria-labelledby="addCategoriesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content common-border__radius">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("product.category.category_form.heading") }}
            </h5>
            <button
              type="button"
              class="modal-close"
              id="action-close"
              data-bs-dismiss="modal"
            >
              <img src="../../assets/icon/cross.svg" alt="cross" />
            </button>
          </div>
          <div class="modal-body m-2">
            <form @submit.prevent="handleSubmit()">
              <div>
                <p>{{ $t("product.category.category_form.category_image") }}</p>

                <div
                  class="single-product-image my-4 me-3"
                  v-if="image != null"
                >
                  <img
                    src="../../assets/icon/cross.svg"
                    width="25"
                    height="25"
                    alt=""
                    class="new-product__close broder-circle"
                    @click="removeImage()"
                  />
                  <img
                    class="w-100 h-100 common-border__radius new-image"
                    :src="url"
                  />
                </div>

                <div
                  class="dotted-border d-flex flex-column py-3 px-4 text-center"
                  v-else
                >
                  <img
                    src="../../assets/icon/image.svg"
                    alt=""
                    width="40"
                    class="mx-auto my-auto"
                  />

                  <label
                    class="custom-file-upload mx-auto my-auto state-color__fg--Info google_sans_medium"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      @change="uploadImage($event)"
                      id="file-input"
                    />
                    {{
                      $t(
                        "product.category.category_form.category_image_placeholder"
                      )
                    }}
                  </label>
                  <p class="mb-0 state-color__fg--grey1">
                    {{ $t("product.category.category_form.support_before") }}
                    <br />{{
                      $t("product.category.category_form.support_after")
                    }}
                  </p>
                </div>

                <p v-if="errorShopLogo != ''" class="state-color__fg--error">
                  {{ errorShopLogo }}
                </p>
              </div>

              <div class="my-4">
                <p>{{ $t("product.category.category_form.category_name") }}</p>
                <!-- <Multiselect
                  v-model="categoryName"
                  mode="tags"
                  :searchable="true"
                  :createTag="true"
                  max="1"
                  :options="
                    getAllProductCategoriesUnderBusinessCategory?.list_categories
                  "
                  :placeholder="
                    $t(
                      'product.category.category_form.category_name_placeholder'
                    )
                  "
                /> -->

                <input
                  type="text"
                  name=""
                  id=""
                  required
                  v-model="categoryName"
                  class="form-control"
                  style="height: 50px;"
                  :placeholder="
                    $t(
                      'product.category.category_form.category_name_placeholder'
                    )
                  "
                />
                <p
                  v-if="errorCategoryName != ''"
                  class="state-color__fg--error"
                >
                  {{ errorCategoryName }}
                </p>
              </div>

              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn btn-primary google_sans_medium"
                  style="padding: 15px 40px"
                >
                  {{ $t("product.category.category_form.btn") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <categoryImageCropper
      :ImageUrl="originalImageUrl"
      :image="blobImage"
      @croppedData="addProductCroppedImage"
    />

    <button
      id="categoryImageCrop"
      data-bs-toggle="modal"
      data-bs-target="#categoryImageCropModal"
      class="d-none"
    ></button>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
// import Multiselect from "@vueform/multiselect";

import categoryImageCropper from "../cropper/categoryImageCropper.vue";

export default {
  components: { categoryImageCropper },
  emits: ["updateSelectedCategoryId"],

  data: function() {
    return {
      blobImage: null,
      originalImageUrl: "",
      categoryName: "",
      image: null,
      url: "",
      errorShopLogo: "",
      errorCategoryName: "",
    };
  },

  computed: {
    ...mapGetters("productCategories", [
      "getAllProductCategoriesUnderBusinessCategory",
    ]),
    ...mapGetters("auth", ["getUser"]),
  },

  methods: {
    ...mapActions("productCategories", ["categoryCreateRequest"]),

    uploadImage(event) {
      if (!event.target.files[0]) return;
      this.image = event.target.files[0];

      if (this.image.size > 1024 * 1021 * 6) {
        this.errorShopLogo = this.$t("product.main.toast.image_size");
        this.image = null;
        this.url = "";
        return;
      } else if (
        event.target.files[0]["type"] === "image/jpeg" ||
        event.target.files[0]["type"] === "image/png" ||
        event.target.files[0]["type"] === "image/jpg" ||
        event.target.files[0]["type"] === "image/bmp"
      ) {
        this.errorShopLogo = "";
        // this.url = URL.createObjectURL(this.image);

        this.croppedImage(event);
        this.image = null;
        this.url = "";
      } else {
        this.errorShopLogo = this.$t("product.main.toast.image_format");
        this.image = null;
        this.url = "";
      }
    },

    removeImage() {
      this.image = null;
      this.url = "";
    },

    croppedImage(event) {
      this.originalImageUrl = URL.createObjectURL(event.target.files[0]);
      const blob = URL.createObjectURL(event.target.files[0]);
      this.blobImage = {
        src: blob,
        type: event.target.files[0].type,
        name: event.target.files[0].name,
      };

      $("#categoryImageCrop").trigger("click");
    },

    addProductCroppedImage(data) {
      this.url = data.url;
      this.image = data.image;
    },

    async handleSubmit() {
      let vm = this;
      console.log(this.categoryName);
      if (this.categoryName === "") {
        this.errorCategoryName = vm.$t(
          "product.category.direct_category_assign.error_select_category"
        );
        return;
      } else if (this.categoryName.length > 40) {
        this.errorCategoryName = vm.$t("product.category.error.name_length");

        return;
      }
      this.errorCategoryName = "";
      //this.errorCategoryName = "";

      const formData = new FormData();
      formData.append(
        "id_business_category",
        this.getUser.business_category_id
      );
      formData.append("product_category_name", vm.categoryName);

      if (vm.image != null) {
        formData.append("image", vm.image);
        this.errorShopLogo = "";
      }

      // else {
      //   this.errorShopLogo = vm.$t("product.category.error.image");
      //   this.$store.commit("setOverlayStatus", false);
      //   return;
      // }

      const newCategory = await this.categoryCreateRequest(formData);
      if (newCategory == undefined) return;
      $("#action-close").trigger("click"); // close modal
      vm.removeImage();
      vm.categoryName = [];
      this.$emit("updateSelectedCategoryId", newCategory);
    },
  },
};
</script>

<style>
.multiselect-tags {
  overflow: hidden !important;
}
</style>
