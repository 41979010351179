import Repository from "../../api/RepositoryFactory";
const coupon = Repository.get("coupon");
import Toast, { successBackground } from "../../config/toastConfig";
import router from "../../router";
import store from "../../store";

const state = {
  coupons: [],
  coupon: {},
  couponType: 1,
  total: 0,
  lastPage: 1,
  currentPage: 1,
};

const getters = {
  allCoupons: (state) => state.coupons,
  singleCoupon: (state) => state.coupon,
  couponType: (state) => state.couponType,
  total: (state) => state.total,
  lastPage: (state) => state.lastPage,
};

const mutations = {
  SET_COUPONS: (state, coupons) => (state.coupons = coupons),
  SET_COUPON: (state, coupon) => (state.coupon = coupon),

  REMOVE_COUPON: (state, id) => {
    const data = state.coupons.filter((coupon) => coupon.id_coupon !== id);
    state.coupons = data;
  },

  setCouponType(state, { type }) {
    state.couponType = type;
  },

  GET_SINGLE_COUPON: (state, { id }) => {
    const data = state.coupons.filter((coupon) => coupon.id_coupon == id);
    if (data.length > 0) {
      state.coupon = data[0];
    }
  },

  SET_TOTAL: (state, data) => (state.total = data),
  SET_LAST_PAGE: (state, data) => (state.lastPage = data),
  SET_CURRENT_PAGE: (state, data) => (state.currentPage = data),
};

const actions = {
  async getAllCoupons({ commit, state }, page) {
    if (!page) {
      page = state.currentPage;
    }
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await coupon.getAllCoupon(page);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.data) {
        commit("SET_COUPONS", response.data.coupon_list);
        commit("SET_TOTAL", response.data.total);
        commit("SET_LAST_PAGE", response.data.last_page);
        commit("SET_CURRENT_PAGE", page);
      } else {
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error.response);
    }
  },

  async deleteCoupon({ commit }, id) {
    try {
      const res = await coupon.deleteCoupon(id);
      if (res.success) {
        Toast.fire({
          icon: "success",
          title: res.message,
          background: successBackground,
        });
      }

      commit("REMOVE_COUPON", id);
    } catch (error) {
      console.log(error);
    }
  },

  async createCoupon({ commit }, data) {
    const res = await coupon.createCoupon(data);
    if (res.success) {
      Toast.fire({
        icon: "success",
        title: res.message,
        background: successBackground,
      });
      commit("SET_COUPON", res.data);
      router.push({
        name: "CouponView",
        params: { languageCode: store.getters["language/getLanguage"] },
      });
    }
  },

  async updateCoupon({ commit }, data) {
    commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
    const res = await coupon.updateCoupon(data);

    if (res.success) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      Toast.fire({
        icon: "success",
        title: res.message,
        background: successBackground,
      });
      commit("SET_COUPON", res.data);
      router.push({
        name: "CouponView",
        params: { languageCode: store.getters["language/getLanguage"] },
      });
    } else {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
    }
  },

  async updateCouponStatusRequest({ commit, dispatch }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await coupon.updateCouponStatus(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        dispatch("getAllCoupons");
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async searchCouponRequest({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await coupon.searchCoupon(data);

      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      commit("SET_COUPONS", response.data.coupon_list);
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error.response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
