<template>
  <!-- fake div for taking the fixed div (scroll bar space) -->
  <div
    class="min-height-100vh col-auto col-xxl-2 col-xl-2 col-lg-2 col-md-2 px-0"
    :class="
      $store.getters.getSidebarVisibility
        ? 'responsive-sidebar-container-show'
        : 'responsive-sidebar-container-hide'
    "
  ></div>
  <div
    class="min-height-100vh col-auto col-xxl-2 col-xl-2 col-lg-2 px-0 position-fixed min-height-100vh z-index-50"
    :class="
      $store.getters.getSidebarVisibility
        ? 'responsive-sidebar-container-show'
        : 'responsive-sidebar-container-hide'
    "
    style="background-color: #272f46"
  >
    <div class="min-vh-100 d-flex flex-column justify-content-between pb-4">
      <div
        class="
        d-flex
        flex-column
        align-items-center align-items-sm-start
        px-3
        pt-2
        text-white
        
        position-relative
      "
        :class="
          $store.getters.getSidebarVisibility
            ? 'responsive-sidebar-show'
            : 'responsive-sidebar-hide'
        "
        style="background-color: #272f46"
      >
        <div class="w-100 sidebar-toggle__btn">
          <img
            src="../../assets/sidebar/02_User@2x.png"
            width="20"
            class="sidebar-toggle__btn"
            id="sideBarCloseBtn"
            @click="
              $store.commit(
                'setSidebarVisibility',
                !this.$store.getters.getSidebarVisibility
              )
            "
          />
        </div>
        <div class="mw-100 sidebar-image-min-height mx-auto">
          <div
            class="text-white text-decoration-none position-relative top-50 start-50 translate-middle"
          >
            <div class="d-flex justify-content-center">
              <img
                :src="getShopLogoUrl"
                alt="home"
                width="84"
                class="fs-5 m-auto mx-auto rounded-circle"
              />
            </div>

            <h4
              class="text-center fw-bolder mw-100 text-truncate mt-3"
              :class="
                getLanguage == 'en'
                  ? 'majhi_name_font_poppins'
                  : 'majhi_name_font_hind_siliguri'
              "
            >
              {{ getUser.shop_name }}
            </h4>
          </div>
        </div>

        <div
          class="d-flex flex-column mb-sm-auto routes scroll"
          style="min-width: 100%"
        >
          <div style="min-width: 100%" @click="closeSideBar">
            <router-link
              :to="{ name: 'Home', params: { languageCode: getLanguage } }"
              class="
              d-sm-flex
              justify-content-lg-start
              justify-content-md-start
              justify-content-sm-start
              p-3
              nav-link
            "
            >
              <img
                src="../../assets/sidebar/selected/home-s.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-if="$route.name.toLowerCase() == 'home'"
              />
              <img
                src="../../assets/sidebar/home.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-else
              />
              <span
                class="ms-1 small-text font-weight-600 letter-spacing-5px google_sans_bold"
                :class="
                  $route.name.toLowerCase() == 'home'
                    ? 'text-white'
                    : 'state-color__fg--grey3'
                "
                >{{ $t("home.main.sidebar.home") }}</span
              >
            </router-link>
          </div>

          <div style="min-width: 100%" @click="closeSideBar">
            <router-link
              :to="{ name: 'AllOrders', params: { languageCode: getLanguage } }"
              class="d-flex justify-content-between p-3 nav-link"
              :class="
                $route.path.toLowerCase().includes('orders')
                  ? 'active-sidebar'
                  : ''
              "
              style="min-width: 100%"
            >
              <div
                class="
                d-sm-flex
                justify-content-lg-start
                justify-content-md-start
                justify-content-sm-start
              "
              >
                <img
                  src="../../assets/sidebar/selected/orders-s.svg"
                  alt="home"
                  width="15"
                  class="my-auto me-3"
                  v-if="$route.path.toLowerCase().includes('orders')"
                />
                <img
                  src="../../assets/sidebar/orders.svg"
                  alt="orders"
                  width="15"
                  class="my-auto me-3"
                  v-else
                />
                <span
                  class="ms-1 small-text font-weight-600 letter-spacing-5px google_sans_bold"
                  :class="
                    $route.path.toLowerCase().includes('orders')
                      ? 'text-white'
                      : 'state-color__fg--grey3'
                  "
                  >{{ $t("home.main.sidebar.orders") }}</span
                >
              </div>
              <div class="sidebar-order-count">
                {{ salesDetails?.total_orders }}
              </div>
            </router-link>
          </div>

          <div style="min-width: 100%" @click="closeSideBar">
            <router-link
              :to="{
                name: 'AllProducts',
                params: { languageCode: getLanguage },
              }"
              class="
              d-sm-flex
              justify-content-lg-start
              justify-content-md-start
              justify-content-sm-start
              p-3
              nav-link
            "
              :class="
                $route.path.toLowerCase().includes('products')
                  ? 'active-sidebar'
                  : ''
              "
            >
              <img
                src="../../assets/sidebar/selected/product-s.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-if="$route.path.toLowerCase().includes('products')"
              />

              <img
                src="../../assets/sidebar/product.svg"
                alt="products"
                width="15"
                class="my-auto me-3"
                v-else
              />
              <span
                class="ms-1 small-text font-weight-600 letter-spacing-5px google_sans_bold"
                :class="
                  $route.path.toLowerCase().includes('products')
                    ? 'text-white'
                    : 'state-color__fg--grey3'
                "
                >{{ $t("home.main.sidebar.products") }}</span
              >
            </router-link>
          </div>

          <div style="min-width: 100%" @click="closeSideBar">
            <router-link
              :to="{ name: 'Manage', params: { languageCode: getLanguage } }"
              class="
              d-sm-flex
              justify-content-lg-start
              justify-content-md-start
              justify-content-sm-start
              p-3
              nav-link
            "
              :class="
                $route.path.toLowerCase().includes('manage')
                  ? 'active-sidebar'
                  : ''
              "
            >
              <img
                src="../../assets/sidebar/selected/manage-s.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-if="$route.path.toLowerCase().includes('manage')"
              />

              <img
                src="../../assets/sidebar/manage.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-else
              />
              <span
                class="ms-1 small-text font-weight-600 letter-spacing-5px google_sans_bold"
                :class="
                  $route.path.toLowerCase().includes('manage')
                    ? 'text-white'
                    : 'state-color__fg--grey3'
                "
                >{{ $t("home.main.sidebar.manage") }}</span
              >
            </router-link>
          </div>

          <div style="min-width: 100%" @click="closeSideBar">
            <router-link
              :to="{
                name: 'Appearance',
                params: { languageCode: getLanguage },
              }"
              class="
              d-sm-flex
              justify-content-lg-start
              justify-content-md-start
              justify-content-sm-start
              p-3
              nav-link
            "
              :class="
                $route.path.toLowerCase().includes('appearance')
                  ? 'active-sidebar'
                  : ''
              "
            >
              <img
                src="../../assets/sidebar/selected/appearance.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-if="$route.path.toLowerCase().includes('appearance')"
              />

              <img
                src="../../assets/sidebar/appearance.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-else
              />
              <span
                class="ms-1 small-text font-weight-600 letter-spacing-5px google_sans_bold"
                :class="
                  $route.path.toLowerCase().includes('appearance')
                    ? 'text-white'
                    : 'state-color__fg--grey3'
                "
                >{{ $t("home.main.sidebar.appearance") }}</span
              >
            </router-link>
          </div>

          <div style="min-width: 100%" @click="closeSideBar">
            <router-link
              :to="{ name: 'Settings', params: { languageCode: getLanguage } }"
              class="
              d-sm-flex
              justify-content-lg-start
              justify-content-md-start
              justify-content-sm-start
              p-3
              nav-link
            "
            >
              <img
                src="../../assets/sidebar/selected/setting-s.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-if="$route.path.toLowerCase().includes('settings')"
              />

              <img
                src="../../assets/sidebar/setting.svg"
                alt="home"
                width="15"
                class="my-auto me-3"
                v-else
              />
              <span
                class="ms-1 small-text font-weight-600 letter-spacing-5px google_sans_bold"
                :class="
                  $route.path.toLowerCase().includes('settings')
                    ? 'text-white'
                    : 'state-color__fg--grey3'
                "
                >{{ $t("home.main.sidebar.settings") }}</span
              >
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <div class="px-3 d-lg-none d-xl-none d-xxl-none">
          <p class="state-color__fg--grey3 small-text mb-0">
            {{ $t("home.main.topBar.support") }} :
          </p>
          <p class="text-white small-text font-weight-500 mb-0">
            {{ chatWithUsSupportNumber }}
          </p>
        </div>
        <div
          class="text-dark text-left mt-3 chat-with-us-container mx-auto"
          style="width: 95% !important; background-color: #3a435d"
        >
          <div role="button" @click="chatWithAdmin">
            <div style="margin-top:5px">
              <p
                class="ms-1 nav-link state-color__fg--grey1 small-text1 google_sans_bold p-0 mb-1"
              >
                {{ $t("home.main.sidebar.help") }}?
              </p>
            </div>
            <h5 class="ms-1 large-text text-white google_sans_bold">
              {{ $t("home.main.sidebar.chat") }}
            </h5>
          </div>
        </div>
        <div class="d-none d-lg-block d-xl-block d-xxl-block ms-2">
          <p class="mb-0 small-text mt-3">
            <span class="text-white opacity-500">{{
              $t("auth.signUp.success.powered")
            }}</span>

            <span class="state-color__fg--Info font-weight-600 ms-2">
              <img
                src="../../assets/icon/majhi_primary.svg"
                alt="home"
                height="22"
                class="fs-5 m-auto mx-auto"
              />{{ $t("auth.signUp.heading") }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SideBar",
  components: {},

  data() {
    return {
      adminPhone: "01322908231",
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
    ...mapGetters("graph", ["salesDetails"]),
    ...mapGetters("auth", ["getUser", "getShopLogoUrl"]),
    ...mapGetters("settings", ["chatWithUsSupportNumber"]),
  },

  methods: {
    ...mapActions("graph", ["requestGraphInfo"]),

    chatWithAdmin() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.location.replace(
          `https://api.whatsapp.com/send?phone=+88${this.adminPhone}&text=Hi`
        );
        setTimeout(() => {
          window.location.replace(
            "https://play.google.com/store/apps/details?id=com.whatsapp"
          );
        }, 700);
        // setTimeout(fallbackToStore, 700);
        // upper 700ms is problematic. not work perfectly.
        // (50-700) ms is ok.
      } else {
        //window.open("https://web.whatsapp.com/", "");
        window.open(
          `https://api.whatsapp.com/send?phone=+88${this.adminPhone}&text=Hi`
        );
      }
    },

    closeSideBar() {
      let btn = document.getElementById("sideBarCloseBtn");
      if (btn) {
        btn.click();
      }
    },
  },

  async created() {
    await this.requestGraphInfo({ timeline: "", start: "", end: "" });
  },
};
</script>

<style>
.active-sidebar,
.routes a.router-link-active {
  background-color: #41485c !important;
  border-radius: 4px;
  font-weight: bold;
}

.sidebar-image-min-height {
  height: 250px;
}

.chat-with-us-container {
  /* position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, -50%); */
  border-radius: 10px;
  padding: 10px;
}

/* vertical scroll bar on menu  */
@media screen and (max-height: 700px) {
  .scroll {
    overflow-y: scroll;
    max-height: 250px;
  }

  /* .chat-with-us-container {
    bottom: 0;
  } */
}

@media screen and (max-height: 600px) {
  .scroll {
    overflow-y: scroll;
    /* max-height: 170px; */
  }

  /* .chat-with-us-container {
    bottom: -8%;
  } */
}

@media screen and (max-width: 992px) {
  .responsive-sidebar-show {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    min-width: 300px;
    /* min-height: 100vh; */
  }

  .responsive-sidebar-container-show {
    background-color: #272f46 !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 400px;
    min-height: 100%;
    animation: 0.7s ease-out 0s 1 slideInLeft;
  }

  .responsive-sidebar-container-hide {
    display: none !important;
  }

  .responsive-sidebar-hide {
    width: 0;
  }

  .sidebar-image-min-height {
    min-height: 100%;
  }
}

.sidebar-order-count {
  height: 21px !important;
  width: 21px !important;
  background-color: #3a86ff;
  border-radius: 50%;
  color: white;
  font-size: 0.625rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
