<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div class="my-3 state-color__fg--grey page-heading-top-padding">
            <h4 class="page-heading-text-size">
              {{ $t("appearance.main.title") }}
            </h4>
            <div class="pt-3 d-flex flex-wrap">
              <div class="card m-1" style="border-radius:10px !important">
                <img
                  src="../../assets/appearance/appearance1.png"
                  width="360"
                  height="340"
                  alt=""
                  class="appearance-image"
                />
                <div
                  class="d-flex justify-content-between align-items-center p-3 my-2"
                >
                  <div class="theme-name">
                    {{ $t("appearance.main.theme_name") }}
                  </div>
                  <div
                    class="btn btn-primary appearance-button"
                    @click="openThemPage"
                  >
                    {{ $t("appearance.main.customize") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <loading-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";

import { mapGetters } from "vuex";

export default {
  components: { SideBar, TopBar, LoadingSpinner },
  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    openThemPage() {
      this.$router.push({
        name: "Theme",
        params: { languageCode: this.getLanguage },
      });
    },
  },
};
</script>

<style scoped>
.theme-name {
  font-size: 20px !important;
  color: #333333 !important;
  font-weight: 700;
}

.appearance-button {
  padding: 0.78rem 2.2rem;
  border-radius: 8px !important;
}

.appearance-image {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
</style>
