<template>
  <div
    class="container d-flex justify-content-center
    align-items-center vh-100 not-found-container
"
  >
    <div class="d-flex flex-column align-items-center ">
      <img src="../assets/icon/404.svg" alt="" class="not-found-image" />
      <h3>{{ $t("notFound.page.title") }}</h3>
      <p>{{ $t("notFound.page.description") }}</p>

      <div>
        <router-link
          :to="{ name: 'SignIn', params: { languageCode: getLanguage } }"
          class="btn btn-primary google_sans_medium py-3 px-4"
        >
          {{ $t("notFound.page.btn_text") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
};
</script>

<style scoped>
.not-found-container {
  overflow: hidden !important;
}
.not-found-image {
  width: 100%;
}
</style>
