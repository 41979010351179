import Client from "../client/axiosClient";
import store from "../../store";

export default {
  countryList() {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/country_list/${language}`,
      method: "GET",
    });
  },

  divisionByCountry(id) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/division_by_country/${language}/${id}`,
      method: "GET",
    });
  },

  cityByDivision(id) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/city_by_division/${language}/${id}`,
      method: "GET",
    });
  },

  areaByCity(id) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/area_by_city/${language}/${id}`,
      method: "GET",
    });
  },

  updateContactNumber(data) {
    return Client({
      url: "/seller_phone_update",
      method: "POST",
      data,
    });
  },

  updateProfile(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/update_seller_profile/${language}`,
      method: "POST",
      data,
    });
  },

  sellerInfoByShopName(shopName) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_info_by_shop/${language}?shop_name=${shopName}`,
      method: "GET",
    });
  },

  userProfileDetails(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_profile/${language}/${data}`,
      method: "GET",
    });
  },
};
