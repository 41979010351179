<template>
  <div class="wrapper">
    <img class="image" :src="imageUrl()" alt="background" />
    <div class="card-content">
      <div class="logo-container">
        <img :src="store_logo_url" alt="logo" class="logo" />
      </div>

      <div>
        <p class="store-name">{{ shopName }}</p>
        <p class="category-name">{{ category }}</p>
      </div>

      <div class="contact-info">
        <p>{{ phone }}</p>
        <p>{{ email }}</p>
        <p>{{ area }},{{ city }},{{ division }}</p>
      </div>
      <div class="footer">
        <p>{{ baseUrl }}/{{ shopName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: "",
      category: "",
      phone: "",
      email: "",
      area: "",
      city: "",
      division: "",
      baseUrl: "www.majhi.com/en",
      store_logo_url: "",
    };
  },

  created() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);

      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  updated() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  methods: {
    imageUrl() {
      return require("@/assets/marketing-materials/business-card/card-3/BC-3.png");
    },

    loadLogo() {
      return require("@/assets/marketing-materials/business-card/card-3/Group.png");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 4px;
  position: relative;
  color: white;
}

.image {
  width: 100%;
}

.logo-container {
  text-align: center;
}

.logo {
  width: 9em;
  height: 9em;
  border-radius: 50%;
}

.card-content {
  position: absolute;
  top: 3em;
  left: 13em;
}

.store-name {
  margin-top: 0.3em;
  font-size: 3.5em;
  font-weight: 700;
  text-align: center;
}

.category-name {
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 600;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.contact-info {
  font-size: 2em;
  margin: 3px 0 5px 0;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.footer {
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #fac823;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 1600px) {
  .wrapper {
    font-size: 3px;
  }
}

@media only screen and (max-width: 1280px) {
  .wrapper {
    font-size: 4.9px;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    font-size: 7.8px;
  }

  .contact-info {
    margin: 3px 0 3px 0;
  }

  .card-content {
    top: 1.7em;
    left: 15em;
  }
}
</style>
