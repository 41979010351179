<template>
  <div
    class="image-container"
    :style="{
      backgroundImage: 'url(' + getImgUrl(imageName) + ')',
    }"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      // selectedImage:"S1.png"
    };
  },

  props: ["imageName"],

  methods: {
    getImgUrl(data) {
      return require("../../assets/marketing-materials/facebook-instagram-post/" +
        data);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Nunito:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Open+Sans:wght@400;500;700&display=swap");

.image-container {
  width: 100%;
  height: 200px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.store-name-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.store-name {
  position: absolute;
  left: 30%;
  top: 400px;
  font-family: "Nunito Sans", sans-serif;
}

@media only screen and (max-width: 600px) {
  .image-container {
    width: 100%;
  }
}
</style>
