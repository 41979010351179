import Client from "../client/axiosClient";
import store from "../../store";

export default {
  updateStoreTiming(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/store_timing_update/${language}`,
      method: "PUT",
      data,
    });
  },

  getStoreTime() {
    return Client({
      url: `store_timing_list`,
      method: "GET",
    });
  },
};
