<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div
          class="m-0 p-3 catageries-container app_container"
          style="min-width:400px"
        >
          <div
            class="common-border__radius d-flex my-4 page-heading-top-padding"
          >
            <img
              src="../assets/icon/back_btn_image.svg"
              alt="back"
              @click="$router.go(-1)"
              role="button"
            />
            <h4 class="ms-3">{{ $t("home.notification.heading") }}</h4>
          </div>

          <div class="my-4">
            <div class="notification-container">
              <div class="notification">
                <div
                  class=" p-3 d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="p-3 heading">
                      {{ $t("home.notification.all") }}
                    </div>
                    <div class="new">
                      {{ getUnReadNotification }}
                      {{ $t("home.notification.new") }}
                    </div>
                  </div>

                  <a
                    v-if="getUnReadNotification !== 0"
                    href="#"
                    class="text-decoration-none all-read"
                    @click="markAllRead()"
                  >
                    {{ $t("home.notification.mark_all_btn") }}
                  </a>
                </div>

                <div class="d-flex flex-column">
                  <notification-card
                    v-for="notification in allNotification"
                    :key="notification.id_notification"
                    :notification="notification"
                  />
                </div>

                <div
                  v-if="!allNotification.length && showNotFound"
                  class="text-center"
                  style="height:500px"
                >
                  <img src="../assets/icon/No-notifications.svg" alt="" />
                  <h5 class="text-center state-color__fg--grey1">
                    {{ $t("home.notification.not_found") }}!
                  </h5>
                </div>

                <pagination-component
                  v-bind:lastPage="lastPage"
                  v-bind:currentPage="currentPage"
                  v-on:requestDataByPageNumber="getDataByPage($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-spinner />
  </div>
</template>

<script>
import SideBar from "../components/menubar/SideBar.vue";
import TopBar from "../components/menubar/TopBar.vue";
import NotificationCard from "../components/notification/NotificationCard.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import PaginationComponent from "../../src/components/PaginationComponent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SideBar,
    TopBar,
    NotificationCard,
    LoadingSpinner,
    PaginationComponent,
  },

  data() {
    return {
      currentPage: 1,
      totalUnreadNotifications: 0,
      showNotFound: false,
    };
  },

  computed: mapGetters("notification", [
    "allNotification",
    "getUnReadNotification",
    "lastPage",
  ]),

  watch: {
    allNotification(newValue, oldValue) {
      let sellerKey = JSON.parse(localStorage.getItem("majhiUser")).seller_key;
      let data = {
        sellerKey,
        page: this.currentPage,
      };

      if (newValue.length === 0 && oldValue.length !== 0) {
        this.showNotFound = false;
        this.getAllNotification(data);
      } else {
        this.showNotFound = true;
      }
    },
  },

  created() {
    this.$store.commit("notification/changeModalStatus", false);
    this.loadNotification();
  },

  methods: {
    ...mapActions("notification", [
      "getAllNotification",
      "markAllNotificationRed",
    ]),
    getDataByPage(pageNumber) {
      this.currentPage = pageNumber;
      this.loadNotification();
    },

    async loadNotification() {
      let sellerKey = JSON.parse(localStorage.getItem("majhiUser")).seller_key;
      let data = {
        sellerKey,
        page: this.currentPage,
      };
      this.getAllNotification(data);
    },

    async markAllRead() {
      let sellerKey = JSON.parse(localStorage.getItem("majhiUser")).seller_key;
      await this.markAllNotificationRed(sellerKey);
    },
  },
};
</script>

<style scoped>
.notification {
  width: 50%;
  margin: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 100px;
}

.heading {
  /* font-size: 24px; */
  font-size: 1.5rem !important;
  color: #1d1d1d;
  font-weight: 600;
}

.new {
  background: #eaf2ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 7px;
  color: #3a86ff;
}

@media only screen and (max-width: 800px) {
  .notification {
    width: 100%;
  }

  .heading {
    /* font-size: 20px; */
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 425px) {
  .notification {
    width: 100%;
  }

  .heading {
    /* font-size: 20px; */
    font-size: 1.25rem;
  }

  .all-read {
    display: none;
  }
}
</style>
