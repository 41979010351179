<template>
  <div class="p-3 " style="margin-left:15px !important">
    <div class="d-flex flex-column align-items-start">
      <div>
        <h5 style="">
          {{ $t("product.main.product_images.title") }}
          <span class="text-primary">*</span>
        </h5>
      </div>
      <div class="d-flex flex-row flex-wrap z-index-1 my-4">
        <div
          class="single-product-image my-4 me-3 border-3"
          v-for="imageInfo in productImages"
          :key="imageInfo.index"
        >
          <img
            src="../../assets/icon/cross.svg"
            width="25"
            height="25"
            alt=""
            class="new-product__close broder-circle"
            @click="deleteImage(imageInfo.index)"
          />
          <img
            class="common-border__radius new-image"
            :src="imageInfo.url"
            style="border:1px solid gray !important; height:170px; width:170px"
          />
        </div>

        <!-- shows existing product images -->
        <div
          class="single-product-image my-4 me-3"
          v-for="imageInfo in existingProdcutImages"
          :key="imageInfo.id_product"
        >
          <img
            src="../../assets/icon/cross.svg"
            width="25"
            height="25"
            alt=""
            class="new-product__close broder-circle"
            @click="deletePreviousImages(imageInfo)"
          />
          <img
            class="common-border__radius new-image"
            :src="imageInfo.image_path"
            style="border:1px solid gray !important; height:170px; width:170px"
          />
        </div>

        <div
          class="dotted-border d-flex flex-column p-4 text-center my-4"
          style="min-width: 11.06rem !important"
        >
          <img
            src="../../assets/icon/image.svg"
            alt=""
            width="40"
            class="mx-auto my-auto"
            @click="triggerFileInput()"
          />

          <label
            class="custom-file-upload mx-auto my-auto state-color__fg--Info"
          >
            <input
              ref="productImageUploader"
              type="file"
              accept="image/*"
              @change="uploadImage($event)"
              id="file-input"
            />
            {{ $t("product.main.product_images.placeholder") }}
          </label>

          <p class="mb-0 state-color__fg--grey1">
            {{ $t("product.main.product_images.support_before") }} <br />{{
              $t("product.main.product_images.support_after")
            }}
          </p>
        </div>

        <!-- test image start -->
        <!-- <img :src="testImageUrl" /> -->

        <!-- test image end -->
      </div>
    </div>
    <p class="text-danger" v-if="productCreateInputErrorNo == 1">
      {{ productCreateInputErrorMessage }}
    </p>
  </div>

  <crop-image-modal
    :ImageUrl="testImageUrl"
    :image="image"
    @croppedData="addProductCroppedImage"
  />
  <button
    id="imageCrop"
    data-bs-toggle="modal"
    data-bs-target="#addCropImageModal"
    class="d-none"
  ></button>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";

import cropImageModal from "./cropImageModal.vue";

export default {
  props: ["prevImage"],
  emits: ["requestAddNewImage"],
  data: function() {
    return {
      index: 0,
      errorNewImage: "",
      testImageUrl: "",

      show: false,
      params: {
        token: "123456798",
        name: "avatar",
      },
      headers: {
        smail: "*_~",
      },
      imgDataUrl: "", //
      image: "",
      cropUrl: "",
    };
  },

  components: {
    cropImageModal,
  },

  computed: {
    ...mapGetters("product", [
      "productChargeName",
      "productImages",
      "existingDeletedImages",
      "existingProdcutImages",
      "productCreateInputErrorNo",
      "productCreateInputErrorMessage",
    ]),
  },

  methods: {
    ...mapActions("product", ["setProductImage", "deleteProductImageRequest"]),

    // ******  test start for image crop**********

    cropImage() {
      const result = this.$refs.cropper.getResult();

      const canvas = result.canvas;

      let url = result.canvas.toDataURL(this.image.type);

      this.cropUrl = url;

      let vm = this;
      canvas.toBlob((blob) => {
        vm.$store.commit("product/setProductImage", {
          index: vm.index,
          image: blob,
          url: url,
        });
      });
    },

    UploadToCrop(event) {
      this.testImageUrl = URL.createObjectURL(event.target.files[0]);
      const blob = URL.createObjectURL(event.target.files[0]);
      this.image = {
        src: blob,
        type: event.target.files[0].type,
        name: event.target.files[0].name,
      };

      $("#imageCrop").trigger("click");
    },

    addProductCroppedImage(data) {
      this.$store.commit("product/setProductImage", {
        index: this.index,
        image: data.image,
        url: data.url,
      });
    },

    // ****** test end for image crop**********

    triggerFileInput() {
      $("#file-input").trigger("click");
    },

    uploadImage(event) {
      if (!event.target.files[0]) return;

      this.image = event.target.files[0];
      if (this.image.size > 1024 * 1021 * 6) {
        this.$store.commit("product/setProductCreateErrorValue", {
          errorNo: 1,
          msg: this.$t("product.main.toast.image_size"),
        });
      } else if (
        this.productImages.length + this.existingProdcutImages.length >=
        6
      ) {
        this.$store.commit("product/setProductCreateErrorValue", {
          errorNo: 1,
          msg: this.$t("product.main.toast.image_count"),
        });
      } else if (
        event.target.files[0]["type"] === "image/jpeg" ||
        event.target.files[0]["type"] === "image/png" ||
        event.target.files[0]["type"] === "image/jpg" ||
        event.target.files[0]["type"] === "image/bmp"
      ) {
        this.$store.commit("product/setProductCreateErrorValue", {
          errorNo: 1,
          msg: "",
        });

        this.UploadToCrop(event);

        this.index = this.index + 1;
        $("#file-input").val("");
      } else {
        this.$store.commit("product/setProductCreateErrorValue", {
          errorNo: 1,
          msg: this.$t("product.main.toast.image_format"),
        });
        this.image = null;
        this.url = "";
      }
    },

    sendData() {},

    deleteImage(delIndex) {
      let i = 0;
      for (i = 0; i < this.productImages.length; i++) {
        if (this.productImages[i].index == delIndex) {
          this.$store.commit("product/removeProductImage", { index: i });
          break;
        }
      }

      if (this.productImages.length < 6 && this.productImages.length >= 1) {
        this.errorNewImage = "";
      } else if (this.productImages.length < 1) {
        this.errorNewImage = "product must contain at least one image";
        this.index = 0;
      }
    },

    async deletePreviousImages(image) {
      let imageUrl = image.image_path;
      let i = 0;
      for (i = 0; i < this.existingProdcutImages.length; i++) {
        if (this.existingProdcutImages[i].image_path == imageUrl) {
          this.$store.commit("product/removeExistingProductImage", {
            index: i,
          });
          this.$store.commit("product/setExistingDeletedImages", {
            imageUrl: imageUrl,
          });
          break;
        }
      }

      // Toast.fire({
      //   icon: "success",
      //   title: "Attribute combination deleted successfully",
      //   background: successBackground,
      // });
    },

    imageSubmitErrorMessage(message) {
      this.errorNewImage = message;
    },
  },

  created() {
    this.$store.commit("product/setImageToEmpty");
    this.$store.commit("product/resetExistingDeletedImag");
  },
};
</script>

<style>
.single-product-image {
  height: 10em;
  width: 11em;
  position: relative !important;
}

.single-product-image .new-product__close {
  position: absolute;
  z-index: 1;
  right: 5%;
  top: 5%;
  cursor: pointer;
}

.single-product-image .new-image {
  position: relative;
  width: 100%;
  object-fit: contain;
}
</style>
