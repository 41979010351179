import Repository from "../../api/RepositoryFactory";
const InstorePickup = Repository.get("instorePickup");
import Toast, { successBackground } from "../../config/toastConfig";

const {
  getDivisionOfCountry,
  getCityByDivision,
  getAreaByCity,
  updateInstorePickupStatus,
  updateUserInfo,
} = InstorePickup;

export default {
  namespaced: true,

  state: {
    allDivison: [],
    allCity: [],
    allArea: [],
    inStorePickup: "",
  },

  getters: {
    allDivison: (state) => state.allDivison,
    allCity: (state) => state.allCity,
    allArea: (state) => state.allArea,
    inStorePickupStatus: (state) => state.inStorePickup,
  },

  mutations: {
    SET_ALL_DIVISION_LIST(state, { allDivison }) {
      state.allDivison = allDivison;
    },

    SET_ALL_CITY_LIST(state, { allCity }) {
      state.allCity = allCity;
    },

    SET_ALL_AREA_LIST(state, { allArea }) {
      state.allArea = allArea;
    },

    UPDATE_INSTORE_PICKUP_STATUS(state, data) {
      state.inStorePickup = data;
    },
  },

  actions: {
    async getAllDivision({ commit }, payload) {
      const response = await getDivisionOfCountry(payload.countryId);
      commit("SET_ALL_DIVISION_LIST", { allDivison: response.data });
    },

    async getAllCity({ commit }, payload) {
      const response = await getCityByDivision(payload.divisionId);
      commit("SET_ALL_CITY_LIST", { allCity: response.data });
    },

    async getAllArea({ commit }, payload) {
      const response = await getAreaByCity(payload.cityId);
      commit("SET_ALL_AREA_LIST", { allArea: response.data });
    },

    async updateInstorePickupStatus({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await updateInstorePickupStatus(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        let status = response.data.instore_pickup;
        if (status === 2) {
          if (document.getElementById("instorePickupModal")) {
            document.getElementById("instorePickupModal").click();
            return;
          }
        }

        const userObject = localStorage.getItem("majhiUser");
        let authUser = JSON.parse(userObject);
        let user = {
          ...authUser,
          instore_pickup: status,
        };
        localStorage.setItem("majhiUser", JSON.stringify(user));
        commit("UPDATE_INSTORE_PICKUP_STATUS", status);
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async updateUserInfo({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await updateUserInfo(data);
        if (response.success) {
          let resData = response.data.user[0];
          const userObject = localStorage.getItem("majhiUser");
          let authUser = JSON.parse(userObject);

          let user = {
            ...authUser,
            id_division: resData.id_division,
            id_city: resData.id_city,
            id_area: resData.id_area,
            shop_address: resData.shop_address,
          };

          localStorage.setItem("majhiUser", JSON.stringify(user));
        }

        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },
  },
};
