<template>
  <div class="w-100">
    <div class="d-flex justify-content-center">
      <img src="../../assets/icon/no-result.svg" alt="" />
    </div>

    <h5 class="mt-3 text-center">{{ $t("notFound.main.search.title") }}</h5>
    <p class="fg_grey2 text-center">
      {{ $t("notFound.main.search.description") }}
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
