<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container" style="min-width: 500px;">
          <div class="m-3 d-flex">
            <img src="../../assets/icon/back_btn_image.svg" alt="back" />
            <h4 class="ms-4 my-0">Extra Charges</h4>
          </div>

          <div
            class="bg-white common-border__radius coupon-create_container my-4 mx-auto p-4"
          >
            <h5>Delivery Charge</h5>

            <div class="my-4">
              <!-- Inside design -->
              <div class="">
                <h5 style="font-size:1.125rem">Inside City</h5>
                <p class="state-color__fg--grey1">
                  Delivery address within the same city as your shop will be
                  billled according to this delivery charge
                </p>
              </div>

              <div
                class="d-flex flex-column  flex-md-row flex-lg-row flex-xl-row justify-content-between mt-2"
              >
                <div
                  class="form-group discount-inputfield  m-query-width-90 mt-3"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >Delivery Charge
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px;"
                      placeholder="50"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div
                  class="form-group discount-inputfield  m-query-width-90 mt-3"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >Free Delivery Above</label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px;"
                      placeholder="Enter Amount"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-4 mt-2">
                <p class="state-color__fg--Info">
                  <span>&#2547; </span>50 will be charged as Delivery Charge on
                  Inside City Orders
                </p>
              </div>

              <!-- Outside design -->
              <div class="">
                <h5 style="font-size:1.125rem">Outside</h5>
                <p class="state-color__fg--grey1">
                  Delivery address except the same city as your shop will be
                  billled according to this delivery charge
                </p>
              </div>

              <div
                class="d-flex flex-column  flex-md-row flex-lg-row flex-xl-row justify-content-between mt-2"
              >
                <div
                  class="form-group discount-inputfield  m-query-width-90 mt-3"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >Delivery Charge
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px;"
                      placeholder="50"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div
                  class="form-group discount-inputfield  m-query-width-90 mt-3"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >Free Delivery Above</label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px;"
                      placeholder="Enter Amount"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-4 mt-2">
                <p class="state-color__fg--Info">
                  <span>&#2547; </span>100 will be charged as Delivery Charge on
                  Outside City Orders
                </p>
              </div>
            </div>
          </div>

          <div
            class="bg-white common-border__radius coupon-create_container my-4 mx-auto p-4"
          >
            <div class="d-flex justify-content-between">
              <h5>Delivery Charge</h5>
              <div class="position-relative">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-three-dots-vertical"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                    />
                  </svg>
                </div>
                <div class="shadow position-absolute dots-dropdown__items p-2">
                  <div class="m-2">
                    <img
                      src="../../assets/icon/edit.svg"
                      alt=""
                      height="20"
                      width="20"
                      class="mb-1"
                    /><span class="ms-2">Edit</span>
                  </div>
                  <div class="m-2">
                    <img
                      src="../../assets/icon/menu_delete.svg"
                      alt=""
                      height="17"
                      width="17"
                      class="mb-1"
                    /><span class="ms-2">Delete</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="my-3">
              <div>
                <label class="radio-inline ms-1 me-3">
                  <input type="radio" name="optradio" checked /><span
                    class="mx-2"
                    >Both</span
                  >
                </label>
                <label class="radio-inline mx-3">
                  <input type="radio" name="optradio" /><span class="mx-2"
                    >Inside City</span
                  >
                </label>
                <label class="radio-inline mx-3">
                  <input type="radio" name="optradio" /><span class="mx-2"
                    >Outside City</span
                  >
                </label>
              </div>
            </div>

            <div class="my-4">
              <!-- Inside design -->

              <div
                class="d-flex flex-column  flex-md-row flex-lg-row flex-xl-row justify-content-between mt-2"
              >
                <div
                  class="form-group discount-inputfield  m-query-width-90 mt-3"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >Charge Name
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px;"
                      placeholder="Lifting Charge"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div
                  class="form-group discount-inputfield  m-query-width-90 mt-3"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >Charge Amount
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px;"
                      placeholder="500"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>

              <div class="my-2">
                <label for="exampleInputEmail1"
                  >Product Category
                  <span class="state-color__fg--Info">*</span></label
                >
                <Select2
                  :placeholder="productCategoriesPlaceholder"
                  class="mt-2 w-100 border rounded"
                  :options="allAvailableCategories"
                />
              </div>

              <div class="mb-4 mt-2">
                <p class="state-color__fg--Info">
                  <span>&#2547; </span>500 will be charged as Lifting Charge on
                  Regregerators
                </p>
              </div>
            </div>
          </div>

          <div class="coupon-create_container my-4 mx-auto">
            <button
              type="button"
              class="btn btn-outline-primary px-4 py-2 "
              style="color:black;"
              data-bs-toggle="modal"
              data-bs-target="#addNewExtraChargeModal"
            >
              Add New Charges
            </button>
            <button type="button" class="btn btn-primary float-end px-5 py-3">
              Save Changes
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
    <!-- Extra Charge Modal -->
    <extra-charge-input-modal />
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import ExtraChargeInputModal from "../../components/manage/ExtraChargeInputModal.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import Select2 from "vue3-select2-component";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    ExtraChargeInputModal,
    Select2,
  },

  data: function() {
    return {
      allAvailableCategories: [],
    };
  },
};
</script>

<style>
.coupon-create_container {
  width: 60%;
  min-width: 400px;
}

.form-control-lg {
  height: 50px !important;
  font-size: 18px !important;
}

.discount-inputfield {
  width: 48%;
}

/* 3 dots dropdown style */
.dots-dropdown__items {
  left: -100px;
  min-width: 120px;
  background-color: white;
}

@media only screen and (max-width: 800px) {
  .coupon-create_container {
    width: 90%;
  }

  .m-query-width-90 {
    min-width: 100%;
  }
}
</style>
