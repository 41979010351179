import signIn from "./signIn.json";
import signUp from "./signUp.json";
import otp from "./otp.json";
import validation from "./validation.json";

export default {
  signIn,
  signUp,
  otp,
  validation,
};
