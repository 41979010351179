<template>
  <div class="all-categories__container common-border__radius overflow-x-auto">
    <table>
      <thead>
        <tr
          class="state-color__fg--grey1 google_sans_medium category-table-heading"
          style="background-color: #fafcfd !important"
        >
          <td scope="col" class="category-table-single-header">
            {{ $t("product.main.category_table_headers.h1") }}
          </td>
          <td scope="col" class="category-table-single-header">
            {{ $t("product.main.category_table_headers.h2") }}
          </td>
          <!-- <td scope="col" class="category-table-single-header">
            {{ $t("product.main.category_table_headers.h3") }}
          </td> -->
          <td scope="col" class="category-table-single-header">
            {{ $t("product.main.category_table_headers.h4") }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="productCategory in allProductCategories"
          :key="productCategory.id_product_category"
          class="category-table-body-text-size"
        >
          <td
            data-label="CATEGORY"
            role="button"
            @click="
              redirectSelectedCategoryPage(
                productCategory.id_product_category,
                productCategory.product_category_name
              )
            "
          >
            <div
              class="d-lg-flex d-md-flex justify-content-lg-start ustify-content-md-start"
              style="min-height: 100px; padding-left:20px !important"
            >
              <img
                v-if="productCategory.image_path"
                :src="productCategory.image_path"
                alt=""
                width="80"
                height="80"
                class="border common-border__radius-8  my-auto"
              />

              <!-- <img
                v-if="!productCategory.image_path"
                src="../../assets/icon/hide.png"
                alt=""
                width="80"
                height="80"
                class="border common-border__radius-8  my-auto"
              /> -->

              <p class="my-auto google_sans_medium mx-1 category-title">
                {{ productCategory.product_category_name }}
              </p>
            </div>
          </td>
          <td data-label="NO OF PRODUCTS">
            {{ productCategory.itemIn_category }}
            {{ $t("product.main.products") }}
          </td>

          <!-- <td data-label="AVAILABILITY">
            <div class="" v-if="productCategory.status == 1">
              <label
                class="mx-1 text-in-stock cursor-pointer"
                @click="showAvailabilityStatus(productCategory)"
                data-bs-toggle="modal"
                data-bs-target="#updateStockModal"
                >{{ $t("product.main.update_stock.in_stock") }}</label
              >
              <img
                src="../../assets/icon/instock.png"
                alt=""
                class="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#updateStockModal"
                @click="showAvailabilityStatus(productCategory)"
              />
            </div>

            <div v-else-if="productCategory.status == 0">
              <label
                class="mx-1 text-hide cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#updateStockModal"
                @click="showAvailabilityStatus(productCategory)"
                >{{ $t("product.main.update_stock.hide") }}</label
              >
              <img
                src="../../assets/icon/hide.png"
                alt=""
                class="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#updateStockModal"
                @click="showAvailabilityStatus(productCategory)"
              />
            </div>

            <div v-else-if="productCategory.status == 2">
              <label
                class="mx-1 text-out-of-stock cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#updateStockModal"
                @click="showAvailabilityStatus(productCategory)"
                >{{ $t("product.main.update_stock.out_of_stock") }}</label
              >
              <img
                src="../../assets/icon/outofstock.png"
                alt=""
                class="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#updateStockModal"
                @click="showAvailabilityStatus(productCategory)"
              />
            </div>
          </td> -->

          <td data-label="ACTION">
            <!-- <button
              type="button"
              class="btn btn-outline-secondary m-1"
              data-bs-toggle="modal"
              data-bs-target="#updateStockModal"
              @click="showAvailabilityStatus(productCategory)"
            >
              <img src="../../assets/icon/edit.svg" alt="" width="20" />
              Edit
              <span class="d-none d-md-block d-lg-block">Edit</span>
            </button> -->
            <button
              type="button"
              class="btn btn-outline-secondary m-1"
              @click="copy(productCategory)"
            >
              <img src="../../assets/icon/share.svg" alt="" width="14" />

              <span class="category-action-button" style="margin-left:5px">{{
                $t("product.main.share_btn")
              }}</span>
            </button>
            <!-- <button type="button" class="btn btn-outline-secondary m-1">
              <img src="../../assets/icon/menu_delete.svg" alt="" width="14" />

              <span class="category-action-button" style="margin-left:5px">{{
                $t("product.main.delete_btn")
              }}</span>
            </button> -->
          </td>
        </tr>

        <tr
          v-if="
            (!allProductCategories ||
              (allProductCategories && !allProductCategories.length)) &&
              getSearchField == ''
          "
        >
          <td colspan="4">
            <div class="d-flex flex-column align-items-center mb-5">
              <img src="../../assets/icon/no-category.svg" alt="" />
              <h6 class="state-color__fg--grey1">
                {{ $t("product.main.no_category_found.title") }}!
              </h6>
              <p>{{ $t("product.main.no_category_found.sub_title") }}</p>

              <!-- <div>
                <router-link
                  :to="{
                    name: 'AppDirectProductCategoryAssign',
                    params: { languageCode: getLanguage },
                  }"
                >
                  <button class="btn btn-primary google_sans_medium py-3 px-4">
                    <img src="../../assets/icon/button-add.svg" alt="" />
                    {{ $t("product.main.new_category_btn") }}
                  </button>
                </router-link>
              </div> -->
            </div>
          </td>
        </tr>

        <tr
          v-if="
            (!allProductCategories ||
              (allProductCategories && !allProductCategories.length)) &&
              getSearchField != ''
          "
        >
          <td colspan="4">
            <Search />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- modal  -->
  <div
    class="modal fade"
    id="updateStockModal"
    tabindex="-1"
    aria-labelledby="updateStockModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius">
        <div class="modal-header">
          <h5 class="modal-title" id="updateStockModalLabel">
            {{ $t("product.main.update_stock.title") }}
          </h5>
          <button type="button" class="modal-close" data-bs-dismiss="modal">
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="d-flex align-items-start">
              <input
                class="my-1"
                type="radio"
                id="huey"
                name="drone"
                value="1"
                v-model="availabilityStatus"
                :checked="availabilityStatus == 1"
                @click="requestUpdateAvaailabilityStatus(1)"
              />
              <div class="mx-2">
                <h6>{{ $t("product.main.update_stock.in_stock") }}</h6>
                <p class="state-color__fg--grey1 radio-font">
                  {{ $t("product.main.update_stock.in_stock_message") }}
                </p>
              </div>
            </div>

            <div class="d-flex align-items-start">
              <input
                class="my-1"
                type="radio"
                id="huey"
                name="drone"
                value="2"
                v-model="availabilityStatus"
                :checked="availabilityStatus == 2"
                @click="requestUpdateAvaailabilityStatus(2)"
              />
              <div class="mx-2">
                <h6>{{ $t("product.main.update_stock.out_of_stock") }}</h6>
                <p class="state-color__fg--grey1 radio-font">
                  {{ $t("product.main.update_stock.out_of_stock_message") }}
                </p>
              </div>
            </div>

            <div class="d-flex align-items-start">
              <input
                class="my-1"
                type="radio"
                id="huey"
                name="drone"
                value="0"
                v-model="availabilityStatus"
                :checked="availabilityStatus == 0"
                @click="requestUpdateAvaailabilityStatus(0)"
              />
              <div class="mx-2">
                <h6>{{ $t("product.main.update_stock.hide") }}</h6>
                <p class="state-color__fg--grey1 radio-font">
                  {{ $t("product.main.update_stock.hide_message") }}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast, {
  errorBackground,
  successBackground,
} from "../../config/toastConfig";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2";

import Search from "../not-found/search.vue";
import useClipboard from "vue-clipboard3";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: ["allProductCategories"],
  emits: ["requestUpdateAvailableStatus"],

  setup() {
    const { toClipboard } = useClipboard();
    const { t } = useI18n();

    const copy = async (data) => {
      try {
        await toClipboard(data.category_share_link);
        Toast.fire({
          icon: "success",
          title: t("product.main.toast.category_link_copy_successfully"),
          background: successBackground,
        });
      } catch (e) {
        Toast.fire({
          icon: "error",
          title: t("product.main.toast.category_link_copy_error"),
          background: errorBackground,
        });
      }
    };
    return { copy };
  },

  data: function() {
    return {
      availabilityStatus: 0,
      productCategoryId: null,
    };
  },

  computed: {
    ...mapGetters("productCategories", ["getSearchField"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("productCategories", ["requestDeleteCategory"]),
    ...mapMutations("productCategories", ["SET_SEARCHFIELD"]),
    showAvailabilityStatus(productCategory) {
      this.availabilityStatus = productCategory.status;
      this.productCategoryId = productCategory.id_product_category;
    },
    requestUpdateAvaailabilityStatus(requestedStatus) {
      this.$store.commit("setOverlayStatus", true); //loader open
      this.availabilityStatus = requestedStatus;
      this.$emit("requestUpdateAvailableStatus", {
        availabilityStatus: this.availabilityStatus,
        productCategoryId: this.productCategoryId,
      });
    },
    onCopy(data) {
      navigator.clipboard.writeText(data.category_share_link);
      Toast.fire({
        icon: "success",
        title: "Link is copied",
        background: successBackground,
      });
    },
    async deleteCategory(data) {
      Swal.fire({
        title: this.$t("product.main.delete_confirmation_message.title"),
        text: this.$t("product.main.delete_confirmation_message.category"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "product.main.delete_confirmation_message.delete_btn"
        ),
        cancelButtonText: this.$t(
          "product.main.delete_confirmation_message.cancel_btn"
        ),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.requestDeleteCategory(data.id_product_category);
        }
      });
    },
    redirectSelectedCategoryPage(id, name) {
      this.$router.push({
        name: "CategoriesProduct",
        params: { categoryId: id, categoryName: name },
      });
    },
  },
  beforeUnmount() {
    this.SET_SEARCHFIELD("");
  },
  components: { Search },
};
</script>

<style>
.category-table-body-text-size {
  font-size: 14px;
  color: #1d1d1d;
}

.category-table-heading {
  background-color: #fafcfd !important;
  font-size: 14px;
  height: 60px !important;
  margin: auto;
}

.category-table-single-header {
  padding-top: 15px !important;
}

.category-title {
  font-size: 16px;
  padding-left: 5px;
}

.text-out-of-stock {
  color: #f13939;
}

.text-in-stock {
  color: #2dc06b;
}

.text-hide {
  color: #8f3cff;
}

.category-action-button {
  font-size: 14px;
  color: #1d1d1d;
}

.form-check-input {
  width: 32px !important;
  height: 20px !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.all-categories__container .btn-outline-secondary {
  background-color: white !important;
  color: #6c757d !important;
  text-decoration: none;
}

.all-categories__container .cursor-pointer {
  cursor: pointer;
}

input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.radio-font {
  /* font-size: 12px; */
  font-size: 0.75rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    min-width: 600px !important;
    max-width: auto !important;
  }
}
</style>
