<template>
  <div class="w-100" :class="$style.max_height">
    <div v-if="allProductCategoryLength > 0">
      <div class="d-flex flex-wrap">
        <div
          v-for="(productCategory, index) in filteredProductCategories"
          :key="index"
          class="px-2 pb-3"
        >
          <OnBoardingSingleCategoryTab
            :bg_color="
              state.categoryTabBgColorCodes[productCategory.id_product_category]
            "
            :fg_color="
              state.categoryTabFgColorCodes[productCategory.id_product_category]
            "
            @click="addNewCategoryToList(productCategory.id_product_category)"
            ><template v-slot:tab_name>
              {{ productCategory.label }}</template
            ></OnBoardingSingleCategoryTab
          >
        </div>
      </div>

      <div
        v-if="filteredProductCategories?.length <= 0"
        class="w-100 h-100 d-flex justify-content-center align-items-center"
      >
        <div>
          <div class="d-flex justify-content-center">
            <img src="../../assets/icon/no-result.svg" alt="" width="242" />
          </div>
          <p class="fg_grey2 text-center">
            {{ $t("notFound.main.search.description") }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-else
      class="w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <div class="text-center">
        <div class="d-flex justify-content-center">
          <img src="../../assets/icon/no-category.svg" alt="" width="242" />
        </div>
        <h6 class="state-color__fg--grey1">
          {{ $t("product.main.no_category_found.title") }}!
        </h6>
        <!-- <p>{{ $t("product.main.no_category_found.sub_title") }}</p> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import OnBoardingSingleCategoryTab from "./OnBoardingSingleCategoryTab.vue";

import {
  defineProps,
  defineEmits,
  computed,
  onBeforeMount,
  reactive,
} from "vue";
import { useStore } from "vuex";

const props = defineProps({
  searchedKey: { type: String, default: "" },
});
const emit = defineEmits(["setSelectedCategory"]);

//state varaibles
const state = reactive({
  existingSelectedCategory: {},
  newSelectedCategory: {},
  categoryTabBgColorCodes: {}, // stores bg color for existing & new selected category based on id as key
  categoryTabFgColorCodes: {}, // stores fg color for existing & new selected category based on id as key
});

const store = useStore();
const user = store.getters["auth/getUser"];
const allProductCategories = computed(
  () =>
    store.getters[
      "productCategories/getAllProductCategoriesUnderBusinessCategory"
    ]
);
const filteredProductCategories = computed(() => {
  return (
    allProductCategories.value.list_categories &&
    allProductCategories.value.list_categories.filter((category) => {
      return category.value
        .toLowerCase()
        .includes(props.searchedKey.toLowerCase());
    })
  );
});

const allProductCategoryLength = computed(
  () => allProductCategories?.value?.list_categories?.length
);

// add new category
const addNewCategoryToList = (idProductCategory) => {
  if (state.existingSelectedCategory[idProductCategory]) return;

  if (state.newSelectedCategory[idProductCategory] == undefined) {
    state.newSelectedCategory[idProductCategory] = idProductCategory;
    state.categoryTabBgColorCodes[idProductCategory] = "#3A86FF";
    state.categoryTabFgColorCodes[idProductCategory] = "#FFFFFF";
  } else {
    delete state.newSelectedCategory[idProductCategory];
    state.categoryTabBgColorCodes[idProductCategory] = "#FFFFFF";
    state.categoryTabFgColorCodes[idProductCategory] = "#1D1D1D";
  }

  emit("setSelectedCategory", state.newSelectedCategory);
};

//converts selected category list to hashmap(object)
// sets bg & fg of existing category
const mapSelectedCategory = () => {
  state.existingSelectedCategory = allProductCategories.value.selected_categories.reduce(
    function(map, obj) {
      map[obj.id_product_category] = obj.id_product_category;
      state.categoryTabBgColorCodes[obj.id_product_category] = "#8993A3";
      state.categoryTabFgColorCodes[obj.id_product_category] = "#FFFFFF";
      return map;
    },
    {}
  );
};

onBeforeMount(async () => {
  await store.dispatch(
    "productCategories/getAllCategoryUnderBusinessCategory",
    { idBusinessCategory: user.business_category_id }
  );
  mapSelectedCategory();

  // console.log(
  //   "allProductCategories",
  //   allProductCategories.value.list_categories.length
  // );
});
</script>

<style module>
.max_height {
  height: 19rem;
  /* min-height: 8rem; */
  overflow-y: auto;
}
</style>
