<template>
  <div
    class="row px-2 m-2 position-relative"
    style="margin-top: -2rem !important; "
  >
    <div
      class="d-flex justify-content-xxl-between justify-content-xl-between justify-content-lg-between justify-content-xs-center justify-content-sm-center justify-content-md-center flex-row flex-wrap p-0 app_container"
    >
      <vertical
        :verticalChartColor="'#B7AAF5'"
        :leftHeader="'home.main.graph.sales.total_sales'"
        :rightHeader="'home.main.graph.sales.total_orders'"
        :salesInfo="salesDetails?.total_sales_details"
        :graphCategoriesName="salesGraphCategories"
        :graphData="salesData"
        :totalSales="salesDetails?.total_sales"
        :payAmountUnit="'home.main.graph.sales.bdt'"
      />
      <vertical
        v-bind:verticalChartColor="'#F5BB91'"
        :leftHeader="'home.main.graph.views.total_views'"
        :rightHeader="'home.main.graph.views.product_view'"
        :graphCategoriesName="viewsGraphCategories"
        :graphData="viewsData"
        :totalSales="[
          {
            total_order: salesDetails?.total_product_views,
            total_pay_amount: salesDetails?.total_views,
          },
        ]"
        :payAmountUnit="''"
      />
      <horizontal
        v-bind:horizontalChartColor="'#8BE8B2'"
        :graphCategoriesName="bestSellingCategories"
        :graphData="bestSellingData"
        :bestProduct="bestProduct"
      />
    </div>
  </div>

  <div class="row mx-1 mb-2 app_container">
    <h6
      class="col-6 font-weight-600 state-color__fg--grey"
      style="font-size:24px !important"
    >
      {{ $t("home.main.orders.active") }}
    </h6>
    <p
      class="col-6 d-flex justify-content-end make-cursor"
      style="color:#272f46"
      @click="
        $router.push({
          name: 'AllOrders',
          params: { languageCode: getLanguage },
        })
      "
    >
      <u>{{ $t("home.main.orders.view_all") }}</u>
    </p>
  </div>
  <div class="row mx-3 app_container">
    <div class="col-12 bg-white rounded">
      <menu-bar
        v-bind:allAvailableStatusList="allAvailableStatusList"
        v-on:requestOrderDataByStatus="OrderByStatus($event)"
        v-bind:homeFlag="1"
      />
    </div>
  </div>
  <div class="row mx-3 my-4 app_container">
    <home-order-card
      v-bind:allOrders="allOrders"
      v-on:requestToUpdateOrderStatus="updateOrderStatus($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import HomeOrderCard from "../../components/orders/Home-order-card.vue";
import Horizontal from "../../components/charts/horizontal.vue";
import Vertical from "../../components/charts/vertical.vue";
import MenuBar from "../../components/orders/MenuBar.vue";

export default {
  components: {
    Vertical,
    Horizontal,
    HomeOrderCard,
    MenuBar,
  },
  props: [
    "salesData",
    "salesGraphCategories",
    "viewsGraphCategories",
    "viewsData",
    "bestSellingCategories",
    "bestSellingData",
    "bestProduct",
  ],

  computed: {
    ...mapGetters("Order", ["allAvailableStatusList", "allOrders"]),
    ...mapGetters("graph", ["salesDetails"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("notification", ["getAllNotification"]),
    ...mapActions("Order", [
      "allAvailableStatus",
      "getOrderByStatus",
      "requestUpdateOrderStatus",
    ]),

    async OrderByStatus(order) {
      this.activeMenuId = order.activeMenuId;
      let url = `/seller_order_by_status/${this.getLanguage}/${order.activeMenuId}/1`;
      await this.getOrderByStatus(url);
    },

    async updateOrderStatus(order) {
      let data = {
        id_order_status: order.statusId,
        id_order: order.orderId,
        payment_status: "paid",
      };
      await this.requestUpdateOrderStatus({ data, redirect: false });
      await this.OrderByStatus({ activeMenuId: this.activeMenuId });
    },
  },

  created() {
    this.OrderByStatus({ activeMenuId: 1 });
  },
};
</script>

<style></style>
