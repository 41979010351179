<template>
  <!-- modal  -->
  <div
    class="modal fade"
    id="orderAdressEditModal"
    tabindex="-1"
    aria-labelledby="orderAdressEditModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius">
        <div class="modal-header" style="border:none;">
          <h5 class="modal-title" id="exampleModalLabel">Update Delivery Details</h5>
          <button
            type="button"
            class="modal-close"
            id="action-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body ">
          <form @submit.prevent="handleSubmit()">
          
            <div class="my-3 form-group">
              <p class="mb-2">Name</p>
              <input
                class="form-control modal-focus__shadow"
                type="text"
                required
              />
              <p class="state-color__fg--error">
               
              </p>
            </div>

            <div class="my-3">
              <p class="mb-2">Mobile</p>
              <input
                class="form-control modal-focus__shadow"
                type="text"
                required
              />
              <p class="state-color__fg--error">
               
              </p>
            </div>

            <div class="my-3">
              <p class="mb-2">Shipping Address</p>
              <input
                class="form-control modal-focus__shadow"
                type="text"
                required
              />
              <p class="state-color__fg--error">
               
              </p>
            </div>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-secondary px-3 py-2 mx-1">Cancel</button>

              <button type="submit" class="btn btn-primary px-3 py-2 mx-1">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {};
</script>

<style>
.modal-focus__shadow:focus{
  border: 2px solid #3A86FF !important;
 
}
</style>
