<template>
  <!-- modal  -->
  <div
    class="modal fade"
    id="orderQuantityUpdateModal"
    tabindex="-1"
    aria-labelledby="orderQuantityUpdateModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered update-order-modal">
      <div class="modal-content common-border__radius">
        <div class="modal-header" style="border: none">
          <h6 class="modal-title" id="exampleModalLabel">
            {{ $t("home.order.update.title") }}
          </h6>
          <button
            type="button"
            class="modal-close"
            id="action-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body order-product-table-container overflow-x-auto">
          <table class="w-100 order-product-table">
            <tr>
              <th class="fw-normal fg-black">
                {{ $t("home.order.update.image") }}
                {{ generateHeader() }}
              </th>
              <th class="fw-normal fg-black">
                {{ $t("home.order.update.product_name") }}
              </th>
              <th
                v-for="(level, index) in headerList"
                :key="index"
                class="fw-normal fg-black"
              >
                {{ level.charAt(0).toUpperCase() + level.slice(1) }}
              </th>
              <!-- <th class="fw-normal fg-black">Color</th>
              <th class="fw-normal fg-black">Ram</th> -->
              <th class="fw-normal fg-black">
                {{ $t("home.order.update.quantity") }}
              </th>
              <th class="fw-normal fg-black">
                {{ $t("home.order.update.price") }}
              </th>
              <th class="fw-normal fg-black">
                {{ $t("home.order.update.total_price") }}
              </th>
            </tr>
            <tr
              v-for="(product, index) in orderedProducts"
              :key="index"
              class="order-product"
            >
              <td class="d-flex justify-content-center align-items-center">
                <img
                  v-if="product.product_images"
                  :src="product.product_images"
                  alt=""
                  width="55"
                  height="55"
                  class="image common-border__radius p-1"
                />
              </td>
              <td class="title">
                {{ product.title }}
              </td>
              <!-- start dynamic render-->
              <td v-for="(level, index) in headerList" :key="index">
                <div
                  v-if="level === 'color'"
                  class="d-flex justify-content-center align-items-center"
                >
                  <div
                    class="position-relative width-200px"
                    v-click-outside="hideDropDown"
                  >
                    <div
                      class="
                        selected-color-dropdown
                        border
                        common-border__radius
                        d-flex
                        align-items-center
                        justify-content-around
                        make-cursor
                        py-2
                      "
                    >
                      <div class="d-flex align-items-center">
                        <div
                          class="color-code-box"
                          :style="{ background: getColor(product) }"
                        ></div>
                        <span class="ms-2">
                          {{ getColorName(product) }}
                        </span>
                      </div>
                      <!-- <img src="../../assets/icon/dropdown.svg" alt="" /> -->
                    </div>
                  </div>
                </div>

                <!-- <select
                  v-if="level !== 'color'"
                  :name="level"
                  :id="level"
                  disabled
                >
                  <option>{{ getValue(product, level) }}</option>
                </select> -->

                <div
                  class="border common-border__radius py-2 px-2"
                  v-if="level !== 'color'"
                  :name="level"
                  :id="level"
                  disabled
                >
                  <option>{{ getValue(product, level) }}</option>
                </div>
              </td>

              <!-- end dynamic render-->

              <td>
                <div class="quantity-update-container">
                  <img
                    src="../../assets/icon/minus.svg"
                    alt=""
                    width="17"
                    height="17"
                    class="my-auto make-cursor"
                    @click="decreaseQuantity(assignId(product))"
                  />
                  <span
                    class="my-auto"
                    :id="assignId(product)"
                    style="color: #3a86ff; font-size: 1.25rem"
                  >
                    <!-- {{product.quantity}} -->
                    {{ quantity(product) }}
                  </span>
                  <img
                    src="../../assets/icon/plus.svg"
                    alt=""
                    width="17"
                    height="17"
                    class="my-auto make-cursor"
                    @click="increaseQuantity(assignId(product), product)"
                  />
                </div>
              </td>
              <td>
                <p class="mb-0 font-weight-500">
                  <span style="color:black; font-size:20px">&#2547;</span>
                  <span :id="`price${assignId(product)}`">{{
                    discountPrice(product)
                  }}</span>
                </p>
                <p
                  class="mb-0 state-color__fg--grey2 text-decoration-line-through"
                  v-if="price(product)"
                >
                  ৳{{ price(product) }}
                </p>
              </td>
              <td>
                <p class="font-weight-500 mb-0">
                  <!-- ৳{{ Math.ceil(product.quantity * product.product_price) }} -->
                  <span style="color:black;font-size:20px"> &#2547;</span>
                  <span :id="`total-price-${assignId(product)}`">{{
                    totalPricePerProduct(product)
                  }}</span>
                </p>
              </td>
            </tr>
          </table>

          <div class="w-100 d-flex justify-content-center mt-5 mb-1">
            <button
              type="button"
              class="btn btn-outline-secondary px-3 py-2 mx-1"
              style="width: 150px"
              id="action-close"
              data-bs-dismiss="modal"
            >
              {{ $t("home.order.update.cancel") }}
            </button>

            <button
              type="submit"
              class="btn btn-primary px-3 py-2 mx-1"
              style="width: 150px"
              @click="updateOrder(orderDetails)"
            >
              {{ $t("home.order.update.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vClickOutside from "click-outside-vue3";
import Toast, { errorBackground } from "../../config/toastConfig";

export default {
  props: ["orderedProducts", "orderDetails"],

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data: function() {
    return {
      dropdownQuantityFlag: true,
      productQuantity: "",
      totalPrice: 0,
      headerList: [],
    };
  },

  methods: {
    ...mapActions("Order", ["updateOrderQuantity"]),
    toggleDropdownFlag() {
      this.dropdownQuantityFlag = !this.dropdownQuantityFlag;
    },

    hideDropDown() {
      this.dropdownQuantityFlag = false;
    },

    quantity(product) {
      if (product.product_attribute.length) {
        let data = product.product_attribute[0];
        return data ? data.quantity : "";
      } else {
        return product.quantity;
      }
    },

    assignId(product) {
      if (product.product_attribute.length) {
        let data = product.product_attribute[0];
        return data.unique_id;
      } else {
        let id = `product-${product.id_product}-${product.quantity}`;
        return id;
      }
    },

    decreaseQuantity(id) {
      let quantity = parseInt(document.getElementById(id).innerText);

      let price = parseInt(document.getElementById(`price${id}`).innerText);
      let totalPriceEl = document.getElementById(`total-price-${id}`);
      quantity = quantity - 1;
      if (quantity >= 1) {
        document.getElementById(id).innerHTML = quantity;
        let NewPrice = Math.ceil(price * quantity);
        totalPriceEl.innerText = NewPrice;
      }
    },

    increaseQuantity(id, product) {
      let stock_quantity = 0;
      let order_quantity = 0;
      if (product.product_attribute.length) {
        let data = product.product_attribute[0];
        stock_quantity = data.stock_quantity;
        order_quantity = data.quantity;
      } else {
        stock_quantity = product.stock_quantity;
        order_quantity = product.quantity;
      }
      let quantity = parseInt(document.getElementById(id).innerText);
      if (stock_quantity + order_quantity < quantity + 1) {
        Toast.fire({
          icon: "error",
          title: this.$t("home.order.toast.stock_limit"),
          background: errorBackground,
        });

        return;
      }

      quantity = quantity + 1;
      document.getElementById(id).innerHTML = quantity;
      let price = parseInt(document.getElementById(`price${id}`).innerText);
      let totalPriceEl = document.getElementById(`total-price-${id}`);
      totalPriceEl.innerText = Math.ceil(price * quantity);
    },

    getValue(product, value) {
      if (product.product_attribute.length) {
        let productAttributes = product.product_attribute;
        let data = productAttributes.filter(
          (productAttribute) => productAttribute.global_attribute_name === value
        );
        if (data.length > 0) {
          return data[0].attribute_name;
        }
      } else {
        return "";
      }
    },

    getColor(product) {
      if (product.product_attribute.length) {
        let productAttributes = product.product_attribute;
        let data = productAttributes.filter(
          (productAttribute) =>
            productAttribute.global_attribute_name === "color"
        );
        if (data.length > 0) {
          return data[0].attribute_name;
        }
      } else {
        return "";
      }
    },

    getColorName(product) {
      if (product.product_attribute.length) {
        let productAttributes = product.product_attribute;
        let data = productAttributes.filter(
          (productAttribute) =>
            productAttribute.global_attribute_name === "color"
        );
        if (data.length > 0) {
          return this.colorCodeToName(data[0].attribute_name);
        }
      } else {
        return "";
      }
    },

    colorCodeToName(code) {
      const ntc = require("ntcjs");

      const n_match = ntc.name(code);
      return n_match[1];
    },

    price(product) {
      if (product.product_attribute.length) {
        let data = product.product_attribute[0];
        if (parseInt(data.discount_price) !== 0) {
          let price = Math.ceil(data.price);
          return price;
        } else {
          return "";
        }
      } else {
        if (parseInt(product.discount_price) !== 0) {
          let price = Math.ceil(product.product_price);
          return price;
        } else {
          return "";
        }
      }
    },

    discountPrice(product) {
      if (product.product_attribute.length) {
        let data = product.product_attribute[0];
        if (parseInt(data.discount_price) !== 0) {
          let price = Math.ceil(data.discount_price);
          return price;
        } else {
          let price = Math.ceil(data.price);
          return price;
        }
      } else {
        if (parseInt(product.discount_price) !== 0) {
          let price = Math.ceil(product.discount_price);
          return price;
        } else {
          let price = Math.ceil(product.product_price);
          return price;
        }
      }
    },

    totalPricePerProduct(product) {
      if (product.product_attribute.length) {
        let data = product.product_attribute[0];
        let quantity = data.quantity;
        if (parseInt(data.discount_price) !== 0) {
          let price = Math.ceil(data.discount_price);
          return Math.ceil(price * quantity);
        } else {
          let price = Math.ceil(data.price);
          return Math.ceil(price * quantity);
        }
      } else {
        let price;
        if (parseInt(product.discount_price) !== 0) {
          price = Math.ceil(product.discount_price);
        } else {
          price = Math.ceil(product.product_price);
        }
        return Math.ceil(price * product.quantity);
      }
    },

    generateHeader() {
      if (this.orderedProducts) {
        this.orderedProducts.forEach((product) => {
          if (product.product_attribute.length) {
            product.product_attribute.forEach((attributes) => {
              let name = attributes.global_attribute_name;
              if (!this.headerList.includes(name)) {
                this.headerList.push(name);
              }
            });
          }
        });
      }
    },

    async updateOrder(data) {
      let id_order = data.id_order;
      let products = data.product;
      let updatedProduct = [];

      while (products.length) {
        let i = 0;
        let sameProduct = products.filter(
          (product) => product.id_product === products[i].id_product
        );

        if (sameProduct.length > 1) {
          let attribute_combination = [];
          for (let j = 0; j < sameProduct.length; j++) {
            let singleProduct = sameProduct[j];

            if (singleProduct.product_attribute.length) {
              // todo
              let attribute = singleProduct.product_attribute[0];
              let productAttribute = {
                id_product_attribute: attribute.id_product_attribute,
                price: attribute.price,
                discount_price: attribute.discount_price,
                quantity: parseInt(
                  document.getElementById(attribute.unique_id).innerText
                ),
              };

              attribute_combination.push(productAttribute);
            } else {
              let id = `product-${singleProduct.id_product}-${singleProduct.quantity}`;
              let product = {
                id_product: singleProduct.id_product,
                id_order: id_order,
                price: singleProduct.product_price,
                discount_price: singleProduct.discount_price,
                quantity: parseInt(document.getElementById(id).innerText),
                product_charge: singleProduct.extra_charge,
                product_attributes_combination: [],
              };

              updatedProduct.push(product);
            }
          }

          let product = {
            id_product: sameProduct[0].id_product,
            id_order: id_order,
            product_attributes_combination: attribute_combination,
          };

          updatedProduct.push(product);
        } else {
          let singleProduct = sameProduct[0];
          if (singleProduct.product_attribute.length) {
            let attribute = singleProduct.product_attribute[0];
            let attribute_combination = [];

            let productAttribute = {
              id_product_attribute: attribute.id_product_attribute,
              price: attribute.price,
              discount_price: attribute.discount_price,
              quantity: parseInt(
                document.getElementById(attribute.unique_id).innerText
              ),
            };

            attribute_combination.push(productAttribute);

            let product = {
              id_product: singleProduct.id_product,
              id_order: id_order,
              product_attributes_combination: attribute_combination,
            };

            updatedProduct.push(product);
          } else {
            let id = `product-${singleProduct.id_product}-${singleProduct.quantity}`;
            let product = {
              id_product: singleProduct.id_product,
              id_order: id_order,
              price: singleProduct.product_price,
              discount_price: singleProduct.discount_price,
              quantity: parseInt(document.getElementById(id).innerText),
              product_charge: singleProduct.extra_charge,
              product_attributes_combination: [],
            };

            updatedProduct.push(product);
          }
        }

        products = products.filter(
          (product) => product.id_product !== products[i].id_product
        );
      }
      let update_json = {
        ProductList: updatedProduct,
      };

      let orderId = updatedProduct[0].id_order;

      let stringifyData = JSON.stringify(update_json);
      let formData = new URLSearchParams();
      formData.append("update_json", stringifyData);

      await this.updateOrderQuantity({ formData, orderId });

      // close modal
      let closeBtn = document.getElementById("orderQuantityUpdateModal");
      closeBtn.click();
    },
  },
};
</script>

<style scoped>
/* .update-order-modal table tr td select {
  background-image: none !important;
} */
</style>
