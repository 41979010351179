<template>
  <div
    class="modal fade"
    id="googleMapModal"
    tabindex="-1"
    aria-labelledby="googleMapModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content  common-border__radius  ">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important;"
        >
          <h5>Select your Store Address</h5>
          <button
            type="button"
            class="modal-close"
            id="google-map-leave-modal"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div
          class="modal-body d-flex justify-content-center align-items-center flex-column m-2 p-2"
        >
          <div class="map-holder w-100 ">
            <iframe
              id="gmap_canvas"
              :src="searchedAddress"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>

          <div class="w-100 pt-4">
            <input
              v-model="address"
              type="text"
              class="form-control address-input-field w-100"
              @keyup.enter="searchAddress()"
            />
          </div>

          <div class="pt-4">
            <button type="button" class="btn btn-primary py-3 px-4">
              Save Address
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchedAddress:
        "https://maps.google.com/maps?q=kuril bishow road dhaka &t=&z=15&ie=UTF8&iwloc=&output=embed",
      address: "",
    };
  },

  methods: {
    searchAddress() {
      this.searchedAddress =
        "https://maps.google.com/maps?q=" +
        this.address +
        "&t=&z=15&ie=UTF8&iwloc=&output=embed";
    },
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 400px;
}

#googleMapModal .address-input-field {
  height: 50px;
}
</style>
