<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div class="my-3 d-flex align-items-center page-heading-top-padding">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="$router.go(-1)"
            />
            <h4 v-if="!id" class="my-0 ms-3 page-heading-text-size ">
              {{ $t("appearance.main.pages.add_page_btn") }}
            </h4>
            <h4 v-else class="my-0 ms-3 page-heading-text-size ">
              {{ $t("appearance.main.pages.edit_page") }}
            </h4>
          </div>

          <div class="d-flex flex-column align-items-center ">
            <div class="bg-white common-border__radius wd-50 my-4 py-4 px-4">
              <h6 v-if="!id">{{ $t("appearance.main.pages.add_page_btn") }}</h6>
              <h6 v-else>{{ $t("appearance.main.pages.edit_page") }}</h6>

              <div class="py-4">
                <p class="mb-2">
                  {{ $t("appearance.main.pages.page_title") }}
                  <span class="state-color__fg--Info">*</span>
                </p>
                <input
                  type="text"
                  v-model.trim="pageTitle"
                  :placeholder="
                    $t('appearance.main.pages.placeholders.description')
                  "
                  class="page-input-field common-border__radius-8 w-100"
                />
              </div>

              <p class="state-color__fg--grey mb-1">
                {{ $t("appearance.main.pages.page_link") }}:
                <span class="state-color__fg--Info text-decoration-underline">{{
                  pageLink
                }}</span>
              </p>
            </div>

            <div class="bg-white common-border__radius wd-50 my-4 py-4 px-4">
              <h6>{{ $t("appearance.main.pages.page_content") }}</h6>

              <div class="pt-4">
                <editor
                  api-key="6qm1kbb9466eaye6dw56ity8g0w81iv7jvbtnk52q9xu5j3p"
                  v-model.trim="pageContent"
                  :init="{
                    menubar: false,
                    plugins: 'lists link image emoticons',
                    toolbar:
                      'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons',
                    placeholder: $t(
                      'appearance.main.pages.placeholders.description'
                    ),
                    height: '288',
                  }"
                />
              </div>
            </div>
          </div>
          <div v-if="!id" class="btn-container w-75 d-flex justify-content-end">
            <button
              class="btn btn-outline-primary m-1 px-4 py-2"
              @click="saveAsDaft"
            >
              {{ $t("appearance.main.pages.save_draft") }}
            </button>
            <button class="btn btn-primary m-1 px-4 py-2" @click="publishPage">
              {{ $t("appearance.main.pages.save_published") }}
            </button>
          </div>

          <div v-if="id" class=" btn-container w-75 d-flex justify-content-end">
            <button
              class="btn btn-outline-primary m-1 px-4 py-2"
              @click="UpdateAsDaft(id)"
            >
              {{ $t("appearance.main.pages.save_draft") }}
            </button>
            <button
              class="btn btn-primary m-1 px-4 py-2"
              @click="UpdatePublishPage(id)"
            >
              {{ $t("appearance.main.pages.save_published") }}
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import Toast, { errorBackground } from "../../config/toastConfig";

//import axios from "axios";
import { mapActions } from "vuex";

export default {
  components: { editor: Editor, SideBar, TopBar, LoadingSpinner },

  data() {
    return {
      pageTitle: "",
      pageContent: "",
      id: "",
      slug: "",
      url: "",
    };
  },

  computed: {
    pageLink() {
      return `${this.url}/${this.pageTitle
        .toLowerCase()
        .replace(/\s+/g, " ")
        .trim()
        .replaceAll(" ", "-")}`;
    },
  },

  created() {
    this.id = this.$route.params.id;
    this.loadData(this.$route.params.id);
    let localStorageData = localStorage.getItem("majhiUser");
    let data = JSON.parse(localStorageData);
    this.url = data.shop_front_url;
  },

  methods: {
    ...mapActions("ExtraPages", ["createPage", "updatePage", "loadSinglePage"]),

    async saveAsDaft() {
      if (!this.pageTitle.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.title_error"),
          background: errorBackground,
        });
      }
      if (!this.pageContent.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.content_error"),
          background: errorBackground,
        });
      }

      const data = {
        title: this.pageTitle,
        description: this.pageContent,
        is_published: 0,
      };

      await this.createPage(data);
    },

    async publishPage() {
      if (!this.pageTitle.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.title_error"),
          background: errorBackground,
        });
      }
      if (!this.pageContent.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.content_error"),
          background: errorBackground,
        });
      }

      const data = {
        title: this.pageTitle,
        description: this.pageContent,
        is_published: 1,
      };
      await this.createPage(data);
    },

    async loadData(id) {
      await this.loadSinglePage(id);

      let data = this.$store.getters["ExtraPages/getPage"];
      if (data && data.length) {
        this.pageTitle = data[0].title;
        this.pageContent = data[0].description;
        this.slug = data[0].slug;
      }
    },

    // ----------- update page data and save as a draft
    async UpdateAsDaft(id) {
      if (!this.pageTitle.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.title_error"),
          background: errorBackground,
        });
      }
      if (!this.pageContent.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.content_error"),
          background: errorBackground,
        });
      }

      const data = {
        id_extra_page: id,
        title: this.pageTitle,
        description: this.pageContent,
        is_published: 0,
      };
      await this.updatePage(data);
    },

    // ----------- update page data and save as a published

    async UpdatePublishPage(id) {
      if (!this.pageTitle.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.title_error"),
          background: errorBackground,
        });
      }
      if (!this.pageContent.length) {
        return Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.content_error"),
          background: errorBackground,
        });
      }

      const data = {
        id_extra_page: id,
        title: this.pageTitle,
        description: this.pageContent,
        is_published: 1,
      };

      await this.updatePage(data);
    },
  },
};
</script>

<style scoped>
.wd-50 {
  width: 50%;
}

.page-input-field {
  border: 1px solid #bdbdbd;
  height: 50px;
  padding-left: 10px;
}

@media screen and (max-width: 950px) {
  .wd-50 {
    width: 90%;
  }

  .btn-container {
    width: 100%;
    margin-left: 40px;
  }

  .btn-container button {
    /* font-size: 15px; */
    font-size: 0.9375rem;
    padding: 10px !important;
  }
}
</style>
