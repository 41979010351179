<template>
  <div class="p-2">
    <h5 class="text-center" style="font-weight: bold">
      {{ $t("auth.otp.title") }}
    </h5>
  </div>
  <div>
    <!-- <p class="text-center">We have sent the OTP code To your mobile number</p> -->

    <p class="mb-0 text-center">{{ $t("auth.otp.text_1") }}</p>
    <p class="text-center">{{ $t("auth.otp.text_2") }}</p>
    <p class="text-center state-color__fg--Info" style="font-size: 20px">
      {{ phoneNumber }}
    </p>

    <div class="d-flex justify-content-center">
      <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator=" "
        :num-inputs="6"
        :should-auto-focus="true"
        :is-input-num="true"
        @on-complete="handleOnComplete"
      />
    </div>

    <div class="text-center mt-3">
      <p class="state-color__fg--error" v-if="getauthOtpError != ''">
        {{ getauthOtpError }}
      </p>

      <p
        class="mobile-text state-color__fg--error"
        v-if="timer.minutes <= 0 && timer.seconds <= 0"
      >
        {{ $t("auth.otp.otp_expired") }}
        <span class="state-color__fg--Info make-cursor"
          ><u @click="resendOtp()" class="ms-1">
            {{ $t("auth.otp.otp_resend") }}
          </u></span
        >
      </p>
      <p class="d-block mobile-text" v-else>
        {{ $t("auth.otp.otp_placeholder") }}
        <b class="d-inline-block"
          >( {{ timer.minutes }}:{{ timer.seconds }} )</b
        >
      </p>
    </div>
  </div>
</template>

<script>
import Toast, { errorBackground } from "../../config/toastConfig"
import { useTimer } from "vue-timer-hook"
import VOtpInput from "vue3-otp-component"
import { mapGetters, mapMutations } from "vuex"

export default {
  props: ["phoneNumber"],
  emits: ["requestVerifyOtp", "requestResendOtp"], // you need to add this :)
  components: { VOtpInput },

  data() {
    return {
      timer: Object,
    }
  },

  computed: {
    ...mapGetters("auth", ["getauthOtpError"]),
  },

  methods: {
    ...mapMutations("auth", ["SET_AUTH_OTP_ERROR", "SET_NUMBER_STATUS"]),

    handleOnComplete(event) {
      this.$emit("requestVerifyOtp", {
        otp: event,
        phoneNumber: this.phoneNumber,
      })
    },

    resendOtp() {
      if (this.timer.seconds <= 0) {
        // this.$store.commit("setOtpError", "");
        this.$emit("requestResendOtp", this.phoneNumber)

        let time = new Date()
        time.setSeconds(time.getSeconds() + 240) // 4 minutes timer
        this.timer = useTimer(time)
      }
    },
  },

  async created() {
    if (
      this.phoneNumber == undefined ||
      this.phoneNumber == null ||
      this.phoneNumber == ""
    ) {
      Toast.fire({
        icon: "error",
        title: this.$t("auth.validation.phone.phone_required"),
        background: errorBackground,
      })
      this.$store.state.otpError = ""
      this.SET_NUMBER_STATUS(false)
    }

    this.$store.state.otpError = ""
    let time = new Date()
    time.setSeconds(time.getSeconds() + 180) // 3 minutes timer

    //time.setSeconds(time.getSeconds() + 18)

    this.timer = useTimer(time)
  },
}
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
</style>
