<template>
  <div class="d-flex width-50-100">
    <div
      class="input-group my-3 border border-1 bg-white common-border__radius d-flex "
    >
      <div
        class="bg-white common-border__radius d-flex justify-content-center align-items-center"
        style="padding-left:10px"
      >
        <img
          src="../../assets/icon/search.svg"
          alt=""
          class="mx-2 make-cursor"
          @click="SearchByCategory()"
          height="23"
          width="23"
        />
      </div>
      <input
        type="text"
        class="no-border common-border__radius form-control google_sans_normal"
        :placeholder="$t('product.main.product_search.placeholder')"
        v-model="inputProductSearch"
        @keyup.enter="SearchByCategory()"
        style="min-height: 50px"
      />
    </div>
    <div class="position-relative my-3" v-if="isAllCategoryOPtionOpen">
      <div
        class="d-flex justify-content-center align-items-center bg-white border common-border__radius h-100 ms-2 px-2"
        @click="categoryDivVisibility = !categoryDivVisibility"
      >
        <img
          src="../../assets/icon/filter-category.svg"
          alt=""
          class="my-auto"
        />
      </div>

      <div
        class="position-absolute shadow-lg common-border__radius bg-white categories-search pt-3 pb-1 px-1"
        v-if="categoryDivVisibility"
        v-click-outside="onClickOutsideCategorySearchModal"
      >
        <div class="categories-list overflow-auto">
          <div class="">
            <p class="fw-bold google_sans_bold ms-3">
              {{ $t("product.main.product_search.filter_categories") }}
            </p>
          </div>
          <div
            v-if="
              isAllCategoryOPtionOpen && AllAvailableProductCategories != null
            "
            class="form-check p-0 ms-3 my-2"
          >
            <input
              type="radio"
              name="exampleRadios"
              id="categoriesNameRadio"
              value="true"
              v-model="showAllProductFlag"
              @click="allCategoriesBtnOnClick()"
            />
            <label class="form-check-label ms-3" for="categoriesNameRadio">
              {{ $t("product.main.product_search.all_categories") }}
            </label>
          </div>

          <div
            v-else-if="AllAvailableProductCategories == null"
            class="w-100 d-flex align-items-center justify-content-center"
            style="height: 8rem;"
          >
            <label class="form-check-label ms-3" for="categoriesNameRadio">
              {{ $t("product.main.no_category_found.title") }}!
            </label>
          </div>

          <div
            class="form-check p-0 ms-3 my-3"
            v-for="category in AllAvailableProductCategories"
            :key="category.id_product_category"
          >
            <input
              class=""
              type="checkbox"
              :id="category.id_product_category"
              :value="category.id_product_category"
              v-model="checkedCategories"
            />
            <label
              class="form-check-label d-inline ms-3"
              :for="category.id_product_category"
              @click="removeRadio()"
            >
              {{ category.product_category_name }}
            </label>
          </div>
        </div>
        <div
          class="d-flex justify-content-end mt-2"
          v-if="AllAvailableProductCategories != null"
        >
          <button
            type="button"
            class="btn"
            style="
              background-color: #3a86ff;
              color: white;
              border-radius: 5px;
              height: 40px;
            "
            @click="SearchByCategory()"
          >
            <img src="../../assets/icon/search-white.svg" alt="" width="25" />
            {{ $t("product.main.product_search.search") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <button
      @click="
        $router.push({
          name: 'AddNewProductWithAttribuites',
          params: { language: this.getLanguage },
        })
      "
      class="btn btn-primary google_sans_medium py-3 px-4 d-flex justify-content-center align-items-center"
      style="border-radius:8px !important"
    >
      <img src="../../assets/icon/button-add.svg" alt="" />

      <span style="margin-left:10px">{{
        $t("product.main.add_product_btn")
      }}</span>
    </button>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import { mapGetters, mapActions } from "vuex";

import Toast, { warningBackground } from "../../config/toastConfig";

export default {
  props: {
    AllAvailableProductCategories: Array,
    isAllCategoryOPtionOpen: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["requestSearchProductByCategory"],
  data: function() {
    return {
      //search fields
      checkedCategories: [],
      inputProductSearch: "",

      showAllProductFlag: true,

      //dropdown div visibility
      categoryDivVisibility: false,
    };
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  watch: {
    checkedCategories: function() {
      this.removeRadio();
    },
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
    ...mapGetters("product", ["allProductCategories"]),
    ...mapGetters("auth", ["getUser"]),
  },

  methods: {
    ...mapActions("product", ["getAllCategories"]),
    ...mapActions("profileUpdate", ["sellerInfoByShopName"]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    allCategoriesBtnOnClick() {
      this.checkedCategories = [];
      this.showAllProductFlag = !this.showAllProductFlag;
    },

    SearchByCategory() {
      this.$store.commit("setOverlayStatus", true); //loader close
      this.categoryDivVisibility = false;

      if (this.checkedCategories.length == 0) {
        this.showAllProductFlag = true;
      }

      this.$emit("requestSearchProductByCategory", {
        checkedCategories: this.checkedCategories.toString(),
        inputProdcutSearch: this.inputProductSearch,
      });
    },
    removeRadio() {
      this.showAllProductFlag = false;
    },

    onClickOutsideCategorySearchModal() {
      this.categoryDivVisibility = false;
    },

    async checkIfCategoryExists() {
      await this.getAllCategories();
      if (
        this.allProductCategories == null ||
        this.allProductCategories.length <= 0
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.no_category_found"),
          background: warningBackground,
        });
        this.setOverlayStatus(false);
        this.$router.push({
          name: "AppDirectProductCategoryAssign",
          params: { language: this.getLanguage },
        });

        return;
      }

      // seller can now create product without address
      // let shopName = this.getUser.shop_name.replace(/\s+/g, "-").toLowerCase();

      // const data = await this.sellerInfoByShopName({ shopName });

      // if (data.seller[0].shop_address == "") {
      //   Toast.fire({
      //     icon: "error",
      //     title: this.$t("product.main.toast.no_address_added"),
      //     background: warningBackground,
      //   });
      //   this.setOverlayStatus(false);
      //   return;
      // }

      this.$router.push({
        name: "AddNewProductWithAttribuites",
        params: { language: this.getLanguage },
      });
    },
  },
};
</script>

<style>
.categories-search {
  min-width: 300px;
  max-height: 350px;
  right: 0;
}

.categories-list {
  max-height: 15.625rem;
  max-width: 21.875rem;
  word-wrap: break-word;
  overflow: auto;
}
</style>
