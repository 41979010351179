import _ from "lodash";
import Repository from "../../api/RepositoryFactory";
const Product = Repository.get("Product");
import Toast, { successBackground } from "../../config/toastConfig";
import router from "../../router";
import store from "../../store";

const {
  getAllProduct,
  getAllCategories,
  deleteProduct,
  addNewProduct,
  getAttributeByCategory,
  getAllRibbons,
  getAllUnits,
  updateProduct,
  deleteProductImage,
} = Product;

export default {
  namespaced: true,
  state: {
    productImages: [],
    productName: { title_en: "", title_bn: "" },
    productCategory: null,
    productPrice: "",
    productDiscountedPrice: "",
    productChargeName: "",
    productChargeInsideCity: "",
    productChargeOutsideCity: "",
    quantityAmount: "",
    quantityName: "",
    productDetails: "",
    productRibbons: [],
    fixedDeliveryCharge: "no",

    productAttributeCombination: { product_attribute_combination: [] },

    allProductCategories: [],

    categoryAttributeValue: [],
    allRibbons: [],
    allUnits: [],

    productCreateInputErrorNo: -1,
    productCreateInputErrorMessage: "",

    existingProduct: {},
    existingProdcutImages: [],
    existingDeletedImages: { remove_image: [] },
    existingProductCategory: 0,
    previosProductAttributeCombination: { product_attribute_combination: [] },
    existingProductId: 0,
    existingValueFlag: false,

    filteredProducts: [],
    allProducts: null,
    totalPage: 0,
    lastPage: 0,
    AllAvailableProductCategories: [],
  },

  getters: {
    productImages: (state) => state.productImages,
    productName: (state) => state.productName,
    productCategory: (state) => state.productCategory,
    productPrice: (state) => state.productPrice,
    productDiscountedPrice: (state) => state.productDiscountedPrice,
    productChargeName: (state) => state.productChargeName,
    productChargeInsideCity: (state) => state.productChargeInsideCity,
    productChargeOutsideCity: (state) => state.productChargeOutsideCity,
    quantityAmount: (state) => state.quantityAmount,
    quantityName: (state) => state.quantityName,
    productDetails: (state) => state.productDetails,
    productRibbons: (state) => state.productRibbons,
    fixedDeliveryCharge: (state) => state.fixedDeliveryCharge,

    productAttributeCombination: (state) => state.productAttributeCombination,

    allProductCategories: (state) => state.allProductCategories,

    categoryAttributeValue: (state) => state.categoryAttributeValue,
    allRibbons: (state) => state.allRibbons,
    allUnits: (state) => state.allUnits,

    productCreateInputErrorNo: (state) => state.productCreateInputErrorNo,
    productCreateInputErrorMessage: (state) =>
      state.productCreateInputErrorMessage,

    existingProduct: (state) => state.existingProduct,
    existingProdcutImages: (state) => state.existingProdcutImages,
    existingDeletedImages: (state) => state.existingDeletedImages,

    existingProductCategory: (state) => state.existingProductCategory,
    existingProductId: (state) => state.existingProductId,
    previosProductAttributeCombination: (state) =>
      state.previosProductAttributeCombination.product_attribute_combination,
    existingValueFlag: (state) => state.existingValueFlag,

    filteredProducts: (state) => state.filteredProducts,
    allProducts: (state) => state.allProducts,
    totalPage: (state) => state.totalPage,
    lastPage: (state) => state.lastPage,
    AllAvailableProductCategories: (state) =>
      state.AllAvailableProductCategories,
  },

  mutations: {
    resetAllExistingValue(state) {
      state.productName = { title_en: "", title_bn: "" };
      state.productPrice = "";
      state.productDiscountedPrice = "";
      state.productCategory = null;
      state.quantityAmount = "";
      state.quantityName = "";
      state.productChargeName = "";
      state.productChargeInsideCity = "";
      state.productChargeOutsideCity = "";
      state.productRibbons = [1];
    },

    setProductImage(state, { index, image, url }) {
      state.productImages.push({
        index: index,
        image: image,
        url: url,
      });
    },

    removeProductImage(state, { index }) {
      state.productImages.splice(index, 1);
    },

    setImageToEmpty(state) {
      state.productImages = [];
      state.existingProdcutImages = [];
    },

    setProductName(state, { name }) {
      state.productName.title_en = name;
      state.productName.title_bn = name;
    },

    setProductCategory(state, { name }) {
      state.productCategory = name;
    },

    setProductPrice(state, { price }) {
      state.productPrice = price;
    },

    setproductDiscountedPrice(state, { price }) {
      state.productDiscountedPrice = price;
    },

    setproductChargeName(state, { name }) {
      state.productChargeName = name;
    },

    setproductChargeInsideCity(state, { chargeInsideCity }) {
      state.productChargeInsideCity = chargeInsideCity;
    },

    setproductChargeOutsideCity(state, { chargeOutsideCity }) {
      state.productChargeOutsideCity = chargeOutsideCity;
    },

    setquantityAmount(state, { amount }) {
      state.quantityAmount = amount;
    },

    setquantityName(state, { name }) {
      state.quantityName = name;
    },

    setProductAttributeCombination(state, { attributeCombination }) {
      state.productAttributeCombination.product_attribute_combination = attributeCombination;
    },

    removeProductAttributeCombination(state, { index }) {
      state.productAttributeCombination.product_attribute_combination.splice(
        index,
        1
      );
    },

    setProductDetails(state, { productDetails }) {
      state.productDetails = productDetails;
    },

    setProductRibbons(state, { productRibbons }) {
      state.productRibbons = productRibbons;
    },

    setFixedDeliveryCharge(state, { fixedCharge }) {
      state.fixedDeliveryCharge = fixedCharge;
    },

    setAllProductCategories(state, { categories }) {
      state.allProductCategories = categories;
    },

    APPEND_NEW_CATEGORY(state, category) {
      state.allProductCategories.push(category);
    },

    setCategoryAttributeVal(state, { categoryAttribute }) {
      state.categoryAttributeValue = categoryAttribute;
    },

    setAllRibbons(state, { allRibbons }) {
      state.allRibbons = allRibbons;
    },

    setAllUnits(state, { allUnits }) {
      state.allUnits = allUnits;
    },

    setExistingProduct(state, { existingProduct }) {
      state.existingProduct = existingProduct;
    },

    addIdProductAttributeToExistingProduct(state, { index, id }) {
      state.existingProduct.product_attributes_combination[index][
        "id_product_attribute"
      ] = id;
    },

    setExistingProdcutImages(state, { existingImages }) {
      state.existingProdcutImages = existingImages;
    },

    removeExistingProductImage(state, { index }) {
      state.existingProdcutImages.splice(index, 1);
    },

    setExistingDeletedImages(state, { imageUrl }) {
      state.existingDeletedImages.remove_image.push({ image_path: imageUrl });
    },

    resetExistingDeletedImag(state) {
      state.existingDeletedImages.remove_image = [];
    },

    setExistingProductCategory(state, { existingProductCategory }) {
      state.existingProductCategory = existingProductCategory;
    },

    setPreviosProductAttributeCombination(
      state,
      { previosProductAttributeCombination }
    ) {
      state.previosProductAttributeCombination.product_attribute_combination = previosProductAttributeCombination;
    },

    setExistingProductId(state, { id }) {
      state.existingProductId = id;
    },

    setExistingValueFlag(state, { existingValueFlag }) {
      state.existingValueFlag = existingValueFlag;
    },

    // product error

    setProductCreateErrorValue(state, { errorNo, msg }) {
      state.productCreateInputErrorNo = errorNo;
      state.productCreateInputErrorMessage = msg;
    },

    SET_PRODUCT_INFO(state, data) {
      state.filteredProducts = data.ProductList;
      state.allProducts = data.ProductList;
      state.lastPage = data.last_page;
      state.totalPage = data.total;
    },

    SET_ALL_CATEGORY(state, data) {
      state.AllAvailableProductCategories = data;
    },

    REMOVE_PRODUCT(state, productId) {
      state.filteredProducts = state.filteredProducts.filter(
        (product) => product.id_product !== productId
      );
    },
  },
  actions: {
    checkProductCreateErrorInputError({ commit }, payload) {
      if (payload.productImages.length + payload.existingImages.length < 1) {
        commit("setProductCreateErrorValue", {
          errorNo: 1,
          msg: payload.i18n("product.main.toast.min_image"),
        });
      } else if (
        payload.productTitle.title_en == "" ||
        payload.productTitle.title_bn == ""
      ) {
        commit("setProductCreateErrorValue", {
          errorNo: 2,
          msg: payload.i18n("product.main.toast.name"),
        });
      } else if (!payload.productCategory) {
        commit("setProductCreateErrorValue", {
          errorNo: 3,
          msg: payload.i18n("product.main.toast.category"),
        });
      } else if (
        payload.price == "" &&
        payload.attributeCombination.product_attribute_combination.length <= 1
      ) {
        commit("setProductCreateErrorValue", {
          errorNo: 4,
          msg: payload.i18n("product.main.toast.product_price"),
        });
      } else if (
        payload.discountPrice != "" &&
        payload.attributeCombination.product_attribute_combination.length <=
          1 &&
        parseFloat(payload.price) <= parseFloat(payload.discountPrice)
      ) {
        commit("setProductCreateErrorValue", {
          errorNo: 4,
          msg: payload.i18n("product.main.toast.priceValidate"),
        });
      } else if (parseInt(payload.chargeInsideCity) < 0) {
        commit("setProductCreateErrorValue", {
          errorNo: 7,
          msg: payload.i18n("product.main.toast.valid_number"),
        });
      } else if (parseInt(payload.chargeOutsideCity) < 0) {
        commit("setProductCreateErrorValue", {
          errorNo: 8,
          msg: payload.i18n("product.main.toast.valid_number"),
        });
      } else if (payload.quantityUnit == "") {
        // return 1;
        commit("setProductCreateErrorValue", {
          errorNo: 9,
          msg: payload.i18n("product.main.toast.valid_quantity"),
        });
      } else if (
        payload.quantityAmount == "" &&
        payload.attributeCombination.product_attribute_combination.length <= 1
      ) {
        commit("setProductCreateErrorValue", {
          errorNo: 9,
          msg: payload.i18n("product.main.toast.product_quantity"),
        });
      } else if (payload.description == "") {
        // return 1;
        commit("setProductCreateErrorValue", {
          errorNo: 10,
          msg: payload.i18n("product.main.toast.product_description"),
        });
      } else if (
        _.isEmpty(payload.ribbonList) ||
        payload.ribbonList.length <= 0
      ) {
        commit("setProductCreateErrorValue", {
          errorNo: 11,
          msg: payload.i18n("product.main.toast.produc_ribbons"),
        });
      } else if (
        payload.attributeCombination.product_attribute_combination.length > 1
      ) {
        let i = 0,
          flag = 0;
        for (
          i = 0;
          i <
          payload.attributeCombination.product_attribute_combination.length - 1;
          i++
        ) {
          if (
            payload.attributeCombination.product_attribute_combination[i]
              .price == "" ||
            isNaN(
              payload.attributeCombination.product_attribute_combination[i]
                .price
            ) ||
            parseInt(
              payload.attributeCombination.product_attribute_combination[i]
                .price
            ) < 0
          ) {
            flag = 1;
            break;
          } else if (
            (payload.attributeCombination.product_attribute_combination[i]
              .discount_price != "" &&
              isNaN(
                payload.attributeCombination.product_attribute_combination[i]
                  .discount_price
              )) ||
            parseInt(
              payload.attributeCombination.product_attribute_combination[i]
                .discount_price
            ) < 0
          ) {
            flag = 2;
            break;
          } else if (
            payload.attributeCombination.product_attribute_combination[i]
              .quantity == "" ||
            isNaN(
              payload.attributeCombination.product_attribute_combination[i]
                .quantity
            ) ||
            parseInt(
              payload.attributeCombination.product_attribute_combination[i]
                .quantity
            ) < 0
          ) {
            flag = 3;
            break;
          } else if (
            parseFloat(
              payload.attributeCombination.product_attribute_combination[i]
                .price
            ) <=
            parseFloat(
              payload.attributeCombination.product_attribute_combination[i]
                .discount_price
            )
          ) {
            flag = 4;
            break;
          }
        }

        if (flag == 1) {
          commit("setProductCreateErrorValue", {
            errorNo: 11,
            msg: payload.i18n("product.main.toast.arr_price"),
          });
          return 0;
        } else if (flag == 2) {
          commit("setProductCreateErrorValue", {
            errorNo: 11,
            msg: payload.i18n("product.main.toast.arr_discount_price"),
          });
          return 0;
        } else if (flag == 3) {
          commit("setProductCreateErrorValue", {
            errorNo: 11,
            msg: payload.i18n("product.main.toast.arr_quantity"),
          });
          return 0;
        } else if (flag == 4) {
          commit("setProductCreateErrorValue", {
            errorNo: 11,
            msg: payload.i18n("product.main.toast.arr_pice_gre_discount"),
          });
          return 0;
        }

        return 1;
      } else if (isNaN(payload.price) && payload.price != "") {
        commit("setProductCreateErrorValue", {
          errorNo: 4,
          msg: payload.i18n("product.main.toast.valid_number_in_product_price"),
        });

        return 0;
      } else {
        commit("setProductCreateErrorValue", {
          errorNo: 0,
          msg: "",
        });
        return 1;
      }

      //commit("setOverlayStatus", true);
      return 0;
    },

    async getAllProduct({ commit }, url) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await getAllProduct(url);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success && response.data) {
          commit("SET_PRODUCT_INFO", response.data);
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async getAllCategories({ commit }) {
      try {
        let userObject = localStorage.getItem("majhiUser");
        let authUser = JSON.parse(userObject);
        let businessCategoryId = authUser.business_category_id;
        if (!businessCategoryId) {
          return;
        }
        //   commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await getAllCategories(businessCategoryId);

        if (response.success && response.data) {
          commit("SET_ALL_CATEGORY", response.data.product_category);

          commit("setAllProductCategories", {
            categories: response.data.product_category,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async deleteProductRequest({ commit }, productId) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await deleteProduct(productId);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          commit("REMOVE_PRODUCT", productId);
          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async deleteProductImageRequest({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await deleteProductImage(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async addNewProductRequest({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await addNewProduct(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          commit("setProductCreateErrorValue", {
            errorNo: 0,
            msg: "",
          });

          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
          router.push({
            name: "AllProducts",
            params: { languageCode: store.getters["language/getLanguage"] },
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async requestAttributeByCategory({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await getAttributeByCategory(data);
        // commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success && response.data) {
          commit("setCategoryAttributeVal", {
            categoryAttribute: response.data,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async requestGetAllRibbons({ commit }) {
      try {
        // commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await getAllRibbons();
        // commit("Loader/SET_LOADER_VISIBILITY", false, { root: true })

        if (response.success && response.data) {
          let json = JSON.parse(
            JSON.stringify(response.data.ribbon)
              .split('"promotion_name":')
              .join('"label":')
          );

          commit("setAllRibbons", {
            allRibbons: JSON.parse(
              JSON.stringify(json)
                .split('"id_promotion":')
                .join('"value":')
            ),
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async requestGetAllUnits({ commit }) {
      try {
        // commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await getAllUnits();
        //  commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        if (response.success && response.data) {
          let json = JSON.parse(
            JSON.stringify(response.data.unit)
              .split('"unit_name":')
              .join('"text":')
          );

          commit("setAllUnits", {
            allUnits: JSON.parse(
              JSON.stringify(json)
                .split('"id_unit":')
                .join('"id":')
            ),
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },

    async updateProductRequest({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await updateProduct(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        if (response.success && response.data) {
          commit("setProductCreateErrorValue", {
            errorNo: 0,
            msg: "",
          });

          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
          router.push({
            name: "AllProducts",
            params: { languageCode: store.getters["language/getLanguage"] },
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log(error);
      }
    },
  },
};
