<template>
  <div class="body px-3 py-4" :class="{ notVisited: !notification.read_at }">
    <div class="icon-container">
      <img
        :src="notification.image_path"
        alt="notification"
        width="40"
        height="40"
        class="fs-5 m-auto"
      />
    </div>
    <div class="d-flex justify-content-between align-items-center card-content">
      <div
        class="px-3 "
        @click="makeItVisited(notification.id_notification)"
        style="margin-top:15px "
      >
        <p class="title">
          {{ notification.data }}
        </p>
        <p class="time fw-ligh">{{ notification.created_at }}</p>
      </div>
      <div @click="deleteNotification(notification.id_notification)">
        <img
          src="../../assets/icon/Group.svg"
          alt="delete"
          width="15"
          height="15"
          class="fs-5 m-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["notification"],

  methods: {
    ...mapActions("notification", [
      "deleteNotificationRequest",
      "markNotificationRed",
    ]),
    // delete notification ----------------------------------
    async deleteNotification(id) {
      await this.deleteNotificationRequest(id);
    },

    // -------------Make notification visited-----------------------
    async makeItVisited(id) {
      await this.markNotificationRed(id);
    },
  },
};
</script>

<style scoped>
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 90px;
  width: 100%;
}

.body:hover {
  cursor: pointer;
}

.icon-container {
  border-radius: 50%;
}

.title {
  /* font-size: 16px; */
  font-size: 1rem;
  margin: 0;
  color: #1d1d1d;
}
.time {
  /* font-size: 14px; */
  font-size: 0.875rem;
  color: #707070;
}

.card-content {
  width: 90%;
}

.notVisited {
  background: #ebf2ff;
  /* color: blueviolet; */
}
</style>
