<template>
  <div>
    <div class="bg-white mt-3 p-4 radius-10">
      <h6>{{ $t("manage.main.store_address.title1") }}</h6>

      <div class="border-bottom" style="border-bottom-color:#EBEBEB"></div>
      <div class="form-group row py-3">
        <label
          for="inputPassword"
          class="col-sm-4 col-form-label google_sans_medium"
          >{{ $t("manage.main.store_address.country") }}
          <span class="text-primary">*</span>
        </label>
        <div class="col-sm-8">
          <Select2
            :disabled="true"
            v-model="idCountry"
            :options="addressCountryList"
            @select="getDivisonByCountry($event)"
            class="google_sans_medium"
          />
        </div>
      </div>

      <div class="form-group row  py-3">
        <label
          for="inputPassword"
          class="col-sm-4 col-form-label google_sans_medium"
          >{{ $t("manage.main.store_address.division") }}
          <span class="text-primary">*</span>
        </label>
        <div class="col-sm-8">
          <Select2
            v-model="idDivision"
            :options="addressDivisionList"
            @select="getCityByDivision($event, true)"
            class="google_sans_medium"
          />
        </div>
      </div>

      <div class="form-group row mr-2 py-3">
        <label
          for="inputPassword"
          class="col-sm-4 col-form-label google_sans_medium"
          >{{ $t("manage.main.store_address.city") }}
          <span class="text-primary">*</span>
        </label>
        <div class="col-sm-8">
          <Select2
            v-model="idCity"
            :options="addressCityList"
            @select="getAreaByCity($event, true)"
            class="google_sans_medium"
          />
        </div>
      </div>

      <div class="form-group row mr-2 py-3">
        <label
          for="inputPassword"
          class="col-sm-4 col-form-label google_sans_medium"
          >{{ $t("manage.main.store_address.area") }}
          <span class="text-primary">*</span>
        </label>
        <div class="col-sm-8">
          <Select2
            v-model="idArea"
            :options="addressAreaList"
            class="google_sans_medium"
          />
        </div>
      </div>

      <div class="form-group row mr-2 py-3">
        <label
          for="inputPassword"
          class="col-sm-4 col-form-label google_sans_medium"
          >{{ $t("manage.main.store_address.details_address") }}
          <span class="text-primary">*</span>
        </label>
        <div class="col-sm-8">
          <textarea
            class="form-control google_sans_medium"
            id="exampleFormControlTextarea1"
            rows="3"
            v-model="shopAddress"
            placeholder="house no: ***,  road no: **"
          />
        </div>
      </div>

      <div class="w-100">
        <iframe
          width="100%"
          height="400px"
          id="gmap_canvas"
          :src="iframeUrl"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>

      <div class="form-group row mx-2 py-3 d-none">
        <div class="col-sm-8">
          <input
            type="hidden"
            class="form-control"
            id="inputLat"
            placeholder="latitude"
            v-model="lat"
          />
        </div>
      </div>

      <div class="form-group row mx-2 py-3 d-none">
        <div class="col-sm-8">
          <input
            type="hidden"
            class="form-control"
            id="inputLong"
            placeholder="longitude"
            v-model="long"
          />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        :class="
          `common-border__radius-8 state-color__bg--Info text-white ${$style.save_btn}`
        "
        @click="sendUpdateAddressRequest()"
      >
        {{ $t("manage.main.store_address.save") }}
      </button>
    </div>
  </div>
</template>
<script>
import Select2 from "vue3-select2-component";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

import Toast, { errorBackground } from "../../../config/toastConfig";

export default {
  components: { Select2 },

  data() {
    return {
      idCountry: 1,
      idDivision: null,
      idCity: null,
      idArea: null,
      lat: "",
      long: "",
      areaName: "",
      cityName: "",
      divisionName: "",
      addressCountryList: [],
      addressDivisionList: [],
      addressCityList: [],
      addressAreaList: [],
      shopAddress: "",
      iframeUrl:
        "https://maps.google.com/maps?q=bangladesh&t=&z=7&ie=UTF8&iwloc=&output=embed",
    };
  },

  computed: {
    ...mapGetters("auth", ["getUser"]),
  },

  methods: {
    ...mapActions("address", ["updateAddress"]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    async sendUpdateAddressRequest() {
      if (this.shopAddress == "") {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.address.shop_address"),
          background: errorBackground,
        });
        return;
      }

      this.setOverlayStatus(true);

      const payload = {
        shop_address: this.shopAddress,
        id_country: this.idCountry,
        id_division: this.idDivision,
        id_city: this.idCity,
        id_area: this.idArea,
      };

      await this.updateAddress(payload);
      this.setOverlayStatus(false);
    },

    getCountryList() {
      let vm = this;
      vm.$store.commit("setOverlayStatus", true); //loader open

      axios
        .get(`/country_list/${this.getLanguage}`)
        .then(function(response) {
          vm.addressCountryList = response.data.data;

          let json = JSON.parse(
            JSON.stringify(vm.addressCountryList)
              .split('"country_name":')
              .join('"text":')
          );
          vm.addressCountryList = JSON.parse(
            JSON.stringify(json)
              .split('"id_country":')
              .join('"id":')
          );
          vm.$store.commit("setOverlayStatus", false); //loader close
        })
        .catch(function() {
          vm.$store.commit("setOverlayStatus", false); //loader close
        });

      this.addressDivisionList = null;
      this.addressCityList = null;
      this.addressAreaList = null;
    },

    getDivisonByCountry({ id }) {
      let vm = this;
      vm.$store.commit("setOverlayStatus", true); //loader open

      axios
        .get(`/division_by_country/${this.getLanguage}/` + id)
        .then(function(response) {
          vm.addressDivisionList = response.data.data;
          let json = JSON.parse(
            JSON.stringify(vm.addressDivisionList)
              .split('"division_name":')
              .join('"text":')
          );
          vm.addressDivisionList = JSON.parse(
            JSON.stringify(json)
              .split('"id_division":')
              .join('"id":')
          );
          vm.$store.commit("setOverlayStatus", false); //loader close
        })
        .catch(function(error) {
          // handle error
          vm.$store.commit("setOverlayStatus", false); //loader close

          console.log(error);
        });

      this.addressCityList = null;
      this.addressAreaList = null;
    },

    getCityByDivision({ id, text }, flag) {
      let vm = this;

      vm.divisionName = text;

      vm.$store.commit("setOverlayStatus", true); //loader open
      axios
        .get(`/city_by_division/${this.getLanguage}/` + id)
        .then(function(response) {
          vm.addressCityList = response.data.data;

          let json = JSON.parse(
            JSON.stringify(vm.addressCityList)
              .split('"city_name":')
              .join('"text":')
          );
          vm.addressCityList = JSON.parse(
            JSON.stringify(json)
              .split('"id_city":')
              .join('"id":')
          );
          if (flag) {
            vm.getAreaByCity(
              {
                id: vm.addressCityList[0].id,
                text: vm.addressCityList[0].text,
              },
              true
            );
            vm.idCity = vm.addressCityList[0].id;
            vm.cityName = vm.addressCityList[0].text;
          }
          vm.$store.commit("setOverlayStatus", false); //loader close
        })
        .catch(function() {
          vm.$store.commit("setOverlayStatus", false); //loader close
        });

      this.addressAreaList = null;
      // return (vm.addressCityList != null) ? vm.addressCityList[0].id : -1;
    },

    getAreaByCity({ id, text }, flag) {
      let vm = this;

      vm.cityName = text;

      vm.$store.commit("setOverlayStatus", true); //loader open
      axios
        .get(`/area_by_city/${this.getLanguage}/` + id)
        .then(function(response) {
          vm.addressAreaList = response.data.data;
          let json = JSON.parse(
            JSON.stringify(vm.addressAreaList)
              .split('"area_name":')
              .join('"text":')
          );
          vm.addressAreaList = JSON.parse(
            JSON.stringify(json)
              .split('"id_area":')
              .join('"id":')
          );
          if (flag) {
            vm.idArea = vm.addressAreaList[0].id;
          }
          vm.$store.commit("setOverlayStatus", false); //loader close
        })
        .catch(function(error) {
          // handle error
          console.log(error);
          vm.$store.commit("setOverlayStatus", false); //loader close
        });
    },

    setAddressInfo() {
      this.shopAddress =
        this.getUser.shop_address == null ? "" : this.getUser.shop_address;
      this.idCountry = 1;
      this.idDivision = this.getUser.id_division;
      this.idCity = this.getUser.id_city;
      this.idArea = this.getUser.id_area;
      this.lat = this.getUser.lat;
      this.long = this.getUser.long;
      this.areaName =
        this.getUser.area_name == null ? "" : this.getUser.area_name;
      this.cityName =
        this.getUser.city_name == null ? "" : this.getUser.city_name;
      this.divisionName =
        this.getUser.division_name == null ? "" : this.getUser.division_name;
      let zoomIn = this.getUser.area_name == null ? 7 : 15;
      this.iframeUrl =
        "https://maps.google.com/maps?q=" +
        this.shopAddress +
        ", " +
        this.areaName +
        ", " +
        this.cityName +
        ", " +
        this.divisionName +
        ", Bangladesh" +
        "&t=&z=" +
        zoomIn +
        "&ie=UTF8&iwloc=&output=embed";

      this.getCountryList();
      this.getDivisonByCountry({ id: this.idCountry });
      this.getCityByDivision(
        { id: this.idDivision, text: this.getUser.division_name },
        false
      );
      this.getAreaByCity(
        { id: this.idCity, text: this.getUser.city_name },
        false
      );
    },
  },

  created() {
    this.setAddressInfo();
  },
};
</script>
<style module>
.save_btn {
  border: none;
  width: 12.5rem;
  height: 3.375rem;
  margin-top: 2.25rem;
}
</style>
