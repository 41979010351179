<template>
  <div class="wrapper">
    <div
      class="image-container"
      :style="{
        backgroundImage: 'url(' + getImgUrl(selectedImage) + ')',
      }"
    >
      <div class="store-name-container">
        <p class="store-name" :style="style">{{ baseUrl }}/{{ storeName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      storeName: "",
      baseUrl: "majhi.com/en",
    };
  },

  props: ["selectedImage", "style"],

  created() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.storeName = parseData.shop_name;
    }
  },

  updated() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.storeName = parseData.shop_name;
    }
  },

  methods: {
    getImgUrl(data) {
      return require("../../assets/marketing-materials/facebook-instagram-post/" +
        data);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Nunito:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans&family=Nunito:ital,wght@0,200;0,300;0,400;1,200;1,300;1,400&family=Open+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&family=Open+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Quicksand:wght@500;600&display=swap");

/* -------------post -----------------*/

@import url("https://fonts.googleapis.com/css2?family=Belleza&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,600;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.wrapper {
  margin-top: 30px;
}

.image-container {
  width: 60%;
  height: 600px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.store-name-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.store-name {
  position: absolute;
  /* left: 30%;
  top: 400px;
  font-family: 'Nunito Sans', sans-serif; */
}

<<<<<<< head ======= @media only screen and (max-width: 1600px) {
  .store-name {
    position: absolute;
    left: 20% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .store-name {
    left: 12% !important;
  }
}

>>>>>>>29735674c91a07c80f03a8f24105724a51ffeee3
  @media
  only
  screen
  and
  (max-width: 600px) {
  .image-container {
    width: 95%;
  }

  .store-name {
    left: 25% !important;
    font-size: 17px !important;
    letter-spacing: 0px !important;
  }

  .wrapper {
    margin-top: 5px;
  }
}
</style>
