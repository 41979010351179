<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div
        class="col-12 col-md-9 col-xl-10 px-0 min-vh-100 d-flex flex-column justify-content-start"
      >
        <top-bar />
        <div class="m-0 p-3 catageries-container">
          <div
            class="
              mt-3
              state-color__fg--grey
              d-flex
              justify-content-between
              flex-column flex-lg-row flex-md-row flex-xl-row
            "
          >
            <div
              class="my-auto d-flex align-items-center state-color__fg--grey"
            >
              <img
                src="../../assets/icon/back_btn_image.svg"
                alt="back"
                class="make-cursor"
                height="25"
                width="20"
                @click="$router.go(-1)"
              />
              <h4 class="ms-3 mb-0">{{ $t("product.main.update.heading") }}</h4>
            </div>

            <button
              type="button"
              class="btn btn-primary py-3 px-4"
              @click="editProduct()"
            >
              {{ $t("product.main.update.btn") }}
            </button>
          </div>
          <div class="bg-white common-border__radius m-1 mt-3">
            <new-product-image />
          </div>

          <div class="bg-white common-border__radius m-1 mt-3 py-3">
            <basic-information
              :category_disable="true"
              @requestToResetAttributeCombination="restAttributeCombination"
            />
          </div>

          <div class="bg-white common-border__radius m-1 mt-3 p-3 w-100">
            <select-product-attribute ref="productAttributeCombination" />
          </div>

          <div class="bg-white common-border__radius m-1 mt-3 p-3 mb-4">
            <details-information />
          </div>

          <div class="d-flex justify-content-end mb-4 pb-3">
            <button
              type="button"
              class="btn btn-primary py-3 px-4"
              @click="editProduct()"
            >
              {{ $t("product.main.update.btn") }}
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast, { errorBackground } from "../../config/toastConfig";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import NewProductImage from "../../components/product/NewProductImage.vue";
import BasicInformation from "../../components/product/with-attribute/BasicInformation.vue";
import SelectProductAttribute from "../../components/product/with-attribute/SelectProductAttribute.vue";
import DetailsInformation from "../../components/product/with-attribute/DetailsInformation.vue";

export default {
  components: {
    SideBar,
    LoadingSpinner,
    TopBar,
    NewProductImage,
    BasicInformation,
    SelectProductAttribute,
    DetailsInformation,
  },
  props: ["existingProductInfo"],

  computed: {
    ...mapGetters("product", [
      "existingProductId",
      "productCategory",
      "existingProduct",
      "existingProductCategory",
      "previosProductAttributeCombination",
      "existingValueFlag",
      "fixedDeliveryCharge",

      "existingDeletedImages",
      "existingProdcutImages",

      "productImages",
      "productName",
      "productPrice",
      "productDiscountedPrice",
      "productChargeName",
      "productChargeInsideCity",
      "productChargeOutsideCity",
      "quantityAmount",
      "quantityName",
      "productDetails",
      "productRibbons",
      "productAttributeCombination",

      "productCreateInputErrorNo",
      "productCreateInputErrorMessage",
    ]),
    ...mapGetters("language", ["getLanguage"]),
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions("product", [
      "checkProductCreateErrorInputError",
      "updateProductRequest",
      "getAllCategories",
      "requestGetAllRibbons",
      "requestGetAllUnits",

      "requestAttributeByCategory",
    ]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    setExistingProductCategory() {
      let existingProductCategory = JSON.parse(
        JSON.stringify(this.existingProduct.product_category)
          .split('"id_product_category":')
          .join('"id":')
      );
      this.$store.commit("product/setProductCategory", {
        name: existingProductCategory,
      });
    },

    async editProduct() {
      let errorCode = 0,
        i = 0,
        ribbonList = [],
        // attributeCombinationLength = 0,
        editedAttributeCombination = [];
      this.$store.commit("setOverlayStatus", true); //loader close
      editedAttributeCombination = JSON.parse(
        JSON.stringify(this.productAttributeCombination)
      );

      errorCode = await this.checkProductCreateErrorInputError({
        productImages: this.productImages,
        existingImages: this.existingProdcutImages,
        productTitle: this.productName,
        productCategory: this.productCategory,
        price: this.productPrice,
        discountPrice: this.productDiscountedPrice,
        chargeName: this.productChargeName,
        chargeInsideCity: this.productChargeInsideCity,
        chargeOutsideCity: this.productChargeOutsideCity,
        quantityAmount: this.quantityAmount,
        quantityUnit: this.quantityName,
        description: this.productDetails,
        attributeCombination: editedAttributeCombination,
        ribbonList: this.productRibbons,
        i18n: this.$t,
      });

      if (errorCode == 0) {
        this.$store.commit("setOverlayStatus", false);
        // if (this.productCreateInputErrorNo == 11) {
        //   this.toast(this.productCreateInputErrorMessage, "danger");
        // }
        //this.toast(this.productCreateInputErrorMessage, "danger");

        Toast.fire({
          icon: "error",
          title: this.productCreateInputErrorMessage,
          background: errorBackground,
        });

        return;
      }

      let tmpQuantity = JSON.parse(
        JSON.stringify(this.quantityName)
          .split('"id_unit":')
          .join('"id":')
      );

      let formData = new FormData();

      formData.append("id_product", this.existingProductId);
      this.productImages.forEach((file) => {
        formData.append("image[]", file.image);
      });

      formData.append(
        "remove_image",
        JSON.stringify(this.existingDeletedImages)
      );

      formData.append("product_title", JSON.stringify(this.productName));
      formData.append("id_product_category", this.productCategory.id);

      if (editedAttributeCombination.product_attribute_combination.length > 1) {
        formData.append("price", 0);
        formData.append("discount_price", 0);
        formData.append("quantity", 0);
      } else {
        formData.append("price", this.productPrice);
        formData.append(
          "discount_price",
          parseFloat(this.productDiscountedPrice) || 0
        );
        formData.append("quantity", this.quantityAmount);
      }

      formData.append("charge_name", this.productChargeName);
      formData.append(
        "charge_amount_inside_city",
        this.productChargeInsideCity
      );
      formData.append(
        "charge_amount_outside_city",
        this.productChargeOutsideCity
      );

      formData.append("unit_id", tmpQuantity.id);

      formData.append(
        "product_description",
        JSON.stringify({
          description_en: this.productDetails,
          description_bn: "none",
        })
      );

      for (i = 0; i < this.productRibbons.length; i++) {
        ribbonList.push({ ribbon_id: this.productRibbons[i] });
      }
      formData.append("ribbon_id", JSON.stringify({ ribbons: ribbonList }));

      editedAttributeCombination.product_attribute_combination.pop();
      formData.append(
        "product_attribute_combination",
        JSON.stringify(editedAttributeCombination)
      );

      formData.append("_method", "put");
      formData.append("fixed_delivery_charge", this.fixedDeliveryCharge);

      await this.updateProductRequest(formData);
    },

    async getAllProductCategories() {
      await this.getAllCategories();
    },

    async getAllRibbons() {
      await this.requestGetAllRibbons();
    },

    async getAllUnits() {
      await this.requestGetAllUnits();
    },

    setExistingProductInfo() {
      this.$store.commit("product/setExistingProductId", {
        id: this.existingProduct.id_product,
      });

      this.$store.commit("product/setExistingProdcutImages", {
        existingImages: this.existingProduct.product_images,
      });
      this.$store.commit("product/setProductName", {
        name: this.existingProduct.title,
      });

      this.setExistingProductCategory();

      this.$store.commit("product/setProductPrice", {
        price: this.existingProduct.price,
      });
      this.$store.commit("product/setproductDiscountedPrice", {
        price: this.existingProduct.discount_price,
      });

      if (this.existingProduct.product_charge != null) {
        this.$store.commit("product/setproductChargeName", {
          name: this.existingProduct.product_charge.charge_name,
        });
        this.$store.commit("product/setproductChargeInsideCity", {
          chargeInsideCity: this.existingProduct.product_charge
            .charge_amount_inside_city,
        });
        this.$store.commit("product/setproductChargeOutsideCity", {
          chargeOutsideCity: this.existingProduct.product_charge
            .charge_amount_outside_city,
        });
      }

      this.$store.commit("product/setquantityAmount", {
        amount: this.existingProduct.quantity,
      });
      this.$store.commit("product/setquantityName", {
        name: this.existingProduct.product_unit[0],
      });

      this.$store.commit("product/setquantityName", {
        name: this.existingProduct.product_unit[0],
      });

      let combination = JSON.parse(
        JSON.stringify(this.existingProduct.product_attributes_combination)
          .split('"attributes_list":')
          .join('"attribute":')
      );

      if (combination == null) {
        combination = [];
      }

      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: combination,
      });

      this.$store.commit("product/setPreviosProductAttributeCombination", {
        previosProductAttributeCombination: combination,
      });

      let existingProductCategory = JSON.parse(
        JSON.stringify(this.existingProduct.product_category)
          .split('"id_product_category":')
          .join('"id":')
      );

      this.$store.commit("product/setExistingProductCategory", {
        existingProductCategory: existingProductCategory,
      });

      this.$store.commit("product/setProductDetails", {
        productDetails: this.existingProduct.description,
      });

      let previousRibbonList = [],
        i = 0;
      for (i = 0; i < this.existingProduct.product_ribbon?.length; i++) {
        previousRibbonList.push(
          this.existingProduct.product_ribbon[i].id_ribbon
        );
      }

      this.$store.commit("product/setProductRibbons", {
        productRibbons: previousRibbonList,
      });

      this.$store.commit("product/setExistingValueFlag", {
        existingValueFlag: true,
      });

      this.$store.commit("product/setFixedDeliveryCharge", {
        fixedCharge: this.existingProduct.fixed_delivery_charge,
      });
    },

    addIdProductAttributeToExistingCombination() {
      let i = 0;
      for (
        i = 0;
        i < this.existingProduct.product_attributes_combination.length;
        i++
      ) {
        this.$store.commit("product/addIdProductAttributeToExistingProduct", {
          id: this.existingProduct.product_attributes_combination[i]
            .attributes_list[0]?.id_product_attribute,
          index: i,
        });
      }
    },

    restAttributeCombination() {
      if (this.productCategory.id == this.existingProductCategory.id) {
        this.$refs.productAttributeCombination.resetToExistingCategoryCombination();
      } else {
        this.$refs.productAttributeCombination.clearAttributeCombination();
      }
    },

    extractUserData() {
      let userObject = localStorage.getItem("majhiUser");
      let authUser = JSON.parse(userObject);
      this.businessCategoryId = authUser.business_category_id;
    },
  },

  async created() {
    if (this.existingProductInfo) {
      //  this.$store.commit("setOverlayStatus", true);
      this.setOverlayStatus(true);
      this.$store.commit("product/setProductCreateErrorValue", {
        errorNo: 0,
        msg: "",
      });

      this.$store.commit("product/resetAllExistingValue");
      this.$store.commit("product/setExistingProduct", {
        existingProduct: JSON.parse(this.existingProductInfo),
      });
      await this.requestAttributeByCategory(
        JSON.parse(this.existingProductInfo).product_category
          .id_product_category
      );
      await this.extractUserData();
      await this.getAllUnits();
      await this.getAllProductCategories();
      await this.getAllRibbons();
      if (this.existingProduct.product_attributes_combination != null) {
        await this.addIdProductAttributeToExistingCombination();
      }

      await this.setExistingProductInfo();
      this.setOverlayStatus(false);
    } else {
      this.$router.push({
        name: "AllProducts",
        params: { languageCode: this.getLanguage },
      });
    }
  },

  beforeUnmount() {
    this.$store.commit("product/setExistingValueFlag", {
      existingValueFlag: false,
    });
  },
};
</script>

<style></style>
