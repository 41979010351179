<template>
  <div
    class="
      w-100
      min-vh-100
      d-flex
      justify-content-center
      align-items-center
      signin-page
    "
  >
    <div class="signup-container">
      <div class="left position-relative">
        <a :href="`${majhiLandingBaseUrl}/${getLanguage}`">
          <div class="position-absolute top-0 start-0 mt-3 ms-3">
            <img
              src="../../assets/logo/Bangla/majhi_logo_primary_bangla_2.png"
              alt=""
              width="144"
              :class="getLanguage == 'bn' ? 'd-block' : 'd-none'"
            />
            <img
              src="../../assets/logo/English/majhi_logo_primary_english_2.png"
              alt=""
              width="144"
              :class="getLanguage == 'en' ? 'd-block' : 'd-none'"
            />
          </div>
        </a>
        <div class="d-flex justify-content-center align-items-center">
          <img
            alt=""
            src="https://majhi-space.sgp1.cdn.digitaloceanspaces.com/Static/auth_img.png"
            class="large-image"
          />
        </div>
      </div>

      <div class="right">
        <form @submit.prevent="handleSubmit()" class="w-100">
          <div>
            <h4 class="text-center py-4" style=" line-height: 1.6;">
              {{ $t("auth.signUp.title") }}<br />{{ $t("auth.signUp.store") }}
            </h4>
          </div>

          <div
            class="
              signup-company-logo
              mx-auto
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <img
              v-if="url"
              :src="url"
              alt=""
              width="100"
              height="100"
              class="rounded-circle"
            />
            <img
              v-else
              src="../../assets/icon/demo-shop.svg"
              alt=""
              width="50"
              height="50"
            />
          </div>

          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <label
              class="custom-file-upload text-decoration-underline"
              v-if="image == null"
            >
              <input
                type="file"
                accept="image/*"
                @change="uploadImage($event)"
                id="file-input"
              />
              {{ $t("auth.signUp.upload_logo") }}
            </label>

            <label
              v-else-if="image != null"
              class="
                custom-file-upload
                text-decoration-underline
                state-color__fg--error
                mt-2
                mb-2
              "
              @click="removeImage()"
              >{{ $t("auth.signUp.remove") }}</label
            >
            <p v-if="errorShopLogo != ''" class="state-color__fg--error mt-2">
              {{ errorShopLogo }}
            </p>
          </div>

          <div class="">
            <div class="mb-3 mx-auto" style="width: 80%">
              <div class="text-start">
                <label for="exampleInputEmail1" class="form-label"
                  >{{ $t("auth.signUp.bus_name") }}
                  <span class="text-primary">*</span></label
                >
              </div>
              <input
                type="mobileNo"
                class="form-control form-control-sm"
                aria-describedby="mobileNoHelp"
                :placeholder="$t('auth.signUp.bus_placeholder')"
                v-model.trim="shopName"
                required
                style="height: 50px"
              />

              <p
                v-if="errorShopName != ''"
                class="state-color__fg--error mt-2 mb-2"
              >
                {{ errorShopName }}
              </p>
            </div>

            <div class="mb-3 mx-auto" style="width: 80%">
              <div class="text-start">
                <label for="exampleInputEmail1" class="form-label"
                  >{{ $t("auth.signUp.bus_category") }}
                  <span class="text-primary">*</span></label
                >
              </div>
              <div class="select-box">
                <select
                  class="form-control"
                  :class="
                    selectedBusinessCatagoryId == 0
                      ? 'state-color__fg--grey2'
                      : ''
                  "
                  aria-label="form-select-sm example"
                  style="height: 50px"
                  v-model="selectedBusinessCatagoryId"
                >
                  <option value="0" disabled>
                    {{ $t("auth.signUp.bus_cat_placeholder") }}
                  </option>
                  <option
                    v-for="businessCategory in businessCategories"
                    v-bind:key="businessCategory.id_business_category"
                    :value="businessCategory.id_business_category"
                  >
                    {{ businessCategory.business_category_name }}
                  </option>
                </select>
                <div class="select-box-icon">
                  <img src="@/assets/icon/Scroll.svg" alt="" />
                </div>
                <p
                  v-if="errorBusinessCategory"
                  class="state-color__fg--error mt-2 mb-2"
                >
                  {{ errorBusinessCategory }}
                </p>
              </div>
              <button
                type="submit"
                class="btn btn-primary my-4"
                style="min-width: 100%; min-height: 25%"
              >
                {{ $t("auth.signUp.sign_up_btn") }}
              </button>
            </div>

            <div>
              <p class="text-center">
                {{ $t("auth.signUp.old_majhi") }}?
                <router-link to="/signin" class="state-color__fg--Info">{{
                  $t("auth.signUp.sing_in")
                }}</router-link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>

    <loading-spinner />
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import "mosha-vue-toastify/dist/style.css";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: { LoadingSpinner },
  props: ["phoneNo"],

  data() {
    return {
      image: null,
      url: null,
      phone: this.phoneNo,
      shopName: "",
      selectedBusinessCatagoryId: 0,
      deviceToken: "",
      errorShopLogo: "",
      errorShopName: "",
      errorBusinessCategory: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["businessCategories"]),
    ...mapGetters("language", ["getLanguage"]),

    majhiLandingBaseUrl() {
      return process.env.VUE_APP_FRONTED_BASE_URL;
    },
  },

  methods: {
    ...mapMutations("auth", ["SET_AUTH_OTP_ERROR", "SET_NUMBER_STATUS"]),
    ...mapActions("auth", ["businessCategoryRequest", "signUpRequest"]),

    uploadImage(event) {
      if (!event.target.files[0]) return;
      this.image = event.target.files[0];

      if (this.image.size > 1024 * 1021 * 6) {
        this.errorShopLogo = this.$t("auth.validation.logo");
        this.image = null;
        this.url = "";
        return;
      }

      this.errorShopLogo = "";
      this.url = URL.createObjectURL(this.image);
    },
    removeImage() {
      this.image = null;
      this.url = null;
    },
    async handleSubmit() {
      let storeNamePattern = /^[a-zA-Z0-9\s]+$/;

      if (!storeNamePattern.test(this.shopName)) {
        this.errorShopName = this.$t("auth.validation.bus_name");
        return;
      }

      if (this.selectedBusinessCatagoryId == 0) {
        this.errorBusinessCategory = this.$t("auth.validation.bus_category");
        return;
      }

      let vm = this;
      vm.$store.commit("setOverlayStatus", true); //loader open

      const formData = new FormData();
      formData.append("phone", vm.phone);
      formData.append("shop_name", vm.shopName);
      formData.append("business_category_id", vm.selectedBusinessCatagoryId);
      formData.append("device_token", vm.deviceToken);

      if (vm.image != null) {
        formData.append("shop_logo", vm.image);
      }

      let flag = await this.signUpRequest(formData);
      if (flag) {
        this.$router.push({
          name: "SignupSuccess",
          params: {
            isRegistrationSuccessful: true,
            shopName: vm.shopName,
            languageCode: this.getLanguage,
          },
        });
      }
    },

    async getCatagories() {
      await this.businessCategoryRequest();
    },
  },

  created() {
    if (
      this.phoneNo == undefined ||
      this.phoneNo == null ||
      this.phoneNo.length == 0
    ) {
      this.SET_NUMBER_STATUS(false);

      this.$router.push({
        name: "SignUp",
        params: { languageCode: this.getLanguage },
      });
      return;
    }
    this.phone = this.phoneNo;
    this.$store.commit("setOverlayStatus", false); //loader close
    this.getCatagories();
  },
};
</script>

<style scoped>
.signUpLogo {
  background-color: #e0e0e0;
}

.signup-company-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #e0e0e0;
}

.company-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #e0e0e0;
}

.company-logo img {
  border-radius: 50%;
}

.signup-page {
  background-color: #f3f6f9;
}

.signup-container {
  width: 60%;

  box-shadow: 0px 3px 12px #1d1d1d0d;
  display: flex;
  border-radius: 10px;
}

.left {
  width: 50%;
}

.left .large-image {
  width: 100%;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 5;
}

.select-box {
  position: relative;
}

.select-box-icon {
  position: absolute;
  right: 10px;
  top: 20%;
}

@media only screen and (max-width: 900px) {
  .left,
  .right {
    width: 100%;
  }

  .signup-container {
    flex-direction: column;
  }

  .left .large-image {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .signup-page {
    background-color: white;
  }
  .signup-container {
    width: 100%;
    min-height: 100vh;
    box-shadow: none;
  }

  .left {
    background-color: #f3f6f9;
  }

  .right {
    margin-top: -50px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0px 3px 30px #0000001f !important;
  }
}
</style>
