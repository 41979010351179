<template>
  <div
    class="overlay-loader d-flex align-items-center justify-content-center flex-column"
    v-if="$store.getters['Loader/getOverlayStatus']"
  >
    <atom-spinner :animation-duration="1500" :size="100" color="#3A86FF" />

    <!-- <h2>Loading ...</h2> -->
    <!-- <img src="../assets/double-loader.gif" alt="" /> -->
  </div>
</template>

<script>
import { AtomSpinner } from "epic-spinners";

export default {
  components: { AtomSpinner },
};
</script>

<style>
.overlay-loader {
  background-color: rgba(100, 100, 100, 0.5);

  z-index: 1070;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
}

/* .overlay-loader image {
  max-width: 100%;
} */
</style>
