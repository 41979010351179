<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div
            class="mt-3 d-flex align-items-center state-color__fg--grey page-heading-top-padding"
          >
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="font-weight-500 ms-3 mb-0 page-heading-text-size">
              {{ $t("manage.main.store_qr.title") }}
            </h4>
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <div
              class="bg-white qrcode-container common-border__radius my-4 py-4 d-flex justify-content-center"
            >
              <div
                class="border common-border__radius qrcode-container2nd my-auto p-3"
              >
                <div
                  class="common-border__radius qrcode-container3rd"
                  ref="printcontent"
                  id="print-qr"
                  style="background:white"
                >
                  <div class="qrcode-company-name">
                    <h4
                      class="text-center"
                      style="font-size:1.625rem !important"
                    >
                      {{ this.authUser.shop_name }}
                    </h4>
                  </div>
                  <div class="qrcode-image">
                    <div
                      v-if="this.authUser.shop_front_url"
                      class="text-center"
                    >
                      <qrcode-vue
                        :value="this.authUser.shop_front_url"
                        :size="size"
                        level="H"
                      />
                    </div>
                    <h5
                      style="width: 220px; font-size:1.375rem !important"
                      class="text-center mt-3"
                    >
                      {{ $t("manage.main.store_qr.message") }}
                    </h5>
                  </div>
                  <div class="qrcode-urlname">
                    <p class="m-0" style="font-size:0.9375rem !important">
                      {{ this.authUser.shop_front_url }}
                    </p>
                  </div>
                  <div class="qrcode-footer py-2 d-flex justify-content-center">
                    <img
                      :src="this.authUser.store_logo"
                      alt="Store Logo"
                      width="60"
                      height="60"
                      class="rounded"
                    />

                    <!-- <img
                      src="../../assets/icon/korean-mart.png"
                      alt="Store Logo"
                      width="60"
                      height="60"
                      class="rounded"
                    /> -->

                    <h6 class="m-1">{{ this.authUser.shop_name }}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="qr-code-btns d-flex justify-content-center pb-4">
              <button
                type="button"
                class="btn btn-outline-primary google_sans_medium mx-1 px-5 qr-button-padding br-8"
                @click="saveQRcode()"
              >
                {{ $t("manage.main.store_qr.save_btn") }}
              </button>

              <button
                type="button"
                class="btn btn-primary google_sans_medium mx-1 px-5 qr-button-padding br-8"
                @click="printDiv()"
              >
                {{ $t("manage.main.store_qr.print_btn") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
//import html2canvas from "html2canvas";
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";

import domtoimage from "dom-to-image";

export default {
  components: { SideBar, TopBar, LoadingSpinner, QrcodeVue },
  data() {
    return {
      size: 220,
      authUser: Object,
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    async saveQRcode() {
      // const el = this.$refs.printcontent;
      // const options = {
      //   type: "dataURL",
      // };
      // const printCanvas = await html2canvas(el, options);
      // const link = document.createElement("a");
      // link.setAttribute("download", "download.png");
      // link.setAttribute("crossOrigin", "Anonymous");
      // link.setAttribute(
      //   "href",
      //   printCanvas
      //     .toDataURL("image/png")
      //     .replace("image/png", "image/octet-stream")
      // );
      // link.click();
      var node = document.getElementById("print-qr");
      domtoimage
        .toPng(node, { cacheBust: true })
        .then(function(dataUrl) {
          var link = document.createElement("a");
          link.download = `my-store.jpeg`;
          link.href = dataUrl;
          link.click();
        })
        .catch(function(error) {
          console.error("oops, something went wrong!", error);
        });
    },

    async printDiv() {
      // var printContents = document.getElementById("print-qr").innerHTML;
      // var originalContents = document.body.innerHTML;

      // document.body.innerHTML = printContents;

      // window.print();

      // document.body.innerHTML = originalContents;

      // const el = this.$refs.printcontent;

      // const options = {
      //   type: "dataURL",
      // };

      // const printCanvas = await html2canvas(el, options);
      // let url = await printCanvas.toDataURL("image/png");

      var node = document.getElementById("print-qr");
      const data = await domtoimage.toPng(node, { cacheBust: true });

      var image = new Image();
      image.src = data;
      var w = window.open("about:blank");

      w.document.write(image.outerHTML);
      w.document.close();
    },
  },

  created() {
    let userObject = localStorage.getItem("majhiUser");
    this.authUser = JSON.parse(userObject);
    this.value = this.authUser.shop_front_url;
  },
};
</script>

<style scoped>
.qrcode-container {
  min-width: 350px;
  width: 40%;
}

.qrcode-container2nd {
  max-width: 80%;
  min-width: 350px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.qrcode-container3rd {
  min-width: 100%;
  min-height: 520px;
  border: 3px solid #3a86ff;
}

.qrcode-company-name {
  background-color: #3a86ff;
  min-height: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrcode-image {
  min-height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.qrcode-urlname {
  background-color: #3a86ff;
  color: white;
  text-align: center;
  padding: 2px;
}

.qrcode-footer {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-btns {
  width: 40%;
}

.br-8 {
  border-radius: 0.5rem !important;
}

.qr-button-padding {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
</style>
