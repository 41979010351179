<template>
  <div class="coupon-card-container common-border__radius">
    <div class="header" :class="{ deactivate: coupon.is_active == 0 }">
      <div class="d-flex justify-content-center align-items-center">
        <h5 class="m-0 text-white" :id="coupon.code">
          {{ coupon.code }}
        </h5>
        <!-- <img
          src="../../assets/icon/export.svg"
          alt="home"
          width="20"
          class="fs-5 export-coupon-button"
        /> -->
        <!-- <div class="export-coupon-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.204"
            height="14.204"
            viewBox="0 0 14.204 14.204"
          >
            <g id="export" transform="translate(0.1 0.1)">
              <g id="Group_53" data-name="Group 53" transform="translate(0)">
                <g id="Group_52" data-name="Group 52">
                  <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M223.144.98h2.585l-5.911,5.913a.49.49,0,1,0,.691.694l5.913-5.913V4.259a.49.49,0,1,0,.98,0V.491A.491.491,0,0,0,226.912,0h-3.768a.49.49,0,1,0,0,.98Z"
                    transform="translate(-213.399)"
                    fill="#ffff"
                    stroke="#ffff"
                    stroke-width="0.2"
                  />
                  <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M2.94,14h8.122A2.943,2.943,0,0,0,14,11.064V7.239a.49.49,0,0,0-.98,0v3.822a1.963,1.963,0,0,1-1.96,1.96H2.94a1.963,1.963,0,0,1-1.96-1.96V2.942A1.963,1.963,0,0,1,2.94.983H6.73A.491.491,0,0,0,6.73,0H2.94A2.943,2.943,0,0,0,0,2.94v8.122A2.945,2.945,0,0,0,2.94,14Z"
                    transform="translate(0)"
                    fill="#ffff"
                    stroke="#ffff"
                    stroke-width="0.2"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div> -->
      </div>
      <div class="d-flex justify-content-center align-items-center">
        {{ setCouponFlag(coupon.is_active) }}
        <Toggle
          v-model="couponActiveFlag"
          @change="updateCouponStatus(coupon.id_coupon)"
          class="my-toggle"
        />
        <div class="position-relative ms-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-three-dots-vertical text-white make-cursor"
            viewBox="0 0 16 16"
            @click="toggleCouponOptionsDivVisibility()"
          >
            <path
              d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
            />
          </svg>

          <div
            class="shadow position-absolute dots-dropdown__items common-border__radius-5 p-2 my-2"
            v-click-outside="onClickOutsideCouponOptionsDiv"
            v-if="couponOptionsDivVisibility"
          >
            <div class="m-2 pointer" @click="onEdit(coupon)">
              <img
                src="../../assets/icon/edit.svg"
                alt=""
                height="20"
                width="20"
                class="mb-1"
              /><span class="ms-2">{{
                $t("manage.main.coupon_card.edit")
              }}</span>
            </div>
            <div
              class="m-2 coupon-share-btn pointer"
              @click="copy(coupon.code)"
            >
              <img
                src="../../assets/icon/menu_share.svg"
                alt=""
                height="20"
                width="20"
                class="mb-1"
              /><span class="ms-2">{{
                $t("manage.main.coupon_card.share")
              }}</span>
            </div>
            <div class="m-2 pointer" @click="onDelete(coupon.id_coupon)">
              <img
                src="../../assets/icon/menu_delete.svg"
                alt=""
                height="17"
                width="17"
                class="mb-1"
              /><span class="ms-2">{{
                $t("manage.main.coupon_card.delete")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="d-flex justify-content-between">
        <p class="medium-text" v-if="coupon.discount_type === 'amount'">
          {{ $t("manage.main.coupon_card.amount") }}: <span>&#2547;</span
          >{{ coupon.discount_price }}
        </p>

        <p class="medium-text" v-if="coupon.discount_type === 'percent'">
          {{ $t("manage.main.coupon_card.amount") }}:
          {{ coupon.discount_price_percent }}
          <span>%</span>
        </p>
        <p class="medium-text">
          {{ $t("manage.main.coupon_card.min_order") }}: <span>&#2547;</span
          >{{ coupon.min_price }}
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="normal-text state-color__fg--grey1">
          {{ $t("manage.main.coupon_card.time_used") }}:
        </p>
        <p class="normal-text state-color__fg--grey1">
          {{ $t("manage.main.coupon_card.total_sale") }}:
        </p>
      </div>

      <div class="d-flex justify-content-between">
        <p class="coupon-price">{{ coupon.total_used }}</p>
        <p class="coupon-price">{{ coupon.total_sales }}</p>
      </div>
    </div>
  </div>

  <share-coupon-modal />
</template>

<script>
import { mapActions } from "vuex";
import Toggle from "@vueform/toggle";
import vClickOutside from "click-outside-vue3";
import ShareCouponModal from "./ShareCouponModal.vue";
import useClipboard from "vue-clipboard3";
import Toast, {
  errorBackground,
  successBackground,
} from "../../config/toastConfig";
import Swal from "sweetalert2";

export default {
  components: { Toggle, ShareCouponModal },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    coupon: Object,
  },

  data: function() {
    return {
      couponActiveFlag: true,
      couponOptionsDivVisibility: false,
    };
  },

  methods: {
    ...mapActions("coupon", ["deleteCoupon", "updateCouponStatusRequest"]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    //-----delete coupon ---------------------------------------
    async onDelete(id) {
      Swal.fire({
        title: this.$t("manage.main.delete_confirmation_message.title"),
        text: this.$t("manage.main.delete_confirmation_message.coupon"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "manage.main.delete_confirmation_message.delete_btn"
        ),
        cancelButtonText: this.$t(
          "manage.main.delete_confirmation_message.cancel_btn"
        ),
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.setOverlayStatus(true);
          await this.deleteCoupon(id);
          this.setOverlayStatus(false);
        }
      });
    },

    async copy(data) {
      const { toClipboard } = useClipboard();
      try {
        const codeElement = document.getElementById(`${data}`);
        await toClipboard(codeElement.innerText);
        Toast.fire({
          icon: "success",
          title: `${codeElement.innerText} ${this.$t(
            "manage.main.coupon_card.copy"
          )}`,
          background: successBackground,
        });
      } catch (e) {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.copy_link_error"),
          background: errorBackground,
        });
      }
    },

    onEdit(coupon) {
      this.$router.push({
        name: "UpdateCoupon",
        params: { id: coupon.id_coupon },
      });
    },

    async updateCouponStatus(id) {
      let data = {
        id_coupon: id,
        is_acitve: this.couponActiveFlag ? 1 : 0,
      };
      await this.updateCouponStatusRequest(data);
    },

    setCouponFlag(status) {
      if (status === 1) {
        this.couponActiveFlag = true;
      } else {
        this.couponActiveFlag = false;
      }
    },

    toggleCouponOptionsDivVisibility() {
      this.couponOptionsDivVisibility = !this.couponOptionsDivVisibility;
    },
    onClickOutsideCouponOptionsDiv() {
      this.couponOptionsDivVisibility = false;
    },
  },
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style>
.pointer {
  cursor: pointer;
}

.coupon-price {
  font-size: 40px !important;
  color: #000000;
}

.my-toggle .toggle {
  width: 40px !important;
  height: 21px !important;
}

.my-toggle .toggle-off,
.my-toggle .toggle-on {
  background: transparent !important;
  border: 1px solid #ffffff !important;
  border-radius: 12px !important;
}

.my-toggle .toggle-handle {
  height: 20px !important;
  width: 20px !important;
}

.coupon-card-container {
  min-width: 450px;
  height: 240px;
}

.coupon-card-container .header {
  background-color: #3a86ff;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.deactivate {
  background-color: #777777 !important;
}

.coupon-card-container .body {
  padding: 15px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.coupon-share-btn {
  border: none;
  background-color: white;
}

.export-coupon-button {
  width: 20px;
  margin-left: 7px;
}

:root {
  --toggle-width: 2rem;
  --toggle-height: 1rem;
  --toggle-bg-on: #3a86ff;
  --toggle-border-on: #e5e7eb;

  --toggle-bg-off: #3a86ff;
  --toggle-border-off: #e5e7eb;
}

@media only screen and (max-width: 700px) {
  .coupon-card-container {
    min-width: 350px;
    height: 240px;
  }
}
</style>
