<template>
  <div
    class="w-100 h-100 min-vh-100 d-flex justify-content-center align-items-center"
    :class="$style.global_container"
  >
    <div :class="$style.content_container">
      <div>
        <div class="w-100 d-flex justify-content-center">
          <img
            src="../assets/icon/onboarding-2.png"
            alt=""
            :class="$style.container_image"
          />
        </div>
        <h4 class="state-color__fg--grey text-center py-4">
          {{ $t("manage.main.store_address.title") }}
        </h4>
      </div>

      <div>
        <div class="row">
          <div class="col-md-6 py-2">
            <AppBaseSelectBox
              :optionsList="contryList"
              :isSelectOptionDisabled="true"
            ></AppBaseSelectBox>
          </div>

          <div class="col-md-6 py-2">
            <AppBaseSelectBox
              :optionsList="divisionList"
              @selectOptionsOnChange="handleDivisionChange"
            ></AppBaseSelectBox>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2">
            <AppBaseSelectBox
              :optionsList="cityList"
              @selectOptionsOnChange="handleCityChange"
            ></AppBaseSelectBox>
          </div>

          <div class="col-md-6 py-2">
            <AppBaseSelectBox
              :optionsList="areaList"
              @selectOptionsOnChange="handleAreaChange"
            ></AppBaseSelectBox>
          </div>
        </div>

        <div class="py-2">
          <textarea
            name=""
            id=""
            cols="5"
            rows="3"
            :placeholder="$t('manage.main.store_address.details_address')"
            class="w-100 p-3"
            :class="$style.address_text_area"
            v-model="state.shopAddress"
          ></textarea>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-center py-4">
        <AppBaseBlueBtn @click="submitAddress"
          ><template v-slot:btn_name>{{
            $t("product.category.direct_category_assign.submit_btn")
          }}</template>
        </AppBaseBlueBtn>
      </div>
      <div>
        <p class="text-center make-cursor" @click="skipToOnboarding3">
          {{ $t("manage.main.store_address.skip_btn") }}
        </p>
      </div>
    </div>

    <LoadingSpinner />
  </div>
</template>

<script setup>
import LoadingSpinner from "../components/LoadingSpinner.vue";
import AppBaseSelectBox from "../components/primary/AppBaseSelectBox.vue";
import AppBaseBlueBtn from "../components/primary/AppBaseBlueBtn.vue";

import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const state = reactive({
  idCountry: "",
  idDivision: "",
  idCity: "",
  idArea: "",
  shopAddress: "",
});
const store = useStore();
const router = useRouter();
const contryList = computed(
  () => store.getters["profileUpdate/addressCountryList"]
);
const divisionList = computed(
  () => store.getters["profileUpdate/addressDivisionList"]
);
const cityList = computed(() => store.getters["profileUpdate/addressCityList"]);
const areaList = computed(() => store.getters["profileUpdate/addressAreaList"]);

const handleDivisionChange = async (divisionId) => {
  state.idDivision = divisionId;
  await store.dispatch("profileUpdate/cityByDivisionRequest", state.idDivision);
  state.idCity = store.getters["profileUpdate/addressCityList"][0].id;
  await store.dispatch("profileUpdate/areaByCityRequest", state.idCity);
  state.idArea = store.getters["profileUpdate/addressAreaList"][0].id;
  store.dispatch("Loader/setOverlayStatus", false);
};

const handleCityChange = async (cityId) => {
  state.idCity = cityId;
  await store.dispatch("profileUpdate/areaByCityRequest", cityId);
  state.idArea = store.getters["profileUpdate/addressAreaList"][0].id;
  store.dispatch("Loader/setOverlayStatus", false);
};

const handleAreaChange = (areaId) => {
  state.idArea = areaId;
  store.dispatch("Loader/setOverlayStatus", false);
};

const submitAddress = () => {
  const formData = new FormData();
  formData.append("id_country", state.idCountry);
  formData.append("id_division", state.idDivision);
  formData.append("id_city", state.idCity);
  formData.append("id_area", state.idArea);
  formData.append("shop_address", state.shopAddress);

  let isProfileUpdated = store.dispatch(
    "profileUpdate/userProfileUpdate",
    formData
  );

  if (!isProfileUpdated) return;
  router.push({
    name: "OnBoarding3",
    params: {
      languageCode: store.getters["language/getLanguage"],
      isValidForOnBoarding: true,
    },
  });
};

const skipToOnboarding3 = () => {
  router.push({
    name: "OnBoarding3",
    params: {
      languageCode: store.getters["language/getLanguage"],
      isValidForOnBoarding: true,
    },
  });
};

onBeforeMount(async () => {
  store.dispatch("Loader/setOverlayStatus", true);
  await store.dispatch("profileUpdate/countryListRequest");
  await store.dispatch(
    "profileUpdate/divisionByCountryRequest",
    store.getters["profileUpdate/addressCountryList"][0].id
  );
  await store.dispatch(
    "profileUpdate/cityByDivisionRequest",
    store.getters["profileUpdate/addressDivisionList"][0].id
  );
  await store.dispatch(
    "profileUpdate/areaByCityRequest",
    store.getters["profileUpdate/addressCityList"][0].id
  );
  state.idCountry = store.getters["profileUpdate/addressCountryList"][0].id;
  state.idDivision = store.getters["profileUpdate/addressDivisionList"][0].id;
  state.idCity = store.getters["profileUpdate/addressCityList"][0].id;
  state.idArea = store.getters["profileUpdate/addressAreaList"][0].id;
  store.dispatch("Loader/setOverlayStatus", false);
});
</script>

<style module>
.global_container {
  background-color: #f3f6f9;
}

.content_container {
  width: 60%;
  background-color: white;
  padding: 2rem 9rem;
  border-radius: 0.75rem;
  box-shadow: 0px 3px 12px #1d1d1d0d;
}

.container_image {
  width: 35%;
}

.address_text_area {
  resize: none;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
}

@media only screen and (max-width: 576px) {
  .content_container {
    width: 100%;
    padding: 2rem 2rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .content_container {
    width: 100%;
    padding: 2rem 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .content_container {
    width: 100%;
    padding: 2rem 2rem;
  }
}
</style>
