<template>
  <div class="bg-white common-border__radius mx-auto p-4">
    <h5>{{ $t("appearance.main.footer.highlights") }}</h5>

    <div class="my-3">
      <div class="d-flex justify-content-between my-2 support">
        <div class="form-group my-3 mx-1 wd-50">
          <label for="inputcouponcode" class="state-color__fg--grey"
            >{{ $t("appearance.main.footer.deliver_label") }}
          </label>
          <input
            type="email"
            class="form-control  mt-2"
            style="height: 50px;"
            placeholder="3-5 days"
            autocomplete="off"
            v-model="socialSupport.delivery_duration"
          />
        </div>
        <div class="form-group my-3 mx-1 wd-50">
          <label for="inputcouponcode" class="state-color__fg--grey"
            >{{ $t("appearance.main.footer.customer_email_label") }}
          </label>
          <input
            type="text"
            class="form-control  mt-2"
            style="height: 50px;"
            placeholder="demo@email.com"
            autocomplete="off"
            v-model="socialSupport.support_email"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white common-border__radius my-4 mx-auto p-4">
    <h5>{{ $t("appearance.main.footer.social.title") }}</h5>
    <div class="my-3">
      <div class="form-group mt-4 mb-3">
        <label for="inputcouponcode" class="state-color__fg--grey"
          >{{ $t("appearance.main.footer.social.facebook") }}
        </label>
        <input
          class="form-control form-control-lg my-2"
          type="text"
          placeholder="www.facebook.com/"
          v-model="socialSupport.facebook_url"
        />
      </div>
      <div class="form-group my-3">
        <label for="inputcouponcode" class="state-color__fg--grey">{{
          $t("appearance.main.footer.social.youtube")
        }}</label>
        <input
          class="form-control form-control-lg my-2"
          type="text"
          placeholder="www.instagram.com/"
          v-model="socialSupport.instagram_url"
        />
      </div>
      <div class="form-group my-3">
        <label for="inputcouponcode" class="state-color__fg--grey">{{
          $t("appearance.main.footer.social.instagram")
        }}</label>
        <input
          class="form-control form-control-lg my-2"
          type="text"
          placeholder="www.youtube.com/"
          v-model="socialSupport.youtube_url"
        />
      </div>
    </div>
  </div>
  <div class="width-70 my-4 mx-auto">
    <button
      type="button"
      class="btn btn-primary float-end px-5"
      style="padding-top: 10px; padding-bottom: 10px"
      @click="updateSocialSupportData"
    >
      {{ $t("appearance.main.footer.social.button") }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: mapGetters("socialMedia", ["socialSupport"]),

  methods: {
    ...mapActions("socialMedia", [
      "getSocialSupportData",
      "updateSocialSupport",
    ]),

    async updateSocialSupportData() {
      await this.updateSocialSupport(this.socialSupport);
    },
  },

  async created() {
    await this.getSocialSupportData();
  },
};
</script>

<style scoped>
.wd-50 {
  width: 50%;
}

@media only screen and (max-width: 1250px) {
  .wd-50 {
    width: 100%;
  }
}
</style>
