<template>
  <div class="w-100 d-flex justify-content-between align-items-center">
    <div :class="`${$style.left_content}`">
      <h6 class="medium-text state-color__fg--grey mb-2">{{ task }}</h6>
      <p class="state-color__fg--grey1 small-text mb-0">
        {{ taskDescription }}
      </p>
    </div>

    <div>
      <div
        v-if="isStepCompleted"
        :class="
          `${$style.step_completed} d-flex justify-content-center align-items-center`
        "
      >
        <img
          src="../../../../../assets/icon/white-check.svg"
          alt=""
          width="15"
          height="11"
        />
      </div>
      <router-link
        :to="redirectUrl"
        v-else
        :class="`${$style.add_btn} text-decoration-none`"
        >Add</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: { type: String },
    taskDescription: { type: String },
    isStepCompleted: { type: Boolean, default: false },
    redirectUrl: { type: String, default: "/" },
  },
};
</script>

<style module>
.left_content {
  max-width: 300px;
}

.add_btn {
  border: 1px solid #3a86ff;
  border-radius: 8px;
  background-color: white;
  color: #3a86ff;
  padding: 0.5rem 1.25rem;
}

.step_completed {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: #3a86ff;
}
</style>
