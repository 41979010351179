<template>
  <div class="order-order-card-container">
    <div
      v-for="order in allOrders"
      :key="order.id_order"
      class="order-card-shadow card border-raduis__div"
    >
      <div
        class="card-body"
        style="cursor: pointer"
        @click="showOrderDetails(order.id_order)"
      >
        <div class="w-100 d-flex justify-content-between">
          <p class="state-color__fg--grey1 mb-2">
            {{ $t("home.order.card.order_id") }}
          </p>
          <div>
            <p
              class="mb-1 status-badge shipped-badge"
              v-if="order.id_order_status == 4"
            >
              Shipped
            </p>
            <p
              class="mb-1 status-badge pending-badge"
              v-else-if="order.id_order_status == 1"
            >
              Pending
            </p>
            <p
              class="mb-1 status-badge accepted-badge"
              v-else-if="order.id_order_status == 2"
            >
              Accepted
            </p>

            <p
              class="mb-1 status-badge cancelled-badge"
              v-else-if="order.id_order_status == 3"
            >
              Cancelled
            </p>
            <p
              class="mb-1 status-badge instore-badge"
              v-else-if="order.id_order_status == 5"
            >
              Instore Pickup
            </p>
            <p
              class="mb-1 status-badge delivered-badge"
              v-else-if="order.id_order_status == 6"
            >
              Delivered
            </p>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-between">
          <h5>{{ order.id_order }}</h5>
          <h5><b>&#2547;</b>{{ parseFloat(order.total_pay_amount) }}</h5>
        </div>

        <div class="w-100 d-flex justify-content-between">
          <p class="" style="color: #707070">
            {{ calculateTime(order.order_date) }}
          </p>
          <p class="state-color__fg--grey1">
            {{ order.total_item }} {{ $t("home.order.card.items") }}
          </p>
        </div>

        <div class="w-100 d-flex justify-content-between">
          <p class="state-color__fg--grey1">
            {{ $t("home.order.card.contact") }}:
            <span class="text-dark" style="font-weight: bolder">{{
              order.customer_contact_no
            }}</span>
          </p>
          <p class="state-color__fg--grey1">
            {{ $t("home.order.card.payment") }}:
            <span
              :class="order.payment_type"
              class="google_sans_medium"
              style="color:#3A86FF"
              >{{ order.payment_type }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="allOrders == null">
    <div class="text-center">
      <img src="../../assets/icon/no-order-found.svg" alt="" />
      <h5 style="color: #333333;">{{ $t("home.order.no_order_found") }}</h5>
    </div>
  </div>

  <pagination-component
    v-if="allOrders"
    v-bind:lastPage="lastPage"
    v-bind:currentPage="currentPage"
    v-on:requestDataByPageNumber="getDataByPage($event)"
  />
</template>

<script>
import PaginationComponent from "../PaginationComponent.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: { PaginationComponent },
  name: "OrderCard",
  emits: ["requestDataByPageNumber"],
  props: ["totalPage", "lastPage", "allOrders"],

  computed: mapGetters("Order", ["currentPage"]),

  // data: function() {
  //   return {
  //     currentPage: 1,
  //   };
  // },

  methods: {
    ...mapMutations("Order", ["SET_ACTIVE_MENU", "SET_CURRENT_PAGE"]),
    getDataByPage(pageNumber) {
      if (pageNumber == this.currentPage) {
        return;
      }
      this.SET_CURRENT_PAGE(pageNumber);
      this.$store.commit("setOverlayStatus", true); //loader open
      this.$emit("requestDataByPageNumber", pageNumber);
    },

    calculateTime(data) {
      let formateData = data?.split(" ")[0]?.split("-");

      var date = new Date(
        formateData[0],
        Number(formateData[1]) - 1,
        formateData[2]
      );

      let year = date.getFullYear();
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let month = date.getMonth();
      var day = date.getDate();

      //return `${months[month + 1]} ${day},${year}`;
      let dateArray = data.split(" ");
      let time = `${dateArray[1]} ${dateArray[2]}`;
      return `${day} ${months[month]} ${year}, ${time}`;
    },

    showOrderDetails(orderId) {
      this.$router.push({
        name: "OrderDetails",
        params: { orderId: orderId },
      });
    },

    currentPagereset() {
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.order-status {
  border-radius: 20px;
  max-width: 60%;
}

.border-raduis__div {
  border-radius: 10px !important;
}

.min-width__100 {
  min-width: 100% !important;
}

.border-solid__info {
  border: 1px solid #3a86ff !important;
}

.status-badge {
  border-radius: 34px;
  letter-spacing: 0.03px;
  font-size: 0.875rem;
  padding: 0.2rem 0.55rem;
}

.instore-badge {
  background: rgba(151, 170, 4, 0.16);
  color: rgba(151, 170, 4, 1);
}

.pending-badge {
  background: rgba(255, 147, 0, 0.16);
  color: rgba(255, 147, 0, 1);
}

.accepted-badge {
  background: rgba(58, 134, 255, 0.16);
  color: rgba(58, 134, 255, 1);
}

.delivered-badge {
  background: rgba(168, 77, 245, 0.16);
  color: rgba(168, 77, 245, 1);
}

.cancelled-badge {
  background: rgba(238, 81, 83, 0.16);
  color: rgba(238, 81, 83, 1);
}

.shipped-badge {
  background: rgba(45, 192, 107, 0.16);
  color: rgba(45, 192, 107, 1);
}

.order-card-shadow {
  box-shadow: 0px 3px 12px #1d1d1d0d;
}
</style>
