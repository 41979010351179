import Repository from "../../api/RepositoryFactory";
import Toast, { successBackground } from "../../config/toastConfig";
const Banner = Repository.get("Banner");

const { getExistingBanner, uploadBanner, deleteExisting } = Banner;

const state = {
  banners: [],
};

const getters = {
  getExBanners: (state) => state.banners,
};

const mutations = {
  SET_BANNERS: (state, data) => {
    state.banners = data;
  },

  REMOVE_BANNER: (state, data) => {
    state.banners = state.banners.filter(
      (banner) => banner.image_path !== data
    );
  },
};

const actions = {
  async getBanners({ commit }) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getExistingBanner();
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.data) {
        commit("SET_BANNERS", response.data);
      }

      if (response.data === null) {
        commit("SET_BANNERS", []);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async uploadBanner({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await uploadBanner(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async deleteBanner({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await deleteExisting(data);
      // commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });

        commit("REMOVE_BANNER", response.data);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
