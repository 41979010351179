<template>
  <div class="d-flex align-items-center">
    <img
      src="../../assets/icon/back_btn_image.svg"
      alt="back"
      class="make-cursor"
      height="25"
      width="20"
      @click="redirectToPreviousPage"
    />
    <h4 class="mb-0" :class="$style.padding_left">
      <slot name="btn_placeholder"></slot>
    </h4>
  </div>
</template>

<script setup>
import { defineEmits } from "vue"

const emit = defineEmits(["redirectToPreviousPage"])
const redirectToPreviousPage = () => {
  emit("redirectToPreviousPage")
}
</script>

<style module>
.padding_left {
  padding-left: 1.25rem;
  color: #333333;
}
</style>
