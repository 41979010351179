<template>
  <nav
    class="d-flex justify-content-between static-top top-bar-padding py-2 w-100"
  >
    <div class="d-flex justify-content-center align-items-center">
      <img
        src="../../assets/sidebar/Menu_burger@2x.png"
        width="20"
        class="sidebar-toggle__btn"
        @click="SideBarVisibility()"
      />

      <p
        class="ms-2 my-auto state-color__fg--grey1 mobile-display__none
"
      >
        {{ $t("home.main.topBar.support") }}:
        <span class="text-dark google_sans_medium">{{
          chatWithUsSupportNumber
        }}</span>
      </p>
    </div>

    <div class="navbar">
      <ul class="list-unstyled d-flex my-auto">
        <li class="mx-1 mobile-display__none">
          <a
            target="_blank"
            :href="shopUrl"
            class="nav-link state-color__fg--Info google_sans_medium small-text1"
            aria-current="page"
            >{{ $t("home.main.topBar.view") }} {{ authUser.shop_name }}
            <img
              src="../../assets/icon/export.svg"
              alt="home"
              width="15"
              height="15"
              class="fs-5"
              style="margin-left:10px"
          /></a>
        </li>
        <li class="mx-1" style="margin-right:8px">
          <language-dropdown :fgColor="'#333333'" />
        </li>
        <li class="mx-1 notification-container">
          <div
            class="h-100 border common-border__radius-8 make-cursor p-2"
            @click="handleNotificationModal"
            style="padding-left:5px !important;padding-right:5px !important"
          >
            <img
              v-if="getUnReadNotification === 0"
              src="../../assets/icon/notification@2x.png"
              alt="home"
              width="20"
              height="20"
              class=""
            />

            <img
              v-if="getUnReadNotification !== 0"
              src="../../assets/icon/notification-active-blue.svg"
              alt="home"
              width="20"
              height="20"
              class=""
            />
          </div>

          <div v-if="isNotificationModalOpen" class="notification-dropdown">
            <notification-modal />
            <div v-if="notifications.length" style="padding:15px">
              <hr />
              <router-link
                :to="{
                  name: 'Notification',
                  params: { languageCode: getLanguage },
                }"
                class="nav-link px-0 align-middle"
              >
                <span class=" ml-2 text-primary">{{
                  $t("home.notification.all")
                }}</span>
              </router-link>
            </div>
          </div>
        </li>
        <li
          class="mx-1 d-flex align-items-center"
          style="margin-left:20px !important"
        >
          <button
            class="btn btn-secondary rounded-circle p-0"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              :src="getShopLogoUrl"
              alt=""
              width="35"
              height="35"
              class="rounded-circle"
            />
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end p-0"
            aria-labelledby="navbarDropdown"
            style="min-width: 250px; border-radius: 10px !important"
          >
            <li
              class="
                nav-image__profile
                d-flex
                align-items-center
                justify-content-start
              "
            >
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  flex-column
                  rounded-circle
                  ms-3
                "
              >
                <img
                  :src="getShopLogoUrl"
                  alt=""
                  width="35"
                  height="35"
                  class="rounded-circle mr-0 pr-0"
                />
              </div>
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  text-white
                  mx-2
                "
              >
                <div class="">
                  <p class="small-text1 font-weight-500 mb-1">
                    Hello, {{ authUser.shop_name }}
                  </p>
                  <p class="m-0" style="font-size: 0.75rem">
                    {{ authUser.phone }}
                  </p>
                </div>
              </div>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'EditProfile',
                  params: { languageCode: getLanguage },
                }"
                class="nav-link px-0 align-middle"
              >
                <img
                  src="../../assets/icon/profile.svg"
                  alt="home"
                  width="15"
                  class="mx-3 mb-1"
                />
                <span class="text-dark">{{
                  $t("home.main.topBar.profile.account")
                }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Settings',
                  params: { languageCode: getLanguage },
                }"
                class="nav-link px-0 align-middle"
              >
                <img
                  src="../../assets/icon/top-setting-setting.svg"
                  alt="home"
                  width="15"
                  class="mx-3 mb-1"
                />
                <span class="text-dark">{{
                  $t("home.main.topBar.profile.setting")
                }}</span>
              </router-link>
            </li>
            <li
              class="nav-link px-0 align-middle pt-3"
              style="border-top: 1px solid #ebebeb; cursor: pointer"
              @click="handleSignOutRequest()"
            >
              <img
                src="../../assets/icon/signout.svg"
                alt="home"
                width="15"
                class="mx-3 mb-1"
              />
              <span class="text-dark">{{
                $t("home.main.topBar.profile.signout")
              }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import languageDropdown from "../library/language-dropdown.vue";
import NotificationModal from "../../components/notification/NotificationModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { languageDropdown, NotificationModal },

  data: function() {
    return {
      authUser: Object,
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
    ...mapGetters("auth", ["getUser", "getShopLogoUrl"]),
    ...mapGetters("settings", ["chatWithUsSupportNumber"]),
    ...mapGetters("notification", ["getUnReadNotification"]),

    isNotificationModalOpen() {
      return this.$store.getters["notification/getModalStatus"];
    },

    notifications() {
      return this.$store.getters[`notification/allNotification`].slice(0, 4);
    },

    shopUrl() {
      if (this.getLanguage == "bn") {
        return this.authUser.shop_front_url.replace("/en/", "/bn/");
      } else return this.authUser.shop_front_url;
    },
  },

  methods: {
    ...mapActions("notification", ["getAllNotification"]),
    ...mapActions("auth", ["signOutRequest"]),

    async handleSignOutRequest() {
      await this.signOutRequest();
    },

    SideBarVisibility() {
      this.$store.commit(
        "setSidebarVisibility",
        !this.$store.getters["getSidebarVisibility"]
      );
    },

    extractUser() {
      let userObject = localStorage.getItem("majhiUser");
      this.authUser = JSON.parse(userObject);
    },

    async handleNotificationModal() {
      this.$store.commit(
        "notification/changeModalStatus",
        !this.isNotificationModalOpen
      );
      if (this.$store.getters[`notification/getModalStatus`]) {
        let sellerKey = JSON.parse(localStorage.getItem("majhiUser"))
          .seller_key;
        let data = {
          sellerKey,
          page: 1,
        };

        await this.getAllNotification(data);
      }
    },
  },
  created() {
    this.extractUser();
  },
};
</script>

<style>
.top-bar-padding {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.nav-item {
  margin: 6px !important;
}
.dropdown-toggle::after {
  display: none !important;
}

ul.dropdown-menu {
  position: absolute;
  min-width: 100px;
  max-width: 100px;
}

.notification-container {
  position: relative;
}

.notification-dropdown {
  position: absolute;
  top: 50px;
  right: 0px;
  background: #ffff;
  border-radius: 10px;
  /* padding: 15px; */
  width: 400px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.082);
  z-index: 10;
}

.notification-dropdown .heading {
  /* font-size: 17px; */
  font-size: 1.0625rem !important;
  color: #1d1d1d;
  font-weight: 600;
}

.notification-dropdown .new {
  background: #eaf2ff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 7px;
  /* font-size: 10px; */
  font-size: 0.625rem !important;
  color: #3a86ff;
}

@media screen and (min-width: 992px) {
  .sidebar-toggle__btn {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .mobile-display__none {
    display: none;
  }

  ul.dropdown-menu {
    min-width: 50px;
    max-width: 50px;
  }

  .notification-dropdown {
    position: fixed;
    width: 94%;
    margin-top: 10px;
    margin-right: 3%;
  }
}
</style>
