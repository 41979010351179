import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getAllOrder(url) {
    return Client({
      url,
      method: "GET",
    });
  },

  getOrderByStatus(url) {
    return Client({
      url,
      method: "GET",
    });
  },

  getAvailableStatus() {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_order_status_list/${language}`,
      method: "GET",
    });
  },

  getOrderDetails(orderId) {
    return Client({
      url: `seller_order_details/en/${orderId}`,
      method: "GET",
    });
  },

  updateOrderStatus(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_order_update/${language}`,
      method: "PUT",
      data,
    });
  },

  generateInvoice(id) {
    return Client({
      url: `/generate_invoice/${id}`,
      method: "GET",
    });
  },

  updateOrderQuantity(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_order_qty_update/${language}`,
      method: "PUT",
      data,
    });
  },
};
