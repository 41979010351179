<template>
  <div
    class="p-4 mb-5 bg-white border-raduis__div home-card__width graph-box-shadow"
  >
    <div class="d-flex justify-content-between" style="margin:10px">
      <div>
        <p class="graph-report">
          {{ $t(leftHeader) }}
        </p>
        <h5
          class="large-text font-weight-600 graph-report-value"
          v-if="totalSales"
        >
          {{ totalSales[0].total_pay_amount }} {{ $t(payAmountUnit) }}
        </h5>
      </div>
      <div>
        <p class="graph-report">
          {{ $t(rightHeader) }}
        </p>
        <h5
          class="large-text font-weight-600 float-end graph-report-value"
          v-if="totalSales"
        >
          {{ totalSales[0].total_order }}
        </h5>
      </div>
    </div>
    <apexchart
      type="bar"
      height="250"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "VerticalChart",
  props: [
    "verticalChartColor",
    "leftHeader",
    "rightHeader",
    "salesInfo",
    "graphCategoriesName",
    "graphData",
    "totalSales",
    "payAmountUnit",
  ],

  computed: {
    series() {
      return [
        {
          name: "Servings",
          data: Object.values(this.graphData),
        },
      ]
    },

    chartOptions() {
      return {
        chart: {
          height: 250,
          type: "bar",

          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "20%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
        },

        grid: {
          row: {
            colors: ["#fff"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: Object.values(this.graphCategoriesName),

          // tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        colors: [this.verticalChartColor],
      }
    },
  },

  data: function() {
    return {}
  },
}
</script>

<style scoped>
.border-raduis__div {
  border-radius: 10px !important;
}

.graph-report {
  font-size: 14px;
  color: #777777;

  font-size: 13px;
  margin-top: -8px !important;
}

.graph-box-shadow {
  box-shadow: 0px 3px 12px #1d1d1d0d;
}

.graph-report-value {
  font-size: 18px;
  margin-top: -8px !important;
}
</style>

<!-- svg export option hide -->
<style>
.apexcharts-menu-item.exportSVG {
  display: none;
}
</style>
