<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div
          class="m-0 catageries-container app_container"
          style="padding-left:32px"
        >
          <div class="mt-3 state-color__fg--grey product-title-container">
            <h4 class="product-title-text">{{ $t("product.main.title") }}</h4>
          </div>
          <div
            class="d-flex flex-column-reverse flex-lg-row flex-md-column justify-content-between align-items-center my-1"
          >
            <product-search
              v-bind:AllAvailableProductCategories="
                getAllProductCategoriesUnderBusinessCategory.product_assign_categories
              "
              v-on:requestSearchProductByCategory="requestSearchData($event)"
            />
          </div>
          <div class="bg-white common-border__radius">
            <menu-bar />
          </div>

          <div class="bg-white my-4" style="border-radius:10px !important">
            <product-view
              v-bind:filteredProducts="filteredProducts"
              v-bind:totalPage="totalPage"
              v-bind:lastPage="lastPage"
              :searchField="searchField"
              v-on:requestDataByPageNumber="requestDataByPageNumber($event)"
            />
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import MenuBar from "../../components/product/MenuBar.vue";
import ProductSearch from "../../components/product/productSearch.vue";
import ProductView from "../../components/product/productView.vue";

//import axios from "axios";

export default {
  components: {
    MenuBar,
    LoadingSpinner,
    SideBar,
    ProductSearch,
    TopBar,
    ProductView,
  },
  data: function() {
    return {
      currentPage: 1,
      searchCategory: "",
      searchField: "",
      businessCategoryId: 0,
    };
  },

  computed: {
    ...mapGetters("product", [
      "filteredProducts",
      "allProducts",
      "totalPage",
      "lastPage",
      "AllAvailableProductCategories",
    ]),
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("productCategories", [
      "getAllProductCategoriesUnderBusinessCategory",
    ]),
  },

  methods: {
    ...mapActions("product", ["getAllProduct", "getAllCategories"]),
    ...mapActions("productCategories", ["getAllCategoryUnderBusinessCategory"]),

    requestDataByPageNumber(pageNumber) {
      this.currentPage = pageNumber;

      let url =
        "/seller_products/en/" +
        pageNumber +
        "?cat=" +
        this.searchCategory +
        "&search=" +
        this.searchField;

      this.getAllProduct(url);
      //this.searchProductByCategory(this.searchCategory, pageNumber);
    },

    requestSearchData(searchFields) {
      this.searchField = searchFields.inputProdcutSearch;
      this.searchCategory = searchFields.checkedCategories;

      let url =
        "/seller_products/en/1" +
        "?cat=" +
        searchFields.checkedCategories +
        "&search=" +
        searchFields.inputProdcutSearch;

      this.getAllProduct(url);
    },
  },

  created() {
    this.getAllProduct("/seller_products/en/1");
    // this.getAllCategories();
    this.getAllCategoryUnderBusinessCategory({
      idBusinessCategory: this.getUser.business_category_id,
    });
  },
};
</script>

<style>
.catageries-container {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #f3f6f9;
  min-height: 95vh;
}

.product-title-text {
  font-size: 30px !important;
}

.product-title-container {
  padding-top: 35px !important;
}

@media only screen and (max-width: 700px) {
  .product-title-container {
    padding-left: 1rem !important;
  }
}
</style>
