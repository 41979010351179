<template>
  <div class="pdf-container">
    <div class="content">
      <vue-pdf-embed :source="url" />
    </div>
    <loading-spinner />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VuePdfEmbed from "vue-pdf-embed";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
export default {
  data() {
    return {
      url: "",
    };
  },

  components: {
    VuePdfEmbed,
    LoadingSpinner,
  },

  async created() {
    let id = this.$route.params.id;
    if (id) {
      await this.generateInvoice(id);
      let data = this.$store.getters["Order/getInvoiceLink"];

      this.url = data;
    }
  },

  methods: {
    ...mapActions("Order", ["generateInvoice"]),
  },
};
</script>

<style>
.pdf-container {
  width: 100%;
  background: gray;
}

.content {
  margin: auto;
  width: 750px;
  box-shadow: 2px 2px 10px black;
}
</style>
