<template>
  <div>
    <FinishStoreCard />
  </div>
  <div :class="$style.margin">
    <div
      :class="
        `${$style.video_card_holder} d-flex justify-content-between flex-wrap`
      "
    >
      <div v-for="video in salesDetails?.video_content" :key="video.image">
        <VideoCard
          :title="video.title"
          :description="video.description"
          :imgUrl="video.image"
          :videoLink="video.link"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FinishStoreCard from "./finish-store-card/index.vue";
import VideoCard from "./video-card/index.vue";

export default {
  components: {
    FinishStoreCard,
    VideoCard,
  },

  computed: {
    ...mapGetters("graph", ["salesDetails"]),
  },
};
</script>

<style module>
.margin {
  margin-bottom: 6.9rem;
}
.video_card_holder {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .video_card_holder {
    margin: 1.25rem 0.938rem 0 0.938rem;
  }
}
</style>
