import Repository from "../../api/RepositoryFactory";
const Settings = Repository.get("settings");
const Auth = Repository.get("Auth");
const { toogleWhatsAppChatSupport } = Settings;
const { otpValidate } = Auth;
import Toast, {
  errorBackground,
  successBackground,
} from "../../config/toastConfig";

export default {
  namespaced: true,

  state: {
    isOtpValid: false,
    whatsappSupportStatus: "",
  },

  getters: {
    isOtpValid: (state) => state.isOtpValid,
    getWhatsappSupportStatus: (state) => state.whatsappSupportStatus,

    // constants
    chatWithUsSupportNumber: () => "+880 1322908231",
  },

  mutations: {
    UPDATE_OTP_STATUS(state, data) {
      state.isOtpValid = data;
    },

    UPDATE_WHATSAPP_SUPPORT_STATUS(state, data) {
      state.whatsappSupportStatus = data;
    },
  },

  actions: {
    async updateWhatsAppChatSupport({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await toogleWhatsAppChatSupport(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          let data = response.data.whatsapp_chat;
          if (data === 2) {
            if (document.getElementById("whatsappChatModalBtn")) {
              document.getElementById("whatsappChatModalBtn").click();
            }
            return;
          }

          const userObject = localStorage.getItem("majhiUser");
          let authUser = JSON.parse(userObject);
          let user = {
            ...authUser,
            whatsapp_chat: data,
          };
          localStorage.setItem("majhiUser", JSON.stringify(user));
          commit("UPDATE_WHATSAPP_SUPPORT_STATUS", data);
          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        commit("UPDATE_WHATSAPP_SUPPORT_STATUS", 0);
        Toast.fire({
          icon: "error",
          title: "Fail to update",
          background: errorBackground,
        });
      }
    },

    async otpValidateRequest({ commit }, data) {
      try {
        const response = await otpValidate(data);
        if (response.success) {
          commit("UPDATE_OTP_STATUS", true);
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: error.response.data,
          background: errorBackground,
        });

        commit("UPDATE_OTP_STATUS", false);
      }
    },
  },
};
