<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div class="m-3 d-flex page-heading-top-padding">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="ms-3 my-0 page-heading-text-size">
              {{ $t("manage.socialMedia.title") }}
            </h4>
          </div>

          <div class="bg-white common-border__radius width-60 my-4 mx-auto p-4">
            <h5>{{ $t("manage.socialMedia.customer_support") }}</h5>

            <div class="my-3">
              <div
                class="d-flex flex-wrap justify-content-between my-2 support"
              >
                <div class="form-group width-48 my-1">
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >{{ $t("manage.socialMedia.delivery_time.title") }}
                  </label>
                  <select
                    class="form-select normal-text mb-3 mt-2"
                    style="height: 50px"
                    aria-label=".form-select-lg example"
                    v-model="socialSupport.delivery_duration"
                    @input="enableUpdateBtn()"
                  >
                    <option
                      selected
                      v-if="socialSupport.delivery_duration"
                      :value="socialSupport.delivery_duration"
                    >
                      {{ socialSupport.delivery_duration }}
                    </option>
                    <option
                      v-if="socialSupport.delivery_duration !== '1-7 days'"
                      value="1-7 days"
                      >{{
                        $t("manage.socialMedia.delivery_time.options.o1")
                      }}</option
                    >
                    <option
                      v-if="socialSupport.delivery_duration !== '8-15 days'"
                      value="8-15 days"
                      >{{
                        $t("manage.socialMedia.delivery_time.options.o2")
                      }}</option
                    >
                    <option
                      v-if="
                        socialSupport.delivery_duration !== 'More than 2 weeks'
                      "
                      value="More than 2 weeks"
                      >{{
                        $t("manage.socialMedia.delivery_time.options.o3")
                      }}</option
                    >
                  </select>
                </div>
                <div class="form-group width-48 my-1">
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >{{ $t("manage.socialMedia.customer_email") }}
                  </label>
                  <input
                    type="email"
                    class="form-control mt-2"
                    style="height: 50px"
                    :placeholder="
                      $t('manage.socialMedia.customer_email_placeholder')
                    "
                    autocomplete="off"
                    v-model="socialSupport.support_email"
                    @input="enableUpdateBtn()"
                  />
                </div>
                <div class="form-group width-48 my-1">
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >{{ $t("manage.socialMedia.customer_phone") }}
                  </label>
                  <input
                    type="number"
                    class="form-control mt-2"
                    style="height:50px"
                    :placeholder="
                      $t('manage.socialMedia.customer_phone_placeholder')
                    "
                    autocomplete="off"
                    v-model="socialSupport.support_phone"
                    @click="
                      openPhoneModal(
                        'manage.socialMedia.add_customer_support',
                        `manage.socialMedia.customer_phone`,
                        'socialSupport'
                      )
                    "
                    @mousedown="disableChange($event)"
                  />
                  <small
                    class="text-primary"
                    role="button"
                    v-if="socialSupport.support_phone"
                    @click="
                      socialSupport.support_phone = '';
                      enableUpdateBtn();
                    "
                    >{{ $t("manage.socialMedia.clear_number") }}</small
                  >
                </div>

                <div class="form-group width-48 my-1">
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >{{ $t("manage.socialMedia.customer_whatsapp") }}
                  </label>
                  <input
                    type="number"
                    class="form-control mt-2"
                    style="height: 50px"
                    :placeholder="
                      $t('manage.socialMedia.customer_whatsapp_placeholder')
                    "
                    autocomplete="off"
                    v-model="socialSupport.whatsapp_phone"
                    @change="() => ''"
                    @click="
                      openPhoneModal(
                        'manage.socialMedia.add_whatsapp',
                        'manage.socialMedia.customer_whatsapp',
                        'whatsapp'
                      )
                    "
                    @mousedown="disableChange($event)"
                  />

                  <small
                    class="text-primary"
                    role="button"
                    v-if="socialSupport.whatsapp_phone"
                    @click="
                      socialSupport.whatsapp_phone = '';
                      enableUpdateBtn();
                    "
                    >{{ $t("manage.socialMedia.clear_number") }}</small
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white common-border__radius width-60 my-4 mx-auto p-4">
            <h5>{{ $t("manage.socialMedia.social.title") }}</h5>
            <div class="my-3">
              <div class="form-group mt-4 mb-3">
                <label for="inputcouponcode" class="state-color__fg--grey"
                  >{{ $t("manage.socialMedia.social.facebook") }}
                </label>
                <input
                  class="form-control form-control-lg my-2"
                  type="text"
                  placeholder="www.facebook.com/"
                  v-model="socialSupport.facebook_url"
                  @input="enableUpdateBtn()"
                />
              </div>
              <div class="form-group my-3">
                <label for="inputcouponcode" class="state-color__fg--grey">{{
                  $t("manage.socialMedia.social.instagram")
                }}</label>
                <input
                  class="form-control form-control-lg my-2"
                  type="text"
                  placeholder="www.instagram.com/"
                  v-model="socialSupport.instagram_url"
                  @input="enableUpdateBtn()"
                />
              </div>
              <div class="form-group my-3">
                <label for="inputcouponcode" class="state-color__fg--grey">{{
                  $t("manage.socialMedia.social.youtube")
                }}</label>
                <input
                  class="form-control form-control-lg my-2"
                  type="text"
                  placeholder="www.youtube.com/"
                  v-model="socialSupport.youtube_url"
                  @input="enableUpdateBtn()"
                />
              </div>
            </div>
          </div>

          <div class="width-60 my-4 mx-auto pb-5">
            <button
              type="button"
              class="btn btn-primary float-end px-5"
              style="padding-top: 10px; padding-bottom: 10px"
              @click="updateSocialSupportData"
              :disabled="getIsUpdateBtnDisabled"
            >
              {{ $t("manage.socialMedia.social.button") }}
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>

    <phone-number-modal-vue
      :title="title"
      :sub_title="sub_title"
      :type="type"
    />
    <button
      id="whatsappChatModalBtn"
      data-bs-toggle="modal"
      data-bs-target="#addPhoneNumberModal"
      class="d-none"
    ></button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import PhoneNumberModalVue from "./PhoneNumberModal.vue";
import $ from "jquery";
import Toast, { errorBackground } from "../../config/toastConfig";

export default {
  data() {
    return {
      title: "",
      sub_title: "",
      type: "",
    };
  },
  components: { SideBar, TopBar, LoadingSpinner, PhoneNumberModalVue },

  computed: {
    ...mapGetters("socialMedia", ["socialSupport", "getIsUpdateBtnDisabled"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("socialMedia", [
      "getSocialSupportData",
      "updateSocialSupport",
    ]),
    ...mapMutations("socialMedia", ["SET_UPDATE_BTN_VISIBILITY"]),

    disableChange(e) {
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    },

    async updateSocialSupportData() {
      var email_pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (
        this.socialSupport?.support_email &&
        !this.socialSupport?.support_email.toLowerCase().match(email_pattern)
      ) {
        Toast.fire({
          icon: "error",
          title:
            this.$t("auth.signUp.email_invalid") + " 'example@domain.com' ",
          background: errorBackground,
        });

        return;
      }

      await this.updateSocialSupport(this.socialSupport);
    },

    openPhoneModal(title, sub_title, type) {
      this.title = title;
      this.sub_title = sub_title;
      this.type = type;
      $("#whatsappChatModalBtn").trigger("click");
    },

    enableUpdateBtn() {
      this.SET_UPDATE_BTN_VISIBILITY(false);
    },
  },

  async created() {
    await this.getSocialSupportData();
  },
};
</script>

<style scoped>
.width-60 {
  width: 60%;
}

.width-48 {
  width: 48% !important;
}

.form-control {
  border: 1px solid #bdbdbd;
}

.form-select {
  background-image: url("../../assets/icon/dropdown.svg");
  border: 1px solid #bdbdbd;
}
@media only screen and (max-width: 1250px) {
  .support {
    flex-direction: column !important;
  }

  .width-48 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 800px) {
  .width-60 {
    width: 100%;
  }
}
</style>
