<template>
  <div
    class="modal fade"
    id="addPhoneNumberModal"
    tabindex="-1"
    aria-labelledby="addPhoneNumberModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content  common-border__radius p-3 ">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important;"
        >
          <h5>{{ $t(title) }}</h5>
          <button
            type="button"
            class="modal-close"
            id="PhoneNumberModal"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div
          class="modal-body d-flex justify-content-center align-items-center flex-column m-2 p-2"
        >
          <div class="w-100">
            <label for="exampleInputEmail1" class="form-label">{{
              $t(sub_title)
            }}</label>
            <input
              type="text"
              class="form-control common-border__radius-8"
              :placeholder="$t('manage.socialMedia.customer_phone_placeholder')"
              style="height:50px"
              v-model="whatsappNumber"
            />
          </div>

          <div class="pt-4">
            <button
              type="button"
              class="btn btn-primary"
              style="padding:15px 35px"
              @click="checkPhoneNumber"
            >
              {{ $t("manage.socialMedia.social.button") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    type="button"
    id="otpModalBtn"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#otpModal"
  >
    OTP
  </button>

  <!-- modal -->
  <otp-modal
    :phoneNumber="whatsappNumber"
    ref="otpModalComponent"
    @updateWhatsapp="updatePhone($event)"
    refModal="#addPhoneNumberModal"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import OtpModal from "../../components/settings/otpModal.vue";
import Toast, { errorBackground } from "../../config/toastConfig";

export default {
  data() {
    return {
      whatsappNumber: "",
    };
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    sub_title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  emits: ["close"],

  components: {
    OtpModal,
  },

  computed: {
    ...mapGetters("socialMedia", ["socialSupport"]),
  },

  methods: {
    ...mapActions("auth", ["otpRequest", "resendOtpRequest", "signInRequest"]),
    ...mapActions("settings", ["updateWhatsAppChatSupport"]),
    ...mapActions("socialMedia", [
      "getSocialSupportData",
      "updateSocialSupport",
    ]),

    validatePhone(data) {
      var phone_pattern = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
      if (!data) {
        Toast.fire({
          icon: "error",
          title: this.$t("auth.validation.phone.phone_required"),
          background: errorBackground,
        });
        return false;
      } else if (data.length !== 11) {
        Toast.fire({
          icon: "error",
          title: this.$t("auth.validation.phone.phone_length"),
          background: errorBackground,
        });
        return false;
      } else if (!data.match(phone_pattern)) {
        Toast.fire({
          icon: "error",
          title: this.$t("auth.validation.phone.invalid_phone"),
          background: errorBackground,
        });
        return false;
      } else {
        return true;
      }
    },

    async checkPhoneNumber() {
      if (!this.validatePhone(this.whatsappNumber)) {
        return;
      }
      $("#PhoneNumberModal").trigger("click");
      let data = {
        phone: this.whatsappNumber,
        api_key: "U8cPDR8#",
      };

      await this.otpRequest(data);
      this.$refs.otpModalComponent.startTimer();
      $("#otpModalBtn").trigger("click");
    },

    async updatePhone(data) {
      const socialData = {
        ...this.socialSupport,
      };

      if (this.type == "whatsapp") {
        socialData.whatsapp_phone = data;
      } else {
        socialData.support_phone = data;
      }

      await this.updateSocialSupport(socialData);
      await this.getSocialSupportData();
      this.whatsappNumber = "";
    },
  },
};
</script>

<style></style>
