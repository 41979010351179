import Client from "../client/axiosClient";
import store from "../../store";

export default {
  createCoupon(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_coupon_create/${language}`,
      method: "POST",
      data,
    });
  },

  getAllCoupon(page = 1) {
    let language = store.getters["language/getLanguage"];

    return Client({
      url: `/seller_coupon_list/${language}/${page}`,
      method: "GET",
    });
  },

  deleteCoupon(couponId) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `seller_coupon_delete/${language}/${couponId}`,
      method: "DELETE",
    });
  },

  updateCoupon(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `seller_coupon_update/${language}`,
      method: "PUT",
      data,
    });
  },

  updateCouponStatus(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `seller_coupon_status_update/${language}`,
      method: "PUT",
      data,
    });
  },

  searchCoupon(data) {
    const { page, couponKey } = data;
    let language = store.getters["language/getLanguage"];

    return Client({
      url: `/seller_coupon_list/${language}/${page}?search=${couponKey}`,
      method: "GET",
    });
  },
};
