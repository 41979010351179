<template>
  <div class="wrapper">
      <div class="image-container">
        <div>
        <img :src="getImgUrl(selectedImage)" class="image ">
        </div>
        
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props:['selectedImage'],


   methods:{
      getImgUrl(data) {
        return require('../../assets/marketing-materials/store-banner/'+data)
      }
  }
};
</script>

<style scoped>


.wrapper{
  margin-top: 70px;
}



.image-container{
  width: 90%;
  margin: auto;
  cursor: pointer;
}

.image{
  width: 100%;
}


@media only screen and (max-width: 600px){

  .wrapper{
    margin-top: 10px;
  }
}




</style>
