<template><p></p> </template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
  beforeCreate() {
    if (this.getLanguage) {
      this.$router.push(`/${this.getLanguage}`);
    }
    this.$router.push(`/bn`);
  },
};
</script>
