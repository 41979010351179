<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div
            class="mt-3 d-flex align-items-center state-color__fg--grey page-heading-top-padding"
          >
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="font-weight-500 ms-3 mb-0">
              {{ $t("manage.main.payment_method.title") }}
            </h4>
          </div>

          <div class="w-100 d-flex justify-content-center">
            <div
              class="payment_methods_container bg-white common-border__radius"
            >
              <div class="d-flex align-items-center">
                <input
                  type="checkbox"
                  class="checkbox"
                  checked="true"
                  disabled
                />
                <img
                  src="../../assets/manage/payment-methods/cod.svg"
                  alt=""
                  srcset=""
                />
                <div class="text_container">
                  <h6 class="mb-0">
                    {{ $t("manage.main.payment_method.title") }}
                  </h6>
                  <p class="mb-0 state-color__fg--grey1 ">
                    {{ $t("manage.main.payment_method.cod_details") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import LoadingSpinner from "../../components/LoadingSpinner.vue"
import SideBar from "../../components/menubar/SideBar.vue"
import TopBar from "../../components/menubar/TopBar.vue"
export default {
  components: { SideBar, TopBar, LoadingSpinner },
  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
}
</script>

<style scoped>
.payment_methods_container {
  width: 50%;
  margin-top: 2rem;
  padding: 2rem;
}

.payment_methods_container .checkbox {
  margin-right: 1rem;
  width: 1.15rem;
  height: 1.15rem;
}

.payment_methods_container .text_container {
  padding-left: 1rem;
}

@media only screen and (max-width: 576px) {
  .payment_methods_container {
    width: 95%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .payment_methods_container {
    width: 95%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .payment_methods_container {
    width: 95%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .payment_methods_container {
    width: 80%;
  }
}
</style>
