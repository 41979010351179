<template>
  <div
    class="w-100 h-100 min-vh-100 d-flex justify-content-center align-items-center"
    :class="$style.global_container"
  >
    <div :class="$style.content_container">
      <div class="">
        <div class="d-flex justify-content-end pe-4">
          <AppBaseBottomToolTip></AppBaseBottomToolTip>
        </div>
        <h4 class="state-color__fg--grey text-center">
          {{ $t("product.category.direct_category_assign.heading_1") }} <br />
          {{ $t("product.category.direct_category_assign.heading_2") }}
        </h4>
      </div>

      <div :class="$style.content_padding">
        <div class="d-flex justify-content-center pt-4 pb-4">
          <div :class="$style.search_field_container">
            <AppBaseInputSearchField
              :input_field_placeholder="
                $t(
                  'product.category.direct_category_assign.search_place_holder'
                )
              "
              @sendSearchedField="searchInput($event)"
            ></AppBaseInputSearchField>
          </div>
        </div>

        <div class="w-100 d-flex flex-wrap">
          <OnboardingCategoryTabContainer
            :searchedKey="searchedKey"
            @setSelectedCategory="setSelectedCategory"
          ></OnboardingCategoryTabContainer>
        </div>

        <div class="d-flex justify-content-center" :class="$style.padding_top">
          <AppBaseBlueBtn @click="submitSelectedCategory()">
            <template v-slot:btn_name>
              {{
                $t("product.category.direct_category_assign.submit_btn")
              }}</template
            >
          </AppBaseBlueBtn>
        </div>
      </div>
    </div>

    <LoadingSpinner />
  </div>
</template>

<script setup>
import AppBaseInputSearchField from "../components/primary/AppBaseInputSearchField.vue";
import AppBaseBlueBtn from "../components/primary/AppBaseBlueBtn.vue";
import OnboardingCategoryTabContainer from "../components/primary/OnboardingCategoryTabContainer.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import AppBaseBottomToolTip from "../components/primary/tool-tip/AppBaseBottomToolTip.vue";

import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const searchedKey = ref("");
const selectedCategories = ref({});
const user = store.getters["auth/getUser"];

const searchInput = (searchedField) => {
  searchedKey.value = searchedField;
};

const setSelectedCategory = (categories) => {
  selectedCategories.value = categories;
};

const submitSelectedCategory = async () => {
  if (Object.keys(selectedCategories.value).length == 0) {
    router.push({
      name: "OnBoarding2",
      params: {
        languageCode: store.getters["language/getLanguage"],
        isValidForOnBoarding: true,
      },
    });
    return;
  }
  let idProductCategories = "";
  store.dispatch("Loader/setOverlayStatus", true);

  idProductCategories = Object.values(selectedCategories.value).join(",");
  let isCategoryCreated = await store.dispatch(
    "productCategories/instantCategoryCreate",
    {
      id_product_categories: idProductCategories,
      id_business_category: user.business_category_id,
    }
  );
  store.dispatch("Loader/setOverlayStatus", false);
  if (!isCategoryCreated) return;
  router.push({
    name: "OnBoarding2",
    params: {
      languageCode: store.getters["language/getLanguage"],
      isValidForOnBoarding: true,
    },
  });
};
</script>

<style module>
.global_container {
  background-color: #f3f6f9;
}

.content_container {
  width: 60%;
  background-color: white;
  padding: 1.5rem 0rem;
  border-radius: 0.75rem;
  box-shadow: 0px 3px 12px #1d1d1d0d;
}

.content_padding {
  padding: 0rem 9rem;
}

.search_field_container {
  width: 95%;
}

.padding_top {
  padding-top: 8rem;
}

@media only screen and (max-width: 576px) {
  .content_container {
    width: 100%;
  }

  .content_padding {
    padding: 0.5rem 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .content_container {
    width: 100%;
  }

  .content_padding {
    padding: 0.5rem 0.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .content_container {
    width: 100%;
  }

  .content_padding {
    padding: 0.5rem 0.5rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .content_padding {
    padding: 0.5rem 0.5rem;
  }
}
</style>
