import { createStore } from "vuex";

import product from "./modules/product";
import coupon from "./modules/coupon";
import manageCoupon from "./modules/manageCoupon";
import storeTime from "./modules/storeTime";
import productCategories from "./modules/productCategories";
import notification from "./modules/notification";
import Loader from "./modules/loader";
import CustomerList from "./modules/customer-list";
import Banner from "./modules/banner";
import Order from "./modules/order";
import socialMedia from "./modules/socialMedia";
import auth from "./modules/auth";
import graph from "./modules/graph";
import ExtraPages from "./modules/extraPages";
import profileUpdate from "./modules/profileUpdate";
import favicon from "./modules/favicon";
import settings from "./modules/settings";
import instorePickup from "./modules/instorePickup";
import language from "./modules/language";
import address from "./modules/address";

export default createStore({
  state: {
    responsiveSidebarVisibility: false,

    overlayStatus: false,
    toastNotification: "",

    profileUpdatePhoneEroor: "",
    profileUpdateEmailEroor: "",

    // add new product
    allNewProductColor: [],
    newProductColor: "",
    showProductColorPicker: false,

    // edit & add product field error class flag
    productErrorFlag: -1,
  },
  mutations: {
    setSidebarVisibility(state, status) {
      state.responsiveSidebarVisibility = status;
    },

    setOverlayStatus(state, value) {
      state.overlayStatus = value;
    },

    setToastNotification(state, message) {
      state.toastNotification = message;
    },

    setProfileUpdatePhoneEroor(state, message) {
      state.profileUpdatePhoneEroor = message;
    },
    setProfileUpdateEmailEroor(state, message) {
      state.profileUpdateEmailEroor = message;
    },

    // add new product color
    setNewProductColor(state, colorCode) {
      let i = 0,
        flag = 0;
      for (i = 0; i < state.allNewProductColor.length; i++) {
        if (state.allNewProductColor[i].hex8 == colorCode.hex8) {
          flag = 1;
          break;
        }
      }

      if (state.allNewProductColor.length == 0 || flag == 0) {
        state.allNewProductColor.push({
          hex8: colorCode.hex8,
          global_attribute_id: "2",
        });
      }
    },

    // empty the product color
    removeAllProductColor(state) {
      state.allNewProductColor = [];
    },

    //error product edit/ create
    setProductErrorFlag(state, flag) {
      state.productErrorFlag = flag;
    },

    setExistingProductColor(state, newVal) {
      state.allNewProductColor = newVal;
    },

    removeSingleProductColor(state, index) {
      state.allNewProductColor.splice(index, 1);
    },
    setProductColor(state, colorCode) {
      state.newProductColor = colorCode;
    },
    setshowProductColorPicker(state, status) {
      state.showProductColorPicker = status;
    },
  },
  actions: {
    handleCommonApiError(_, { response }) {
      const status = response.status;

      if (status === 404) {
        let messages = "";
        if (response.data.data) {
          const data = response.data.data;
          for (let key in data) {
            messages += data[key];
            //  messages += "<br />";
          }
        }

        console.log("error ", messages);
      }
    },
  },

  getters: {
    getSidebarVisibility(state) {
      return state.responsiveSidebarVisibility;
    },

    getAllNewProductColor(state) {
      return state.allNewProductColor;
    },

    getProductColor(state) {
      return state.newProductColor;
    },
    getShowColorPicker(state) {
      return state.showProductColorPicker;
    },

    //product error flag
    getProductErrorFlag(state) {
      return state.productErrorFlag;
    },
  },

  modules: {
    product,
    coupon,
    manageCoupon,
    storeTime,
    productCategories,
    notification,
    Loader,
    CustomerList,
    Banner,
    Order,
    socialMedia,
    auth,
    graph,
    ExtraPages,
    profileUpdate,
    favicon,
    settings,
    instorePickup,
    language,
    address,
  },
});
