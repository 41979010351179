<template>
    <div class="image-container">
      <div>
        <img :src="getImgUrl(imageName)" class="image ">
      </div>
    </div>
</template>


<script>
export default{
    props:["imageName"],

    
    methods:{
         getImgUrl(data) {
            return require('../../../assets/marketing-materials/store-banner/'+data)
        }
    },





}

</script>


<style scoped>

.image-container{
  width: 90%;
  margin: auto;
}

.image{
  width: 100%;
}

</style>
