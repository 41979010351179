<template>
  <div
    class="modal fade"
    id="otpModal"
    data-bs-backdrop="static"
    aria-labelledby="otpModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content  common-border__radius  ">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important;"
        >
          <h5></h5>
          <button
            type="button"
            class="d-none"
            id="leave-page-action-close1"
            data-bs-dismiss="modal"
            @click="setOtpStatusToFalse()"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
          <button
            type="button"
            class="modal-close d-none"
            id="leave-page-action-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>

          <button class="modal-close" @click="closeModal()">
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div
          class="modal-body d-flex justify-content-center align-items-center flex-column m-2 p-2"
        >
          <div>
            <h4 class="state-color__fg--grey">
              {{ $t("auth.otp.phone.otp_title") }}
            </h4>
          </div>
          <div class="py-3">
            <p class="text-center mb-1">
              {{ $t("auth.otp.phone.sub_1") }}
            </p>
            <p class="text-center">{{ $t("auth.otp.phone.phone_number") }}</p>
            <h6 class="state-color__fg--Info text-center">{{ phoneNumber }}</h6>
          </div>
          <div class="mb-4" v-if="isProfile">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="confirmProfileUpdateOtp"
            />
          </div>

          <div class="mb-4" v-if="!isProfile">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              v-model="otp"
              @on-complete="confirmSocialMediaUpdateOtp"
            />
          </div>

          <div class="py-2">
            <div v-if="timer.minutes <= 0 && timer.seconds <= 0">
              <p class="mobile-text state-color__fg--error">
                {{ $t("auth.otp.otp_expired") }}
                <span class="state-color__fg--Info make-cursor"
                  ><u @click="resendOtp()" class="ms-1">
                    {{ $t("auth.otp.otp_resend") }}
                  </u></span
                >
              </p>

              <p
                @click="closeModal()"
                type="button"
                data-bs-toggle="modal"
                :data-bs-target="refModal"
                class="text-center state-color__fg--Info make-cursor"
              >
                {{ $t("auth.otp.change_number") }}
              </p>
            </div>
            <div v-else>
              <p class="d-block mobile-text make-cursor">
                {{ $t("auth.otp.otp_placeholder") }}
                <b class="d-inline-block"
                  >( {{ timer.minutes }}:{{ timer.seconds }} )</b
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VOtpInput from "vue3-otp-component";
import { useTimer } from "vue-timer-hook";
import $ from "jquery";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    phoneNumber: { type: String, required: true },
    isProfile: { type: Boolean, default: false },
    refModal: { type: String },
  },
  emits: ["resetPhoneNumber", "updateWhatsapp"],
  components: { VOtpInput },
  data() {
    return {
      timer: Object,
    };
  },

  computed: mapGetters("settings", ["isOtpValid"]),

  methods: {
    ...mapActions("profileUpdate", ["updateSellerContactNumber"]),
    ...mapActions("auth", ["otpRequest", "resendOtpRequest"]),
    ...mapActions("settings", ["otpValidateRequest"]),
    ...mapMutations("settings", ["UPDATE_OTP_STATUS"]),

    resendOtp() {
      if (this.timer.seconds <= 0 && this.timer.minutes <= 0) {
        this.resendOtpRequest({ phone: this.phoneNumber, api_key: "U8cPDR8#" });

        let time = new Date();
        time.setSeconds(time.getSeconds() + 240); // 4 minutes timer
        this.timer = useTimer(time);
      }
    },

    async confirmProfileUpdateOtp(event) {
      let user = await this.updateSellerContactNumber({
        phone: this.phoneNumber,
        otp: event,
      });
      if (user.success) {
        let userObject = localStorage.getItem("majhiUser");
        let authUser = JSON.parse(userObject);
        authUser.phone = user.data.phone;
        localStorage.setItem("majhiUser", JSON.stringify(authUser));
        $("#leave-page-action-close1").trigger("click");
        this.$emit("resetPhoneNumber", authUser.phone);
      }
    },

    async confirmSocialMediaUpdateOtp(event) {
      let data = {
        phone: this.phoneNumber,
        otp: event,
      };
      await this.otpValidateRequest(data);

      if (this.isOtpValid) {
        this.$refs.otpInput.clearInput();
        $("#leave-page-action-close1").trigger("click");
        this.$emit("updateWhatsapp", this.phoneNumber);
      }
    },

    closeModal() {
      if (this.timer.seconds > 0 && this.timer.minutes > 0) return;
      this.$refs.otpInput.clearInput();
      $("#leave-page-action-close").trigger("click");
      $(this.refModal).trigger("click");
    },

    setOtpStatusToFalse() {
      this.UPDATE_OTP_STATUS(false);
    },

    startTimer() {
      let time = new Date();
      time.setSeconds(time.getSeconds() + 180); // 3 minutes timer
      this.timer = useTimer(time);
    },
  },
};
</script>

<style></style>
