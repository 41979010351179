<template>
  <div class="wrapper">
    <img class="image" :src="imageUrl()" alt="background" />

    <div class="card-header-info">
      <p class="store-name">{{ shopName }}</p>
      <p class="category-name">{{ category }}</p>
    </div>

    <div class="card-body-info d-flex align-items-center">
      <img :src="store_logo_url" alt="logo" class="logo" />
      <div>
        <div class="info">
          <img :src="loadEllipse()" alt="icon" class="icon" />
          <span>{{ phone }}</span>
        </div>
        <div class="info">
          <img :src="loadEllipse()" alt="icon" class="icon" />
          <span>{{ email }}</span>
        </div>

        <div class="info">
          <img :src="loadEllipse()" alt="icon" class="icon" />
          <span>{{ area }},{{ city }},{{ division }}</span>
        </div>
      </div>
    </div>

    <div class="card-footer-info">
      <p>{{ baseUrl }}/{{ shopName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: "",
      category: "",
      phone: "",
      email: "",
      area: "",
      city: "",
      division: "",
      baseUrl: "www.majhi.com/en",
      store_logo_url: "",
    };
  },

  created() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);

      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  updated() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  methods: {
    imageUrl() {
      return require("@/assets/marketing-materials/business-card/card-11/BC-11.png");
    },

    loadLogo() {
      return require("@/assets/marketing-materials/business-card/card-11/Group.png");
    },

    loadEllipse() {
      return require("@/assets/marketing-materials/business-card/card-11/Ellipse 4.png");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  font-size: 4px;
  position: relative;
  color: #ffffff;
}

.card-header-info {
  position: absolute;
  top: 2em;
  left: 12em;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.card-body-info {
  position: absolute;
  top: 13.5em;
  left: 10em;
  font-family: "Montserrat", sans-serif;
}

.image {
  width: 100%;
}

.logo {
  width: 7em;
  margin-right: 1.5em;
  height: 7em;
  border-radius: 50%;
}

.info {
  font-size: 1.6em;
  margin-bottom: 1em;
  letter-spacing: 1.2px;
}

.info span {
  margin-left: 0.6em;
}

.card-footer-info {
  position: absolute;
  top: 15em;
  left: 4em;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2em;
  font-family: "Montserrat", sans-serif;
}

.icon {
  width: 0.8em;
}

.store-name {
  font-size: 3.5em;
  font-weight: 900;
  letter-spacing: 3.6px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.category-name {
  font-size: 1.7em;
  letter-spacing: 2.2px;
  font-weight: 600;
  margin-top: 0.7em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.store-url {
  letter-spacing: 1px;
  font-size: 1.6em;
  margin-top: 2em;
  font-weight: 600;
  text-transform: uppercase;
}

.footer-container {
  position: absolute;
  font-size: 1.3em;
  top: 25em;
  left: 5em;
  color: #ffffff;
  font-family: "Overpass", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.footer-container div {
  margin-bottom: 1em;
}

.footer-container span {
  margin-left: 1em;
}

@media only screen and (max-width: 1600px) {
  .wrapper {
    font-size: 3px;
  }
}

@media only screen and (max-width: 1280px) {
  .wrapper {
    font-size: 4.9px;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
    margin-left: 0;
    font-size: 7.8px;
  }

  .card-header-info {
    left: 16em;
    text-align: center;
    letter-spacing: 2.8px;
  }

  .card-body-info {
    top: 17em;
  }

  .card-footer-info {
    top: 16em;
    left: 7em;
    letter-spacing: 2.8px;
  }

  .logo {
    width: 8em;
    margin-right: 2em;
    height: 8em;
    border-radius: 50%;
  }
}
</style>
