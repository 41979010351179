import Toast, { successBackground } from "../../config/toastConfig";
import Repository from "../../api/RepositoryFactory";
const auth = Repository.get("address");
const { updateSellerAddress } = auth;

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {
    UPDATE_LOCAL_STORAGE_ADDRESS(_, data) {
      let user = JSON.parse(localStorage.getItem("majhiUser"));
      user = {
        ...user,

        shop_address: data.shop_address,
        id_country: data.id_country,
        country_name: data.country_name,
        id_division: data.id_division,
        division_name: data.division_name,
        id_city: data.id_city,
        city_name: data.city_name,
        id_area: data.id_area,
        area_name: data.area_name,
      };

      localStorage.setItem("majhiUser", JSON.stringify(user));
    },
  },

  actions: {
    async updateAddress({ commit }, data) {
      try {
        const response = await updateSellerAddress(data);
        commit("UPDATE_LOCAL_STORAGE_ADDRESS", response.data[0]);

        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      }
    },
  },
};
