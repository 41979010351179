<template>
  <div class="wrapper">
    <img class="image" :src="imageUrl()" alt="background" />
    <div class="card-content">
      <div class="d-flex flex-column">
        <div class="logo-container">
          <img :src="store_logo_url" alt="logo" class="logo" />
        </div>

        <div>
          <p class="store-name">{{ shopName }}</p>
          <p class="category-name">{{ category }}</p>
        </div>
      </div>

      <div class="contact-info">
        <p>{{ phone }}</p>
        <p>{{ email }}</p>
        <p>{{ area }},{{ city }},{{ division }}</p>
      </div>
      <div class="footer">
        <p>{{ baseUrl }}/{{ shopName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: "",
      category: "",
      phone: "",
      email: "",
      area: "",
      city: "",
      division: "",
      baseUrl: "www.majhi.com/en",
      store_logo_url: "",
    };
  },

  created() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);

      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  updated() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  methods: {
    imageUrl() {
      return require("@/assets/marketing-materials/business-card/card-6/BC-6.png");
    },

    loadLogo() {
      return require("@/assets/marketing-materials/business-card/card-6/Group.png");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  margin-top: 10px;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 10px;
  font-family: "Lexend Deca", sans-serif;
  font-size: 10px;
  position: relative;
  color: #ffffff;
}

.image {
  width: 100%;
}

.logo-container {
  margin-bottom: 1rem;
}

.logo {
  width: 7em;
  height: 7em;
  border-radius: 50%;
}

.card-content {
  position: absolute;
  top: 6em;
  left: 33em;
}

.store-name {
  font-size: 3.5em;
  font-weight: 600;
  letter-spacing: 0.31px;
  font-family: "Lexend Deca", sans-serif;
  text-transform: capitalize;
}

.category-name {
  font-size: 1.7em;
  letter-spacing: 0.96px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  text-transform: capitalize;
}

.contact-info {
  font-size: 1.7em;
  margin: 30px 0 30px 0;
  letter-spacing: 1.2px;
  font-family: "Raleway", sans-serif;
}
.contact-info p {
  margin-top: 0.9rem;
}

.footer {
  width: 100%;
  font-size: 1.7em;
  font-family: "Raleway", sans-serif;
}

@media only screen and (max-width: 1600px) {
  .wrapper {
    width: 86%;
    margin-left: 7%;
    font-size: 8.5px;
  }
}

@media only screen and (max-width: 1280px) {
  .wrapper {
    width: 90%;
    margin-left: 5%;
    font-size: 7.1px;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
    margin-left: 0;
    font-size: 7px;
  }

  .contact-info {
    margin: 10px 0 14px 0;
  }

  .contact-info p {
    margin-top: 0.3rem;
  }
}
</style>
