import router from "../../router";
import Repository from "../../api/RepositoryFactory";
const Graph = Repository.get("Graph");

const { getGraphInfo } = Graph;

const state = {
  salesDetails: null,
};

const getters = {
  salesDetails: (state) => state.salesDetails,
};

const mutations = {
  SET_SELL_DETAILS: (state, data) => {
    state.salesDetails = data;
  },
};

const actions = {
  async requestGraphInfo({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getGraphInfo(data);
      if (router.currentRoute.value.name != "EditProductWithAttribute")
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      commit("SET_SELL_DETAILS", response.data);
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
