<template>
  <div>
    <table class="w-100">
      <thead>
        <tr
          class="state-color__fg--grey1 text-center "
          style="background-color: #f3f6f9 !important"
        >
          <th class="bg-grey1 font-weight-450 py-3">
            {{ $t("appearance.main.pages.title") }}
          </th>
          <th class="bg-grey1 font-weight-450">
            {{ $t("appearance.main.pages.date") }}
          </th>
          <th class="bg-grey1 font-weight-450">
            {{ $t("appearance.main.pages.preview") }}
          </th>
          <th class="bg-grey1 font-weight-450">
            {{ $t("appearance.main.pages.action") }}
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="pageData in pagesData" :key="pageData.id_extra_page">
          <td
            class="text-center state-color__fg--Info font-weight-450 google_sans_medium py-3"
          >
            {{ pageData.title }}
          </td>
          <td class="text-center fg-black">
            {{ calculateTime(pageData.updated_at) }}
          </td>
          <td
            class="text-center state-color__fg--Info text-decoration-underline google_sans_medium "
            role="button"
            @click="openPageLink(pageData.slug, pageData.is_published)"
          >
            {{ $t("appearance.main.pages.view") }}
          </td>
          <td class="py-4">
            <div class="w-100 d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-outline-secondary m-1 page-action-button"
                @click="updatedPage(pageData.id_extra_page)"
              >
                <img src="../../../assets/icon/edit.svg" alt="" width="20" />
                {{ $t("appearance.main.pages.edit_btn") }}
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary m-1 page-action-button"
                @click="deletePage(pageData.id_extra_page)"
              >
                <img
                  src="../../../assets/icon/menu_delete.svg"
                  alt=""
                  width="15"
                />
                {{ $t("appearance.main.pages.delete_btn") }}
              </button>
            </div>
          </td>
        </tr>

        <tr v-if="!pagesData || (pagesData && !pagesData.length)">
          <td colspan="4">
            <div
              class="d-flex flex-column  justify-content-center align-items-center mb-5"
            >
              <img src="../../../assets/icon/no-page.svg" alt="" />
              <h5>{{ $t("manage.main.no_page_found") }}</h5>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { createToast } from "mosha-vue-toastify";
import Toast, { errorBackground } from "../../../config/toastConfig";
import Swal from "sweetalert2";
export default {
  setup() {
    const toast = (message, type) => {
      createToast(message, {
        type: type,
        transition: "bounce",
        showIcon: true,
      });
    };
    return { toast };
  },

  data() {
    return {
      url: "",
    };
  },

  props: ["pagesData", "shopUrl"],

  methods: {
    async deletePage(id) {
      Swal.fire({
        title: this.$t("manage.main.delete_confirmation_message.title"),
        text: this.$t("manage.main.delete_confirmation_message.page"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "manage.main.delete_confirmation_message.delete_btn"
        ),
        cancelButtonText: this.$t(
          "manage.main.delete_confirmation_message.cancel_btn"
        ),
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$emit("deletedPageId", id);
        }
      });
    },

    calculateTime(data) {
      var date = new Date(data);
      let year = date.getFullYear();
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let month = date.getMonth();
      var day = date.getDate();

      return `${months[month]} ${day},${year}`;
    },

    async updatedPage(id) {
      this.$router.push({ name: "EditPage", params: { id: id } });
    },

    openPageLink(data, is_published) {
      if (is_published === 1) {
        window.open(`${this.shopUrl}/${data}`, "_blank");
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.pages.not_published"),
          background: errorBackground,
        });
      }
    },
  },
};
</script>

<style scoped>
.page-action-button {
  font-size: 14px;
  color: #1d1d1d;
}
</style>
