<template>
  <div
    class="w-100 min-vh-100 d-flex justify-content-center align-items-center signup-page"
  >
    <div class="signup-container">
      <div class="left position-relative">
        <div class="position-absolute top-0 start-0 mt-3 ms-3">
          <a :href="`${majhiLandingBaseUrl}/${getLanguage}`">
            <div class="d-flex align-items-center">
              <img
                src="../../assets/logo/Bangla/majhi_logo_primary_bangla_2.png"
                alt=""
                width="144"
                :class="getLanguage == 'bn' ? 'd-block' : 'd-none'"
              />
              <img
                src="../../assets/logo/English/majhi_logo_primary_english_2.png"
                alt=""
                width="144"
                :class="getLanguage == 'en' ? 'd-block' : 'd-none'"
              />
            </div>
          </a>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <img
            alt=""
            src="https://majhi-space.sgp1.cdn.digitaloceanspaces.com/Static/auth_img.png"
            class="large-image"
          />
        </div>
      </div>

      <div class="right">
        <signup v-if="!numberGiven" v-on:requestOtp="sendOtp($event)" />
        <otp
          v-else-if="numberGiven"
          :phoneNumber="phoneNumber"
          v-on:requestVerifyOtp="confirmOtp($event)"
          v-on:requestResendOtp="resendOtp($event)"
        />
      </div>
    </div>
    <loading-spinner />
  </div>
</template>

<script>
//import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";

import Signup from "../../components/auth/Signup.vue";
import Otp from "../../components/auth/Otp.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
export default {
  components: { Signup, Otp, LoadingSpinner },

  data() {
    return {
      appVerifier: "",
      otpConfirmation: Object,
      phoneNumber: "",
      flag: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["numberGiven"]),
    ...mapGetters("language", ["getLanguage"]),

    majhiLandingBaseUrl() {
      return process.env.VUE_APP_FRONTED_BASE_URL;
    },
  },
  methods: {
    ...mapMutations("auth", ["SET_AUTH_OTP_ERROR", "SET_NUMBER_STATUS"]),
    ...mapActions("auth", [
      "checkUserExistance",
      "otpRequest",
      "resendOtpRequest",
      "otpValidate",
    ]),

    handleOtpRequest(phoneNo) {
      console.log(phoneNo);
    },

    async sendOtp(phoneNo) {
      this.phoneNumber = phoneNo;
      // this.SET_NUMBER_STATUS(true)

      const doesAccountExist = await this.checkUserExistance({
        phone: phoneNo,
        user_type: 1,
      });
      if (doesAccountExist) return;
      await this.otpRequest({
        phone: phoneNo,
        api_key: "U8cPDR8#",
      });
    },

    async confirmOtp(payload) {
      let data = {
        phone: payload.phoneNumber,
        otp: payload.otp,
      };

      await this.otpValidate(data);
    },

    async resendOtp(phoneNo) {
      let data = {
        phone: phoneNo,
        api_key: "U8cPDR8#",
      };
      await this.resendOtpRequest(data);
    },
  },
  created() {
    //this.SET_NUMBER_STATUS(false)
  },
};
</script>

<style scoped>
.signup-page {
  background-color: #f3f6f9;
}

.signup-container {
  width: 60%;

  box-shadow: 0px 3px 12px #1d1d1d0d;
  display: flex;
  border-radius: 10px;
}

.left {
  width: 50%;
}

.left .large-image {
  width: 100%;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media only screen and (max-width: 900px) {
  .left,
  .right {
    width: 100%;
  }

  .signup-container {
    flex-direction: column;
  }

  .left .large-image {
    width: 90%;
  }
}

@media only screen and (max-width: 450px) {
  .signup-page {
    background-color: white;
  }
  .signup-container {
    width: 100%;
    min-height: 100vh;
    box-shadow: none;
  }

  .left {
    background-color: #f3f6f9;
  }

  .right {
    z-index: 10;
    margin-top: -100px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
}
</style>
