<template>
  <div class="d-flex justify-content-end py-4" style="padding-right:10px">
    <v-pagination
      v-model="page"
      :pages="lastPage"
      :range-size="1"
      active-color="#DCEDFF"
      @update:modelValue="getDataByPage"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  components: { VPagination },
  props: { currentPage: { type: Number }, lastPage: { type: Number } },
  emits: ["requestDataByPageNumber"],

  data: function() {
    return { page: 0 };
  },

  watch: {
    currentPage(newVal) {
      this.page = newVal;
    },
  },

  methods: {
    ...mapActions("Loader", ["setOverlayStatus"]),

    getDataByPage() {
      if (this.currentPage === this.page) {
        return;
      }
      this.setOverlayStatus(true);
      this.$emit("requestDataByPageNumber", this.page);
    },
  },

  created() {
    this.page = this.currentPage;
  },
};
</script>

<style></style>
