<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="CustomOverview"
    tabindex="-1"
    aria-labelledby="CustomOverviewLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius p-4">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important"
        >
          <h5 class="modal-title" id="CustomOverviewLabel">
            {{ $t("home.order.order_search.title") }}
          </h5>
          <button
            type="button"
            class="modal-close"
            id="custom-overview-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">{{
              $t("home.order.order_search.start_date")
            }}</label>
            <input
              type="date"
              class="form-control"
              name="custom-order-search-start-date"
              v-model="startDate"
              @change="startDateUpdate()"
            />
          </div>

          <div class="mb-3">
            <label class="form-label">{{
              $t("home.order.order_search.end_date")
            }}</label>
            <input
              type="date"
              class="form-control"
              name="custom-order-search-end-date"
              v-model="endDate"
              @change="endDateUpdate()"
            />
          </div>

          <button class="btn btn-primary p-2" @click="searchOrder()">
            {{ $t("home.order.order_search.btn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: function() {
    return {
      startDate: "",
      endDate: "",
    };
  },

  computed: {
    ...mapGetters("Order", [
      "activeMenu",
      "currentPage",
      "isCustomSearch",
      "isSearchKeyword",
    ]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("Order", ["allOrderRequest", "getOrderByStatus"]),
    ...mapMutations("Order", [
      "SET_CUSTOM_SEARCH_DATE",
      "SET_CUSTOM_SEARCH_STATUS",
    ]),

    async searchOrder() {
      if (this.startDate && this.endDate) {
        this.$emit("searchData", { start: this.startDate, end: this.endDate });
        document.getElementById("custom-overview-close").click();
      }
    },

    startDateUpdate() {
      document
        .getElementsByName("custom-order-search-end-date")[0]
        .setAttribute("min", this.startDate);
    },

    endDateUpdate() {
      document
        .getElementsByName("custom-order-search-start-date")[0]
        .setAttribute("max", this.endDate);
    },
  },

  mounted() {
    let today = new Date().toISOString().split("T")[0];
    document
      .getElementsByName("custom-order-search-start-date")[0]
      .setAttribute("max", today);
    document
      .getElementsByName("custom-order-search-end-date")[0]
      .setAttribute("max", today);
  },
};
</script>
