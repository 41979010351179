<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <SideBar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <TopBar />
        <div class="m-0 p-3 catageries-container app_container">
          <div
            class="mt-3 d-flex align-items-center state-color__fg--grey page-heading-top-padding"
          >
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="font-weight-500 ms-3 mb-0 page-heading-text-size">
              {{ $t("manage.main.store_address.title") }}
            </h4>
          </div>

          <div :class="`my-4 mx-auto ${$style.storeaddress_form_container}`">
            <StoreAddressForm />
          </div>
        </div>
      </div>
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import StoreAddressForm from "../../components/manage/address/store.vue";

export default {
  components: { SideBar, TopBar, LoadingSpinner, StoreAddressForm },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
};
</script>

<style module>
.storeaddress_form_container {
  max-width: 50rem;
}
</style>
