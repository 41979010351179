<template>
  <div
    class="modal fade"
    id="settingInstorePickupModal"
    tabindex="-1"
    aria-labelledby="settingInstorePickupModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal-content common-border__radius"
        style="min-height: 300px"
      >
        <div
          class="modal-header float-end"
          style="border-bottom: none !important"
        >
          <h5>{{ $t("manage.main.store_address.title") }}</h5>
          <button
            type="button"
            class="modal-close"
            id="instorePickUpModalClose"
            data-bs-dismiss="modal"
            @click="$emit('close')"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <form>
          <div
            class="modal-body d-flex justify-content-center align-items-center flex-column m-2 p-2"
          >
            <div class="w-100 mb-4">
              <label class="form-label">{{
                $t("manage.main.store_address.country")
              }}</label>
              <select class="form-select form-select-lg">
                <option value="">Bangladesh</option>
              </select>
            </div>

            <div class="w-100 mb-4">
              <label class="form-label">{{
                $t("manage.main.store_address.division")
              }}</label>
              <select
                class="form-select form-select-lg"
                @change="divisionOnChange($event)"
              >
                <option
                  v-for="(division, index) in allDivison"
                  :key="division.id_division"
                  :value="index"
                  :selected="idDivision == division.id_division"
                >
                  {{ division.division_name }}
                </option>
              </select>
            </div>

            <div class="w-100 mb-4">
              <label class="form-label">{{
                $t("manage.main.store_address.city")
              }}</label>
              <select
                class="form-select form-select-lg"
                @change="cityOnChange($event)"
              >
                <option
                  v-for="(city, index) in allCity"
                  :key="city.id_city"
                  :value="index"
                  :selected="idCity === city.id_city"
                >
                  {{ city.city_name }}
                </option>
              </select>
            </div>

            <div class="w-100 mb-4">
              <label class="form-label">{{
                $t("manage.main.store_address.area")
              }}</label>
              <select
                class="form-select form-select-lg"
                @change="areaOnChange($event)"
              >
                <option
                  v-for="(area, index) in allArea"
                  :key="area.id_area"
                  :value="index"
                  :selected="idArea == area.id_area"
                >
                  {{ area.area_name }}
                </option>
              </select>
            </div>

            <div class="w-100 mb-4">
              <label class="form-label">{{
                $t("manage.main.store_address.details_address")
              }}</label>
              <textarea v-model="address" rows="" cols="" class="form-control">
              </textarea>
            </div>

            <div class="pt-4">
              <button
                type="button"
                class="btn btn-primary"
                style="padding: 15px 35px"
                @click="addStoreAddress"
              >
                {{ $t("manage.main.store_address.save") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="d-none">
    <button
      type="button"
      class="btn btn-primary"
      id="instorePickupMOdalBtn"
      data-bs-toggle="modal"
      data-bs-target="#settingInstorePickupModal"
    >
      Launch demo modal
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toast, { errorBackground } from "../../config/toastConfig";

export default {
  data() {
    return {
      divisionName: "",
      idDivision: "",
      CityName: "",
      idCity: "",
      areaName: "",
      idArea: "",
      address: "",

      flag: true,
    };
  },

  emits: ["close"],

  computed: {
    ...mapGetters("instorePickup", ["allDivison", "allCity", "allArea"]),
  },

  methods: {
    ...mapActions("instorePickup", [
      "getAllDivision",
      "getAllCity",
      "getAllArea",
      "updateUserInfo",
      "updateInstorePickupStatus",
    ]),
    ...mapActions("address", ["updateAddress"]),

    async divisionOnChange(event) {
      this.divisionName = this.allDivison[event.target.value].division_name;
      this.idDivision = this.allDivison[event.target.value].id_division;
      await this.getAllCity({ divisionId: this.idDivision });
      await this.getAllArea({ cityId: this.allCity[0].id_city });

      this.idCity = this.allCity[0].id_city;
      this.CityName = this.allCity[0].city_name;
      this.idArea = this.allArea[0].id_area;
      this.areaName = this.allArea[0].area_name;
    },

    async cityOnChange(event) {
      this.CityName = this.allCity[event.target.value].city_name;
      this.idCity = this.allCity[event.target.value].id_city;
      await this.getAllArea({
        cityId: this.allCity[event.target.value].id_city,
      });
      this.idArea = this.allArea[0].id_area;
      this.areaName = this.allArea[0].area_name;
    },

    areaOnChange(event) {
      this.areaName = this.allArea[event.target.value].area_name;
      this.idArea = this.allArea[event.target.value].id_area;
    },

    async addStoreAddress() {
      if (!this.address) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.store_address.address_error"),
          background: errorBackground,
        });

        return;
      }

      //const formData = new FormData();
      // let userObject = localStorage.getItem("majhiUser");
      // let authUser = JSON.parse(userObject);
      // if (authUser?.email != null) {
      //   formData.append("email", authUser.email);
      // }
      // formData.append("shop_address", this.address);
      // formData.append("lat", authUser.lat);
      // formData.append("lon", authUser.long);
      // formData.append("id_country", 1);
      // formData.append("id_division", this.idDivision);
      // formData.append("id_city", this.idCity);
      // formData.append("id_area", this.idArea);

      const payload = {
        shop_address: this.address,
        id_country: 1,
        id_division: this.idDivision,
        id_city: this.idCity,
        id_area: this.idArea,
      };

      if (document.getElementById("instorePickUpModalClose")) {
        document.getElementById("instorePickUpModalClose").click();
      }

      // await this.updateUserInfo(formData);

      await this.updateAddress(payload);

      await this.updateInstorePickupStatus({ status: true });
      this.flag = false;
    },
  },

  async created() {
    await this.getAllDivision({ countryId: 1 });
    await this.getAllCity({ divisionId: this.allDivison[0]?.id_division });
    await this.getAllArea({ cityId: this.allCity[0]?.id_city });
    this.idDivision = this.allDivison[0]?.id_division;
    this.divisionName = this.allDivison[0]?.division_name;
    this.idCity = this.allCity[0]?.id_city;
    this.CityName = this.allCity[0]?.city_name;
    this.idArea = this.allArea[0]?.id_area;
    this.areaName = this.allArea[0]?.area_name;
  },
};
</script>

<style></style>
