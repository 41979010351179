<template>
  <div class="state-color__fg--grey1" :class="$style.tooltip">
    <img src="../../../assets/icon/help.svg" alt="" />
    {{ $t("setting.main.oboarding_1.help_btn") }}
    <span :class="$style.tooltiptext">{{
      $t("setting.main.oboarding_1.help_tooltip")
    }}</span>
  </div>
</template>

<script setup></script>

<style module>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 20rem;
  background-color: #ffffff;
  color: #333333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: -11.5rem;
  margin-left: -60px;
  box-shadow: 0px 6px 16px #00000029;
  padding: 1rem;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
