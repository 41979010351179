import Repository from "../../api/RepositoryFactory";
const ExtraPages = Repository.get("ExtraPages");
const {
  getAllPages,
  getPagesBasedOnStatus,
  deletePage,
  createPage,
  updatePage,
  loadSinglePage,
} = ExtraPages;
import Toast, {
  successBackground,
  errorBackground,
} from "../../config/toastConfig";
import router from "../../router";
import store from "../../store";

const state = {
  pages: [],
  page: "",
};

const getters = {
  allPages: (state) => state.pages,
  getPage: (state) => state.page,
};

const actions = {
  async getAllPages({ commit }) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getAllPages();
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        commit("SET_PAGES", response.data);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async getRequestedPages({ commit }, status) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await getPagesBasedOnStatus(status);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        commit("SET_PAGES", response.data);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async deletePage({ commit }, id) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await deletePage(id);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        commit("DELETE_PAGE", id);
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log(error);
    }
  },

  async createPage({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await createPage(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        router.push({
          name: "AllPages",
          params: { languageCode: store.getters["language/getLanguage"] },
        });
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      let message = error.response.data.data.slug
        ? error.response.data.data.slug[0]
        : error.response.data.data.total_page[0];
      Toast.fire({
        icon: "error",
        title: message,
        background: errorBackground,
      });
    }
  },

  async updatePage({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await updatePage(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        router.push({
          name: "AllPages",
          params: { languageCode: store.getters["language/getLanguage"] },
        });
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      let message = error.response.data.data.slug
        ? error.response.data.data.slug[0]
        : error.response.data.data.total_page[0];
      Toast.fire({
        icon: "error",
        title: message,
        background: errorBackground,
      });
    }
  },

  async loadSinglePage({ commit }, id) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await loadSinglePage(id);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success) {
        commit("SET_PAGE", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  SET_PAGES: (state, data) => {
    state.pages = data;
  },

  DELETE_PAGE: (state, id) => {
    state.pages = state.pages.filter((page) => page.id_extra_page !== id);
  },

  SET_PAGE: (state, data) => {
    state.page = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
