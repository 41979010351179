<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div
        class="col px-0 min-vh-100 w-100 d-flex flex-column justify-content-start"
      >
        <top-bar />
        <div class="m-0 p-3 catageries-container container-padding">
          <div
            class="mt-3 state-color__fg--grey"
            style="padding-top:30px !important"
          >
            <h4 class="fw-bold letter-spacing-5px state-color__fg--grey">
              {{ $t("product.category.title") }}
            </h4>
          </div>
          <div
            class="d-flex flex-column-reverse flex-lg-row flex-md-column justify-content-between my-1"
          >
            <categories-seach
              v-bind:businessCategoryId="businessCategoryId"
              v-on:requestCreateCategory="createCategory($event)"
            />
          </div>
          <div class="bg-white common-border__radius">
            <menu-bar />
          </div>

          <div class="bg-white my-4" style="border-radius:10px">
            <categories-view
              v-bind:allProductCategories="filteredProductCategories"
              v-on:requestUpdateAvailableStatus="
                updateAvailabilityStatus($event)
              "
            />
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import CategoriesSeach from "../../components/product/CategoriesSeach.vue";
import CategoriesView from "../../components/product/CategoriesView.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";

import { mapActions, mapGetters } from "vuex";

//import "mosha-vue-toastify/dist/style.css";
import Toast, { successBackground } from "../../config/toastConfig";
import MenuBar from "../../components/product/MenuBar.vue";

export default {
  components: {
    LoadingSpinner,
    SideBar,
    TopBar,
    CategoriesSeach,
    CategoriesView,
    MenuBar,
  },

  data: function() {
    return {
      businessCategoryId: "",
    };
  },

  computed: {
    ...mapGetters("productCategories", [
      "filteredProductCategories",
      "allProductCategories",
      "getAllProductCategoriesUnderBusinessCategory",
    ]),
  },

  methods: {
    ...mapActions("productCategories", [
      "getAllCategoryUnderBusinessCategory",
      "getSellerProductCategory",
      "updateCategoryStatus",
    ]),

    async getProductCategoryById() {
      await this.getSellerProductCategory();
    },

    createCategory(message) {
      Toast.fire({
        icon: "info",
        title: message,
        background: successBackground,
      });
    },

    async updateAvailabilityStatus(statusInfo) {
      const data = {
        id_product_category: statusInfo.productCategoryId,
        status: statusInfo.availabilityStatus,
      };
      await this.updateCategoryStatus(data);
      this.getProductCategoryById();
    },

    extractUserData() {
      let userObject = localStorage.getItem("majhiUser");
      let authUser = JSON.parse(userObject);

      this.businessCategoryId = authUser.business_category_id;
    },
  },

  async created() {
    await this.extractUserData();
    // this.getProductCategoryById();

    this.getAllCategoryUnderBusinessCategory({
      idBusinessCategory: this.businessCategoryId,
    });
  },
};
</script>

<style scoped>
.container-padding {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
</style>
