import Repository from "../../api/RepositoryFactory";
import router from "../../router";
import store from "../../store";
import Toast, {
  successBackground,
  errorBackground,
} from "../../config/toastConfig";

const Auth = Repository.get("Auth");

const {
  otpRequest,
  resendOtpRequest,
  otpValidate,
  signIn,
  signOut,
  getBusinessCategory,
  signUp,
  checkExistingUser,
  deleteStore,
} = Auth;

export default {
  namespaced: true,
  state: {
    otpContactNumber: "",
    authOtpError: "",
    numberGiven: false,
    businessCategories: null,
    shopLogoUrl: JSON.parse(localStorage.getItem("majhiUser"))?.store_logo,
  },

  getters: {
    getOtpContactNumber: (state) => state.otpContactNumber,
    getauthOtpError: (state) => state.authOtpError,
    numberGiven: (state) => state.numberGiven,
    businessCategories: (state) => state.businessCategories,
    getUser: () => JSON.parse(localStorage.getItem("majhiUser")),
    getShopLogoUrl: (state) => state.shopLogoUrl,
  },

  mutations: {
    SET_OTP_CONTACT_NUMBER(state, { number }) {
      state.otpContactNumber = number;
    },

    SET_AUTH_OTP_ERROR(state, { msg }) {
      state.authOtpError = msg;
    },

    SET_NUMBER_STATUS(state, data) {
      state.numberGiven = data;
    },

    SET_USER_TO_LOCAL_STORAGE(state, data) {
      let user = {
        shop_name: data.user[0].shop_name,
        shop_front_url: data.user[0].shop_front_url,
        store_logo: data.user[0].store_logo_path,
        phone: data.user[0].phone,
        lat: data.user[0].lat,
        long: data.user[0].long,
        email: data.user[0].email,
        business_category_id: data.user[0].id_business_category,
        business_category_name: data.user[0].business_category_name,
        shop_address: data.user[0].shop_address,
        id_country: data.user[0].id_country,
        id_division: data.user[0].id_division,
        id_city: data.user[0].id_city,
        id_area: data.user[0].id_area,
        area_name: data.user[0].area_name,
        city_name: data.user[0].city_name,
        division_name: data.user[0].division_name,
        apk_download_link: data.user[0].apk_download_link,
        whatsapp_chat: data.user[0].whatsapp_chat,
        instore_pickup: data.user[0].instore_pickup,
        push_notification: data.user[0].push_notification,
        facebook_url: data.user[0].facebook_url,
        instagram_url: data.user[0].instagram_url,
        youtube_url: data.user[0].youtube_url,
        linkedin_url: data.user[0].linkedin_url,
        delivery_duration: data.user[0].delivery_duration,
        store_timing: data.user[0].store_timining,
        seller_id: data.user[0].id_user,
        seller_key: data.user[0].seller_key,
      };
      localStorage.setItem("majhiUser", JSON.stringify(user));
    },

    SET_BUSINESS_CATEGORY(state, data) {
      state.businessCategories = data;
    },

    SET_SHOP_LOGO_URL(state, url) {
      state.shopLogoUrl = url;
    },
  },

  // actions
  actions: {
    async otpRequest({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await otpRequest(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        if (response.success) {
          commit("auth/SET_NUMBER_STATUS", true, {
            root: true,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log({ error });
      }
    },

    async resendOtpRequest({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await resendOtpRequest(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          commit("SET_AUTH_OTP_ERROR", { msg: "" });
        } else {
          commit("auth/SET_NUMBER_STATUS", false, {
            root: true,
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log({ error });
        commit("SET_AUTH_OTP_ERROR", { msg: error.response.data });
        commit("auth/SET_NUMBER_STATUS", false, {
          root: true,
        });
      }
    },

    async otpValidate({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await otpValidate(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        if (response.success) {
          commit("SET_AUTH_OTP_ERROR", { msg: "" });
          router.push({
            name: "SignUp1",
            params: {
              phoneNo: data.phone,
              languageCode: store.getters["language/getLanguage"],
            },
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log({ error });
        commit("SET_AUTH_OTP_ERROR", { msg: error.response.data?.data?.otp });
      }
    },

    async signInRequest({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await signIn(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        if (response.success) {
          if (response.data.status == 0) {
            router.push({
              name: "SignUp1",
              params: {
                phoneNo: data.phone,
                languageCode: store.getters["language/getLanguage"],
              },
            });
          } else if (response.data.status == 1) {
            localStorage.setItem(
              "majhiAccessToken",
              response.data.access_token
            );

            commit("SET_USER_TO_LOCAL_STORAGE", response.data);
            commit("SET_SHOP_LOGO_URL", response.data?.user[0].store_logo_path);
            localStorage.setItem("isAuthenticated", "true");
            commit("SET_AUTH_OTP_ERROR", { msg: "" });

            /*eslint-disable */
            if (false)
              // response.data.user[0].first_time_login == 1
              /*eslint-enable */
              router.push({
                name: "OnBoarding1",
                params: {
                  languageCode: store.getters["language/getLanguage"],
                  isValidForOnBoarding: true,
                },
              });
            else
              router.push({
                name: "Home",
                params: { languageCode: store.getters["language/getLanguage"] },
              });
          } else if (response.data.status == 2 || response.data.status == 3) {
            Toast.fire({
              icon: "success",
              title: response.message,
              background: successBackground,
            });
            commit("SET_NUMBER_STATUS", false);
          }
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        console.log({ error });
        commit("SET_AUTH_OTP_ERROR", { msg: error.response.data });
      }
    },

    async signOutRequest({ commit }) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await signOut();

        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          localStorage.clear();
          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
          router.push({
            name: "SignIn",
            params: { languageCode: store.getters["language/getLanguage"] },
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        Toast.fire({
          icon: "Error",
          title: "Failed to logout",
          background: errorBackground,
        });
      }
    },

    async storeDeleteRequest({ commit }) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await deleteStore();

        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (response.success) {
          localStorage.clear();
          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });
          router.push({
            name: "SignIn",
            params: { languageCode: store.getters["language/getLanguage"] },
          });
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        Toast.fire({
          icon: "Error",
          title: error.response.data.message,
          background: errorBackground,
        });
      }
    },

    async businessCategoryRequest({ commit }) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await getBusinessCategory();
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        commit("SET_BUSINESS_CATEGORY", response.data.business_category);
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        Toast.fire({
          icon: "Error",
          title: error.response.data,
          background: errorBackground,
        });

        console.log(error);
      }
    },

    async signUpRequest({ commit }, data) {
      try {
        commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
        const response = await signUp(data);
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

        if (response.success) {
          Toast.fire({
            icon: "success",
            title: response.message,
            background: successBackground,
          });

          localStorage.setItem("majhiAccessToken", response.data.access_token);

          commit("SET_USER_TO_LOCAL_STORAGE", response.data);
          commit("SET_SHOP_LOGO_URL", response.data?.user[0].store_logo_path);
          localStorage.setItem("isAuthenticated", "true");
          commit("SET_AUTH_OTP_ERROR", { msg: "" });

          return true;
        }
      } catch (error) {
        commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
        if (error.response.data) {
          Toast.fire({
            icon: "error",
            title: error.response.data[0],
            background: errorBackground,
          });
        }
        return false;
      }
    },

    async checkUserExistance(_, data) {
      const response = await checkExistingUser(data);

      if (response.data.status == 1) {
        Toast.fire({
          icon: "info",
          title: response.data.message,
          background: successBackground,
        });
        router.push({
          name: "SignIn",
          params: { languageCode: store.getters["language/getLanguage"] },
        });
        return true;
      } else if (response.data.status == 2) {
        Toast.fire({
          icon: "info",
          title: response.data.message,
          background: successBackground,
        });
        router.push({
          name: "SignIn",
          params: {
            languageCode: store.getters["language/getLanguage"],
          },
        });
        return true;
      }

      return false;
    },
  },
};
