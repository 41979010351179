<template>
  <div class="d-flex app_category_search_container w-100">
    <div
      class="input-group my-3 border border-1 bg-white width-50-100 search-box__container common-border__radius"
    >
      <div
        class="bg-white common-border__radius d-flex justify-content-center align-items-center"
        style="padding-left:10px"
      >
        <img src="../../assets/icon/search.svg" alt="" height="23" width="23" />
      </div>
      <input
        type="text"
        class="form-control no-border common-border__radius py-3"
        :placeholder="$t('product.category.placeholder')"
        aria-label=""
        aria-describedby="basic-addon1"
        v-model.trim="searchField"
        @keyup="requestSearch"
      />
    </div>
    <div class="my-3">
      <!-- <router-link
        :to="{
          name: 'AppDirectProductCategoryAssign',
          params: { languageCode: getLanguage },
        }"
        class="btn btn-primary google_sans_medium py-3 px-4 d-flex justify-content-center align-items-center"
      >
        <img src="../../assets/icon/button-add.svg" alt="" />

        <span style="margin-left:10px">
          {{
            $t("product.category.direct_category_assign.update_heading")
          }}</span
        >
      </router-link> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CategoriesSearch",
  props: ["businessCategoryId"],
  emits: ["requestCreateCategory", "requestCategorySearch"],
  components: {},

  data: function() {
    return {
      image: null,
      url: "",
      categoryName: [],
      searchField: "",
      errorShopLogo: "",
      errorCategoryName: "",
      blobImage: null,
      originalImageUrl: "",
    };
  },

  computed: { ...mapGetters("language", ["getLanguage"]) },

  methods: {
    requestSearch() {
      this.$store.commit(
        "productCategories/FILTER_CATEGORY",
        this.searchField,
        {
          root: true,
        }
      );
    },
  },
};
</script>

<style>
#addCategoriesModal .multiselect-tag {
  position: relative;
  padding: 8px !important;

  background-color: #eaeaea !important;
  color: black !important;
  font-weight: normal !important;
  border-radius: 10px !important;
}

#addCategoriesModal .multiselect-tag-remove {
  display: none !important;
}

#addCategoriesModal .multiselect.is-active {
  box-shadow: none;
}

.app_category_search_container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.search-box__container {
  min-width: 250px !important;
}

.no-border {
  border: 0 !important;
  box-shadow: none; /* You may want to include this as bootstrap applies these styles too */
  outline-width: 0;
}

.form-control:focus {
  box-shadow: none !important;
}

.dotted-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%233A86FFFF' stroke-width='4' stroke-dasharray='12%2c16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 7px;
  max-width: 12rem;
  min-height: 11em;
  /* font-size: 15px; */
  font-size: 0.9375rem;

  font-weight: 500;
}

.modal {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.modal-backdrop {
  background-color: transparent !important;
}

.modal-close {
  border: navajowhite;
  background-color: white;
}

@media only screen and (max-width: 700px) {
  .app_category_search_container {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
  align-items: center; */
  }

  .app_category_search_container input {
    padding: 1rem 0;
  }
}
</style>
