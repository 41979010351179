import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getAllPages() {
    return Client({
      url: `/seller_extra_pages_list`,
      method: "GET",
    });
  },

  getPagesBasedOnStatus(status) {
    return Client({
      url: `/seller_extra_pages_list?is_published=${status}`,
      method: "GET",
    });
  },

  deletePage(id) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `seller_extra_page_delete/${language}/${id}`,
      method: "DELETE",
    });
  },

  createPage(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_extra_page_create/${language}`,
      method: "POST",
      data,
    });
  },

  updatePage(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_extra_page_update/${language}`,
      method: "PUT",
      data,
    });
  },

  loadSinglePage(id) {
    return Client({
      url: `/seller_extra_pages_list?id_extra_page=${id}`,
      method: "GET",
    });
  },
};
