import Repository from "../../api/RepositoryFactory";
import Toast, {
  successBackground,
  errorBackground,
} from "../../config/toastConfig";
const FavIcon = Repository.get("FavIcon");

const { getFavicon, uploadFavicon, deleteFavicon } = FavIcon;

const state = {
  imageUrl: "",
  faviconStatus: false,
};
const getters = {
  getImageUrl: (state) => state.imageUrl,
  faviconStatus: (state) => state.faviconStatus,
};
const mutations = {
  SET_IMAGE_URL: (state, data) => {
    state.imageUrl = data;
  },

  SET_FAVICON_STATUS: (state, data) => {
    state.faviconStatus = data;
  },
};

const actions = {
  async getFaviconRequest({ commit }) {
    try {
      const response = await getFavicon();

      if (response.success && response?.data?.favicon_url) {
        commit("SET_IMAGE_URL", response.data.favicon_url);
        commit("SET_FAVICON_STATUS", true);
      }
    } catch (error) {
      commit("SET_FAVICON_STATUS", false);
      console.log({ error });
    }
  },
  async uploadFaviconRequest({ commit }, data) {
    try {
      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await uploadFavicon(data);
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      if (response.success) {
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });
        commit("SET_FAVICON_STATUS", true);
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      console.log({ error });
      Toast.fire({
        icon: "error",
        title: error.response.data,
        background: errorBackground,
      });
      commit("SET_FAVICON_STATUS", false);
    }
  },

  async faviconDeleteRequest({ state, commit }) {
    try {
      if (!state.faviconStatus) {
        commit("SET_IMAGE_URL", "");
        return;
      }

      commit("Loader/SET_LOADER_VISIBILITY", true, { root: true });
      const response = await deleteFavicon();
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });

      if (response.success) {
        commit("SET_IMAGE_URL", "");
        commit("SET_FAVICON_STATUS", false);
        Toast.fire({
          icon: "success",
          title: response.message,
          background: successBackground,
        });

        localStorage.removeItem("favicon");
      }
    } catch (error) {
      commit("Loader/SET_LOADER_VISIBILITY", false, { root: true });
      Toast.fire({
        icon: "error",
        title: error.response.data,
        background: errorBackground,
      });

      commit("SET_FAVICON_STATUS", true);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
