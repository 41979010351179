<template>
    <div class="wrapper">
      <img class="image" :src="imageUrl()" alt="background">
       
         <div class="body-content d-flex align-items-center">
            <div class="d-flex align-items-center">
              <img :src=" loadLogo()" alt="logo" class="logo">
              <div>
                <p class="store-name">Classic<br/>Store</p>
                <p class="category-name">Mobile & Technology</p>
              </div>
            </div>
            <div class="divider"></div>
            <div class="contact-info">
              <p class="store-url">MAJHI.COM/CLASSICSTORE</p>
              <div class="info">
                <img :src="loadPhone()" alt="icon" class="icon">
                <span>+123-456-7890</span>
              </div>

              <div class="info">
                <img :src="loadHome()" alt="icon" class="icon">
                <span>hello@reallygreatsite.com</span>
              </div>

                <div class="info">
                <img :src="loadEmail()" alt="icon" class="icon">
                <span>123 Anywhere St., Any City</span>
              </div>
            </div>
          </div>
      
     </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    imageUrl() {
      return require("@/assets/marketing-materials/business-card/card-12/BC-12.png");
    },

    loadLogo(){
      return require("@/assets/marketing-materials/business-card/card-12/Group.png");
    },

    loadPhone(){
      return require("@/assets/marketing-materials/business-card/card-12/phone.png");
    }, 

    loadHome(){
      return require("@/assets/marketing-materials/business-card/card-12/home.png");
    }, 

    loadEmail(){
      return require("@/assets/marketing-materials/business-card/card-12/email.png");
    },

  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');

*{
  margin: 0;
  padding: 0;
}

.wrapper{
  margin-top: 10px;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 10px;
  font-size: 10px;
  position: relative;
  color: #FFFFFF;
}


.body-content{
  position: absolute;
  top: 15em;
  left: 10em;
}



.image{
  width: 100%;
}


.logo{
  width: 10em;
  margin-right: 1em;
}


.icon{
  width: 1.5em;
}


.store-name{
  font-size: 3.5em;
  font-weight: 900;
  letter-spacing: 0.31px;
  font-family: 'Playfair Display', serif;
}

.category-name{
  font-size: 1.7em;
  letter-spacing: 0.96px;
  font-weight: 500;
  margin-top: .7em;
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
}

.contact-info{
  font-size: 1.6em;
  font-family: 'Andika', sans-serif;
}


.store-url{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 1em;
  letter-spacing: 0.44px;
}


.info{
  margin-bottom: .5em;
}

.info span{
  margin-left: .3em;
}

.divider{
  width: 2px;
  height: 17em;
  background: #FFFFFF;
  margin: 0 1.5em;

}




@media only screen and (max-width: 600px){
	.wrapper{
      width: 100%;
      margin-left: 0;
      font-size: 5px;
    }

  .divider{
    margin: 0 0.3em;
  }


  .body-content{
    position: absolute;
    top: 12em;
    left: 7em;
  }

  .logo{
    width: 8em;
    margin-right: 0.6em;
  }

  
}
</style>
