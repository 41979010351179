<template>
  <div class="p-4 create-attribute">
    <h5>{{ $t("product.main.product_attribute.title") }}</h5>
    <div class="mt-4">
      <p class="mb-2">
        {{ $t("product.main.product_attribute.product_category") }}
        <span class="text-primary">*</span>
      </p>
      <input
        type="text"
        name=""
        id=""
        class="inp-field w-100"
        style="background: #f8fbff; color: #1d1d1d !important"
        :value="attributeList[0]?.product_category_name"
        readonly
      />
    </div>

    <div
      v-for="attribute in attributeList"
      :key="attribute.id_global_attribute"
    >
      <div
        class="mt-4"
        v-if="attribute.global_attribute_name.toLowerCase() == 'color'"
      >
        <p class="mb-2">{{ $t("product.main.product_attribute.color") }}</p>
        <div class="d-flex">
          <div class="w-100 me-3 position-relative">
            <div class="select-color-picker-div d-flex align-items-center">
              <div
                class="selected-color"
                v-if="singleColorCode != ''"
                :style="{ background: singleColorCode }"
              ></div>
              <p
                class="state-color__fg--Info normal-text make-cursor mb-0 ms-2"
                @click="toggleColorPicker()"
              >
                {{ $t("product.main.product_attribute.select_color") }}
              </p>
            </div>

            <color-picker
              :showColorPicker="showColorPicker"
              @requestUpdateColorPickerFlag="
                requestUpdateColorPickerFlag($event)
              "
              @requestToAddSingleColor="addSingleColor($event)"
            />
          </div>
          <button
            type="button"
            class="btn btn-primary px-4"
            @click="
              createAttribute({
                id_product_category: attribute.id_product_category,
                id_global_attribute: attribute.id_global_attribute,
                name: singleColorCode,
              })
            "
          >
            {{ $t("product.main.product_attribute.add_btn") }}
          </button>
        </div>
      </div>

      <div class="mt-4" v-else>
        <p class="mb-2">
          {{
            attribute.global_attribute_name.charAt(0).toUpperCase() +
              attribute.global_attribute_name.slice(1)
          }}
        </p>
        <div class="d-flex">
          <input
            type="text"
            v-model="newAttributeValue[attribute.id_global_attribute]"
            class="inp-field w-100 me-3"
            :class="
              newAttributeAddError[attribute.id_global_attribute]
                ? 'border-danger'
                : ''
            "
            :placeholder="attributePlaceHolder(attribute.global_attribute_name)"
            @keyup.enter="
              createAttribute({
                id_product_category: attribute.id_product_category,
                id_global_attribute: attribute.id_global_attribute,
                name: newAttributeValue[attribute.id_global_attribute],
              })
            "
          />
          <button
            type="button"
            class="btn btn-primary px-4"
            @click="
              createAttribute({
                id_product_category: attribute.id_product_category,
                id_global_attribute: attribute.id_global_attribute,
                name: newAttributeValue[attribute.id_global_attribute],
              })
            "
          >
            {{ $t("product.main.product_attribute.add_btn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { mapGetters, mapActions } from "vuex";
import Toast, {
  errorBackground,
  successBackground,
} from "../../../config/toastConfig";

import ColorPicker from "../../color-picker/ColorPicker.vue";
export default {
  components: { ColorPicker },
  emits: ["requestToSetNewAttributeList"],
  props: ["allAttributes"],
  data: function() {
    return {
      allCategories: [],
      selectedCategory: {},
      selectedCategoryId: 0,

      attributeList: [],
      newAttributeValue: [],
      newAttributeAddError: [],

      //color picker variables
      showColorPicker: 0,
      singleColorCode: "",

      businessCategoryId: 0,
    };
  },

  computed: {
    ...mapGetters("product", ["categoryAttributeValue"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  watch: {
    allAttributes(val) {
      this.attributeList = val;
    },
  },

  methods: {
    // function for color picker library
    ...mapActions("Loader", ["setOverlayStatus"]),

    attributePlaceHolder(data) {
      if (data.toLowerCase() === "size") {
        return 'Enter Size (eg. "S" or "L" etc.)';
      } else {
        return "Enter " + data;
      }
    },

    toggleColorPicker() {
      if (this.showColorPicker == 1) {
        this.showColorPicker = 0;
      } else if (this.showColorPicker == 0) {
        this.showColorPicker = 1;
      } else if (this.showColorPicker == 2) {
        this.showColorPicker = 1;
      }
    },

    requestUpdateColorPickerFlag(flag) {
      this.showColorPicker = flag;
    },

    addSingleColor(code) {
      this.singleColorCode = code;
      this.showColorPicker = false;
    },

    // function for color picker library ends

    createAttribute(formData) {
      const vm = this;
      vm.setOverlayStatus(true);
      axios
        .post(`/seller_attribute_value_create/${this.getLanguage}`, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("majhiAccessToken"),
          },
        })
        .then(function(response) {
          localStorage.setItem("newAttributeValueFlag", "yes");
          vm.newAttributeAddError[formData.id_global_attribute] = false;
          vm.newAttributeValue[formData.id_global_attribute] = "";
          vm.$emit("requestToSetNewAttributeList");
          vm.singleColorCode = "";
          vm.setOverlayStatus(false);
          Toast.fire({
            icon: "success",
            title: response?.data?.message,
            background: successBackground,
          });
        })
        .catch(function(error) {
          vm.newAttributeAddError[formData.id_global_attribute] = true;
          if (error.response.data.data.name) {
            Toast.fire({
              icon: "error",
              title: error.response.data.data.name[0],
              background: errorBackground,
            });
          }
          vm.setOverlayStatus(false);
        });
    },

    extractUserData() {
      let userObject = localStorage.getItem("majhiUser");
      let authUser = JSON.parse(userObject);

      this.businessCategoryId = authUser.business_category_id;
    },
  },

  async created() {
    this.$store.commit("setOverlayStatus", true);
    await this.extractUserData();
    this.$store.commit("setOverlayStatus", false);
  },
};
</script>

<style>
.create-attribute .inp-field {
  height: 50px;

  border-radius: 8px;
  border: 1px solid #bdbdbd;
  color: black !important;
  padding-left: 10px;
}

.select-color-picker-div {
  height: 50px;
  border: 1px solid #bdbdbd;
  width: 100%;
  border-radius: 8px;
  padding-left: 10px;
}

.selected-color {
  height: 40px;
  width: 40px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
}
</style>
