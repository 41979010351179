<template>
  <div>
    <div class="mb-3" style="padding-top:30px !important">
      <h4>{{ $t("home.order.title") }}</h4>
    </div>
    <div
      class="d-flex justify-content-center justify-content-md-between justify-content-lg-between justify-content-xl-between align-items-center flex-column flex-lg-row flex-md-row flex-xl-row"
    >
      <div
        class="my-3 border border-1 bg-white common-border__radius d-flex width-50-100"
      >
        <div
          class="bg-white my-auto px-2 d-flex common-border__radius search-icon-container"
        >
          <img
            style="margin-left:7px"
            src="../../assets/icon/search.svg"
            alt=""
            class="my-auto"
            width="25"
            @click="searchOrder"
          />
        </div>
        <form
          class="d-flex align-items-center w-100"
          @submit.prevent="searchOrder"
        >
          <input
            type="text"
            class="form-control no-border common-border__radius google_sans_normal"
            :placeholder="$t('home.order.placeholder')"
            id="order-search"
            aria-label=""
            aria-describedby="basic-addon1"
            v-model="searchKeyword"
            style="min-height: 50px"
          />
        </form>
      </div>
      <div class="">
        <select
          class="form-select form-select-lg order-select__timeline common-border__radius-8 google_sans_medium order-filter-text-size"
          aria-label=".form-select-lg example"
          @click="openCustomDateModal($event)"
          @change="getDataByDays($event)"
        >
          <option class="order-filter-text-size" value="1" selected>{{
            $t("home.order.filter.all")
          }}</option>
          <option class="order-filter-text-size" value="7">{{
            $t("home.order.filter.last_7")
          }}</option>
          <option class="order-filter-text-size" value="14">{{
            $t("home.order.filter.last_14")
          }}</option>

          <option class="order-filter-text-size" value="custom">{{
            $t("home.order.filter.custom")
          }}</option>
        </select>

        <button
          type="button"
          class="btn btn-primary d-none p-3"
          data-bs-toggle="modal"
          data-bs-target="#CustomOrderSearch"
          id="modalOpen"
        >
          Custom Order Search
        </button>

        <custom-order-search />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import CustomOrderSearch from "./CustomOrderSearch.vue";
export default {
  data: function() {
    return {
      searchKeyword: "",
    };
  },
  components: {
    CustomOrderSearch,
  },

  methods: {
    ...mapMutations("Order", [
      "SET_CUSTOM_SEARCH_STATUS",
      "SET_SEARCH_KEYWORD",
    ]),
    getDataByDays(event) {
      if (
        document.getElementById("order-search") &&
        !document.getElementById("order-search").value
      ) {
        this.SET_SEARCH_KEYWORD("");
      }

      if (event.target.value === "custom") {
        document.getElementById("modalOpen").click();
        return;
      }
      this.SET_CUSTOM_SEARCH_STATUS(false);
      this.$store.commit("setOverlayStatus", true); //loader open
      this.$emit("requestToSetTimeBindings", event.target.value);
    },

    searchOrder() {
      this.$store.commit("setOverlayStatus", true); //loader open

      this.$emit("requestToFilteredData", this.searchKeyword);
      this.SET_SEARCH_KEYWORD(this.searchKeyword);

      // if (parseInt(this.searchKeyword)) {
      //   this.$emit("requestToFilteredData", this.searchKeyword);
      //   this.SET_SEARCH_KEYWORD(this.searchKeyword);
      // } else {
      //   this.$emit("requestToFilteredData", this.searchKeyword);
      //   this.SET_SEARCH_KEYWORD(this.searchKeyword);
      // }
    },

    openCustomDateModal(event) {
      var isChrome = navigator.userAgent.indexOf("Chrome") != -1;

      if (!isChrome) return;

      if (event.detail === 0) {
        if (
          document.getElementById("order-search") &&
          !document.getElementById("order-search").value
        ) {
          this.SET_SEARCH_KEYWORD("");
        }

        if (event.target.value === "custom") {
          document.getElementById("modalOpen").click();
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.order-filter-text-size {
  font-size: 18px !important;
}

.order-select__timeline {
  background-color: #f3f6f9 !important;
  border-color: #3a86ff !important;
  color: #3a86ff !important;
}

.form-select {
  background-image: url("../../assets/icon/dropdown-blue.svg") !important;
}

.search-icon-container {
  width: 7%;
}

.search-box-container {
  width: 92%;
}

#order-search {
  height: 100%;
}

@media only screen and (max-width: 1300px) {
  .search-icon-container {
    width: 12%;
  }

  .search-box-container {
    width: 88%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
</style>
