<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container">
          <div class="m-3 d-flex">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'CouponView',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="ms-3 my-0">
              {{ $t("manage.main.coupon_form.update_title") }}
            </h4>
          </div>

          <div class="bg-white common-border__radius w-60-96 my-4 mx-auto p-4">
            <h5>{{ $t("manage.main.coupon_form.update_title") }}</h5>

            <div class="my-3">
              <div class="form-group mt-4 mb-3">
                <label for="inputcouponcode" class="state-color__fg--grey"
                  >{{ $t("manage.main.coupon_form.coupon_code") }}
                  <span class="state-color__fg--Info">*</span></label
                >
                <input
                  class="form-control form-control-lg my-2"
                  :class="createErrorCodes == 1 ? 'border-danger' : ''"
                  type="text"
                  :placeholder="
                    $t('manage.main.coupon_form.coupon_code_placeholder')
                  "
                  v-model="code"
                />
              </div>

              <div class="form-group my-3">
                <label for="inputcouponcode" class="state-color__fg--grey"
                  >{{ $t("manage.main.coupon_form.upc") }}
                  <span class="state-color__fg--Info">*</span></label
                >
                <input
                  class="form-control form-control-lg my-2"
                  :class="createErrorCodes == 2 ? 'border-danger' : ''"
                  type="text"
                  :placeholder="$t('manage.main.coupon_form.upc_placeholder')"
                  v-model="usePerCustomer"
                />
              </div>

              <div class="form-group my-3">
                <label for="inputcouponcode" class="state-color__fg--grey"
                  >{{ $t("manage.main.coupon_form.noc") }}
                  <span class="state-color__fg--Info">*</span></label
                >
                <input
                  class="form-control form-control-lg my-2"
                  :class="createErrorCodes == 3 ? 'border-danger' : ''"
                  type="text"
                  :placeholder="$t('manage.main.coupon_form.noc_placeholder')"
                  v-model="TotalAvailableCoupons"
                />
              </div>
            </div>
          </div>

          <div class="bg-white common-border__radius w-60-96 my-4 mx-auto p-4">
            <h5>{{ $t("manage.main.coupon_form.discount_type") }}</h5>

            <div class="my-3">
              <div>
                <label class="radio-inline ms-1 me-3">
                  <input
                    type="radio"
                    name="discountType"
                    value="1"
                    :checked="couponType == 1"
                    @click="toggleCoupon($event)"
                  /><span class="mx-2">{{
                    $t("manage.main.coupon_form.amount")
                  }}</span>
                </label>
                <label class="radio-inline mx-3">
                  <input
                    type="radio"
                    name="discountType"
                    value="2"
                    @click="toggleCoupon($event)"
                    :checked="couponType == 2"
                  /><span class="mx-2">{{
                    $t("manage.main.coupon_form.percentage")
                  }}</span>
                </label>
              </div>

              <div class="row mt-2">
                <div
                  class="col-md-6 form-group discount-inputfield m-query-width-90 mt-2"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    ><span v-if="couponType == 1">{{
                      $t("manage.main.coupon_form.discount_amount")
                    }}</span>
                    <span v-else>{{
                      $t("manage.main.coupon_form.discount_percent")
                    }}</span>
                    <span class="state-color__fg--Info"> *</span></label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-1 p-1"
                    :class="createErrorCodes == 4 ? 'border-danger' : ''"
                  >
                    <span
                      v-if="couponType == 1"
                      class="text-center mx-1 my-auto"
                      >&#2547;
                    </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px; border: none"
                      :placeholder="placeHolderValue"
                      autocomplete="off"
                      v-model="discountPrice"
                    />
                  </div>
                </div>
                <div
                  class="col-md-6 form-group discount-inputfield m-query-width-90 mt-2"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >{{ $t("manage.main.coupon_form.min_order") }}
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-1 p-1"
                    :class="createErrorCodes == 5 ? 'border-danger' : ''"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px; border: none"
                      :placeholder="
                        $t('manage.main.coupon_form.min_order_placeholder')
                      "
                      autocomplete="off"
                      v-model="minPrice"
                    />
                  </div>
                </div>
              </div>

              <div v-if="couponType == 2" class="row mt-2">
                <div
                  class="col-md-6 form-group discount-inputfield m-query-width-90 mt-2"
                >
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >{{ $t("manage.main.coupon_form.max_discount") }}
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <div
                    class="quantity-input m-query-width-90 d-flex border rounded my-1 p-1"
                    :class="createErrorCodes == 6 ? 'border-danger' : ''"
                  >
                    <span class="text-center mx-1 my-auto">&#2547; </span>
                    <input
                      type="text"
                      class="form-control width-80 1"
                      style="height: 40px; border: none"
                      :placeholder="
                        $t('manage.main.coupon_form.max_discount_placeholder')
                      "
                      autocomplete="off"
                      v-model="maxPrice"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-4 d-none">
                <p class="medium-text google_sans_medium">Make It Public?</p>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked
                    disabled
                  />
                </div>
              </div>
              <div>
                <p class="state-color__fg--grey1 d-none">
                  All users will see this coupon
                </p>
              </div>
            </div>
          </div>

          <div class="w-60-96 my-4 mx-auto">
            <button
              type="button"
              class="btn btn-primary float-end px-4 py-3"
              @click="submitUpdateCouponForm()"
            >
              {{ $t("manage.main.coupon_form.update_btn") }}
            </button>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import Toast, { errorBackground } from "../../config/toastConfig";

export default {
  components: { SideBar, TopBar, LoadingSpinner },
  data() {
    return {
      code: "",
      usePerCustomer: "",
      TotalAvailableCoupons: "",
      discountPrice: "",
      minPrice: "",
      maxPrice: "",

      createErrorCodes: 0,
    };
  },

  computed: {
    ...mapGetters("coupon", ["couponType", "singleCoupon"]),
    ...mapGetters("language", ["getLanguage"]),
    ...mapState("coupon", ["setCouponType"]),

    placeHolderValue() {
      if (this.couponType == 1) {
        return this.$t("manage.main.coupon_form.discount_amount_placeholder");
      } else {
        return this.$t("manage.main.coupon_form.discount_percent_placeholder");
      }
    },
  },

  //   computed: mapGetters(["singleCoupon"]),

  watch: {
    // getLanguage(oldValue, newVal) {},

    "$store.state.language.language": function() {
      console.log(this.$store.state.drawer);
    },
  },

  created() {
    let id = this.$route.params.id;
    if (id) {
      this.$store.commit("coupon/GET_SINGLE_COUPON", { id });
      let data = this.singleCoupon;
      if (Object.keys(data).length === 0)
        this.$router.push({ name: "CouponView" });
    }
  },

  mounted() {
    let id = this.$route.params.id;
    this.$store.commit("coupon/GET_SINGLE_COUPON", { id });
    let data = this.singleCoupon;
    this.code = data.code;
    this.usePerCustomer = data.use_per_customer;
    this.TotalAvailableCoupons = data.total_available_coupon;
    this.discountPrice =
      data.discount_type === "percent"
        ? data.discount_price_percent
        : data.discount_price;
    this.minPrice = data.min_price;
    if (data.max_price_percent) {
      this.maxPrice = data.max_price_percent;
    }
    if (data.discount_type === "percent") {
      this.$store.commit("coupon/setCouponType", {
        type: 2,
      });
    } else {
      this.$store.commit("coupon/setCouponType", {
        type: 1,
      });
    }
  },

  methods: {
    ...mapActions("coupon", ["updateCoupon"]),
    toggleCoupon(event) {
      if (this.couponType != event.target.value) {
        this.$store.commit("coupon/setCouponType", {
          type: event.target.value,
        });
      }
    },

    async submitUpdateCouponForm() {
      //const formData = new FormData(),

      const formData = new URLSearchParams();
      //let vm = this;
      let verifiedInputs = 1;

      verifiedInputs = await this.verifyInputs();
      if (verifiedInputs == 0) {
        this.$store.commit("setOverlayStatus", false); //loader close
        return;
      }

      formData.append("id_coupon", this.$route.params.id);
      formData.append("code", this.code);
      formData.append("use_per_customer", this.usePerCustomer);
      formData.append("total_available_coupon", this.TotalAvailableCoupons);
      if (this.couponType == 1) {
        formData.append("discount_type", "amount");
        formData.append("discount_price", this.discountPrice);
      } else {
        formData.append("discount_type", "percent");
        formData.append("discount_price_percent", this.discountPrice);
        formData.append("max_price", this.maxPrice);
      }
      formData.append("min_price", this.minPrice);
      formData.append("max_price_percent", this.maxPrice);

      // ---call the function for updating coupon.----
      await this.updateCoupon(formData);
    },

    verifyInputs() {
      if (this.code == "") {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.empty_field"),
          background: errorBackground,
        });
        this.createErrorCodes = 1;
      } else if (this.code.length >= 30) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.coupon_length"),
          background: errorBackground,
        });
        this.createErrorCodes = 1;
      } else if (
        this.usePerCustomer == "" ||
        !Number.isInteger(Number(this.usePerCustomer)) ||
        isNaN(this.usePerCustomer)
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.uses_per_customer"),
          background: errorBackground,
        });
        this.createErrorCodes = 2;
      } else if (
        this.TotalAvailableCoupons == "" ||
        !Number.isInteger(Number(this.TotalAvailableCoupons)) ||
        isNaN(this.TotalAvailableCoupons)
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.total_coupon_integer"),
          background: errorBackground,
        });
        this.createErrorCodes = 3;
      } else if (
        this.discountPrice == "" ||
        isNaN(this.discountPrice) ||
        !Number.isInteger(Number(this.discountPrice))
      ) {
        if (this.couponType == 1) {
          Toast.fire({
            icon: "error",
            title: this.$t("manage.validation.coupon.discount_price_integer"),
            background: errorBackground,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: this.$t("manage.validation.coupon.discount_price_per_int"),
            background: errorBackground,
          });
        }
        this.createErrorCodes = 4;
      } else if (this.couponType == 2 && Number(this.discountPrice) >= 100) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.dis_pri_less_100"),
          background: errorBackground,
        });

        this.createErrorCodes = 4;
      } else if (this.minPrice == "" || isNaN(this.minPrice)) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.min_order_int"),
          background: errorBackground,
        });
        this.createErrorCodes = 5;
      } else if (
        this.couponType == 2 &&
        (this.maxPrice == "" || isNaN(this.maxPrice))
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.max_dis_int"),
          background: errorBackground,
        });
        this.createErrorCodes = 6;
      } else if (
        this.couponType == 1 &&
        Number(this.discountPrice) >= Number(this.minPrice)
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.min_order_g_dis_amount"),
          background: errorBackground,
        });
      } else if (
        Number(this.usePerCustomer) > Number(this.TotalAvailableCoupons)
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t(
            "manage.validation.coupon.num_coupon_g_uses_per_customer"
          ),
          background: errorBackground,
        });
      } else if (Number(this.usePerCustomer) <= 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.uses_per_customer_g_0"),
          background: errorBackground,
        });
      } else if (Number(this.TotalAvailableCoupons) <= 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.total_avail_coupon_g_0"),
          background: errorBackground,
        });
      } else if (this.couponType == 1 && Number(this.discountPrice) <= 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.dis_amount_g_0"),
          background: errorBackground,
        });
      } else if (this.couponType == 2 && Number(this.discountPrice) <= 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.dis_per_g_0"),
          background: errorBackground,
        });
      } else if (Number(this.minPrice) <= 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.min_order_g_0"),
          background: errorBackground,
        });
      } else if (this.couponType == 2 && Number(this.maxPrice) <= 0) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.max_dis_g_0"),
          background: errorBackground,
        });
      } else if (!Number.isInteger(Number(this.minPrice))) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.min_order"),
          background: errorBackground,
        });
      } else if (!Number.isInteger(Number(this.maxPrice))) {
        Toast.fire({
          icon: "error",
          title: this.$t("manage.validation.coupon.max_discount"),
          background: errorBackground,
        });
      } else {
        this.createErrorCodes = 0;
        return 1;
      }

      return 0;
    },
  },
};
</script>

<style scoped>
.border-danger {
  border: 1px solid #f13939;
}

.w-60-96 {
  width: 60%;
}
.form-control-lg {
  height: 50px !important;
  /* font-size: 18px !important; */
  font-size: 1.125rem !important;
}

.discount-inputfield {
  width: 48%;
}

@media only screen and (max-width: 800px) {
  .w-60-96 {
    width: 96%;
  }
}
</style>
