<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container" style="min-width: 500px;">
          <div class="m-3 d-flex">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="$router.go(-1)"
            />
            <h4 class="ms-3 my-0">Upload Banner(upto 3)</h4>
          </div>

          <div>
            <drop-zone
              v-bind:existingImages="existingImages"
              v-on:requestUploadBannerImages="uploadBannerImage($event)"
              v-on:requestDeleteExistingImage="deleteExistingImage($event)"
              v-on:requestShowToast="showToast"
              ref="bannerDropZone"
            />
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
    <!-- Extra Charge Modal -->
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import DropZone from "../../components/manage/DropZone.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import Toast, {
  errorBackground,
  successBackground,
} from "../../config/toastConfig";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { SideBar, TopBar, LoadingSpinner, DropZone },
  data: function() {
    return {
      existingImages: [],
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    showToast(data) {
      Toast.fire({
        icon: "error",
        title: data,
        background: errorBackground,
      });
    },

    uploadBannerImage(allImages) {
      let vm = this;
      const formData = new FormData();

      allImages.forEach((file) => {
        formData.append("banner_image[]", file.image);
      });

      axios
        .post(`/banner_create/${this.getLanguage}`, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("majhiAccessToken"),
          },
        })
        .then(function(response) {
          vm.getExistingBannerImages();

          Toast.fire({
            icon: "success",
            title: response.data.message,
            background: successBackground,
          });

          //vm.toast("Banner image uploaded successfully", "success");
        })
        .catch(function(error) {
          vm.$store.commit("setOverlayStatus", false); //loader close
          //vm.toast("Image upload failed", "danger");
          Toast.fire({
            icon: "error",
            title: vm.$t("manage.validation.banner.upload_failed"),
            background: errorBackground,
          });

          console.log(error);
        });
    },

    deleteExistingImage(imgPath) {
      let vm = this;
      axios
        .post(
          `/remove_banner/${this.getLanguage}`,
          {
            banner_image_path: imgPath,
          },
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("majhiAccessToken"),
            },
          }
        )
        .then(function(response) {
          let i = 0;
          for (i = 0; i < vm.existingImages.length; i++) {
            if (vm.existingImages[i].image_path == imgPath) {
              vm.existingImages.splice(i, 1);
              break;
            }
          }

          vm.$store.commit("setOverlayStatus", false); //loader close
          Toast.fire({
            icon: "success",
            title: response.data.message,
            background: successBackground,
          });
        })
        .catch(function(error) {
          vm.$store.commit("setOverlayStatus", false); //loader close
          //vm.toast("Failed to delete banner image", "danger");
          Toast.fire({
            icon: "error",
            title: vm.$t("manage.validation.banner.delete_failed"),
            background: errorBackground,
          });

          console.log(error);
        });
    },

    getExistingBannerImages() {
      let vm = this;
      axios
        .get(`/banners_list/${this.getLanguage}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("majhiAccessToken"),
          },
        })
        .then(function(response) {
          vm.existingImages = response.data.data;
          if (vm.existingImages == null) {
            vm.existingImages = [];
          }
          vm.$refs.bannerDropZone.allImagesInfo = [];
          vm.$store.commit("setOverlayStatus", false); //loader close
        })
        .catch(function(error) {
          vm.$store.commit("setOverlayStatus", false); //loader close
          //vm.toast("Failed to get existing banner", "danger");

          Toast.fire({
            icon: "error",
            title: vm.$t("manage.validation.banner.get_banner_error"),
            background: errorBackground,
          });

          console.log(error);
        });
    },
  },

  created() {
    this.$store.commit("setOverlayStatus", true); //loader close
    this.getExistingBannerImages();
  },
};
</script>

<style></style>
