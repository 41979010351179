<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container " v-if="orderDetails">
          <div
            class="
              mx-3
              state-color__fg--grey
              d-flex
              justify-content-between
              flex-column flex-lg-row flex-md-row flex-xl-row
            "
          >
            <div
              class="d-flex justify-content-start align-items-center state-color__fg--grey page-heading-top-padding"
              style="margin-bottom:30px !important"
            >
              <div style="margin-right:20px !important">
                <img
                  src="../../assets/icon/back_btn_image.svg"
                  alt="back"
                  class="make-cursor"
                  @click="
                    $router.push({
                      name: 'AllOrders',
                      params: { languageCode: getLanguage },
                    })
                  "
                />
              </div>
              <h4 class="ms-2 my-0">
                {{ $t("home.order.details.title") }}
              </h4>
            </div>

            <div class="py-2">
              <button
                style="color: #3a86ff"
                type="button"
                class="btn border border-primary m-1 py-2 px-4"
                data-bs-toggle="modal"
                data-bs-target="#cancelConfirationModal"
                v-if="
                  orderDetails &&
                    orderDetails.id_order_status != 6 &&
                    orderDetails &&
                    orderDetails.id_order_status != 3
                "
              >
                {{ $t("home.order.details.cancelled_order") }}
              </button>
              <button
                type="button"
                class="btn m-1 py-2 px-4"
                style="background-color: #3a86ff; color: white"
                @click="updateOrderStatus(orderDetails.next_id_order_status)"
                v-if="orderDetails.next_id_order_status != 0"
              >
                {{ orderDetails.next_order_status_name }}
              </button>

              <!-- refund btn
              <button
                type="button"
                class="btn btn-outline-danger m-1 py-2 px-4"
                @click="updateOrderStatus(orderDetails.next_id_order_status)"
                v-if="orderDetails.id_order_status == 3"
              >
                Refund
              </button> -->
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <div class="order-details-info-container">
              <div class="order-basic-info p-3 order-details-header">
                <div class="d-flex justify-content-between flex-wrap">
                  <h4>ID{{ orderDetails.id_order }}</h4>
                  <div class="">
                    <!-- <span class="code-badge">COD</span> -->

                    <!-- shipping status  -->
                    <span
                      class="mb-1 status-badge shipped-badge"
                      v-if="orderDetails.order_status == 'Shipped'"
                      >Shipped</span
                    >
                    <span
                      class="mb-1 status-badge pending-badge"
                      v-else-if="orderDetails.order_status == 'Pending'"
                      >Pending</span
                    >
                    <span
                      src="../../assets/order-buttons/orderDeliveed.svg"
                      alt=""
                      class="mb-1 status-badge delivered-badge"
                      v-else-if="orderDetails.order_status == 'Delivered'"
                      >Delivered</span
                    >
                    <span
                      src="../../assets/order-buttons/orderAccepted.svg"
                      alt=""
                      class="mb-1 status-badge accepted-badge"
                      v-else-if="orderDetails.order_status == 'Accepted'"
                      >Accepted</span
                    >
                    <span
                      class="mb-1 status-badge cancelled-badge"
                      v-else-if="orderDetails.order_status == 'Cancelled'"
                      >Cancelled</span
                    >

                    <span
                      class="mb-1 status-badge instore-badge"
                      v-else-if="
                        orderDetails.order_status == 'Instore' ||
                          orderDetails.order_status ==
                            'Ready for In-store Pickup'
                      "
                    >
                      Instore Pickup
                    </span>
                  </div>
                </div>

                <div class="d-flex justify-content-between flex-wrap">
                  <p class="" style="color: #777777">
                    {{ calculateTime(orderDetails.order_date) }}
                  </p>
                  <div>
                    <p class="fg_grey2">
                      <span style="color: #777777; font-size: 1.125rem"
                        >{{ $t("home.order.details.payment_status") }}:</span
                      >
                      <span class="fg-success-color payment-status">{{
                        orderDetails.payment_status
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="bg-white app-order-details-container"
                ref="printcontent"
              >
                <div class="p-3">
                  <!-- Ordered items -->
                  <div
                    v-for="(product, index) in orderDetails.product"
                    :key="index"
                    class="ordered-item d-flex justify-content-between"
                    :class="
                      index == orderDetails.product?.length - 1
                        ? 'border-bottom'
                        : ''
                    "
                  >
                    <div class="w-100 d-flex">
                      <div style="width: 5.625rem;">
                        <div class="ratio ratio-1x1">
                          <img
                            v-if="product.product_images"
                            :src="product?.product_images"
                            alt=""
                            class="image border"
                          />
                        </div>
                      </div>

                      <div
                        class="w-100 d-flex flex-column justify-content-between ms-3"
                      >
                        <div class="d-flex justify-content-between">
                          <div>
                            <h6 class="mb-1">{{ product.title }}</h6>
                            <div
                              class="d-flex flex-wrap state-color__fg--grey1"
                            >
                              <div class="d-flex flex-wrap">
                                <p class="pe-3 mb-0 state-color__fg--grey1">
                                  {{ productQuantity(product) }}
                                  {{ product.product_unit }}
                                </p>
                              </div>
                              <div
                                class="d-flex flex-wrap state-color__fg--grey1 pe-3"
                                v-for="(attribute,
                                index) in product.product_attribute"
                                :key="index"
                              >
                                <span
                                  class="text-capitalize"
                                  v-if="
                                    attribute.global_attribute_name.toLowerCase() !=
                                      'color'
                                  "
                                >
                                  <!-- {{ attribute.global_attribute_name }} -->
                                  {{ attribute.attribute_name }}</span
                                >
                                <div class="" v-else>
                                  <span
                                    class="order-details-product-color"
                                    :style="{
                                      'background-color':
                                        attribute.attribute_name,
                                    }"
                                  ></span>
                                  <span class="text-capitalize">{{
                                    colorCodeToName(attribute.attribute_name)
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <button
                              type="button"
                              class="btn order-details-edit-btn d-flex"
                              data-bs-toggle="modal"
                              data-bs-target="#orderQuantityUpdateModal"
                              v-if="
                                index == 0 &&
                                  orderDetails.order_status === 'Pending'
                              "
                              @click="setOrderInfo()"
                            >
                              <img
                                src="../../assets/icon/edit.svg"
                                alt="home"
                                width="20"
                                height="20"
                                class="fs-5 d-none d-sm-inline my-auto mx-1"
                              />
                              {{ $t("home.order.details.edit_btn") }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="single-product-price-container d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div
                              v-if="
                                product.product_attribute == null ||
                                  product.product_attribute?.length == 0
                              "
                              class="d-flex align-items-center"
                            >
                              <p
                                class="medium_text__size fw-bold google_sans_normal mb-0"
                              >
                                {{ product.quantity }}
                                <span>&#215; &#2547;</span
                                >{{
                                  parseFloat(
                                    product.discount_price > 0
                                      ? product.discount_price
                                      : product.product_price
                                  )
                                }}
                              </p>

                              <p
                                v-if="product.discount_price > 0"
                                class="small-text font-weight-500 state-color__fg--grey5 text-decoration-line-through mb-0 ms-1"
                              >
                                <span class="fw-bold">&#2547;</span
                                >{{ parseFloat(product.product_price) }}
                              </p>
                            </div>

                            <div v-else class="d-flex align-items-center">
                              <p class="medium_text__size fw-bold mb-0">
                                {{ product.product_attribute[0]?.quantity }}
                                <span>&#215; &#2547;</span
                                >{{
                                  parseFloat(
                                    product.product_attribute[0]
                                      ?.discount_price > 0
                                      ? product.product_attribute[0]
                                          ?.discount_price
                                      : product.product_attribute[0]?.price
                                  )
                                }}
                              </p>

                              <p
                                v-if="
                                  product.product_attribute[0]?.discount_price >
                                    0
                                "
                                class="mb-0 ms-2 small-text font-weight-500 state-color__fg--grey5 text-decoration-line-through"
                              >
                                <span class="fw-bold">&#2547;</span
                                >{{ product.product_attribute[0]?.price }}
                              </p>
                            </div>
                          </div>

                          <div class="">
                            <h5
                              class="mb-0 state-color__fg--Info"
                              v-if="
                                product.product_attribute != null &&
                                  product.product_attribute?.length > 0
                              "
                            >
                              <span class="fw-bold">&#2547;</span
                              >{{
                                parseFloat(
                                  product.product_attribute[0]?.discount_price >
                                    0
                                    ? product.product_attribute[0]
                                        ?.discount_price
                                    : product.product_attribute[0]?.price
                                ) * product.product_attribute[0]?.quantity
                              }}
                            </h5>

                            <h5 class="mb-0 state-color__fg--Info" v-else>
                              <span class="fw-bold">&#2547;</span
                              >{{
                                (parseFloat(product.discount_price) > 0
                                  ? parseFloat(product.discount_price)
                                  : parseFloat(product.product_price)) *
                                  parseFloat(product.quantity)
                              }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Order Summary -->

                <div class="order-summary border-bottom p-3">
                  <div class="d-flex justify-content-between">
                    <p class="fg_grey2 mb-1" style="color: #777777">
                      {{ $t("home.order.details.item_total") }}
                    </p>
                    <p class="mb-1">
                      <span>&#2547;</span
                      >{{
                        parseFloat(orderDetails.total_pay_amount) +
                          parseFloat(orderDetails.coupon_code_amount) -
                          parseFloat(orderDetails.total_extra_charge)
                      }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between">
                    <p class="fg_grey2 mb-1" style="color: #777777">
                      {{ $t("home.order.details.extra_charges") }}
                    </p>
                    <p class="mb-1">
                      &#2547;{{ parseFloat(orderDetails.total_extra_charge) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between">
                    <p class="fg_grey2 mb-1" style="color: #777777">
                      {{ $t("home.order.details.coupon_discount") }}
                    </p>
                    <p class="mb-1">
                      - &#2547;{{ parseFloat(orderDetails.coupon_code_amount) }}
                    </p>
                  </div>

                  <div class="d-flex justify-content-between mt-4">
                    <h5 class="mb-1">
                      {{ $t("home.order.details.grand_total") }}
                    </h5>
                    <h5 class="mb-1">
                      <span class="fw-bold">&#2547;</span
                      >{{ parseFloat(orderDetails.total_pay_amount) }}
                    </h5>
                  </div>
                </div>

                <div class="d-flex justify-content-between flex-wrap p-3">
                  <div>
                    <!-- <button
                      type="button cancel-btn"
                      class="btn cancel-btn mt-2"
                      data-bs-toggle="modal"
                      data-bs-target="#cancelConfirationModal"
                    >
                      Cancel Order
                    </button> -->
                  </div>
                  <div class="d-flex flex-wrap">
                    <button
                      type="button"
                      class="btn download-invoice-btn me-2 mt-2"
                      @click="handleInvoiceDownload(orderDetails.id_order)"
                    >
                      <img
                        src="../../assets/icon/download-invoice.svg"
                        alt=""
                        width="16"
                        height="16"
                        class="me-1"
                      />
                      {{ $t("home.order.details.download_invoice") }}
                    </button>
                    <button
                      type="button"
                      class="btn print-invoice-btn mt-2"
                      @click="printThis(orderDetails.id_order)"
                      target="_blank"
                    >
                      <img
                        src="../../assets/icon/print-invoice.svg"
                        alt=""
                        width="16"
                        height="16"
                        class="me-1"
                      />
                      {{ $t("home.order.details.print_invoice") }}
                    </button>
                  </div>
                </div>
              </div>

              <!-- Address -->
              <!-- <div
                class="bg-white border-radius-5 mt-4 p-3"
                v-if="orderDetails.extra_charge_area == 'instore_pickup'"
              >
                <h5>Store Details</h5>

                <div class="mt-3">
                  <p class="medium_text__size fg_grey2 mb-1">Store Name</p>
                  <p class="font-weight-500">{{ orderDetails.shop_name }}</p>
                </div>

                <div class="mt-3">
                  <p class="medium_text__size fg_grey2 mb-1">Mobile</p>
                  <p class="font-weight-500">{{ authUser?.phone }}</p>
                </div>

                <div class="mt-3">
                  <p class="medium_text__size fg_grey2 mb-1">Store Address</p>
                  <p class="font-weight-500">
                    {{ orderDetails.shop_address }}
                  </p>
                </div>

                <div class="mt-3">
                  <p class="medium_text__size fg_grey2 mb-1">Additional Info</p>
                  <p class="font-weight-500">
                    Please make sure you have active Business License in order
                    to sell on majhi as a Corporate.
                  </p>
                </div>
              </div> -->

              <div
                class="bg-white border-radius-5 mt-4 p-3"
                style="border-radius:10px !important"
              >
                <h5>{{ $t("home.order.details.delivery_title") }}</h5>

                <div class="mt-3">
                  <p
                    class="medium_text__size fg_grey2 mb-1"
                    style="color: #777777"
                  >
                    {{ $t("home.order.details.name") }}
                  </p>
                  <p class="font-weight-500">
                    {{ orderDetails.delivery_name }}
                  </p>
                </div>

                <div class="mt-3">
                  <p
                    class="medium_text__size fg_grey2 mb-1"
                    style="color: #777777"
                  >
                    {{ $t("home.order.details.mobile") }}
                  </p>
                  <p class="font-weight-500">
                    {{ orderDetails.delivery_phone }}
                  </p>
                </div>

                <div
                  class="mt-3"
                  v-if="orderDetails.extra_charge_area == 'instore_pickup'"
                >
                  <p class="medium_text__size state-color__fg--grey1 mb-1">
                    {{ $t("home.order.details.store_address") }}
                  </p>
                  <p class="font-weight-500">
                    {{ orderDetails.shop_address }}
                  </p>
                </div>

                <div class="mt-3" v-else>
                  <p class="medium_text__size state-color__fg--grey1 mb-1">
                    {{ $t("home.order.details.shipping_address") }}
                  </p>
                  <p class="font-weight-500">
                    {{ orderDetails.delivery_address }}
                  </p>
                </div>

                <div class="mt-3">
                  <p class="medium_text__size state-color__fg--grey1 mb-1">
                    {{ $t("home.order.details.additional_info") }}
                  </p>
                  <p class="font-weight-500">
                    {{ $t("home.order.details.info_text") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <not-found v-if="orderDetails === null" />
      </div>

      <loading-spinner />
      <!-- <not-found v-if="!orderDetails" /> -->
    </div>
    <order-address-modal />
    <confirmation-modal v-on:requestCancelOrder="updateOrderStatus($event)" />
    <order-product-quantity-update-modal
      v-if="orderDetails"
      v-bind:orderedProducts="product"
      v-bind:orderDetails="orderInfo"
    />
  </div>
</template>

<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import OrderAddressModal from "../../components/orders/OrderAddressModal.vue";
import ConfirmationModal from "../../components/orders/ConfirmationModal.vue";
import OrderProductQuantityUpdateModal from "../../components/orders/OrderProductQuantityUpdateModal.vue";
import NotFound from "../404.vue";

//import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";

import $ from "jquery";
//import html2canvas from "html2canvas";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    OrderAddressModal,
    ConfirmationModal,
    OrderProductQuantityUpdateModal,
    NotFound,
  },
  data: function() {
    return {
      orderId: 0,
      total: 0,
      authUser: null,
      product: null,
      orderInfo: null,
    };
  },

  computed: {
    ...mapGetters("Order", ["orderDetails", "getInvoiceLink"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("Order", [
      "requestOrderDetails",
      "requestUpdateOrderStatus",
      "generateInvoice",
    ]),

    ...mapMutations("Order", [
      "SET_CUSTOM_SEARCH_DATE",
      "SET_CUSTOM_SEARCH_STATUS",
    ]),

    setOrderInfo() {
      this.product = this.orderDetails.product;
      this.orderInfo = this.orderDetails;
    },

    printThis(id) {
      let routeData = this.$router.resolve({
        name: "PrintInvoice",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },

    calculateTime(data) {
      let formateData = data?.split(" ")[0]?.split("-");

      var date = new Date(
        formateData[0],
        Number(formateData[1]) - 1,
        formateData[2]
      );
      let year = date.getFullYear();
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let month = date.getMonth();
      var day = date.getDate();

      //return `${months[month + 1]} ${day},${year}`;
      let dateArray = data.split(" ");
      let time = `${dateArray[1]} ${dateArray[2]}`;
      return `${day} ${months[month]} ${year}, ${time}`;
    },

    async updateOrderStatus(orderStatusId) {
      const data = {
        id_order_status: orderStatusId,
        id_order: this.orderId,
        payment_status: "paid",
      };
      await this.requestUpdateOrderStatus({ data, redirect: false });
      $("#order-cancel-modal-close").trigger("click");
      await this.requestOrderDetails(this.orderId);
    },

    // calculate product property------------------------------------

    productQuantity(product) {
      if (product.product_attribute.length) {
        let data = product.product_attribute[0];
        if (data) {
          return data.quantity;
        }
      } else {
        return product.quantity;
      }
    },

    colorCodeToName(code) {
      const ntc = require("ntcjs");

      const n_match = ntc.name(code);
      return n_match[1];
    },

    // download Invoice -----------------------------------------
    async handleInvoiceDownload(id) {
      await this.generateInvoice(id);
      window.location.href = this.getInvoiceLink;
    },
  },

  async created() {
    this.orderId = this.$route.params.orderId;
    if (this.orderId) {
      await this.requestOrderDetails(this.orderId);
    }

    let userData = localStorage.getItem("majhiUser");
    if (!userData) return;
    this.authUser = JSON.parse(userData);

    this.SET_CUSTOM_SEARCH_DATE(null);
    this.SET_CUSTOM_SEARCH_STATUS(false);
  },
};
</script>

<style scoped>
.payment-status {
  color: #2dc06b;
  text-transform: capitalize;
  margin-left: 4px;
  font-size: 1.125rem;
}

.status-badge {
  border-radius: 34px;
  letter-spacing: 0.03px;
  font-size: 0.875rem;
  padding: 0.49rem 0.875rem;
}

.instore-badge {
  background: rgba(151, 170, 4, 0.16);
  color: rgba(151, 170, 4, 1);
}

.pending-badge {
  background: rgba(255, 147, 0, 0.16);
  color: rgba(255, 147, 0, 1);
}

.accepted-badge {
  background: rgba(58, 134, 255, 0.16);
  color: rgba(58, 134, 255, 1);
}

.delivered-badge {
  background: rgba(168, 77, 245, 0.16);
  color: rgba(168, 77, 245, 1);
}

.cancelled-badge {
  background: rgba(238, 81, 83, 0.16);
  color: rgba(238, 81, 83, 1);
}

.shipped-badge {
  background: rgba(45, 192, 107, 0.16);
  color: rgba(45, 192, 107, 1);
}

.code-badge {
  color: #3e89ee;
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "GoogleSans-Medium, Google Sans";
  margin-right: 5px;
}

.order-details-header {
  border-radius: 10px 10px 0px 0px;
}

.app-order-details-container {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
</style>
