import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getGraphInfo(data) {
    const { timeline, start, end } = data;

    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/seller_home_info/${language}?time_line=${timeline}&start=${start}&end=${end}`,
      method: "GET",
    });
  },
};
