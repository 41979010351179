<template>
  <div class="wrapper">
    <img class="image" :src="imageUrl()" alt="background" />
    <div class="card-content">
      <img :src="store_logo_url" alt="logo" class="logo" />
      <p class="store-name">{{ shopName }}</p>
      <p class="category-name">{{ category }}</p>
      <p class="store-url">{{ baseUrl }}/{{ shopName }}</p>
    </div>

    <div class="footer-container">
      <div>
        <img :src="loadPhoneLogo()" alt="icon" class="icon" />
        <span>{{ phone }}</span>
      </div>

      <div>
        <img :src="loadEmailLogo()" alt="icon" class="icon" />
        <span>{{ email }}</span>
      </div>

      <div>
        <img :src="loadLocationLogo()" alt="icon" class="icon" />
        <span>{{ area }},{{ city }},{{ division }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: "",
      category: "",
      phone: "",
      email: "",
      area: "",
      city: "",
      division: "",
      baseUrl: "www.majhi.com/en",
      store_logo_url: "",
    };
  },

  created() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);

      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  updated() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  methods: {
    imageUrl() {
      return require("@/assets/marketing-materials/business-card/card-9/BC-9.png");
    },

    loadLogo() {
      return require("@/assets/marketing-materials/business-card/card-9/Group.png");
    },

    loadPhoneLogo() {
      return require("@/assets/marketing-materials/business-card/card-9/phone.svg");
    },

    loadEmailLogo() {
      return require("@/assets/marketing-materials/business-card/card-9/email.svg");
    },

    loadLocationLogo() {
      return require("@/assets/marketing-materials/business-card/card-9/Ellipse 4.svg");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@400;500&display=swap");

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  margin-top: 10px;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 10px;
  font-size: 10px;
  position: relative;
  color: #141414;
}

.image {
  width: 100%;
}

.logo-container {
  text-align: center;
}

.logo {
  width: 9em;
  height: 9em;
  border-radius: 50%;
}

.card-content {
  position: absolute;
  top: 2em;
  left: 22em;
  text-align: center;
}

.icon {
  width: 1.7em;
}

.store-name {
  margin-top: 0.6em;
  font-size: 3.5em;
  font-weight: 900;
  text-align: center;
  letter-spacing: 1.35px;
  color: #141414;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.category-name {
  font-size: 1.7em;
  letter-spacing: 2.4px;
  font-weight: 700;
  text-align: center;
  margin-top: 0.7em;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.store-url {
  letter-spacing: 1px;
  font-size: 1.6em;
  margin-top: 2em;
  font-weight: 600;
  text-transform: uppercase;
}

.footer-container {
  position: absolute;
  font-size: 1.3em;
  top: 25em;
  left: 5em;
  color: #ffffff;
  font-family: "Overpass", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.footer-container div {
  margin-bottom: 1em;
}

.footer-container span {
  margin-left: 1em;
}

@media only screen and (max-width: 1600px) {
  .wrapper {
    width: 86%;
    margin-left: 7%;
    font-size: 8.5px;
  }

  .footer-container {
    top: 26em;
  }
}

@media only screen and (max-width: 1280px) {
  .wrapper {
    width: 90%;
    margin-left: 5%;
    font-size: 7.1px;
  }

  .footer-container {
    top: 26em;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
    margin-left: 0;
    font-size: 8px;
  }

  .card-content {
    left: 16em;
  }

  .store-url {
    margin-top: 1em;
  }

  .footer-container {
    top: 23em;
  }
}
</style>
