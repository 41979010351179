<template>
  <div class="home-order-card-container px-0">
    <div
      class="p-4 order-card card border-raduis__div order-card-shadow"
      v-for="order in allOrders?.slice(0, 9)"
      :key="order.id_order"
    >
      <div class="">
        <div class="w-100 d-flex justify-content-between">
          <p class="state-color__fg--grey1 mb-2">
            {{ $t("home.main.orders.card.order_id") }}
          </p>
          <div>
            <p
              class="mb-1 status-badge shipped-badge"
              v-if="order.id_order_status == 4"
            >
              Shipped
            </p>
            <p
              class="mb-1 status-badge pending-badge"
              v-else-if="order.id_order_status == 1"
            >
              Pending
            </p>
            <p
              class="mb-1 status-badge accepted-badge"
              v-else-if="order.id_order_status == 2"
            >
              Accepted
            </p>

            <p
              class="mb-1 status-badge cancelled-badge"
              v-else-if="order.id_order_status == 3"
            >
              Cancelled
            </p>
            <p
              class="mb-1 status-badge instore-badge"
              v-else-if="order.id_order_status == 5"
            >
              Instore Pickup
            </p>
            <p
              class="mb-1 status-badge delivered-badge"
              v-else-if="order.id_order_status == 6"
            >
              Delivered
            </p>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-between">
          <h5 style="font-size:24px !important">ID{{ order.id_order }}</h5>
          <h5><b>&#2547;</b>{{ parseFloat(order.total_pay_amount) }}</h5>
        </div>

        <div class="w-100 d-flex justify-content-between">
          <p class="state-color__fg--grey1 mb-2">
            {{ calculateTime(order.order_date) }}
          </p>
          <p class="state-color__fg--grey1 mb-2">
            {{ order.total_item }} {{ $t("home.main.orders.card.items") }}
          </p>
        </div>

        <div class="w-100 d-flex justify-content-between mb-2">
          <p class="state-color__fg--grey1 mb-1">
            {{ $t("home.main.orders.card.contact") }}:
            <span class="text-dark" style="font-weight:bolder;">{{
              order.customer_contact_no
            }}</span>
          </p>

          <p class="state-color__fg--grey1 mb-1">
            {{ $t("home.main.orders.card.payment") }}:
            <span
              :class="order.payment_type"
              class="google_sans_medium"
              style="color:#3A86FF"
              >{{ order.payment_type }}</span
            >
          </p>
        </div>

        <div class="w-100 d-flex justify-content-between">
          <button
            class="btn bg-white w-50 state-color__fg--Info border-solid__info google_sans_medium me-3"
            v-if="order.id_order_status == 4"
            @click="requestToUpdateOrderStatus(order.id_order, 3)"
          >
            {{ $t("home.main.orders.card.cancel") }}
          </button>

          <button
            class="btn w-50 text-white state-color__bg--Info google_sans_medium"
            v-if="order.id_order_status == 4"
            @click="requestToUpdateOrderStatus(order.id_order, 6)"
          >
            {{ $t("home.main.orders.card.mad") }}
          </button>
        </div>
      </div>
      <div v-if="order.id_order_status != 4">
        <router-link
          class="btn text-white w-100 my-2 common-border__radius-8 state-color__bg--Info google_sans_medium"
          :to="{
            name: 'OrderDetails',
            params: { orderId: order.id_order, languageCode: getLanguage },
          }"
        >
          {{ $t("home.main.orders.card.details") }}
        </router-link>
      </div>
    </div>
  </div>

  <div v-if="allOrders == null" class="d-flex justify-content-center w-100">
    <div class="text-center w-100 ">
      <img src="../../assets/icon/No-active-order-found.svg" alt="" />
      <h5 style="color: #333333; font-size:24px ">
        {{ $t("home.order.no_active_order_found") }}
      </h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["allOrders"],
  emits: ["requestToUpdateOrderStatus"],

  data: function() {
    return {};
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    requestToUpdateOrderStatus(orderId, statusId) {
      this.$store.commit("setOverlayStatus", true); //loader open

      this.$emit("requestToUpdateOrderStatus", {
        orderId: orderId,
        statusId: statusId,
      });
    },

    calculateTime(data) {
      let formateData = data?.split(" ")[0]?.split("-");

      var date = new Date(
        formateData[0],
        Number(formateData[1]) - 1,
        formateData[2]
      );
      let year = date.getFullYear();
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let month = date.getMonth();
      var day = date.getDate();

      //return `${months[month + 1]} ${day},${year}`;
      let dateArray = data.split(" ");
      let time = `${dateArray[1]} ${dateArray[2]}`;
      return `${day} ${months[month]} ${year}, ${time}`;
    },
  },
};
</script>

<style scoped>
.status-badge {
  border-radius: 34px;
  letter-spacing: 0.03px;
  font-size: 0.875rem;
  padding: 0.2rem 0.55rem;
}

.instore-badge {
  background: rgba(151, 170, 4, 0.16);
  color: rgba(151, 170, 4, 1);
}

.pending-badge {
  background: rgba(255, 147, 0, 0.16);
  color: rgba(255, 147, 0, 1);
}

.accepted-badge {
  background: rgba(58, 134, 255, 0.16);
  color: rgba(58, 134, 255, 1);
}

.delivered-badge {
  background: rgba(168, 77, 245, 0.16);
  color: rgba(168, 77, 245, 1);
}

.cancelled-badge {
  background: rgba(238, 81, 83, 0.16);
  color: rgba(238, 81, 83, 1);
}

.shipped-badge {
  background: rgba(45, 192, 107, 0.16);
  color: rgba(45, 192, 107, 1);
}

.order-card-shadow {
  box-shadow: 0px 3px 12px #1d1d1d0d;
}
</style>
