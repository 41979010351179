<template>
  <router-view />
</template>

<script>
import Pusher from "pusher-js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toast, { successBackground } from "./config/toastConfig";
import { track } from "@amplitude/analytics-browser";

export default {
  computed: {
    ...mapGetters("favicon", ["getImageUrl"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("favicon", ["getFaviconRequest"]),
    ...mapMutations("language", ["SET_LANGUAGE"]),
    ...mapActions("notification", ["getAllNotification"]),
  },

  watch: {
    $route: {
      handler: function(newValue) {
        let availableLanguage = ["en", "bn"];
        let selectedLanguage = newValue?.params?.languageCode;
        if (availableLanguage.includes(selectedLanguage)) {
          this.SET_LANGUAGE(newValue?.params?.languageCode);
          this.$i18n.locale = newValue?.params?.languageCode;
          // if (selectedLanguage.toLowerCase() === "bn") {
          //   document.getElementById("app").style.fontFamily =
          //     "'Montserrat', sans-serif";
          // }
        } else {
          this.SET_LANGUAGE("bn");
          this.$i18n.locale = "bn";
          let params = this.$route.params;
          let newParams = {
            ...params,
            languageCode: "bn",
          };
          this.$router.push({ ...this.$route, newParams });
        }
      },
    },
  },

  mounted() {
    // set up pusher------------------------------------------

    let userData = localStorage.getItem("majhiUser");
    if (!userData) return;
    let user = JSON.parse(userData);
    let seller_key = user.seller_key;

    var pusher = new Pusher("2e43c0c47950cd6580a2", {
      broadcaster: "pusher",
      appId: "1367910",
      key: "2e43c0c47950cd6580a2",
      secret: "dabcdd7b26902962f9ec",
      cluster: "ap1",
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("majhiAccessToken"),
        },
      },
      authEndpoint: process.env.VUE_APP_PUSHER_ENDPOINT,
    });

    let vm = this;
    var channel1 = pusher.subscribe("private-sellerChannel_" + seller_key);
    channel1.bind("web-notification-event", function(data) {
      Toast.fire({
        icon: "success",
        title: data.webNotification.data,
        background: successBackground,
      });

      vm.$store.commit(
        "notification/catchPushNotification",
        data.webNotification
      );
    });

    window.addEventListener("online", () => {
      this.$router.go(-1);
    });
    window.addEventListener("offline", () => {
      this.$router.push({ name: "NoInternet" });
    });
  },

  async created() {
    try {
      track("Connected");
    } catch (error) {
      console.log(error);
    }

    if (!window.navigator.onLine) {
      this.$router.push({ name: "NoInternet" });
    }

    if (window) {
      window.addEventListener("online", () => {
        this.$router.go(-1);
      });
      window.addEventListener("offline", () => {
        this.$router.push({ name: "NoInternet" });
      });
    }

    if (!localStorage.getItem("majhiUser")) return;

    await this.getFaviconRequest();

    if (this.getImageUrl) {
      localStorage.setItem("favicon", JSON.stringify(this.getImageUrl));
    }

    let sellerKey = JSON.parse(localStorage.getItem("majhiUser")).seller_key;
    let data = {
      sellerKey,
      page: 1,
    };
    await this.getAllNotification(data);
  },

  beforeCreate() {
    if (
      localStorage.getItem("majhiUser") == null ||
      localStorage.getItem("majhiAccessToken") == null
    ) {
      localStorage.clear();
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.swal2-title {
  font-size: 1rem !important;
}

/* remove increment and decrement icon from number type input field. */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
