import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import store from "../../store";

const service = axios.create({
  // baseURL: "http://192.168.0.21/appnap/APN065-Majhi-Backend/public/api/v1",
  baseURL: process.env.VUE_APP_BASE_URL,
  //baseURL: "http://192.168.0.87:8083/api/v1",

  // timeout: 15000,
});

service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (localStorage.getItem("majhiAccessToken")) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers["Bearer"] = store.getters["auth/token"];
      let token = "Bearer " + localStorage.getItem("majhiAccessToken");
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    const isSuccessful = res.success;
    // if isSuccessful is false then it will judged be as failure
    if (!isSuccessful) {
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    const response = error.response;
    const status = error.response.status;

    if (status === 404) {
      let messages = "";

      if (response.data.data) {
        const data = response.data.data;

        if (typeof data === "string") {
          messages += data;
        } else {
          for (let key in data) {
            messages += data[key];
            messages += "<br />";
          }
        }
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: messages,
        reverseButtons: true,
        confirmButtonText: "Ok",
      });
    } else if (status === 429) {
      Swal.fire({
        icon: "error",
        title: "Request Failed",
        html: error.response.statusText,
        reverseButtons: true,
        confirmButtonText: "Ok",
      });
    } else if (status == 401) {
      localStorage.clear();
      router.push({
        name: "SignIn",
        params: { languageCode: store.getters["language/getLanguage"] },
      });
    }

    //  return Promise.reject(error);
    return response;
  }
);

export default service;
