import Client from "../client/axiosClient";

export default {
  otpRequest(data) {
    return Client({
      url: "/send_otp",
      method: "POST",
      data,
    });
  },

  resendOtpRequest(data) {
    return Client({
      url: "/otp_resend",
      method: "POST",
      data,
    });
  },

  signIn(data) {
    return Client({
      url: "/signin",
      method: "POST",
      data,
    });
  },

  otpValidate(data) {
    return Client({
      url: "/otp_validate",
      method: "POST",
      data,
    });
  },

  signUp(data) {
    return Client({
      url: "/seller_registration",
      method: "POST",
      data,
    });
  },

  signOut(data = {}) {
    return Client({
      url: "/logout",
      method: "POST",
      data,
    });
  },

  getBusinessCategory() {
    return Client({
      url: "/businesscategory",
      method: "GET",
    });
  },

  checkExistingUser(data) {
    return Client({
      url: "/account_check",
      method: "POST",
      data,
    });
  },

  deleteStore(data = {}) {
    return Client({
      url: "/account_delete",
      method: "POST",
      data,
    });
  },
};
