<template>
  <div style="width:5rem">
    <img src="../../assets/icon/majhi_primary.svg" alt="" />
  </div>
  <div class="p-2">
    <h4 class="text-center">
      {{ $t("auth.signIn.title") }} <br />{{ $t("auth.signIn.store") }}
    </h4>
  </div>
  <form
    @submit.prevent="handleSubmit()"
    class="mb-3"
    :class="$style.signin_form"
  >
    <div class="text-start">
      <label for="exampleInputEmail1" class="form-label"
        >{{ $t("auth.signIn.mobile")
        }}<span class="text-primary">*</span></label
      >
    </div>
    <input
      type="mobileNo"
      id="phone"
      class="form-control"
      :class="$style.input_phoneno_height"
      aria-describedby="mobileNoHelp"
      :placeholder="$t('auth.signIn.placeholder')"
      v-model.trim="phone"
      required
      autofocus
    />
    <small v-if="!isPhoneValid" class="text-danger">{{
      $t("auth.signIn.invalid_phone")
    }}</small>

    <label class="state-color__fg--error mt-2" v-if="errorLog != ''">{{
      errorLog
    }}</label>

    <button
      type="submit"
      class="btn btn-primary mt-4"
      :class="$style.btn_height"
      style="min-width: 100%"
    >
      {{ $t("auth.signIn.sign_in_btn") }}
    </button>
  </form>
  <div>
    <p class="state-color__fg--grey2">
      {{ $t("auth.signIn.new_majhi") }} ?
      <router-link
        :to="{ name: 'SignUp', params: { languageCode: getLanguage } }"
        class="state-color__fg--Info"
        >{{ $t("auth.signIn.sing_up") }}</router-link
      >
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  emits: ["requestOtp", "showOtpForFixedNumber"],

  data() {
    return {
      phone: "",
      errorLog: "",
      showRecaptcha: true,
      isPhoneValid: true,
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    handleSubmit() {
      if (!this.isPhoneValid) {
        return;
      }

      this.errorLog = "";
      if (this.phone.length != 11) {
        this.errorLog = this.$t("auth.validation.phone.phone_length");
        return;
      } else if (!this.phone.match(/^01[0-9]+$/)) {
        this.errorLog = this.$t("auth.validation.phone.phone_condition");
        return;
      } else if (this.phone == "01572018053") {
        this.$emit("showOtpForFixedNumber", this.phone);
        return;
      }
      this.$emit("requestOtp", this.phone);
    },

    CheckPhoneNumber() {
      let mobile = this.phone;
      if (!mobile) {
        return;
      }

      var phone_pattern = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
      if (!mobile.match(phone_pattern)) {
        this.isPhoneValid = false;
      } else {
        this.isPhoneValid = true;
      }
    },
  },

  mounted() {
    let typingTimer;
    let doneTypingInterval = 2000;
    let PhoneField = document.getElementById("phone");
    PhoneField.addEventListener("keyup", () => {
      clearTimeout(typingTimer);
      if (PhoneField.value) {
        typingTimer = setTimeout(this.CheckPhoneNumber, doneTypingInterval);
      }
    });
    PhoneField.addEventListener("blur", this.CheckPhoneNumber);
  },
};
</script>

<style module>
.signin_form {
  width: 70%;
}

.input_phoneno_height,
.btn_height {
  min-height: 25%;
}
</style>
