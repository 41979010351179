<template>
  <div
    class="modal fade"
    id="addPhoneNumberModal"
    tabindex="-1"
    aria-labelledby="addPhoneNumberModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content  common-border__radius  ">
        <div
          class="modal-header float-end"
          style="border-bottom: none !important;"
        >
          <h5></h5>
          <button
            type="button"
            class="modal-close"
            id="otpcontactnumberclose"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body mx-2 px-2 pt-0 pb-2">
          <div class="text-center">
            <h4 class="state-color__fg--grey">
              {{ $t("setting.main.modal.title") }}
            </h4>
          </div>

          <div class="mb-3">
            <label
              for="exampleInputEmail1"
              class="form-label state-color__fg--grey"
              >{{ $t("setting.main.modal.contact_number") }}</label
            >
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              v-model="contactNumber"
            />
          </div>

          <div class="py-2">
            <p
              class="state-color__fg--Info text-center make-cursor"
              @click="requestToSendOtp()"
            >
              {{ $t("setting.main.modal.change_number") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast, { errorBackground } from "../../config/toastConfig";
import $ from "jquery";

export default {
  props: {
    phoneNumber: String,
  },
  emits: ["requestToSendOtp"],

  data() {
    return {
      contactNumber: "",
    };
  },

  methods: {
    setContactNumber() {
      this.contactNumber = this.phoneNumber;
    },

    requestToSendOtp() {
      if (this.contactNumber.length != 11) {
        Toast.fire({
          icon: "error",
          title: this.$t("auth.validation.phone.phone_length"),
          background: errorBackground,
        });
        return;
      } else if (!this.contactNumber.match(/^01[0-9]+$/)) {
        Toast.fire({
          icon: "error",
          title: this.$t("auth.validation.phone.phone_condition"),
          background: errorBackground,
        });
        return;
      } else if (this.contactNumber == this.phoneNumber) {
        Toast.fire({
          icon: "error",
          title: this.$t("auth.validation.phone.phone_required"),
          background: errorBackground,
        });
        return;
      }
      $("#otpcontactnumberclose").trigger("click");
      this.$emit("requestToSendOtp", this.contactNumber);
    },
  },
};
</script>

<style></style>
