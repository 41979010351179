<template>
  <div
    class="graph-box-shadow p-4 mb-5 bg-white border-raduis__div home-card__width"
  >
    <div class="d-flex justify-content-between flex-column">
      <div class="w-100 d-flex justify-content-between">
        <p class="graph-report" style="font-size:13px; color:'#777777'">
          {{ $t("home.main.graph.best.best_selling_product") }}
        </p>
        <p class="graph-report">{{ $t("home.main.graph.best.total_sold") }}</p>
      </div>
      <div
        class="d-flex justify-content-between flex-wrap"
        style="margin-top:-8px"
      >
        <h5 style="font-size:20px !important;">
          {{ bestProduct?.product_title }}
        </h5>
        <h5
          class="font-weight-600 float-end"
          style="font-size:20px !important;"
        >
          {{ bestProduct?.total_sold }}
        </h5>
      </div>
    </div>
    <apexchart
      v-if="graphCategoriesName.length"
      type="bar"
      height="250"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "HorizontalChart",
  props: [
    "horizontalChartColor",
    "graphCategoriesName",
    "graphData",
    "bestProduct",
  ],

  computed: {
    series() {
      return [
        {
          data: this.graphData,
        },
      ]
    },

    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: true,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 7,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false, //true to show label top of bar
          offsetX: -6,
          style: {
            fontSize: "10px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 5, // width of the bar
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: this.graphCategoriesName,
        },
        yaxis: {
          labels: {
            minWidth: 50,
          },
        },
        colors: [this.horizontalChartColor],
      }
    },
  },

  data: function() {
    return {}
  },
}
</script>

<style scoped>
.border-raduis__div {
  border-radius: 10px !important;
}

.graph-report {
  font-size: 13px;
  color: #777777;
}

.graph-box-shadow {
  box-shadow: 0px 3px 12px #1d1d1d0d;
}
</style>
