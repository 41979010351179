<template>
  <div
    class="
      w-100
      min-vh-100
      d-flex
      justify-content-center
      align-items-center
      signin-page
    "
  >
    <div class="registration-success-container">
      <div class="d-flex justify-content-center success-image">
        <img src="../../assets/auth/registration_success.svg" alt="" />
      </div>
      <div class="d-flex justify-content-center">
        <div class="success-text-container pt-3">
          <h3 class="text-center py-4">
            {{ $t("auth.signUp.success.cong_text") }}
          </h3>

          <p class="text-center pb-4" style="line-height: 1.6;">
            {{ $t("auth.signUp.success.your_store") }}
            <span class="fw-bold">{{ shopName }}'s</span>
            {{ $t("auth.signUp.success.res_completed") }} <br />
            {{ $t("auth.signUp.success.text_1") }}
            <br />
            {{ $t("auth.signUp.success.text_2") }}
            <br />
            {{ $t("auth.signUp.success.times_limit") }}
          </p>

          <router-link
            :to="{ name: 'Home', params: { languageCode: this.getLanguage } }"
            class="btn state-color__bg--Info text-white w-100 mb-5"
          >
            {{ $t("auth.signUp.success.back") }}
          </router-link>

          <div class="py-5">
            <p class="text-center state-color__fg--grey ">
              <span class="state-color__fg--grey4">{{
                $t("auth.signUp.success.powered")
              }}</span>
              {{ $t("auth.signUp.heading") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isRegistrationSuccessful: { type: Boolean, default: false },
    shopName: { type: String },
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  created() {
    if (!this.isRegistrationSuccessful) {
      this.$router.push({
        name: "SignIn",
        params: { languageCode: this.getLanguage },
      });
    }
  },
};
</script>

<style scoped>
.registration-success-container {
  width: 60%;
  box-shadow: 0px 3px 12px #1d1d1d0d;
  border-radius: 10px;
}

.success-image {
  margin-top: 4rem;
}

.success-text-container {
  max-width: 48%;
}

.success-text-container a {
  padding: 15px 120px;
}

@media only screen and (max-width: 576px) {
  .registration-success-container {
    width: 100%;
    min-height: 100vh;
  }

  .success-text-container {
    max-width: 100%;
    padding: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .registration-success-container {
    width: 100%;
    min-height: 100vh;
  }

  .success-text-container {
    max-width: 100%;
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .registration-success-container {
    width: 100%;
    min-height: 100vh;
  }

  .success-text-container {
    max-width: 100%;
    padding: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
}
</style>
