<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div
          class="m-0 p-3 catageries-container app-order-container app_container"
        >
          <div class="">
            <order-search
              v-on:requestToSetTimeBindings="requestToSetTimeBindings($event)"
              @requestToFilteredData="requestToFilteredData"
            />
          </div>
          <div class="bg-white common-border__radius my-4">
            <menu-bar
              v-bind:allAvailableStatusList="allAvailableStatusList"
              v-on:requestOrderDataByStatus="requestOrderByStatus($event)"
              v-bind:homeFlag="0"
              :activeMenu="activeMenuId"
              :page="page"
            />
          </div>

          <div class="my-4">
            <home-order-card
              ref="currentPageresetEvent"
              v-bind:totalPage="totalPage"
              v-bind:lastPage="lastPage"
              v-bind:currentPage="page"
              v-bind:allOrders="allOrders"
              v-on:requestDataByPageNumber="requestDataBypageNumber($event)"
            />
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import MenuBar from "../../components/orders/MenuBar.vue";
import OrderSearch from "../../components/orders/OrderSearch.vue";
import HomeOrderCard from "../../components/orders/Orders-order-card.vue";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    OrderSearch,
    MenuBar,
    HomeOrderCard,
  },
  data: function() {
    return {
      timeBindings: 1,

      activeMenuId: 0,
      page: 1,
    };
  },
  computed: {
    ...mapGetters("Order", [
      "allOrders",
      "totalPage",
      "lastPage",
      "allAvailableStatusList",
      "isCustomSearch",
      "customSearchDate",
      "activeMenu",
      "currentPage",
      "isSearchKeyword",
    ]),
    ...mapGetters("language", ["getLanguage"]),
  },
  methods: {
    ...mapActions("Order", [
      "allOrderRequest",
      "allAvailableStatus",
      "getOrderByStatus",
    ]),
    ...mapMutations("Order", ["SET_ACTIVE_MENU", "SET_CURRENT_PAGE"]),
    requestToSetTimeBindings(timeBindings) {
      this.SET_CURRENT_PAGE(1);
      this.timeBindings = timeBindings;

      if (this.timeBindings == 1) {
        if (this.activeMenuId === 0) {
          if (this.isSearchKeyword) {
            let url = `/seller_order_list/${this.getLanguage}/1?filter=${this.isSearchKeyword}`;
            this.allOrderRequest(url);
          } else {
            let url = `/seller_order_list/${this.getLanguage}/1`;
            this.allOrderRequest(url);
          }
        } else {
          if (this.isSearchKeyword) {
            //let url = "/seller_order_by_status/en/" + this.activeMenuId + "/1";
            let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenuId}/1?filter=${this.isSearchKeyword}`;
            this.getOrderByStatus(url);
          } else {
            let url =
              `/seller_order_by_status/${this.getLanguage}/` +
              this.activeMenuId +
              "/1";
            this.getOrderByStatus(url);
          }
        }
      } else {
        let date = new Date(
          Date.now() - this.timeBindings * 24 * 60 * 60 * 1000
        );

        if (this.activeMenuId == 0) {
          if (this.isSearchKeyword) {
            let url =
              `/seller_order_list/${this.getLanguage}/1?start=` +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate() +
              `&filter=${this.isSearchKeyword}`;
            this.allOrderRequest(url);
          } else {
            let url =
              `/seller_order_list/${this.getLanguage}/1?start=` +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate();
            this.allOrderRequest(url);
          }
        } else {
          if (this.isSearchKeyword) {
            let url =
              `/seller_order_by_status/${this.getLanguage}/` +
              this.activeMenuId +
              "/1?start=" +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate() +
              `&filter=${this.isSearchKeyword}`;

            this.getOrderByStatus(url);
          } else {
            let url =
              `/seller_order_by_status/${this.getLanguage}/` +
              this.activeMenuId +
              "/1?start=" +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate();

            this.getOrderByStatus(url);
          }
        }
      }
    },

    requestToFilteredData(keyword) {
      this.SET_CURRENT_PAGE(1);
      this.page = 1;
      if (this.isCustomSearch === false && this.timeBindings == 1) {
        if (this.activeMenuId === 0) {
          let url = `/seller_order_list/${this.getLanguage}/1?filter=${keyword}`;
          this.allOrderRequest(url);
        } else {
          let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenuId}/1?filter=${keyword}`;
          this.getOrderByStatus(url);
        }
      } else if (this.isCustomSearch) {
        if (this.activeMenu === 0) {
          //
          let url = `/seller_order_list/${this.getLanguage}/1?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}&filter=${keyword}`;
          this.allOrderRequest(url);
        } else {
          let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenuId}/1?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}&filter=${keyword}`;
          this.allOrderRequest(url);
        }
      } else {
        let date = new Date(
          Date.now() - this.timeBindings * 24 * 60 * 60 * 1000
        );

        if (this.activeMenuId == 0) {
          let url =
            `/seller_order_list/${this.getLanguage}/1?start=` +
            date.getUTCFullYear() +
            "-" +
            (date.getUTCMonth() + 1) +
            "-" +
            date.getDate() +
            `&filter=${keyword}`;

          this.allOrderRequest(url);
        } else {
          let url =
            `/seller_order_by_status/${this.getLanguage}/` +
            this.activeMenuId +
            "/1?start=" +
            date.getUTCFullYear() +
            "-" +
            (date.getUTCMonth() + 1) +
            "-" +
            date.getDate() +
            `&filter=${keyword}`;

          this.getOrderByStatus(url);
        }
      }
      this.$store.commit("setOverlayStatus", false); //loader close
      //let url = `/seller_order_list/en/1?filter=${keyword}`
      //this.getAllOrders(url);
    },

    requestOrderByStatus(order) {
      this.SET_CURRENT_PAGE(order.pageNumber);
      this.activeMenuId = order.activeMenuId;

      this.page = order.pageNumber;
      this.$refs.currentPageresetEvent.currentPagereset();
      if (this.isCustomSearch === false && this.timeBindings == 1) {
        if (order.activeMenuId == 0) {
          let url =
            `/seller_order_list/${this.getLanguage}/` + order.pageNumber;
          this.allOrderRequest(url);
        } else {
          let url =
            `/seller_order_by_status/${this.getLanguage}/` +
            order.activeMenuId +
            "/" +
            order.pageNumber;
          this.getOrderByStatus(url);
        }
      } else if (this.isCustomSearch === true) {
        if (this.activeMenu === 0) {
          let url = `/seller_order_list/${this.getLanguage}/${this.currentPage}?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}`;
          this.allOrderRequest(url);
        } else {
          let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenuId}/${this.currentPage}?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}`;
          this.allOrderRequest(url);
        }
      } else {
        let date = new Date(
          Date.now() - this.timeBindings * 24 * 60 * 60 * 1000
        );

        if (order.activeMenuId == 0) {
          let url =
            `/seller_order_list/${this.getLanguage}/` +
            order.pageNumber +
            "?start=" +
            date.getUTCFullYear() +
            "-" +
            (date.getUTCMonth() + 1) +
            "-" +
            date.getDate();
          this.allOrderRequest(url);
        } else {
          let url =
            `/seller_order_by_status/${this.getLanguage}/` +
            order.activeMenuId +
            "/" +
            order.pageNumber +
            "?start=" +
            date.getUTCFullYear() +
            "-" +
            (date.getUTCMonth() + 1) +
            "-" +
            date.getDate();

          this.getOrderByStatus(url);
        }
      }
    },

    requestDataBypageNumber(pageNumber) {
      if (this.isCustomSearch === false && this.timeBindings == 1) {
        if (this.activeMenuId == 0) {
          if (this.isSearchKeyword) {
            let url = `/seller_order_list/${this.getLanguage}/${pageNumber}?filter=${this.isSearchKeyword}`;
            this.allOrderRequest(url);
          } else {
            let url = `/seller_order_list/${this.getLanguage}/` + pageNumber;
            this.allOrderRequest(url);
          }
        } else {
          if (this.isSearchKeyword) {
            let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenuId}/${pageNumber}?filter=${this.isSearchKeyword}`;
            this.getOrderByStatus(url);
          } else {
            let url =
              `/seller_order_by_status/${this.getLanguage}/` +
              this.activeMenuId +
              "/" +
              pageNumber;
            this.getOrderByStatus(url);
          }
        }
      } else if (this.isCustomSearch === true) {
        if (this.activeMenuId === 0) {
          if (this.isSearchKeyword) {
            let url = `/seller_order_list/${this.getLanguage}/${this.currentPage}?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}&&filter=${this.isSearchKeyword}`;
            this.allOrderRequest(url);
          } else {
            let url = `/seller_order_list/${this.getLanguage}/${this.currentPage}?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}`;
            this.allOrderRequest(url);
          }
        } else {
          if (this.isSearchKeyword) {
            let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenuId}/${this.currentPage}?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}&filter=${this.isSearchKeyword}`;
            this.getOrderByStatus(url);
          } else {
            let url = `/seller_order_by_status/${this.getLanguage}/${this.activeMenuId}/${this.currentPage}?start=${this.customSearchDate[0]}&&end=${this.customSearchDate[1]}`;
            this.getOrderByStatus(url);
          }
        }
      } else {
        let date = new Date(
          Date.now() - this.timeBindings * 24 * 60 * 60 * 1000
        );
        if (this.activeMenuId == 0) {
          if (this.isSearchKeyword) {
            let url =
              `/seller_order_list/${this.getLanguage}/` +
              pageNumber +
              "?start=" +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate() +
              `&filter=${this.isSearchKeyword}`;
            this.allOrderRequest(url);
          } else {
            let url =
              `/seller_order_list/${this.getLanguage}/` +
              pageNumber +
              "?start=" +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate();
            this.allOrderRequest(url);
          }
        } else {
          if (this.isSearchKeyword) {
            let url =
              `/seller_order_by_status/${this.getLanguage}/` +
              this.activeMenuId +
              "/" +
              pageNumber +
              "?start=" +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate() +
              `&filter=${this.isSearchKeyword}`;

            this.getOrderByStatus(url);
          } else {
            let url =
              `/seller_order_by_status/${this.getLanguage}/` +
              this.activeMenuId +
              "/" +
              pageNumber +
              "?start=" +
              date.getUTCFullYear() +
              "-" +
              (date.getUTCMonth() + 1) +
              "-" +
              date.getDate();

            this.getOrderByStatus(url);
          }
        }
      }
    },
  },

  created() {
    // if(this.activeMenuId && this.page){
    //    let url = "/seller_order_by_status/en/" + this.activeMenuId + "/1";
    //    this.allOrderRequest(url);
    // }else{
    //   this.allOrderRequest("/seller_order_list/en/1");
    // }
    this.allAvailableStatus();
  },

  updated() {
    //this.SET_ACTIVE_MENU(this.activeMenuId);
    //this.SET_CURRENT_PAGE(this.page);
  },
};
</script>

<style scoped>
.app-order-container {
  min-width: 400px;
}

@media only screen and (max-width: 700px) {
  .app-order-container {
    padding: 0 0.625rem !important;
  }
}
</style>
