<template>
  <div :class="$style.finish_store_card_wrapper">
    <div :class="`${$style.finish_store_card} mx-auto`">
      <div>
        <h4 class="fw-bold">Finish your store setup</h4>
        <p :class="`state-color__fg--grey1 ${$style.description}`">
          Seller who add more than 20 products are more likely to get orders.
          Add all your products to your e-commerce store
        </p>
      </div>

      <div :class="`d-flex`">
        <div :class="`${$style.steps}`">
          <div :class="
            `${$style.step_circle} state-color__bg--Info d-flex justify-content-center align-items-center`
          ">
            <img src="../../../../assets/icon/white-check.svg" alt="" width="15" height="11" />
          </div>
          <div :class="`${$style.line}`"></div>
          <div :class="
            `${$style.step_circle
            } text-white d-flex align-items-center justify-content-center font-weight-500 ${isFirstProductAdded
              ? 'state-color__bg--Info'
              : 'state-color__bg--info1 state-color__fg--Info'
            }`
          ">
            2
          </div>
        </div>

        <div :class="`${$style.step_content_container}`">
          <div>
            <h6>Congratulations! Your e-commerce store is ready.</h6>
            <p :class="`state-color__fg--grey1`">
              Your store is ready and live for your customers.
            </p>
            <p :class="`state-color__fg--Info`">Visit Store</p>
          </div>

          <div :class="`${$style.step2_content}`">
            <h6 :class="`state-color__fg--grey`">Add your first product</h6>
            <p class="state-color__fg--grey1">
              Start adding products to your online store now.
            </p>
            <div class="mt-3">
              <button :class="`${$style.add_product_btn}`"
                @click="$router.push({ name: 'AddNewProductWithAttribuites', params: { languageCode: getLanguage } })">
                Add Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: { isFirstProductAdded: { type: Boolean, default: false } },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
};
</script>
<style module>
.finish_store_card {
  max-width: 50rem;
  min-height: 32rem;
  /* margin-top: 5.625rem; */
  margin-top: 4rem;
  padding: 3rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #1d1d1d0d;
  border-radius: 10px;
}

.description {
  margin-top: 1rem;
  margin-bottom: 3.25rem;
}

.steps {
  margin-top: 0.625rem;
}

.step_circle {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  border: 2px solid #3a86ff;
}

.line {
  width: 3px;
  height: 7.93rem;
  background-color: #3a86ff;
  margin-left: 1rem;
}

.step_content_container {
  margin-left: 1.25rem;
}

.step2_content {
  margin-top: 3rem;
}

.add_product_btn {
  min-height: 2.5rem !important;
  background-color: #3a86ff;
  border-radius: 8px;
  color: white;
  padding: 0 1.125rem;
  text-decoration: none;
  border: none;
}

@media screen and (max-width: 768px) {
  .finish_store_card_wrapper {
    margin: 0 0.938rem;
  }

  .line {
    height: 10.5rem;
  }

  .step2_content {
    margin-top: 2rem;
  }
}
</style>
