<template>
  <ul class="nav nav-pills products_menu common-border__radius">
    <li class="nav-item product-menu-bar">
      <router-link
        :to="routeName"
        class="nav-link state-color__fg--grey1 google_sans_medium"
        >{{ $t("product.main.products") }}</router-link
      >
    </li>
    <li class="nav-item product-menu-bar" v-if="isCategoryOpen">
      <router-link
        :to="{ name: 'AllCategories', params: { languageCode: getLanguage } }"
        class="nav-link state-color__fg--grey1 google_sans_medium"
        >{{ $t("product.main.categories") }}</router-link
      >
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    routeName: {
      default: { name: "AllProducts" },
    },
    isCategoryOpen: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
};
</script>

<style>
.product-menu-bar {
  position: relative;
}

.products_menu a.router-link-active::after {
  content: "";
  position: absolute;
  left: 25%;
  bottom: 0;
  height: 1px;
  width: 50%; /* or 100px */
  border-bottom: 2px solid #3a86ff;
}

/* .products_menu a.router-link-active {
  border-bottom: 2px solid #3a86ff !important;
  color: #3a86ff !important;
} */
</style>
