<template>
  <div>
    <button
      :class="$style.base_btn"
      class=""
      :style="{
        padding: `${props.verticalPadding}rem ${props.horizontalPadding}rem`,
      }"
    >
      <slot name="btn_name"></slot>
    </button>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  horizontalPadding: { type: Number, default: 10 },
  verticalPadding: { type: Number, default: 1 },
});
</script>

<style module>
.base_btn {
  background-color: #3a86ff !important;
  color: white;
  border: none;
  border-radius: 0.5rem;
}
</style>
