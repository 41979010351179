import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { init } from "@amplitude/analytics-browser"

import VueApexCharts from "vue3-apexcharts"
import axios from "axios"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "./assets/css/global.css"

import Echo from "laravel-echo"

import i18n from "./config/i18nConfig"

window.Pusher = require("pusher-js")

init(process.env.VUE_APP_AMPLITUDE_API_KEY)

// connect laravel echo with pusher
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "28088edaa9c9bff4a570",
  cluster: "mt1",
  forceTLS: false,
  disableStats: true,
  encrypted: true,
})

window.axios = require("axios")

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(i18n)
  .mount("#app")
