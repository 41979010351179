<template>
  <!-- modal  -->
  <div
    class="modal fade"
    id="addNewExtraChargeModal"
    tabindex="-1"
    aria-labelledby="addNewExtraChargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius">
        <div class="modal-header" style="border:none;">
          <h5 class="modal-title" id="exampleModalLabel">
            New Charge
          </h5>
          <button
            type="button"
            class="modal-close"
            id="action-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body " style="padding-top:0px;">
          <form @submit.prevent="handleSubmit()">
            <div class="bg-white common-border__radius">
              <div class="my-3">
                <div>
                  <label class="radio-inline ms-1 me-3">
                    <input type="radio" name="optradio" checked /><span
                      class="mx-2"
                      >Both</span
                    >
                  </label>
                  <label class="radio-inline mx-3">
                    <input type="radio" name="optradio" /><span class="mx-2"
                      >Inside City</span
                    >
                  </label>
                  <label class="radio-inline mx-3">
                    <input type="radio" name="optradio" /><span class="mx-2"
                      >Outside City</span
                    >
                  </label>
                </div>

                <div
                  class="d-flex flex-column  flex-md-row flex-lg-row flex-xl-row justify-content-between my-2"
                >
                  <div
                    class="form-group discount-inputfield  m-query-width-90 my-3"
                  >
                    <label for="inputcouponcode" class="state-color__fg--grey"
                      >Charge Name
                      <span class="state-color__fg--Info">*</span></label
                    >
                    <div
                      class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                    >
                      <input
                        type="text"
                        class="form-control width-80 1"
                        style="height: 40px;"
                        placeholder="Enter Amount"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div
                    class="form-group discount-inputfield  m-query-width-90 my-3"
                  >
                    <label for="inputcouponcode" class="state-color__fg--grey"
                      >Charge Amount
                      <span class="state-color__fg--Info">*</span></label
                    >
                    <div
                      class="quantity-input m-query-width-90 d-flex border rounded my-2 p-1"
                    >
                      <span class="text-center mx-1 my-auto">&#2547; </span>
                      <input
                        type="text"
                        class="form-control width-80 1"
                        style="height: 40px;"
                        placeholder="Enter value"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label for="exampleInputEmail1"
                    >Product Category
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <select
                    class="form-select my-2"
                    style="border-radius:5px; height:50px"
                    aria-label="Default select example"
                  >
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div class="mt-3">
                   <p class="state-color__fg--Info"> <span >&#2547; </span>50 will be charged as lifting charge of all product</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: function() {
    return {
      productCategoriesPlaceholder: "All Categories",
      allAvailableCategories: [],
    };
  },
};
</script>

<style></style>
