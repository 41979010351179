<template>
  <div :class="`${$style.backdrop}`">
    <div :class="`px-3 ${$style.setup_container}`">
      <div :class="`d-flex justify-content-between ${$style.title}`">
        <h6 class="state-color__fg--grey">Complete your Store Setup</h6>
        <img
          src="../../../../assets/icon/cross.svg"
          alt=""
          class="make-cursor"
          @click="$emit('onModalClose')"
        />
      </div>

      <div v-if="salesDetails?.summary_count_remaining">
        <div :class="`d-flex ${$style.progress_bar}`">
          <div
            v-for="i in completedStepsCount"
            :key="i"
            :class="
              `state-color__bg--Info ${$style.step} ${
                i == 1 ? $style.step_left_radius : ''
              } ${i == completedStepsCount ? $style.step_right_radius : ''}`
            "
          ></div>

          <div
            v-for="i in salesDetails?.summary_count_remaining"
            :key="i"
            :class="`${$style.step}`"
          ></div>
        </div>
        <div class="d-flex justify-content-between mb-4">
          <p class="state-color__fg--grey1 small-text">Step completed</p>
          <p class="state-color__fg--grey1 small-text">
            {{ completedStepsCount }}/4
          </p>
        </div>
      </div>

      <div class="mb-4 pb-2">
        <StepCard
          task="Add business Logo"
          taskDescription="Stores with a logo get 7 times more orders and 10 times more store
        views."
          :isStepCompleted="salesDetails?.seller_logo_added"
          :redirectUrl="`/${getLanguage}/profile-update`"
        />
      </div>

      <div class="mb-4 pb-2">
        <StepCard
          task="Add store address"
          taskDescription="Stores with addresses build more trust between customers."
          :isStepCompleted="salesDetails?.seller_address_added"
          :redirectUrl="`/${getLanguage}/manage/store-address`"
        />
      </div>

      <div class="mb-4 pb-2">
        <StepCard
          task="Add store timing"
          taskDescription="Users gets the idea about the store timing and reduce wasting their time."
          :isStepCompleted="salesDetails?.seller_store_timing_schedule"
          :redirectUrl="`/${getLanguage}/manage/store-timing`"
        />
      </div>

      <div class="mb-4 pb-2">
        <StepCard
          task="Add Social media and links"
          taskDescription="You can reach out your stores social media profile by adding the links"
          :isStepCompleted="salesDetails?.seller_social_links_add"
          :redirectUrl="`/${getLanguage}/manage/social-media`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import StepCard from "./step-card/index.vue";
export default {
  components: {
    StepCard,
  },

  computed: {
    ...mapGetters("graph", ["salesDetails"]),
    ...mapGetters("language", ["getLanguage"]),

    completedStepsCount() {
      return 4 - this.salesDetails?.summary_count_remaining;
    },
  },
};
</script>

<style module>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(29, 29, 29, 0.6);
  z-index: 50;
}

.setup_container {
  background-color: white;
  width: 29rem;
  position: absolute;
  bottom: 10%;
  right: 2%;
  border-radius: 10px;
}

.title {
  margin-top: 2rem;
}

.progress_bar {
  background-color: #e0e0e0;
  height: 8px;
  border-radius: 10px;
  margin-bottom: 1.25rem;
  margin-top: 2.5rem;
}

.step {
  width: 25%;
}

.step_left_radius {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.step_right_radius {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media only screen and (max-width: 576px) {
  .setup_container {
    width: 100%;
    right: 0;
    top: 5%;
  }
}
</style>
