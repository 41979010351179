<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0  catageries-container">
          <div
            class="my-3 state-color__fg--grey  app_container"
            style="padding-top:30px !important"
          >
            <h4 class="page-heading-text-size">
              {{ $t("manage.main.title") }}
            </h4>
          </div>
          <div class="my-5 manage-container app_container">
            <div>
              <router-link
                :to="{
                  name: 'MarketingMaterials',
                  params: { languageCode: getLanguage },
                }"
                class="manage-card-shadow card manage-card__width border-raduis__div p-3 text-decoration-none text-dark h-100"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-marketing.svg" alt="" />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c1") }}
                </p>
              </router-link>
            </div>

            <div>
              <router-link
                :to="{
                  name: 'CouponView',
                  params: { languageCode: getLanguage },
                }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark h-100"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-coupon.svg" alt="" />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c2") }}
                </p>
              </router-link>
            </div>

            <div>
              <router-link
                :to="{
                  name: 'StoreQRCode',
                  params: { languageCode: getLanguage },
                }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark h-100"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-qr.svg" alt="" />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c3") }}
                </p>
              </router-link>
            </div>

            <div>
              <router-link
                :to="{
                  name: 'CustomerList',
                  params: { languageCode: getLanguage },
                }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark h-100"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-customer.svg" alt="" />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c4") }}
                </p>
              </router-link>
            </div>

            <div>
              <router-link
                :to="{
                  name: 'PaymentMethods',
                  params: { languageCode: getLanguage },
                }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-payment.svg" alt="" />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c5") }}
                </p>
              </router-link>
            </div>

            <!-- <div>
              <router-link
                :to="{ name: 'ExtraCharges' }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-charge.svg" alt="" />
                </div>
                <p>Extra Charges</p>
              </router-link>
            </div>

            <div>
              <router-link
                :to="{ name: 'OrderForm' }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-form.svg" alt="" />
                </div>
                <p>Order Form</p>
              </router-link>
            </div> -->

            <div>
              <router-link
                :to="{
                  name: 'StoreAddress',
                  params: { languageCode: getLanguage },
                }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark h-100"
              >
                <div class="mb-3">
                  <img
                    src="../../assets/manage/storeaddress.svg"
                    alt="store-address"
                  />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c8") }}
                </p>
              </router-link>
            </div>

            <div>
              <router-link
                :to="{
                  name: 'StoreTiming',
                  params: { languageCode: getLanguage },
                }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark h-100"
              >
                <div class="mb-3">
                  <img src="../../assets/manage/manage-time.svg" alt="" />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c6") }}
                </p>
              </router-link>
            </div>

            <div>
              <router-link
                :to="{
                  name: 'SocialMediaSupport',
                  params: { languageCode: getLanguage },
                }"
                class="shadow-sm card manage-card__width border-raduis__div p-3 text-decoration-none text-dark h-100"
              >
                <div class="mb-3">
                  <img
                    src="../../assets/manage/social-media.png"
                    alt=""
                    height="40"
                    width="40"
                  />
                </div>
                <p class="medium-text google_sans_medium">
                  {{ $t("manage.main.cards.c7") }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
export default {
  components: { SideBar, TopBar, LoadingSpinner },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },
};
</script>

<style scoped>
.manage-card-shadow {
  box-shadow: 0px 3px 12px #1d1d1d0d;
}
</style>
