<template>
  <div
    class="modal fade"
    id="deleteAccountConformationModal"
    tabindex="-1"
    aria-labelledby="deleteAccountConformationLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal-content common-border__radius"
        style="min-height: 300px"
      >
        <div
          class="modal-header float-end"
          style="border-bottom: none !important"
        >
          <h5></h5>
          <button
            type="button"
            class="modal-close"
            id="delete-account-modal-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body m-2 p-2">
          <div class="text-center">
            <h5>{{ $t("setting.main.delete_profile.title") }}?</h5>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <button
              type="button"
              class="btn btn-primary mx-2"
              style="width: 148px; height: 50px"
              @click="closeConfirmationModal()"
            >
              {{ $t("setting.main.delete_profile.no") }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary mx-2"
              style="width: 148px; height: 50px"
              @click="deleteAccount"
            >
              {{ $t("setting.main.delete_profile.yes") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("auth", ["storeDeleteRequest"]),

    closeConfirmationModal() {
      document.getElementById("delete-account-modal-close").click();
    },

    async deleteAccount() {
      await this.storeDeleteRequest();
    },
  },
};
</script>

<style></style>
