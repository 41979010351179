<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <!-- comment -->
          <div
            class="mt-3 d-flex align-items-center state-color__fg--grey page-heading-top-padding"
          >
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="font-weight-500 ms-3 mb-0">
              {{ $t("manage.main.coupon.title") }}
            </h4>
          </div>
          <div
            class="mt-4 mb-3 mx-2 d-flex justify-content-center justify-content-md-between justify-content-lg-between justify-content-xl-between flex-column flex-lg-row flex-md-row flex-xl-row"
          >
            <div
              class="
                    input-group
                    border border-1
                    bg-white
                    w-50
                    search-box__container
                    common-border__radius
                    "
              style="max-height:60px"
            >
              <div
                class="input-group-prepend bg-white my-auto px-2 common-border__radius "
                style="padding-left:17px !important"
              >
                <img
                  src="../../assets/icon/search.svg"
                  alt=""
                  class="my-auto"
                  @click="searchByCoupon()"
                  height="23"
                  width="23"
                />
              </div>
              <input
                type="text"
                class="form-control no-border common-border__radius coupon-create-btn-padding"
                :placeholder="
                  $t('manage.main.coupon.coupon_search_placeholder')
                "
                aria-label=""
                aria-describedby="basic-addon1"
                v-model="couponSearchField"
                @keyup.enter="searchByCoupon()"
              />
            </div>

            <div>
              <router-link
                :to="{
                  name: 'CreateCoupon',
                  params: { languageCode: getLanguage },
                }"
                type="button"
                class="btn btn-primary common-border__radius-8 d-flex justify-content-center align-items-center coupon-create-btn-padding"
              >
                <img src="../../assets/icon/button-add.svg" alt="" />
                <span style="margin-left:10px">
                  {{ $t("manage.main.coupon.add_coupon") }}</span
                >
              </router-link>
            </div>
          </div>

          <div class="my-4 mx-auto d-flex flex-wrap">
            <div
              class="m-3"
              v-for="coupon in allCoupons"
              :key="coupon.id_coupon"
            >
              <coupon-card :coupon="coupon" />
            </div>
          </div>

          <div
            v-if="!allCoupons.length"
            class="text-center"
            style="height: 600px"
          >
            <img src="../../assets/icon/No-coupon.svg" alt="" />
            <h5 class="text-center state-color__fg--grey1">
              {{ $t("manage.main.coupon.not_found") }}
            </h5>
          </div>

          <pagination-component
            v-bind:lastPage="lastPage"
            v-bind:currentPage="currentPage"
            v-on:requestDataByPageNumber="getDataByPage($event)"
          />
        </div>
      </div>
    </div>
    <loading-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import CouponCard from "../../components/manage/CouponCard.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    CouponCard,
    PaginationComponent,
  },
  data: function() {
    return {
      couponSearchField: "",
      currentPage: 1,
    };
  },

  computed: {
    ...mapGetters("coupon", ["allCoupons", "lastPage"]),
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    ...mapActions("coupon", ["getAllCoupons", "searchCouponRequest"]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    async searchByCoupon() {
      let data = { page: 1, couponKey: this.couponSearchField };
      await this.searchCouponRequest(data);
    },

    async getDataByPage(page) {
      this.currentPage = page;
      await this.getAllCoupons(page);
    },
  },

  async created() {
    await this.getAllCoupons(this.currentPage);
  },
};
</script>

<style>
.coupon-card-holder-div {
  width: 33%;
}

.coupon-create-btn-padding {
  padding: 0.8125rem 1.4rem !important;
}
</style>
