import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getDivisionOfCountry(countryId) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/division_by_country/${language}/` + countryId,
      method: "GET",
    });
  },

  getCityByDivision(divisionId) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/city_by_division/${language}/` + divisionId,
      method: "GET",
    });
  },

  getAreaByCity(cityId) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/area_by_city/${language}/` + cityId,
      method: "GET",
    });
  },

  updateInstorePickupStatus(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/instore_pickup_status/${language}`,
      method: "PUT",
      data,
    });
  },

  updateUserInfo(data) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `/update_seller_profile/${language}`,
      method: "POST",
      data,
    });
  },
};
