<template>
  <div :class="`${$style.card} d-flex`">
    <a :href="videoLink" target="_blank" class="position-relative">
      <img
        :src="imgUrl"
        alt="youtube url"
        width="138"
        height="138"
        class="common-border__radius"
      />
      <div
        :class="
          `${$style.play_btn_holder} d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle`
        "
      >
        <img
          src="../../../../assets/icon/play-button-svgrepo-com.svg"
          alt="play icon"
          width="17"
        />
      </div>
    </a>
    <div :class="`${$style.right_content}`">
      <h6 class="">{{ title }}</h6>
      <p class="small-text state-color__fg--grey1">
        {{ description }}
      </p>
      <a
        :href="videoLink"
        target="_blank"
        class="state-color__fg--Info font-weight-500 text-decoration-underline"
      >
        Watch video
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    description: { type: String },
    imgUrl: { type: String },
    videoLink: { type: String },
  },
};
</script>

<style module>
.card {
  max-width: 24.375rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #1d1d1d0d;
  border-radius: 10px;
  padding: 0.75rem;
  margin-top: 1.25rem;
}

.play_btn_holder {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  /* opacity: 0.1; */
}

.right_content {
  margin-left: 1rem;
}

@media screen and (max-width: 768px) {
  .card {
    max-width: 100%;
    width: 100%;
  }
}
</style>
