<template>
  <div class="d-flex flex-wrap justify-content-center ">
    <div
      class="banner-dropzone d-flex flex-column justify-content-center align-items-center bg-white mt-2"
      @dragover.prevent
      @drop.prevent="submitDropBannerImage($event)"
    >
      <img src="../../assets/icon/image.svg" alt="" width="40" class="" />

      <div class="text-center">
        <label class="custom-file-upload mx-auto my-auto state-color__fg--Info">
          <input
            ref="productImageUploader"
            type="file"
            accept="image/*"
            id="file-input"
            @change="submitImage($event)"
          />
          <span class="dropzone-upload__message google_sans_medium">{{
            $t("appearance.main.banner.placeholder_1")
          }}</span
          ><span style="font-weight: bold;" class="google_sans_medium">
            {{ $t("appearance.main.banner.placeholder_2") }}</span
          >
        </label>
        <p class="state-color__fg--grey1 mb-1">
          {{ $t("appearance.main.banner.info_text") }}
        </p>
        <p class="state-color__fg--grey1">
          {{ $t("appearance.main.banner.support") }}
        </p>
      </div>
    </div>

    <div class="ps-3 mt-2">
      <!-- existing images -->
      <div
        class="banner-image position-relative mb-2 "
        v-for="imageInfo in existingImagesInfo"
        :key="imageInfo.image_path"
      >
        <img
          src="../../assets/icon/cross.svg"
          width="25"
          height="25"
          alt=""
          class="banner-close__btn broder-circle make-cursor"
          @click="requestDeleteExistingImages(imageInfo.image_path)"
        />

        <img
          class="w-100 h-100 common-border__radius"
          :src="imageInfo.image_path"
        />
      </div>

      <!-- show new images -->
      <div
        class="banner-image position-relative mb-2 "
        v-for="imageInfo in allImagesInfo"
        :key="imageInfo.index"
      >
        <img
          src="../../assets/icon/cross.svg"
          width="25"
          height="25"
          alt=""
          class="banner-close__btn broder-circle make-cursor"
          @click="deleteImage(imageInfo.index)"
        />

        <img class="w-100 h-100 common-border__radius" :src="imageInfo.url" />
      </div>

      <div
        class="banner-image mb-2"
        v-for="index in 3 - (allImagesInfo.length + existingImagesInfo.length)"
        :key="index"
      ></div>

      <div class="my-3 d-flex justify-content-end">
        <button
          class="btn btn-primary"
          style=" padding:10px 45px;"
          type="submit"
          @click="requestUploadBannerImages()"
        >
          {{ $t("appearance.main.banner.button") }}
        </button>
      </div>
    </div>
  </div>

  <crop-image-modal
    :ImageUrl="originalImageUrl"
    :image="blobImage"
    :ratio="1268 / 350"
    parent="banner"
    @croppedData="addProductCroppedImage"
  />
  <button
    id="imageCrop"
    data-bs-toggle="modal"
    data-bs-target="#addCropImageModal"
    class="d-none"
  ></button>
</template>

<script>
import $ from "jquery";
import cropImageModal from "../product/cropImageModal.vue";

export default {
  props: ["existingImages"],
  emits: [
    "requestUploadBannerImages",
    "requestDeleteExistingImage",
    "requestShowToast",
  ],

  components: {
    cropImageModal,
  },

  data: function() {
    return {
      allImagesInfo: [],
      existingImagesInfo: [],
      image: null,
      url: null,
      index: 0,
      originalImageUrl: "",
      blobImage: "",
    };
  },

  watch: {
    existingImages: function(newVal) {
      this.existingImagesInfo = newVal;
    },
  },

  methods: {
    submitDropBannerImage(event) {
      if (!event.dataTransfer.files[0]) return;
      this.image = event.dataTransfer.files[0];
      if (this.image.size > 1024 * 1021 * 6) {
        this.$emit("requestShowToast", "Image size must be less than 6 MB");
      } else if (
        this.allImagesInfo.length + this.existingImagesInfo.length >=
        3
      ) {
        this.$emit("requestShowToast", "Can't upload more than 3 banner image");
      } else if (
        event.dataTransfer.files[0]["type"] === "image/jpeg" ||
        event.dataTransfer.files[0]["type"] === "image/png" ||
        event.dataTransfer.files[0]["type"] === "image/jpg" ||
        event.target.files[0]["type"] === "image/bmp"
      ) {
        this.pushNewImageToList();
      } else {
        this.$emit(
          "requestShowToast",
          this.$t("manage.validation.banner.image_type_error")
        );
      }

      this.image = null;
      this.url = "";
    },

    submitImage(event) {
      if (!event.target.files[0]) return;
      this.image = event.target.files[0];

      if (this.image.size > 1024 * 1021 * 6) {
        this.$emit("requestShowToast", "Image size must be less than 6 MB");
      } else if (
        this.allImagesInfo.length + this.existingImagesInfo.length >=
        3
      ) {
        this.$emit("requestShowToast", "Can't upload more than 3 banner image");
      } else if (
        event.target.files[0]["type"] === "image/jpeg" ||
        event.target.files[0]["type"] === "image/png" ||
        event.target.files[0]["type"] === "image/jpg" ||
        event.target.files[0]["type"] === "image/bmp"
      ) {
        this.croppedImage(event);
      } else {
        this.$emit(
          "requestShowToast",
          this.$t("manage.validation.banner.image_type_error")
        );
      }
      this.image = null;
      this.url = "";
    },

    croppedImage(event) {
      this.originalImageUrl = URL.createObjectURL(event.target.files[0]);
      const blob = URL.createObjectURL(event.target.files[0]);
      this.blobImage = {
        src: blob,
        type: event.target.files[0].type,
      };

      //$("#action-close").trigger("click");
      $("#imageCrop").trigger("click");
    },

    addProductCroppedImage(data) {
      this.pushNewImageToList(data);
    },

    pushNewImageToList(data) {
      this.url = data.url;
      this.allImagesInfo.push({
        index: this.index,
        image: data.image,
        url: this.url,
      });
      this.index = this.index + 1;
      $("#file-input").val("");
    },

    // pushNewImageToList() {
    //   this.url = URL.createObjectURL(this.image);
    //   this.allImagesInfo.push({
    //     index: this.index,
    //     image: this.image,
    //     url: this.url,
    //   });
    //   this.index = this.index + 1;
    //   $("#file-input").val("");
    //
    // },

    deleteImage(index) {
      let i = 0;
      for (i = 0; i < this.allImagesInfo.length; i++) {
        if (this.allImagesInfo[i].index == index) {
          this.allImagesInfo.splice(i, 1);
        }
      }
    },

    requestDeleteExistingImages(imgPath) {
      this.$store.commit("setOverlayStatus", true); //loader open
      this.$emit("requestDeleteExistingImage", imgPath);
    },

    requestUploadBannerImages() {
      if (this.allImagesInfo.length < 1) {
        this.$emit("requestShowToast", "Please add a new banner image");
        return;
      }
      this.$store.commit("setOverlayStatus", true); //loader open
      this.$emit("requestUploadBannerImages", this.allImagesInfo);
      this.allImagesInfo = [];
    },
  },
};
</script>

<style>
.banner-dropzone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%233A86FFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='43' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 14px;
  width: 62%;
  min-width: 330px;
  height: 482px;
}

.dropzone-upload__message {
  font-weight: bold;
  color: #1d1d1d;
}

.banner-image {
  width: 350px;
  height: 150px;
  background-color: white;
  border-radius: 10px;
}

.banner-close__btn {
  position: absolute;
  right: 5%;
  top: 5%;
}
</style>
