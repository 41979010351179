<template>
  <div
    class="modal fade"
    id="addNewOrderFieldModal"
    tabindex="-1"
    aria-labelledby="addNewOrderFieldModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content common-border__radius">
        <div class="modal-header" style="border:none;">
          <h5 class="modal-title" id="exampleModalLabel">
            Add New Field
          </h5>
          <button
            type="button"
            class="modal-close"
            id="action-close"
            data-bs-dismiss="modal"
          >
            <img src="../../assets/icon/cross.svg" alt="cross" />
          </button>
        </div>
        <div class="modal-body " style="padding-top:0px;">
          <form @submit.prevent="handleSubmit()">
            <div class="bg-white common-border__radius">
              <div class="my-2">
                <div class="form-group mb-3">
                  <label for="inputcouponcode" class="state-color__fg--grey"
                    >Field Name
                    <span class="state-color__fg--Info">*</span></label
                  >
                  <input
                    class="form-control form-control-lg my-2"
                    type="text"
                    placeholder="Enter Name"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between mt-2 mb-4">
              <h6>Is This Field Mandatory?</h6>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  checked
                />
              </div>
            </div>
            <div class="d-flex justify-content-center mb-3">
                <button type="button" class="btn btn-primary float-end px-4 py-3">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
