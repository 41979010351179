<template>
  <div
    class="text-capitalize make-cursor"
    :class="$style.category_tab"
    :style="{ 'background-color': props.bg_color, color: props.fg_color }"
  >
    <slot name="tab_name"></slot>
  </div>
</template>

<script setup>
import { defineProps } from "vue"

const props = defineProps({
  bg_color: { type: String, default: "white" },
  fg_color: { type: String, default: "#1D1D1D" },
})
</script>

<style module>
.category_tab {
  border-radius: 1.25rem;
  padding: 0.4rem 1.25rem;
  border: 1px solid #e0e0e0;
  font-family: "google-sans-medium";
}
</style>
