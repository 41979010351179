<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container app_container">
          <div class="my-3 d-flex page-heading-top-padding">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="$router.go(-1)"
            />
            <h4 class="ms-3 my-0">{{ $t("appearance.main.theme") }}</h4>
          </div>

          <div
            class="my-4 d-flex flex-column flex-md-row flex-lg-row flex-xl-row"
          >
            <div class="bg-white me-3 common-border__radius theme-sidebar">
              <div class="mx-2 my-3">
                <h4 class="mx-2">{{ $t("appearance.main.theme_name") }}</h4>
              </div>
              <div
                class="mx-2 border-bottom theme-sidebar__color make-cursor"
                :class="{ 'theme-selected__option': localSelectedMenu == 1 }"
                @click="selectedMenu(1)"
              >
                <p class="mx-2 my-3">
                  {{ $t("appearance.main.banner.title") }}
                </p>
              </div>
              <div
                class="mx-2 border-bottom theme-sidebar__color make-cursor"
                :class="{ 'theme-selected__option': localSelectedMenu == 2 }"
                @click="selectedMenu(2)"
              >
                <p class="mx-2 my-3">
                  {{ $t("appearance.main.footer.title") }}
                </p>
              </div>
              <div
                class="mx-2 border-bottom theme-sidebar__color make-cursor"
                :class="{ 'theme-selected__option': localSelectedMenu == 3 }"
                @click="
                  $router.push({
                    name: 'AllPages',
                    params: { languageCode: getLanguage },
                  })
                "
              >
                <p class="mx-2 my-3">
                  {{ $t("appearance.main.pages.heading") }}
                </p>
              </div>
              <div
                class="mx-2 border-bottom theme-sidebar__color make-cursor"
                :class="{ 'theme-selected__option': localSelectedMenu == 4 }"
                @click="selectedMenu(4)"
              >
                <p class="mx-2 my-3">
                  {{ $t("appearance.main.favicon.title") }}
                </p>
              </div>
            </div>
            <div class="theme-content">
              <banner v-if="localSelectedMenu == 1" />
              <Footer v-else-if="localSelectedMenu == 2" />
              <favicon v-else-if="localSelectedMenu == 4" />
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";

import LoadingSpinner from "../../components/LoadingSpinner.vue";
import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";
import Banner from "../../components/manage/theme/Banner.vue";
import Footer from "../../components/manage/theme/Footer.vue";
import Favicon from "../../components/manage/theme/Favicon.vue";

export default {
  components: { SideBar, TopBar, LoadingSpinner, Banner, Footer, Favicon },
  data: function() {
    return {
      localSelectedMenu: 1,
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    triggerFileInput() {
      $("#file-input").trigger("click");
    },

    selectedMenu(number) {
      this.localSelectedMenu = number;
    },
  },
};
</script>

<style>
.theme-sidebar__color {
  color: #272f46;
}

.theme-sidebar {
  width: 25%;
  align-self: flex-start;
}

.theme-content {
  width: 70%;
}

.theme-selected__option {
  color: #3a86ff;
  border-right: 2px solid #3a86ff;
}

@media only screen and (max-width: 800px) {
  .theme-sidebar,
  .theme-content {
    width: 100%;
  }
}
</style>
