<template>
  <div>
    <select
      class="form-select common-border__radius-8"
      :class="$style.select_box"
      aria-label="Default select example"
      @change="handleOptionChange"
      :disabled="props.isSelectOptionDisabled"
    >
      <option
        v-for="(item, index) in props.optionsList"
        :key="index"
        :value="item.id"
        >{{ item.text }}</option
      >
    </select>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"
import { useStore } from "vuex"

const props = defineProps({
  optionsList: { type: Array, default: () => [] },
  isSelectOptionDisabled: { type: Boolean, default: false },
})
const emit = defineEmits(["selectOptionsOnChange"])
const store = useStore()

const handleOptionChange = (event) => {
  store.dispatch("Loader/setOverlayStatus", true)
  emit("selectOptionsOnChange", event.target.value)
}
</script>

<style module>
.select_box {
  height: 3.25rem;
}
</style>
