import Client from "../client/axiosClient";
import store from "../../store";

export default {
  getAllNotification(data) {
    const { sellerKey, page } = data;
    return Client({
      url: `/web_notifications/${sellerKey}?page=${page}`,
      method: "GET",
    });
  },

  markAllNotificationRed(sellerKey) {
    return Client({
      url: `/web_notifications_mark_read_all/${sellerKey}`,
      method: "GET",
    });
  },

  markNotificationRed(id) {
    return Client({
      url: `web_notification_single_mark_read/${id}`,
      method: "GET",
    });
  },

  deleteNotification(id) {
    let language = store.getters["language/getLanguage"];
    return Client({
      url: `web_notifications/${language}/${id}`,
      method: "DELETE",
    });
  },
};
