<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <div class="m-0 px-3 catageries-container" style="min-width: 200px;">
          <div>
            <drop-zone
              v-bind:existingImages="getExBanners"
              v-on:requestUploadBannerImages="uploadBannerImage($event)"
              v-on:requestDeleteExistingImage="deleteExistingImage($event)"
              v-on:requestShowToast="showToast($event, 'danger')"
              ref="bannerDropZone"
            />
          </div>
        </div>
      </div>
      <!-- <loading-spinner /> -->
    </div>
    <!-- Extra Charge Modal -->
  </div>
</template>

<script>
//import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import DropZone from "../../../components/manage/DropZone.vue";

import Toast, { errorBackground } from "../../../config/toastConfig";

import Swal from "sweetalert2";

import { mapActions, mapGetters } from "vuex";

export default {
  components: { DropZone },
  data: function() {
    return {
      existingImages: [],
    };
  },

  computed: mapGetters("Banner", ["getExBanners"]),

  methods: {
    ...mapActions("Banner", ["getBanners", "uploadBanner", "deleteBanner"]),

    showToast(data) {
      Toast.fire({
        icon: "error",
        title: data,
        background: errorBackground,
      });
    },

    async uploadBannerImage(allImages) {
      //let vm = this;
      const formData = new FormData();

      allImages.forEach((file) => {
        formData.append("banner_image[]", file.image);
      });

      await this.uploadBanner(formData);
      await this.getBanners();
    },

    async deleteExistingImage(imgPath) {
      Swal.fire({
        title: this.$t("manage.main.delete_confirmation_message.title"),
        text: this.$t("manage.main.delete_confirmation_message.banner"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "manage.main.delete_confirmation_message.delete_btn"
        ),
        cancelButtonText: this.$t(
          "manage.main.delete_confirmation_message.cancel_btn"
        ),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            banner_image_path: imgPath,
          };

          await this.deleteBanner(data);
          await this.getBanners();
        }
      });
    },
  },

  async created() {
    await this.getBanners();
  },
};
</script>

<style></style>
