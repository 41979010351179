<template>
  <div>
    <div
      class="d-flex justify-content-between flex-column flex-lg-row flex-md-row flex-xl-row"
    >
      <h5>{{ $t("product.main.variation.title") }}</h5>
      <router-link
        v-if="productCategory != null"
        :to="{
          name: 'CreateProductAttribute',
          params: { categoryId: productCategory.id, languageCode: getLanguage },
        }"
        target="_blank"
        type="button"
        class="btn btn-primary"
      >
        {{ $t("product.main.variation.create_attribute_btn") }}
      </router-link>
    </div>
    <div id="table-wrapper">
      <div class="attribute-table-holder mt-4 h-100">
        <table class="w-100 h-100 attribute-table">
          <thead class="header">
            <tr class="state-color__fg--grey1">
              <th
                v-for="attribute in categoryAttributeValue"
                :key="attribute.id_global_attribute"
              >
                {{
                  attribute.global_attribute_name.charAt(0).toUpperCase() +
                    attribute.global_attribute_name.slice(1)
                }}
              </th>

              <th>
                {{ $t("product.main.variation.field.f1") }}
                <span class="text-primary">*</span>
              </th>
              <th>
                {{ $t("product.main.variation.field.f2") }}
              </th>
              <th>
                {{ $t("product.main.variation.field.f3") }}
                <span class="text-primary">*</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody v-click-outside="hideDropdownColorAttributeSelect">
            <!-- existing attributes -->

            <tr
              class="table-row"
              v-for="(row, index) in nonEditableRow"
              :key="index"
            >
              <td
                v-for="attribute in categoryAttributeValue"
                :key="attribute.id_global_attribute"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <div
                    v-if="attribute.global_attribute_name == 'color'"
                    class="color-code-box mx-1"
                    v-bind:style="{
                      'background-color': attributeValueToSpecificColumn(
                        attribute,
                        index
                      ).attributeValue,
                    }"
                  ></div>
                  {{
                    attributeValueToSpecificColumn(attribute, index)
                      .attributeValue
                  }}
                </div>
              </td>
              <td>
                <div class="custom-input-field">
                  <div class="w-100 h-100 d-flex align-items-center">
                    <span class="taka">&#2547;</span>
                    <input
                      type="text"
                      name=""
                      :id="'existingAttributePrice' + index"
                      class="borderless-inp-field"
                      :placeholder="
                        $t('product.main.variation.price_placeholder')
                      "
                      autocomplete="off"
                      @input="setPreviousPriceAttribute($event, index)"
                      v-model="localProductAttributeCombination[index].price"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="custom-input-field">
                  <div class="w-100 h-100 d-flex align-items-center">
                    <span class="taka">&#2547;</span>
                    <input
                      type="text"
                      name=""
                      :id="'existingAttributeDiscountPrice' + index"
                      class="borderless-inp-field"
                      :placeholder="
                        $t(
                          'product.main.variation.discounted_price_placeholder'
                        )
                      "
                      autocomplete="off"
                      @input="
                        setPreviousDiscountPriceByAttribute($event, index)
                      "
                      v-model="
                        localProductAttributeCombination[index].discount_price
                      "
                    />
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="w-100 h-100 d-flex align-items-center justify-content-center"
                >
                  <input
                    type="text"
                    name=""
                    :id="'existingAttributeQuantity' + index"
                    class="inp-field"
                    :placeholder="
                      $t('product.main.variation.quantity_placeholder')
                    "
                    autocomplete="off"
                    @input="setPreviousQuantityByAttribute($event, index)"
                    v-model="localProductAttributeCombination[index].quantity"
                  />
                </div>
              </td>
              <td>
                <div
                  class="delete-attribute common-border__radius-8 d-flex justify-content-center align-items-center make-cursor"
                  @click="deleteColumn(index)"
                >
                  <img src="../../../assets/icon/delete-red.svg" alt="" />
                </div>
              </td>
            </tr>

            <!-- new attribute combination -->

            <tr class="table-row" v-if="totalRow >= 1">
              <td
                v-for="(attribute, index) in categoryAttributeValue"
                :key="attribute.id_global_attribute"
              >
                <div
                  v-if="
                    attribute.global_attribute_name.toLowerCase() == 'color'
                  "
                  class="d-flex justify-content-center"
                >
                  <div>
                    <button
                      class="select-color-dropdown-btn"
                      :class="
                        attributeCombinationStatus[
                          attribute.id_global_attribute
                        ]
                          ? 'btn-enabled'
                          : 'btn-disabled'
                      "
                      @click="
                        toggleColorAttributeSelector(
                          attribute.id_global_attribute
                        )
                      "
                    >
                      <div
                        v-if="!selectedColor.includes('Select')"
                        class="color-code-box"
                        :style="{ 'background-color': selectedColor }"
                      ></div>
                      <span v-if="!selectedColor.includes('Select')">{{
                        colorCodeToName(selectedColor)
                      }}</span>
                      <span v-else>{{ selectedColor }}</span>
                      <img src="../../../assets/icon/dropdown.svg" alt="" />
                    </button>
                  </div>

                  <div
                    class="shadow position-absolute color-select-dropdown p-2 rounded"
                    v-if="!hideColorAttributeSelector"
                  >
                    <div
                      v-for="attributeVal in attribute.seller_attribute_value"
                      :key="attributeVal.name"
                      class="m-2 d-flex flex-start align-items-center make-cursor"
                      @click="
                        addValueToCombination(
                          totalRow - 1,
                          attribute.id_global_attribute,
                          attributeVal.id_attribute_value,
                          attributeVal.name
                        ),
                          (hideColorAttributeSelector = true),
                          (selectedColor = attributeVal.name)
                      "
                    >
                      <div
                        class="color-code-box"
                        v-bind:style="{ 'background-color': attributeVal.name }"
                      ></div>
                      <span class="ms-2">{{
                        colorCodeToName(attributeVal.name)
                      }}</span>
                    </div>

                    <div
                      role="button"
                      class="text-primary"
                      @click="redirectCreateAttributePage(productCategory.id)"
                    >
                      <p>
                        + {{ $t("product.main.variation.add_new") }}
                        <span class="text-capitalize">{{
                          attribute.global_attribute_name
                        }}</span>
                      </p>
                    </div>

                    <div v-if="attribute.seller_attribute_value.length == 0">
                      No Attribute Found
                    </div>
                  </div>
                </div>

                <div v-else class="d-flex justify-content-center">
                  <div>
                    <button
                      class="select-option-dropdown-btn"
                      :class="
                        attributeCombinationStatus[
                          attribute.id_global_attribute
                        ]
                          ? 'btn-enabled'
                          : 'btn-disabled'
                      "
                      @click="
                        toggleOtherAttributeSelector(
                          index,
                          attribute.id_global_attribute
                        )
                      "
                    >
                      <p class="mb-0">{{ selectAttribute[index] }}</p>
                      <img src="../../../assets/icon/dropdown.svg" alt="" />
                    </button>
                  </div>

                  <div
                    class="shadow position-absolute other-attribute-select-dropdown p-2 rounded"
                    v-if="!hideOtherAttributeSelector[index]"
                  >
                    <div
                      v-for="attributeVal in attribute.seller_attribute_value"
                      :key="attributeVal.name"
                      @click="
                        addValueToCombination(
                          totalRow - 1,
                          attribute.id_global_attribute,
                          attributeVal.id_attribute_value,
                          attributeVal.name
                        ),
                          (hideOtherAttributeSelector[index] = true),
                          (selectAttribute[index] = attributeVal.name)
                      "
                      class="m-2 d-flex flex-start align-items-center justify-content-center make-cursor"
                    >
                      <p class="text-center">{{ attributeVal.name }}</p>
                    </div>
                    <div
                      role="button"
                      class="text-primary"
                      @click="redirectCreateAttributePage(productCategory.id)"
                    >
                      <p>
                        + {{ $t("product.main.variation.add_new") }}
                        <span class="text-capitalize">{{
                          attribute.global_attribute_name
                        }}</span>
                      </p>
                    </div>

                    <div v-if="attribute.seller_attribute_value.length == 0">
                      No Attribute Found
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div class="custom-input-field">
                  <div class="w-100 h-100 d-flex align-items-center">
                    <span class="taka">&#2547;</span>
                    <input
                      type="number"
                      name=""
                      id="attributePriceInsert"
                      class="borderless-inp-field"
                      :placeholder="
                        $t('product.main.variation.price_placeholder')
                      "
                      autocomplete="off"
                      @input="setPriceByAttribute($event, totalRow - 1)"
                      v-model="price"
                    />
                  </div>
                </div>
              </td>

              <td>
                <div class="custom-input-field">
                  <div class="w-100 h-100 d-flex align-items-center">
                    <span class="taka">&#2547;</span>
                    <input
                      type="number"
                      name=""
                      :id="'attributeDiscountPrice' + (totalRow - 1)"
                      class="borderless-inp-field"
                      :placeholder="
                        $t(
                          'product.main.variation.discounted_price_placeholder'
                        )
                      "
                      autocomplete="off"
                      @input="setDiscountPriceByAttribute($event, totalRow - 1)"
                      v-model="discountPrice"
                    />
                  </div>
                </div>
              </td>

              <td>
                <div
                  class="w-100 h-100 d-flex align-items-center justify-content-center"
                >
                  <input
                    type="number"
                    name=""
                    :id="'attributeQuantity' + (totalRow - 1)"
                    class="inp-field"
                    :placeholder="
                      $t('product.main.variation.quantity_placeholder')
                    "
                    autocomplete="off"
                    @input="setQuantityByAttribute($event, totalRow - 1)"
                    v-model="quantity"
                    @keydown="checkIsNumber($event)"
                  />
                </div>
              </td>

              <td>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="addNewRow()"
                >
                  {{ $t("product.main.variation.field.add_btn") }}
                </button>
              </td>
            </tr>

            <tr class="table-row">
              <td class="d-flex justify-content-center align-items-center">
                <!-- <button
                v-if="productCategory != null"
                class="add-new-attribute-val state-color__bg--grey2 fg-black"
                @click="addNewRow()"
              >
                <img
                  src="../../../assets/icon/plus.svg"
                  alt="cross"
                  class="me-2"
                />{{ $t("product.main.variation.add_another") }}
              </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vClickOutside from "click-outside-vue3";
import Toast, {
  errorBackground,
  successBackground,
} from "../../../config/toastConfig";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  data: function() {
    return {
      hideColorAttributeSelector: true,
      hideOtherAttributeSelector: [],

      localProductAttributeCombination: [],
      totalRow: 0,
      nonEditableRow: 0,
      attributeCombinationStatus: {}, // shows if a attribute value is selected = Enabled or not = disabled
      defaultCombinationStatus: {}, // initial all to false
      selectedAttributeLen: 0,

      selectedColor: "Select Color",
      selectAttribute: [],

      dropdownFlagSet: false,

      price: "",
      discountPrice: "",
      quantity: "",
      saved_combinations: [],
    };
  },

  computed: {
    ...mapGetters("product", [
      "categoryAttributeValue",
      "productCategory",
      "productPrice",
      "productDiscountedPrice",
      "quantityAmount",
      "previosProductAttributeCombination",
      "existingValueFlag",
      "existingProductId",
      "existingProduct",
    ]),
    ...mapGetters("language", ["getLanguage"]),
  },

  watch: {
    totalRow(newVal) {
      if (newVal == 0) {
        this.nonEditableRow = 0;
      } else {
        this.nonEditableRow = newVal - 1;
      }
    },

    existingValueFlag(newVal) {
      if (newVal) {
        //  this.setOverlayStatus(true);
        this.localProductAttributeCombination = this.previosProductAttributeCombination;
        this.totalRow = this.localProductAttributeCombination.length;

        this.addNewRow();
        this.dropdownFlagSet = true;
        this.disableUnselectedAttributes();
        if (this.totalRow <= 1) {
          //  this.setOverlayStatus(false);
        }
      }
    },

    productPrice(newVal) {
      if (this.totalRow == 1 && !this.existingValueFlag) {
        this.price = newVal;
        this.localProductAttributeCombination[this.totalRow - 1].price = newVal;
        this.$store.commit("product/setProductAttributeCombination", {
          attributeCombination: this.localProductAttributeCombination,
        });
      }
    },

    productDiscountedPrice(newVal) {
      if (this.totalRow == 1 && !this.existingValueFlag) {
        this.discountPrice = newVal;
        this.localProductAttributeCombination[
          this.totalRow - 1
        ].discount_price = newVal;
        this.$store.commit("product/setProductAttributeCombination", {
          attributeCombination: this.localProductAttributeCombination,
        });
      }
    },

    categoryAttributeValue() {
      if (this.dropdownFlagSet) {
        for (let i = 0; i < this.categoryAttributeValue.length; i++) {
          this.selectAttribute[i] = "select value";
          this.hideOtherAttributeSelector[i] = true;
        }

        this.dropdownFlagSet = false;
      }

      for (let i = 0; i < this.categoryAttributeValue.length; i++) {
        this.attributeCombinationStatus[
          this.categoryAttributeValue[i].id_global_attribute
        ] = true;

        this.defaultCombinationStatus[
          this.categoryAttributeValue[i].id_global_attribute
        ] = false;
      }
    },
  },

  methods: {
    ...mapActions("product", ["deleteProductRequest"]),
    ...mapActions("Loader", ["setOverlayStatus"]),

    redirectCreateAttributePage(id) {
      let routeData = this.$router.resolve({
        name: "CreateProductAttribute",
        params: { categoryId: id },
      });
      window.open(routeData.href, "_blank");
    },

    toggleColorAttributeSelector(idGlobalAttribute) {
      if (!this.attributeCombinationStatus[idGlobalAttribute]) return;
      this.hideColorAttributeSelector = !this.hideColorAttributeSelector;
    },

    hideDropdownColorAttributeSelect() {
      this.hideColorAttributeSelector = true;
    },

    toggleOtherAttributeSelector(index, idGlobalAttribute) {
      if (!this.attributeCombinationStatus[idGlobalAttribute]) return;
      this.hideOtherAttributeSelector[index] = !this.hideOtherAttributeSelector[
        index
      ];
    },

    hideOtherAttributeDropwond(index) {
      this.hideOtherAttributeSelector[index] = false;
    },

    colorCodeToName(code) {
      const ntc = require("ntcjs");

      const n_match = ntc.name(code);
      // let n_rgb = n_match[0]; // RGB value of closest match
      // let n_name = n_match[1]; // Text string: Color name
      // let n_exactmatch = n_match[2]; // True if exact color match

      return n_match[1];
    },

    addValueToCombination(
      index,
      idGlobalAttribute,
      idAttributeValue,
      attributeName
    ) {
      let flag = true,
        i = 0;

      for (
        i = 0;
        i < this.localProductAttributeCombination[index]?.attribute?.length;
        i++
      ) {
        if (
          this.localProductAttributeCombination[index].attribute[i]
            .id_global_attribute == idGlobalAttribute
        ) {
          this.localProductAttributeCombination[index].attribute[
            i
          ].id_attribute_value = idAttributeValue;
          this.localProductAttributeCombination[index].attribute[
            i
          ].name = attributeName;

          flag = false;
          break;
        }
      }

      if (flag) {
        this.localProductAttributeCombination[index].attribute.push({
          id_global_attribute: idGlobalAttribute,
          id_attribute_value: idAttributeValue,
          name: attributeName,
        });
      }

      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
    },

    async addNewRow() {
      let flag = await this.checkPreviousValue();
      if (!flag) return;

      this.localProductAttributeCombination.push({
        id_product_attribute: "",
        discount_price: 0,
        price: 0,
        quantity: 0,
        attribute: new Array(),
      });

      this.hideColorAttributeSelector = true;
      let i = 0;
      for (i = 0; i < this.categoryAttributeValue.length; i++) {
        let gol_arr_name = this.categoryAttributeValue[i].global_attribute_name;
        this.selectAttribute[i] = `Select ${gol_arr_name
          .charAt(0)
          .toUpperCase() + gol_arr_name.slice(1)}`;
        this.hideOtherAttributeSelector[i] = true;
      }

      this.selectedColor = "Select Color";
      this.selectAttribute[this.totalRow] = "Select Size";
      this.totalRow = this.totalRow + 1;
      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });

      this.setNewAttributeToNull();
      // this.setExistingPrice(this.productAttributeCombination);
    },

    checkPreviousValue() {
      if (this.totalRow < 1) return true;

      if (
        this.totalRow > 0 &&
        (parseInt(
          this.localProductAttributeCombination[this.totalRow - 1].price
        ) <= 0 ||
          parseInt(
            this.localProductAttributeCombination[this.totalRow - 1]
              .discount_price
          ) < 0 ||
          parseInt(
            this.localProductAttributeCombination[this.totalRow - 1].quantity
          ) <= 0) &&
        this.localProductAttributeCombination[this.totalRow - 1]
          .id_product_attribute == ""
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.insert_all_attribute"),
          background: errorBackground,
        });

        return false;
      } else if (
        this.localProductAttributeCombination[0].attribute.length == 0
      ) {
        Toast.fire({
          icon: "error",
          title: this.$t("product.main.toast.all_att_err"),
          background: errorBackground,
        });
        return false;
      }

      // else if (
      //   this.localProductAttributeCombination[this.totalRow - 1].attribute
      //     .length !== this.categoryAttributeValue.length
      // ) {
      //   Toast.fire({
      //     icon: "error",
      //     title: this.$t("product.main.toast.all_att_err"),
      //     background: errorBackground,
      //   });

      //   return false;
      // } else if (this.totalRow > 0) {
      //   if (
      //     this.localProductAttributeCombination[this.totalRow - 1].attribute
      //       .length !== this.categoryAttributeValue.length
      //   ) {
      //     Toast.fire({
      //       icon: "error",
      //       title: this.$t("product.main.toast.all_att_err"),
      //       background: errorBackground,
      //     });

      //     return false;
      //   }
      // }

      // check if same combination exists
      let existingCombinationLen = 0;

      while (existingCombinationLen < this.totalRow - 1) {
        let attributeListLen = 0,
          matchCount = 0;

        while (
          attributeListLen <
          this.localProductAttributeCombination[existingCombinationLen]
            .attribute.length
        ) {
          let newAttributeIndex = 0;

          while (
            newAttributeIndex <
            this.localProductAttributeCombination[this.totalRow - 1].attribute
              .length
          ) {
            if (
              this.localProductAttributeCombination[existingCombinationLen]
                .attribute[attributeListLen].id_attribute_value ==
              this.localProductAttributeCombination[this.totalRow - 1]
                .attribute[newAttributeIndex].id_attribute_value
            ) {
              matchCount++;
            }
            newAttributeIndex++;
          }
          if (matchCount == 0) break;

          attributeListLen++;
        }

        if (
          matchCount == this.selectedAttributeLen &&
          this.selectedAttributeLen != 0
        ) {
          Toast.fire({
            icon: "error",
            title: this.$t("product.main.toast.unique_att_combination"),
            background: errorBackground,
          });

          return false;
        }
        matchCount = 0;
        existingCombinationLen++;
      }

      if (this.totalRow == 1) {
        this.disableUnselectedAttributes();
      }

      return true;
    },

    disableUnselectedAttributes() {
      for (
        let i = 0;
        i < this.localProductAttributeCombination[0]?.attribute.length;
        i++
      ) {
        this.defaultCombinationStatus[
          this.localProductAttributeCombination[0].attribute[
            i
          ].id_global_attribute
        ] = true;
        this.selectedAttributeLen = this.selectedAttributeLen + 1;
      }

      this.attributeCombinationStatus = {
        ...this.defaultCombinationStatus,
      };
    },

    setPreviousPriceAttribute(event, index) {
      if (
        event.target.value != "" &&
        parseFloat(event.target.value) !=
          parseFloat(event.target.value).toFixed(2)
      ) {
        this.localProductAttributeCombination[index].price = parseFloat(
          event.target.value
        ).toFixed(2);
      } else
        this.localProductAttributeCombination[index].price = event.target.value;

      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
    },

    setPreviousDiscountPriceByAttribute(event, index) {
      let newDiscountPrice = 0;

      if (event.target.value == "") {
        newDiscountPrice = 0;
      } else if (
        event.target.value != "" &&
        parseFloat(event.target.value) !=
          parseFloat(event.target.value).toFixed(2)
      ) {
        newDiscountPrice = parseFloat(event.target.value).toFixed(2);
      } else newDiscountPrice = event.target.value;

      this.localProductAttributeCombination[
        index
      ].discount_price = newDiscountPrice;

      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
    },

    setPreviousQuantityByAttribute(event, index) {
      this.localProductAttributeCombination[index].quantity =
        event.target.value;
      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
    },

    setPriceByAttribute(event, index) {
      if (
        event.target.value != "" &&
        parseFloat(event.target.value) !=
          parseFloat(event.target.value).toFixed(2)
      ) {
        this.price = parseFloat(event.target.value).toFixed(2);
        this.localProductAttributeCombination[index].price = parseFloat(
          event.target.value
        ).toFixed(2);
      } else
        this.localProductAttributeCombination[index].price = event.target.value;

      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
    },

    setDiscountPriceByAttribute(event, index) {
      if (
        event.target.value != "" &&
        parseFloat(event.target.value) !=
          parseFloat(event.target.value).toFixed(2)
      ) {
        this.discountPrice = parseFloat(event.target.value).toFixed(2);
        return;
      }

      this.localProductAttributeCombination[index].discount_price =
        event.target.value;

      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
    },

    setQuantityByAttribute(event, index) {
      this.localProductAttributeCombination[index].quantity =
        event.target.value;
      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
    },

    async deleteColumn(index) {
      if (
        this.localProductAttributeCombination[index].id_product_attribute !=
          "" &&
        this.localProductAttributeCombination[index].id_product_attribute !=
          null
      ) {
        if (this.saved_combinations.length <= 1) {
          Swal.fire({
            title: this.$t("product.main.delete_confirmation_message.title"),
            text: this.$t(
              "product.main.delete_confirmation_message.last_attribute_deleted"
            ),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t(
              "product.main.delete_confirmation_message.delete_btn"
            ),
            cancelButtonText: this.$t(
              "product.main.delete_confirmation_message.cancel_btn"
            ),
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.deleteProductRequest(this.existingProductId);

              // await this.deleteProductAttributeCombination(
              //   this.localProductAttributeCombination[index]
              //     .id_product_attribute
              // );

              this.$router.push({ name: "AllProducts" });
            }
          });

          return;
        }
        //this.$store.commit("setOverlayStatus", true);
        await this.deleteProductAttributeCombination(
          this.localProductAttributeCombination[index].id_product_attribute
        );

        this.removeSavedCombination(
          this.localProductAttributeCombination[index].id_product_attribute
        );
      }

      this.localProductAttributeCombination.splice(index, 1);
      this.totalRow = this.totalRow - 1;

      if (this.totalRow == 1) {
        for (let i = 0; i < this.categoryAttributeValue.length; i++) {
          this.attributeCombinationStatus[
            this.categoryAttributeValue[i].id_global_attribute
          ] = true;

          this.defaultCombinationStatus[
            this.categoryAttributeValue[i].id_global_attribute
          ] = false;
        }
        this.selectedAttributeLen = 0;
      }

      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });

      //this.$store.commit("setOverlayStatus", false);
    },

    clearAttributeCombination() {
      this.totalRow = 0;
      this.localProductAttributeCombination = [];
      this.hideColorAttributeSelector = true;
      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: [],
      });

      this.addNewRow();
    },

    async resetToExistingCategoryCombination() {
      this.localProductAttributeCombination = this.previosProductAttributeCombination;
      this.$store.commit("product/setProductAttributeCombination", {
        attributeCombination: this.localProductAttributeCombination,
      });
      this.totalRow = this.localProductAttributeCombination.length;
      //this.addNewRow();
    },

    attributeValueToSpecificColumn(attribute, index) {
      let i = 0;
      for (
        i = 0;
        i < this.localProductAttributeCombination[index].attribute.length;
        i++
      ) {
        if (
          this.localProductAttributeCombination[index].attribute[i]
            .id_global_attribute == attribute.id_global_attribute
        ) {
          if (index == this.nonEditableRow - 1)
            this.$store.commit("setOverlayStatus", false);

          if (
            this.localProductAttributeCombination[index].attribute[i]
              .global_attribute_name == "color"
          ) {
            let colorName = this.colorCodeToName(
              this.localProductAttributeCombination[index].attribute[i].name
            );
            return {
              colorName,
              attributeValue: this.localProductAttributeCombination[index]
                .attribute[i].name,
            };
          } else {
            return {
              attributeValue: this.localProductAttributeCombination[index]
                .attribute[i].name,
            };
          }
        }
      }
      return "no value";
    },

    setNewAttributeToNull() {
      this.price = "";
      this.discountPrice = "";
      this.quantity = "";
    },

    setExistingPrice(attributeCombination) {
      let i = 0;

      for (i = 0; i < this.totalRow - 1; i++) {
        document.getElementById("existingAttributePrice" + i).value =
          attributeCombination[i].price;
        document.getElementById("existingAttributeDiscountPrice" + i).value =
          attributeCombination[i].discount_price;
        document.getElementById("existingAttributeQuantity" + i).value =
          attributeCombination[i].quantity;
      }
    },

    deleteProductAttributeCombination(idProductAttribute) {
      let vm = this;
      vm.$store.commit("setOverlayStatus", true);

      axios
        .delete(
          `/product_attribute_combi_delete/${this.getLanguage}/` +
            idProductAttribute,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("majhiAccessToken"),
            },
          }
        )
        .then(function(response) {
          vm.$store.commit("setOverlayStatus", false);
          Toast.fire({
            icon: "success",
            title: response.data.message,
            background: successBackground,
          });
        })
        .catch(function(error) {
          vm.$store.commit("setOverlayStatus", false); //loader close
          Toast.fire({
            icon: "error",
            title: vm.$t("product.main.toast.delete_arr_err"),
            background: errorBackground,
          });
          console.log(error);
        });
    },

    checkIsNumber(event) {
      if (
        (event.which != 8 && event.which != 0 && event.which < 48) ||
        event.which > 57
      ) {
        event.preventDefault();
      }
    },

    removeSavedCombination(id) {
      this.saved_combinations = this.saved_combinations.filter(
        (combination) => combination.id_product_attribute !== id
      );
    },
  },

  created() {
    this.saved_combinations = this.existingProduct?.product_attributes_combination;
    // this.addNewRow();
    //this.localProductAttributeCombination = this.previosProductAttributeCombination;
  },
};
</script>

<style>
.attribute-table-holder {
  border: 1px solid black;
  border-radius: 8px;
  min-height: 250px;
  overflow: hidden;
}

.attribute-table-holder table {
  min-width: 800px;
  overflow-x: auto !important;
}

.attribute-table-holder {
  overflow-x: auto !important;
}

.attribute-table-holder::-webkit-scrollbar {
  height: 10px;
}

.attribute-table-holder::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
}

.attribute-table .header {
  background-color: #f3f6f9 !important;
  height: 50px;
  text-align: center;
}

.attribute-table-holder .attribute-table .table-row {
  text-align: center;
}

.attribute-table-holder .attribute-table .table-row td {
  padding: 10px;
  min-width: 150px;
  min-height: 45px;
}

.attribute-table-holder .attribute-table .table-row td select {
  min-height: 45px;
  border-radius: 8px;
  padding: 5px;
  background-color: white;
  min-width: 100px;
}

.attribute-table-holder .attribute-table .table-row td input {
  min-height: 45px;
  border-radius: 8px;
  padding: 5px;
  background-color: white;
  min-width: 150px;
}

.attribute-table-holder .attribute-table .table-row td select option {
  /* font-size: 16px !important; */
  font-size: 1rem !important;
  padding: 10px 0px;
}

.attribute-table-holder .attribute-table .table-row td .delete-attribute {
  width: 40px;
  height: 40px;
  background-color: #e9ecf2;
}

.select-color-dropdown-btn,
.select-option-dropdown-btn {
  width: 150px;
  background-color: white;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.select-color-dropdown-btn.btn-enabled,
.select-option-dropdown-btn.btn-enabled {
  color: black;
}

.select-color-dropdown-btn.btn-disabled,
.select-option-dropdown-btn.btn-disabled {
  color: #bdbdbd;
}

.color-select-dropdown,
.other-attribute-select-dropdown {
  width: 100%;
  z-index: 5;
  background-color: white;
  max-height: 300px;
  max-width: 150px;
  overflow-y: auto;
  margin-top: 55px;
}

.color-select-dropdown .color-code-box,
.select-color-dropdown-btn .color-code-box,
.table-row .color-code-box {
  width: 16px;
  height: 18px;
  border-radius: 5px;
}

.width-200px {
  width: 150px;
}

.table-row .custom-input-field {
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  height: 50px;
  padding: 5px;
}

.table-row .borderless-inp-field {
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.table-row .inp-field {
  height: 50px;
  width: 70%;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
}

.table-row .add-new-attribute-val {
  height: 48px;
  border-radius: 8px;
  /* font-size: 16px; */
  font-size: 1rem;
  border: 1px solid #bdbdbd;
}
</style>
