<template>
  <div class="wrapper">
    <img class="image" :src="imageUrl()" alt="" />
    <div class="info-container">
      <div>
        <p class="store-name">{{ shopName }}</p>
        <p class="store-category">{{ category }}</p>
      </div>

      <div class="contact-info">
        <div class="d-flex align-items-center">
          <img class="icon" :src="loadPhoneLogo()" alt="" />
          <p>{{ phone }}</p>
        </div>
        <div class="d-flex align-items-center">
          <img class="icon" :src="loadEmailLogo()" alt="" />
          <p>{{ email }}</p>
        </div>
        <div class="d-flex align-items-center">
          <img class="icon" :src="loadLocationLogo()" alt="" />
          <p>{{ area }},{{ city }},{{ division }}</p>
        </div>
      </div>

      <div class="logo-container">
        <img :src="store_logo_url" alt="logo" class="logo" />
      </div>

      <div class="footer">
        <p>{{ baseUrl }}/{{ shopName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: "",
      category: "",
      phone: "",
      email: "",
      area: "",
      city: "",
      division: "",
      baseUrl: "www.majhi.com/en",
      store_logo_url: "",
    };
  },

  created() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);

      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  updated() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
    }
  },

  methods: {
    imageUrl() {
      return require("@/assets/marketing-materials/business-card/card-1/BC-11.png");
    },

    loadLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/Group.svg");
    },

    loadPhoneLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/phone.png");
    },

    loadEmailLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/email.png");
    },

    loadLocationLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/location.png");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
}

.info-container {
  position: absolute;
  top: 3px;
  left: 3px;
}

.image {
  width: 100%;
}

.store-name {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1.35px;
  color: #393939;
}

.store-category {
  color: #555555;
  text-transform: uppercase;
  letter-spacing: 0 px;
  font-size: 10px;
  font-weight: 500;
}

.contact-info {
  font-weight: 500;
  margin: 5px 0 30px 0;
  letter-spacing: 1.2px;
  color: #393939;
  text-transform: uppercase;
  font-size: 7px;
}

.logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  left: 200px;
}

.icon {
  width: 13px;
  margin: 1px;
}

.footer {
  position: absolute;
  top: 100px;
  font-size: 10px;
}

@media only screen and (max-width: 1600px) {
  /*Tablets [601px -> 1200px]*/
  .logo {
    top: 30px;
    left: 150px;
  }

  .logo {
    width: 30px;
  }

  .info-container {
    top: 7px;
  }

  .footer {
    top: 90px;
    font-size: 6px;
  }
}
</style>
