<template>
  <div class="card1-container">
    <div class="wrapper">
      <img class="image" :src="imageUrl()" alt="" />
      <div class="info-container">
        <div>
          <p class="store-name">{{ shopName }}</p>
          <p class="store-category">{{ category }}</p>
        </div>

        <div class="contact-info">
          <div class="d-flex align-items-center">
            <img class="icon" :src="loadPhoneLogo()" alt="" />
            <p>{{ phone }}</p>
          </div>
          <div class="d-flex align-items-center">
            <img class="icon" :src="loadEmailLogo()" alt="" />
            <p>{{ email }}</p>
          </div>
          <div class="d-flex align-items-center">
            <img class="icon" :src="loadLocationLogo()" alt="" />
            <p>{{ area }},{{ city }},{{ division }}</p>
          </div>
        </div>

        <div class="logo-container">
          <img :src="store_logo_url" alt="logo" class="logo" />
          <!-- <img
            src="https://c.ndtvimg.com/2020-08/h5mk7js_cat-generic_625x300_28_August_20.jpg"
            alt="logo"
            class="logo"
          /> -->
        </div>

        <div class="footer-container">
          <p>{{ baseUrl }}/{{ shopName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shopName: "",
      category: "",
      phone: "",
      email: "",
      area: "",
      city: "",
      division: "",
      baseUrl: "www.majhi.com/en",
      store_logo_url: "",
    };
  },

  created() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);

      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
      this.store_logo_url = parseData.store_logo;
    }
  },

  updated() {
    let data = localStorage.getItem("majhiUser");
    if (data) {
      let parseData = JSON.parse(data);
      this.shopName = parseData.shop_name;
      this.category = parseData.business_category_name;
      this.phone = parseData.phone;
      this.email = parseData.email;
      this.area = parseData.area_name;
      this.city = parseData.city_name;
      this.division = parseData.division_name;
    }
  },

  methods: {
    imageUrl() {
      return require("@/assets/marketing-materials/business-card/card-1/BC-11.png");
    },

    loadLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/Group.svg");
    },

    loadPhoneLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/phone.png");
    },

    loadEmailLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/email.png");
    },

    loadLocationLogo() {
      return require("@/assets/marketing-materials/business-card/card-1/location.png");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

.card1-container {
  font-size: 10px;
}

.wrapper {
  margin-top: 2em;
  width: 80%;
  margin-left: 10%;
  position: relative;
  font-family: "Open Sans", sans-serif;
}

.info-container {
  position: absolute;
  top: 12em;
  left: 3em;
}

.image {
  width: 100%;
}

.store-name {
  font-size: 3.7em;
  font-weight: 700;
  letter-spacing: 1.35px;
  color: #393939;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
}

.store-category {
  color: #555555;
  text-transform: uppercase;
  letter-spacing: 3.12px;
  font-size: 2px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.contact-info {
  font-weight: 500;
  margin: 2em 0 2em 0;
  letter-spacing: 1.2px;
  color: #393939;
  text-transform: uppercase;
  font-size: 1.7em;
  font-family: "Open Sans", sans-serif;
}

.logo {
  width: 12em;
  height: 12em;
  border-radius: 50%;
}
.logo-container {
  position: absolute;
  top: 5em;
  left: 140%;
}

.icon {
  width: 2em;
  margin: 3px;
}

.footer-container {
  font-size: 2em;
}

@media only screen and (max-width: 1600px) {
  /*Tablets [601px -> 1200px]*/
  .logo-container {
    position: absolute;
    top: 90px;
    left: 115%;
  }

  .logo {
    width: 80px;
    height: 80px;
  }

  .info-container {
    top: 35px;
  }

  .footer-container {
    position: absolute;
    top: 230px;
  }
}

@media only screen and (max-width: 1280px) {
  /*Tablets [601px -> 1200px]*/
  .logo-container {
    position: absolute;
    top: 90px;
    left: 90%;
  }

  .logo {
    width: 60px;
    height: 60px;
  }

  .info-container {
    top: 20px;
  }

  .footer-container {
    position: absolute;
    top: 230px;
  }
}

@media only screen and (max-width: 600px) {
  .card1-container {
    font-size: 10px;
  }
  .wrapper {
    width: 100%;
    margin-left: 0;
  }

  .info-container {
    top: 5em;
    left: 6em;
  }

  .image {
    width: 100%;
  }

  .store-name {
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: 1.35px;
    color: #393939;
  }

  .store-category {
    color: #555555;
    text-transform: uppercase;
    letter-spacing: 0 px;
    font-size: 1em;
    font-weight: 500;
  }

  .contact-info {
    font-weight: 500;
    margin: 0.5em 0 3em 0;
    letter-spacing: 1.2px;
    color: #393939;
    text-transform: uppercase;
    font-size: 1em;
  }

  .logo {
    width: 6em;
    height: 6em;
  }
  .logo-container {
    left: 40em;
  }

  .icon {
    width: 2em;
    margin: 3px;
  }

  .footer-container {
    position: absolute;
    top: 10em;
    font-size: 1.5em;
  }
}
</style>
