<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <side-bar />
      <div class="col px-0 min-vh-100 d-flex flex-column justify-content-start">
        <top-bar />
        <div class="m-0 p-3 catageries-container">
          <div class="mt-3 d-flex align-items-center state-color__fg--grey">
            <img
              src="../../assets/icon/back_btn_image.svg"
              alt="back"
              class="make-cursor"
              @click="
                $router.push({
                  name: 'Manage',
                  params: { languageCode: getLanguage },
                })
              "
            />
            <h4 class="font-weight-500 ms-3 mb-0">
              {{ $t("manage.main.marketing.marketing_materials") }}
            </h4>
          </div>
          <div class="markeing-materials-container">
            <div class="left common-border__radius">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button google_sans_medium"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {{ $t("manage.main.marketing.bus_card") }}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body px-0 pt-4">
                      <div class="ms-3 card-container" id="custom-scrollbar">
                        <card-one-preview @click="controlBsCard(1)" />
                        <card-two-preview @click="controlBsCard(2)" />
                        <card-three-preview @click="controlBsCard(3)" />
                        <card-four-preview @click="controlBsCard(4)" />
                        <card-five-preview @click="controlBsCard(5)" />
                        <card-six-preview @click="controlBsCard(6)" />
                        <card-seven-preview @click="controlBsCard(7)" />
                        <card-eight-preview @click="controlBsCard(8)" />
                        <card-nine-preview @click="controlBsCard(9)" />
                        <card-ten-preview @click="controlBsCard(10)" />
                        <card-eleven-preview @click="controlBsCard(11)" />
                        <card-twelve-preview @click="controlBsCard(12)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button google_sans_medium collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {{ $t("manage.main.marketing.store_banner") }}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="store-banner-container">
                        <StoreBanner
                          :imageName="storeBannerImages.one"
                          @click="changeStoreBanner(storeBannerImages.one)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.two"
                          @click="changeStoreBanner(storeBannerImages.two)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.three"
                          @click="changeStoreBanner(storeBannerImages.three)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.four"
                          @click="changeStoreBanner(storeBannerImages.four)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.five"
                          @click="changeStoreBanner(storeBannerImages.five)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.six"
                          @click="changeStoreBanner(storeBannerImages.six)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.seven"
                          @click="changeStoreBanner(storeBannerImages.seven)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.eight"
                          @click="changeStoreBanner(storeBannerImages.eight)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.nine"
                          @click="changeStoreBanner(storeBannerImages.nine)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.ten"
                          @click="changeStoreBanner(storeBannerImages.ten)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.eleven"
                          @click="changeStoreBanner(storeBannerImages.eleven)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.twelve"
                          @click="changeStoreBanner(storeBannerImages.twelve)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.thirteen"
                          @click="changeStoreBanner(storeBannerImages.thirteen)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.fourteen"
                          @click="changeStoreBanner(storeBannerImages.fourteen)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.fifteen"
                          @click="changeStoreBanner(storeBannerImages.fifteen)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.sixteen"
                          @click="changeStoreBanner(storeBannerImages.sixteen)"
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.seventeen"
                          @click="
                            changeStoreBanner(storeBannerImages.seventeen)
                          "
                        />
                        <StoreBanner
                          :imageName="storeBannerImages.eighteen"
                          @click="changeStoreBanner(storeBannerImages.eighteen)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button google_sans_medium collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {{ $t("manage.main.marketing.fb_ins_stories") }}
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="story-container">
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.one"
                          @click="changeStory(fbInsStory.one)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.two"
                          @click="changeStory(fbInsStory.two)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.three"
                          @click="changeStory(fbInsStory.three)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.four"
                          @click="changeStory(fbInsStory.four)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.five"
                          @click="changeStory(fbInsStory.five)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.six"
                          @click="changeStory(fbInsStory.six)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.seven"
                          @click="changeStory(fbInsStory.seven)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.eight"
                          @click="changeStory(fbInsStory.eight)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.nine"
                          @click="changeStory(fbInsStory.nine)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.ten"
                          @click="changeStory(fbInsStory.ten)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.eleven"
                          @click="changeStory(fbInsStory.eleven)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.twelve"
                          @click="changeStory(fbInsStory.twelve)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.thirteen"
                          @click="changeStory(fbInsStory.thirteen)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.fourteen"
                          @click="changeStory(fbInsStory.fourteen)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.fifteen"
                          @click="changeStory(fbInsStory.fifteen)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.sixteen"
                          @click="changeStory(fbInsStory.sixteen)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.seventeen"
                          @click="changeStory(fbInsStory.seventeen)"
                        />
                        <FacebookInstagramStoryCard
                          :imageName="fbInsStory.eighteen"
                          @click="changeStory(fbInsStory.eighteen)"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed google_sans_medium"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      {{ $t("manage.main.marketing.fb_ins_posts") }}
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div class="post-container">
                        <facebook-instagram-post-card
                          :imageName="fbInsPost.one"
                          @click="changePost(fbInsPost.one)"
                        />
                        <facebook-instagram-post-card
                          :imageName="fbInsPost.two"
                          @click="changePost(fbInsPost.two)"
                        />
                        <facebook-instagram-post-card
                          :imageName="fbInsPost.three"
                          @click="changePost(fbInsPost.three)"
                        />
                        <facebook-instagram-post-card
                          :imageName="fbInsPost.four"
                          @click="changePost(fbInsPost.four)"
                        />
                        <facebook-instagram-post-card
                          :imageName="fbInsPost.five"
                          @click="changePost(fbInsPost.five)"
                        />
                        <facebook-instagram-post-card
                          :imageName="fbInsPost.six"
                          @click="changePost(fbInsPost.six)"
                        />
                        <facebook-instagram-post-card
                          :imageName="fbInsPost.seven"
                          @click="changePost(fbInsPost.seven)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.eight"
                          @click="changePost(fbInsPost.eight)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.nine"
                          @click="changePost(fbInsPost.nine)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.ten"
                          @click="changePost(fbInsPost.ten)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.eleven"
                          @click="changePost(fbInsPost.eleven)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.twelve"
                          @click="changePost(fbInsPost.twelve)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.thirteen"
                          @click="changePost(fbInsPost.thirteen)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.fourteen"
                          @click="changePost(fbInsPost.fourteen)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.fifteen"
                          @click="changePost(fbInsPost.fifteen)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.sixteen"
                          @click="changePost(fbInsPost.sixteen)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.seventeen"
                          @click="changePost(fbInsPost.seventeen)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.eighteen"
                          @click="changePost(fbInsPost.eighteen)"
                        />

                        <facebook-instagram-post-card
                          :imageName="fbInsPost.nineteen"
                          @click="changePost(fbInsPost.nineteen)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right common-border__radius">
              <div class="options-btn">
                <!-- <button
                  type="button"
                  class="btn btn-outline-primary custom-btn me-3"
                  @click="convertImage"
                >
                  Share
                  
                </button> -->
                <button
                  type="button"
                  class="btn btn-primary custom-btn google_sans_medium"
                  @click="printCard"
                >
                  {{ $t("manage.main.marketing.download") }}
                </button>
              </div>
              <div class="selected-card-container-height">
                <div ref="printcontent" id="printArea">
                  <demoSelect v-if="isVisible" />
                  <card-one v-if="isBusCardOpen && currentCard === 1" />
                  <card-two v-if="isBusCardOpen && currentCard === 2" />
                  <card-three v-if="isBusCardOpen && currentCard === 3" />
                  <card-four v-if="isBusCardOpen && currentCard === 4" />
                  <card-five v-if="isBusCardOpen && currentCard === 5" />
                  <card-six v-if="isBusCardOpen && currentCard === 6" />
                  <card-seven v-if="isBusCardOpen && currentCard === 7" />
                  <card-eight v-if="isBusCardOpen && currentCard === 8" />
                  <card-nine v-if="isBusCardOpen && currentCard === 9" />
                  <card-ten v-if="isBusCardOpen && currentCard === 10" />
                  <card-eleven v-if="isBusCardOpen && currentCard === 11" />
                  <card-twelve v-if="isBusCardOpen && currentCard === 12" />

                  <selected-banner
                    v-if="isBannerOpen"
                    :selectedImage="selectedStoreBanner"
                  />
                  <selected-story-card
                    v-if="isStoryOpen"
                    :selectedImage="selectedStory"
                    :style="storyStyle"
                  />
                  <selected-post-card
                    v-if="isPostOpen"
                    :selectedImage="selectedPost"
                    :style="postStyle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-spinner />
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";

import LoadingSpinner from "../../components/LoadingSpinner.vue";

import SideBar from "../../components/menubar/SideBar.vue";
import TopBar from "../../components/menubar/TopBar.vue";

//import SelectedCard from "../../components/marketing-materials/selected-card.vue";
import demoSelect from "../../components/marketing-materials/demoSelect.vue";

// store banner -----------------
import StoreBanner from "../../components/marketing-materials/store-banner/StoreBanner.vue";
import SelectedBanner from "../../components/marketing-materials/SelectedBanner.vue";

// facebook Instagram story
import FacebookInstagramStoryCard from "../../components/marketing-materials/FacebookInstagramStoryCard.vue";
import SelectedStoryCard from "../../components/marketing-materials/SelectedStory.vue";
import storyStyle from "../../js/storyStyle";

// facebook Instagram Post ------------------
import FacebookInstagramPostCard from "../../components/marketing-materials/FacebookInstagramPostCard.vue";
import SelectedPostCard from "../../components/marketing-materials/SelectedPost.vue";
import postStyle from "../../js/postStyle";

// business card
import CardOnePreview from "../../components/marketing-materials/business-card/CardOnePreview.vue";
import CardTwoPreview from "../../components/marketing-materials/business-card/CardTwoPreview.vue";
import CardThreePreview from "../../components/marketing-materials/business-card/CardThreePreview.vue";
import CardFourPreview from "../../components/marketing-materials/business-card/CardFourPreview.vue";
import CardFivePreview from "../../components/marketing-materials/business-card/CardFivePreview.vue";
import CardSixPreview from "../../components/marketing-materials/business-card/CardSixPreview.vue";
import CardSevenPreview from "../../components/marketing-materials/business-card/CardSevenPreview.vue";
import CardEightPreview from "../../components/marketing-materials/business-card/CardEightPreview.vue";
import CardNinePreview from "../../components/marketing-materials/business-card/CardNinePreview.vue";
import CardTenPreview from "../../components/marketing-materials/business-card/CardTenPreview.vue";
import CardElevenPreview from "../../components/marketing-materials/business-card/CardElevenPreview.vue";
import CardTwelvePreview from "../../components/marketing-materials/business-card/CardTwelvePreview.vue";

import CardOne from "../../components/marketing-materials/business-card/CardOne.vue";
import CardTwo from "../../components/marketing-materials/business-card/CardTwo.vue";
import CardThree from "../../components/marketing-materials/business-card/CardThree.vue";
import CardFour from "../../components/marketing-materials/business-card/CardFour.vue";
import CardFive from "../../components/marketing-materials/business-card/CardFive.vue";
import CardSix from "../../components/marketing-materials/business-card/CardSix.vue";
import CardSeven from "../../components/marketing-materials/business-card/CardSeven.vue";
import CardEight from "../../components/marketing-materials/business-card/CardEight.vue";
import CardNine from "../../components/marketing-materials/business-card/CardNine.vue";
import CardTen from "../../components/marketing-materials/business-card/CardTen.vue";
import CardEleven from "../../components/marketing-materials/business-card/CardEleven.vue";
import CardTwelve from "../../components/marketing-materials/business-card/CardTwelve.vue";

export default {
  components: {
    SideBar,
    TopBar,
    LoadingSpinner,
    StoreBanner,
    SelectedBanner,
    FacebookInstagramStoryCard,
    SelectedStoryCard,
    FacebookInstagramPostCard,
    SelectedPostCard,
    demoSelect,
    CardOnePreview,
    CardTwoPreview,
    CardThreePreview,
    CardFourPreview,
    CardFivePreview,
    CardSixPreview,
    CardSevenPreview,
    CardEightPreview,
    CardTenPreview,
    CardNinePreview,
    CardElevenPreview,
    CardTwelvePreview,
    CardOne,
    CardTwo,
    CardThree,
    CardFour,
    CardFive,
    CardSix,
    CardSeven,
    CardEight,
    CardTen,
    CardNine,
    CardEleven,
    CardTwelve,
  },
  data() {
    return {
      selectedImag: "grunge-paint-background.jpg",
      selectedStoreBanner: "SB8.png",
      selectedStory: "S1.png",
      selectedPost: "1.png",

      isBusCardOpen: true,
      isBannerOpen: false,
      isStoryOpen: false,
      isPostOpen: false,
      isVisible: false,
      currentCard: 1,

      storyStyle: "",
      postStyle: "",

      storeBannerImages: {
        one: "SB8.png",
        two: "SB15.png",
        three: "SB4.png",
        four: "SB7.png",
        five: "SB5.png",
        six: "SB9.png",
        seven: "SB11.png",
        eight: "SB16.png",
        nine: "SB23.png",
        ten: "SB24.png",
        eleven: "SB25.png",
        twelve: "SB21.png",
        thirteen: "SB1.png",
        fourteen: "SB4.png",
        fifteen: "SB12.png",
        sixteen: "SB13.png",
        seventeen: "SB14.png",
        eighteen: "SB15.png",
      },

      fbInsStory: {
        one: "S1.png",
        two: "S2.png",
        three: "S3.png",
        four: "S5.png",
        five: "S9.png",
        six: "S24.png",
        seven: "S10.png",
        eight: "S21.png",
        nine: "S22.png",
        ten: "S12.png",
        eleven: "S16.png",
        twelve: "S17.png",
        thirteen: "S18.png",
        fourteen: "S19.png",
        fifteen: "S25.png",
        sixteen: "S26.png",
        seventeen: "S4.png",
        eighteen: "S8.png",
      },

      fbInsPost: {
        one: "1.png",
        two: "2.png",
        three: "3.png",
        four: "4.png",
        five: "5.png",
        six: "6.png",
        seven: "7.png",
        eight: "8.png",
        nine: "9.png",
        ten: "11.png",
        eleven: "12.png",
        twelve: "13.png",
        thirteen: "14.png",
        fourteen: "15.png",
        fifteen: "16.png",
        sixteen: "18.png",
        seventeen: "19.png",
        eighteen: "21.png",
        nineteen: "22.png",
      },
    };
  },

  computed: {
    ...mapGetters("language", ["getLanguage"]),
  },

  methods: {
    changeStoreBanner(data) {
      this.isStoryOpen = false;
      this.isPostOpen = false;
      this.isBusCardOpen = false;
      this.isBannerOpen = true;
      this.selectedStoreBanner = data;
    },

    changeStory(data) {
      this.isBannerOpen = false;
      this.isPostOpen = false;
      this.isBusCardOpen = false;
      this.isStoryOpen = true;
      let filteredData = storyStyle.filter((style) => style.name === data);
      let style = filteredData[0];
      this.selectedStory = data;
      this.storyStyle = style;
    },

    changePost(data) {
      this.isStoryOpen = false;
      this.isBannerOpen = false;
      this.isPostOpen = true;
      this.isBusCardOpen = false;
      let filteredData = postStyle.filter((style) => style.name === data);
      let style = filteredData[0];
      this.postStyle = style;

      this.selectedPost = data;
    },

    changeSelectedCard(name) {
      this.selectedImag = name;
    },

    controlBsCard(data) {
      this.isStoryOpen = false;
      this.isPostOpen = false;
      (this.isBannerOpen = false), (this.isBusCardOpen = true);
      this.currentCard = data;
    },

    async printCard() {
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "download.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },

    printDiv() {
      var printContents = document.getElementById("printArea").innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #707070;
}

.markeing-materials-container {
  display: flex;
  min-height: 500px;
}
.markeing-materials-container .left,
.markeing-materials-container .right {
  background-color: white;
}

.markeing-materials-container .left {
  width: 35%;
  margin: 20px 0;
}

.markeing-materials-container .right {
  width: 65%;
  margin: 20px 20px;
}

.accordion-button {
  font-weight: 500;
  height: 60px;
}

.accordion-button:not(.collapsed) {
  color: #3a86ff !important;
  background-color: white !important;
}

.card-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  max-height: 450px;
  overflow-y: auto;
  cursor: pointer;
}

.store-banner-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  max-height: 450px;
  overflow-y: auto;
  cursor: pointer;
}

.story-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  max-height: 450px;
  overflow-y: auto;
  cursor: pointer;
}

.post-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  max-height: 450px;
  overflow-y: auto;
  cursor: pointer;
}

#custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #f5f5f5;
}

#custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;

  background-color: #272f46;
}

/* right div */

.options-btn {
  max-height: 60px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
}

.options-btn .custom-btn {
  width: 140px;
}

.selected-card-container-height {
  height: 90%;
  /* padding-top: 3%; */

  width: 100%;
}

#printArea {
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  /*Tablets [601px -> 1200px]*/
  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .markeing-materials-container {
    display: flex;
    flex-direction: column;
  }

  .markeing-materials-container .left {
    width: 100%;
    margin: 20px 0;
  }

  .markeing-materials-container .right {
    width: 100%;
    margin: 20px 0;
  }

  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
}
</style>
