<template>
  <div>
    <div
      class="position-absolute bg-white shadow-lg p-2 common-border__radius"
      style="width: 300px; bottom: 100%"
      v-if="colorPickerVisibilityFlag == 1"
      v-click-outside="hideBasicColorPicker"
    >
      <div class="d-flex justify-content-between">
        <p>Select color</p>
        <img
          src="../../assets/icon/cross.svg"
          width="18"
          height="18"
          alt=""
          class="broder-circle make-cursor"
          @click="hideBasicColorPicker()"
        />
      </div>
      <div class="d-flex flex-wrap">
        <div
          v-for="fixColor in fixColors"
          :key="fixColor"
          v-bind:style="{ background: fixColor }"
          class="basic-img m-2 border rounded"
          @click="addBasicColorToList(fixColor)"
        ></div>
        <div
          class="m-2 rounded px-3 py-2 color-picker-btn text-white make-cursor"
          @click="showSketch()"
        >
          Color Picker
        </div>
      </div>
    </div>

    <Sketch
      v-model="colors"
      class="position-absolute"
      style="bottom: 100%"
      :class="colorPickerVisibilityFlag != 2 ? 'display-none' : ''"
    />
  </div>
</template>

<script>
import { Sketch } from "@ckpack/vue-color";
import vClickOutside from "click-outside-vue3";

export default {
  props: ["showColorPicker"],
  components: { Sketch },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data: function() {
    return {
      colors: "#194D33A8",
      colorPickerVisibilityFlag: 2,
      fixColors: [
        "#FEFFFE",
        "#827F8E",
        "#57526F",
        "#343434",
        "#000000",
        "#A90116",
        "#FE8514",
        "#FFBD18",
        "#FFF013",
        "#097F23",
        "#23C203",
        "#1AE397",
        "#ADD321",
        "#10A998",
        "#0045FF",
        "#495DCB",
        "#009DFF",
        "#41D3FF",
        "#00FFFF",
        "#AB18B6",
        "#FF1195",
        "#7F5345",
      ],

      selectedSingleColor: "",
      selectedColors: [],
    };
  },
  watch: {
    showColorPicker(newVal) {
      this.colorPickerVisibilityFlag = newVal;
    },
    selectedSingleColor(newVal) {
      this.selectedColors.push(newVal);
      this.$emit("requestToAddSingleColor", newVal);
    },
  },

  methods: {
    async showSketch() {
      this.colorPickerVisibilityFlag = 2;
      this.$emit("requestUpdateColorPickerFlag", 2);
    },

    hideBasicColorPicker() {
      this.colorPickerVisibilityFlag = 0;
      this.$emit("requestUpdateColorPickerFlag", 0);
    },

    CustomizeSketch() {
      document.getElementsByClassName("vc-sketch-presets")[0].innerHTML = "";
      document.getElementsByClassName("vc-sketch-presets")[0].innerHTML =
        '<button type="button" class="btn btn-outline-success color-picker-ok my-1 mx-1">Select</button><button type="button" class="btn btn btn-outline-danger color-picker-cancel my-1 mx-1">Cancel</button>';
      document
        .getElementsByClassName("color-picker-ok")[0]
        .addEventListener("click", colorPickerSubmit);

      document
        .getElementsByClassName("color-picker-cancel")[0]
        .addEventListener("click", colorPickerCancel);
    },

    addBasicColorToList(colorCode) {
      this.selectedColors.push(colorCode);
      this.$emit("requestToAddSingleColor", colorCode);
    },
  },

  async mounted() {
    window.colorPicker = this;
    await this.CustomizeSketch();
    this.colorPickerVisibilityFlag = 0;
  },
};

function colorPickerSubmit() {
  window.colorPicker.selectedSingleColor = window.colorPicker.colors.hex;
  colorPickerCancel();
}

function colorPickerCancel() {
  window.colorPicker.colorPickerVisibilityFlag = 0;
}
</script>

<style scoped>
.display-none {
  display: none;
}

.color-picker-btn {
  background-image: url("../../assets/icon/colorpicker.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic-img {
  height: 40px;
  width: 40px;
  cursor: pointer;
}
</style>
